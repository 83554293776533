import { BigNumber, _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import type { IToken } from "@sablier/v2-types";
import { C, SD, SI } from "./atoms";

export const flowCreate = {
  title: (t: Translate) => t("machines.createGroup.title"),
  confirm: (t: Translate, large: boolean) => ({
    description: large
      ? `${t("machines.createGroup.confirm")} ${t(
          "machines.createGroup.large",
        )}`
      : t("machines.createGroup.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.createGroup.fail"),
  }),
  prepare: (
    t: Translate,
    dependencies: { count: number; total: string; token: IToken | undefined },
  ) => {
    const plural = dependencies.count > 1 ? "s" : "";

    const label = t("machines.createGroup.prepare", {
      count: dependencies.count,
      plural,
      symbol: dependencies.token?.symbol,
      total: dependencies.total,
    });

    return { label };
  },
  send: (t: Translate, confirm = false, label: string) => ({
    description: SI(
      t,
      t("machines.create.send", {
        created: `${label}. ${C(t, confirm)}`,
      }),
    ),
  }),
  sign: (t: Translate) => ({
    description: t("machines.create.sign"),
  }),
  success: (t: Translate, label: string, ids?: string) => ({
    description: SD(
      t,
      `${t("machines.createGroup.success", { label })}${
        !_.isNil(ids) ? t("structs.newStreamIds", { ids }) : ""
      }`,
    ),
  }),
} as const;

export const flowPause = {
  title: (t: Translate) => t("machines.pause.title"),
  confirm: (t: Translate) => ({
    description: t("machines.pause.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.pause.fail"),
  }),
  send: (t: Translate, confirm = false) => ({
    description: SI(t, t("machines.pause.send", { confirm: C(t, confirm) })),
  }),
  success: (t: Translate) => ({
    description: SD(t, t("machines.pause.success")),
  }),
} as const;

export const flowVoid = {
  title: (t: Translate) => t("machines.void.title"),
  confirm: (t: Translate) => ({
    description: t("machines.void.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.void.fail"),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
  send: (t: Translate, confirm = false, amount = "0") => ({
    description: SI(
      t,
      t("machines.void.send", {
        amount,
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate, amount = "0") => ({
    description: SD(t, t("machines.void.success", { amount })),
  }),
} as const;

export const flowTransfer = {
  title: (t: Translate) => t("machines.transfer.title"),
  confirm: (t: Translate) => ({
    description: t("machines.transfer.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.transfer.fail"),
  }),
  send: (t: Translate, confirm = false, recipient = "unknown") => ({
    description: SI(
      t,
      t("machines.transfer.send", {
        recipient,
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate, recipient = "unknown") => ({
    description: SD(t, t("machines.transfer.success", { recipient })),
  }),
} as const;

export const flowAdjust = {
  title: (t: Translate) => t("machines.adjust.title"),
  confirm: (t: Translate) => ({
    description: t("machines.adjust.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.adjust.fail"),
  }),
  send: (t: Translate, confirm = false, rate = "unknown") => ({
    description: SI(
      t,
      t("machines.adjust.send", {
        rate,
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate, rate = "unknown") => ({
    description: SD(t, t("machines.adjust.success", { rate })),
  }),
} as const;

export const flowRestart = {
  title: (t: Translate) => t("machines.restart.title"),
  confirm: (t: Translate) => ({
    description: t("machines.restart.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.restart.fail"),
  }),
  send: (t: Translate, confirm = false, rate = "unknown") => ({
    description: SI(
      t,
      t("machines.restart.send", {
        rate,
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate, rate = "unknown") => ({
    description: SD(t, t("machines.restart.success", { rate })),
  }),
} as const;

export const flowRefund = {
  title: (t: Translate) => t("machines.refund.title"),
  confirm: (t: Translate) => ({
    description: t("machines.refund.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.refund.fail"),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
  send: (t: Translate, confirm = false, amount = "0") => ({
    description: SI(
      t,
      t("machines.refund.send", {
        amount,
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate, amount = "0") => ({
    description: SD(t, t("machines.refund.success", { amount })),
  }),
} as const;

export const flowWithdraw = {
  title: (t: Translate) => t("machines.withdraw.title"),
  confirm: (t: Translate) => ({
    description: t("machines.withdraw.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.withdraw.fail"),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
  send: (
    t: Translate,
    confirm = false,
    recipient = "unknown",
    amount = "0",
  ) => ({
    description: SI(
      t,
      t("machines.withdraw.send", {
        amount,
        recipient,
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate, recipient = "unknown", amount = "0") => ({
    description: SD(t, t("machines.withdraw.success", { amount, recipient })),
  }),
} as const;
