import Airstream from "./bundle/Airstream";
import Flow from "./bundle/Flow";
import Stream from "./bundle/Stream";
import Account from "./individual/Account";
import Chain from "./individual/Chain";
import Connect from "./individual/Connect";
import Expired from "./individual/Expired";
import General from "./individual/General";
import Legacy from "./individual/Legacy";
import Numeric from "./individual/Numeric";
import Token from "./individual/Token";

const Bundle = {
  Airstream,
  Stream,
  Flow,
};

const Warning = {
  Account,
  Numeric,
  Bundle,
  Chain,
  Connect,
  Expired,
  General,
  Legacy,
  Token,
};

export { Bundle };

export default Warning;
