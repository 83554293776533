import { memo, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";

const WrapperPartial = styled.div`
  z-index: 2;

  &:empty {
    display: none;
  }

  &:not([data-unpadded="true"]) {
    margin-right: 4px;
    margin-left: 4px;
  }
`;

const Normal = styled.p`
  ${(props) => props.theme.styles.textButton};

  & {
    display: inline;
    color: inherit;
    text-align: center;
  }

  ${(props) => props.theme.medias.maxLG} {
    display: none;
  }
`;

const Large = styled(Normal)`
  display: none;

  ${(props) => props.theme.medias.maxLG} {
    display: inline;
  }

  ${(props) => props.theme.medias.maxMD} {
    display: none;
  }
`;

const Medium = styled(Normal)`
  display: none;

  ${(props) => props.theme.medias.maxMD} {
    display: inline;
  }

  ${(props) => props.theme.medias.maxSM} {
    display: none;
  }
`;

const Short = styled(Normal)`
  display: none;

  ${(props) => props.theme.medias.maxSM} {
    display: inline !important;
  }
`;

interface HiddenProps {
  isNormalHidden: boolean;
  isLargeHidden: boolean;
  isMediumHidden: boolean;
  isShortHidden: boolean;
}

const Wrapper = styled(WrapperPartial)<HiddenProps>`
  &[data-mini="true"] {
    ${Normal} {
      ${(props) => props.theme.styles.textButtonMini};
    }
  }

  ${(props) => props.theme.medias.maxXL} {
    ${(props) =>
      props.isNormalHidden &&
      css`
        display: none;
      `}
  }

  ${(props) => props.theme.medias.maxLG} {
    ${(props) =>
      props.isLargeHidden &&
      css`
        display: none;
      `}
  }

  ${(props) => props.theme.medias.maxMD} {
    ${(props) =>
      props.isMediumHidden &&
      css`
        display: none;
      `}
  }

  ${(props) => props.theme.medias.maxSM} {
    ${(props) =>
      props.isShortHidden &&
      css`
        display: none;
      `}
  }
`;

export interface Props {
  className?: string;
  isMini?: boolean;
  isUnpadded?: boolean;
  title?: string | JSX.Element | JSX.Element[]; // default or extra large screens (laptops)
  titleShort?: string | JSX.Element | JSX.Element[]; // empty string will hide the text on small screens
  titleMedium?: string | JSX.Element | JSX.Element[]; // empty string will hide the text on medium screens
  titleLarge?: string | JSX.Element | JSX.Element[]; // empty string will hide the text on large screens
}

function Text({
  className,
  isMini = false,
  isUnpadded = false,
  title,
  titleLarge = undefined,
  titleMedium = undefined,
  titleShort = undefined,
}: Props) {
  const renderTitleSizes = useCallback(() => {
    const normal = !_.isNil(title) ? <Normal>{title}</Normal> : false;

    const valueL = !_.isNil(titleLarge) ? titleLarge : title;
    const large = !_.isNilOrEmptyString(valueL) ? (
      <Large>{valueL}</Large>
    ) : (
      false
    );

    const valueM = !_.isNil(titleMedium) ? titleMedium : title;
    const medium = !_.isNilOrEmptyString(valueM) ? (
      <Medium>{valueM}</Medium>
    ) : (
      false
    );

    const valueS = !_.isNil(titleShort) ? titleShort : valueM;
    const short = !_.isNilOrEmptyString(valueS) ? (
      <Short>{valueS}</Short>
    ) : (
      false
    );

    return (
      <>
        {normal}
        {large}
        {medium}
        {short}
      </>
    );
  }, [title, titleLarge, titleMedium, titleShort]);

  const isEmpty = useMemo(() => {
    return [title, titleLarge, titleMedium, titleShort].every((t) =>
      _.isNilOrEmptyString(t),
    );
  }, [title, titleMedium, titleShort, titleLarge]);

  const isNormalHidden = useMemo(() => title === "", [title]);
  const isLargeHidden = useMemo(() => titleLarge === "", [titleLarge]);
  const isMediumHidden = useMemo(() => titleMedium === "", [titleMedium]);
  const isShortHidden = useMemo(() => titleShort === "", [titleShort]);

  if (isEmpty) {
    return false;
  }

  return (
    <Wrapper
      className={className}
      data-component={"title"}
      data-unpadded={isUnpadded}
      data-mini={isMini}
      isNormalHidden={isNormalHidden}
      isLargeHidden={isLargeHidden}
      isMediumHidden={isMediumHidden}
      isShortHidden={isShortHidden}
    >
      {renderTitleSizes()}
    </Wrapper>
  );
}

export default memo(Text);
