import { LockupShapes, links } from "@sablier/v2-constants";
import type { IExtensionConstructorParams } from "../../system/setup";
import { Extension } from "../../system/setup";

export interface ILinear {
  purpose: Extension;
}

export function initial(_params: IExtensionConstructorParams): ILinear {
  return { purpose: Extension.LINEAR };
}

export const shape = LockupShapes.linear.id;
export const template = links.streamTemplate.linear;
