import { useMemo } from "react";
import {
  CalendarIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import TokenUnknown from "@sablier/v2-assets/tokens/custom-unknown-light.svg";
import { useChainData } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { client } from "@sablier/v2-subgraphs";
import { vendors } from "@sablier/v2-utils";
import { pages, tabs } from "~/client/constants";
import type { AirstreamList } from "@sablier/v2-components/organisms";
import type { ComponentProps } from "react";
import useAccount from "./useAccount";
import useAirstreamDashboardSearch from "./useAirstreamDashboardSearch";
import useAirstreamDashboardTab from "./useAirstreamDashboardTab";
import useAirstreamsEligible from "./useAirstreamsEligible";
import useAirstreamsOwned from "./useAirstreamsOwned";
import useFlags from "./useFlags";
import useTokens from "./useTokens";

function useData() {
  const { t } = useT();
  const { address } = useAccount();
  const { tab } = useAirstreamDashboardTab();

  const owned = useAirstreamsOwned();
  const searched = useAirstreamDashboardSearch();
  const eligibility = useAirstreamsEligible();

  const { isWaitingInitialAirstreams, isWaitingConfigurations } = useFlags();
  const isSearch = useMemo(() => {
    if (_.isNil(tab)) {
      return false;
    }

    return tab.identifier === tabs.drops.search.identifier;
  }, [tab]);

  const isEligibility = useMemo(() => {
    if (_.isNil(tab)) {
      return false;
    }

    return tab.identifier === tabs.drops.eligible.identifier;
  }, [tab]);

  const doMore = useMemo(() => {
    if (isSearch) {
      if (
        !searched.result?.options.isComplete &&
        searched.result?.airstreams.length
      ) {
        return searched.doMore;
      }
    } else if (isEligibility) {
      if (
        !eligibility.result?.options.isComplete &&
        eligibility.result?.airstreams.length
      ) {
        return eligibility.doMore;
      }
    } else {
      if (
        !owned.result?.options.isComplete &&
        owned.result?.airstreams.length
      ) {
        return owned.doMore;
      }
    }

    return undefined;
  }, [isSearch, isEligibility, owned, searched, eligibility]);

  const isLoading = useMemo(() => {
    if (isWaitingInitialAirstreams) {
      return true;
    }

    if (isWaitingConfigurations) {
      return true;
    }

    if (isSearch) {
      return searched.isLoading;
    }

    if (isEligibility) {
      return eligibility.isLoading;
    }

    return owned.isLoading;
  }, [
    isSearch,
    isEligibility,
    eligibility,
    owned,
    searched,
    isWaitingInitialAirstreams,
    isWaitingConfigurations,
  ]);

  const error = useMemo(() => {
    return isSearch
      ? searched.error
      : isEligibility
      ? eligibility.error
      : owned.error;
  }, [isSearch, isEligibility, eligibility, owned, searched]);

  const list = useMemo(() => {
    const result = isSearch
      ? searched.result
      : isEligibility
      ? eligibility.result
      : owned.result;

    if (_.isNil(result)) {
      return [];
    }

    return result.airstreams;
  }, [isSearch, isEligibility, eligibility, owned, searched]);

  const airstreams = useMemo(() => {
    switch (tab?.identifier) {
      case tabs.drops.search.identifier:
      case tabs.drops.eligible.identifier:
        return list;
      case tabs.drops.created.identifier:
        /**
         * Show streams where the connected account is the admin
         */
        return list.filter((airstream) => airstream.admin === address);
      default:
        return [];
    }
  }, [address, list, tab]);

  const isEmpty = useMemo(
    () => !isLoading && airstreams.length === 0,
    [isLoading, airstreams],
  );

  const options = useMemo(() => {
    const issue = client.issue(error, t);

    switch (tab?.identifier) {
      case tabs.drops.created.identifier:
        return { empty: "client-creator" as const, error: issue };
      case tabs.drops.eligible.identifier:
        return { empty: "client-eligible" as const, error: issue };
      default:
        return { empty: undefined, error: issue };
    }
  }, [error, t, tab]);

  return {
    doMore,
    error,
    isAnimated: false,
    isEmpty,
    isLoading,
    isSearch,
    options,
    airstreams,
  };
}

type Data = ComponentProps<typeof AirstreamList>;

export default function useAirstreamDashboardList(): Data {
  const { airstreams, options, doMore, isEmpty, isAnimated, isLoading } =
    useData();
  const { t } = useT();
  const { find: findToken } = useTokens(false);
  const { find: findChain } = useChainData();

  const items = useMemo(
    (): Data["items"] =>
      airstreams.map((airstream) => {
        const chain = findChain(airstream.chainId);
        const token = findToken({ token: airstream.token });
        const preview = airstream.findPreview(t);

        return {
          identifier: airstream.id,
          action: {
            purpose: "internal",
            to: pages.drops.profile.builder(airstream.id),
          },
          admin: airstream.admin,
          chainId: airstream.chainId,
          token,
          details: [
            {
              left: chain?.image,
              leftPurpose: "image",
              title: "",
              tooltip: {
                value: chain?.name,
              },
            },
            ...(airstream.expires && airstream.expired
              ? [
                  {
                    title: _.capitalize(t("words.expired")),
                    accent: "warning300" as const,
                    tooltip: {
                      value: t("descriptions.airstream.eligibility.expired"),
                    },
                  },
                ]
              : []),
            {
              left: UserIcon,
              title: `${_.toNumeralPrice(
                airstream.claimedCount,
              )} / ${_.toNumeralPrice(airstream.totalRecipients)}`,
              tooltip: {
                value: _.capitalize(t("words.recipients")),
              },
            },
            {
              left: token?.image ?? TokenUnknown,
              leftPurpose: "image",
              title: `${_.capitalize(t("words.claimed"))}: ${
                preview.pairAmount
              }`,
            },

            {
              left: airstream.address,
              leftPurpose: "identicon",
              title: `${_.capitalize(t("words.contract"))}:  ${_.toShortAddress(
                airstream.address,
              )}`,
            },

            {
              left: airstream.expires ? XCircleIcon : undefined,
              title: preview.expiresIn,
              tooltip: {
                value: preview.expiresInLabel,
              },
            },

            {
              left: CalendarIcon,
              title: `${_.capitalize(t("words.created"))}: ${
                _.toDuration(airstream.timestamp, "date-only")[0]
              }`,
            },
          ],
          progress: {
            percentage: airstream.claimedCountPercentage.toNumber(),
          },
          title: preview.title,
        };
      }),
    [airstreams, findChain, findToken, t],
  );

  const instructions = useMemo(() => {
    const actions: Data["instructions"] = {};

    if (_.isFunction(doMore)) {
      actions.onMore = (_event) => {
        try {
          doMore();
        } catch (error) {
          vendors.crash.log(error);
        }
      };
    }

    return actions;
  }, [doMore]);

  return { items, options, isEmpty, isAnimated, isLoading, instructions };
}
