import { LockupShapes, links } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type {
  IExtensionConstructorParams,
  IExtensionHeaders,
} from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import type {
  IExtensionExclude,
  IExtensionOverridesGeneral,
  IExtensionOverridesIndividual,
} from "~/client/types";
import { Extension } from "../../system/setup";

export interface ITranchedMonthly {
  purpose: Extension;
  /** Sourcing `end` from the base form */
  /** Sourcing `start` from the base form */
  months: {
    id: string;
    value?: string;
    warning?: string;
  };
  initial: {
    id: string;
    value?: boolean;
  };
}

export const excludes: IExtensionExclude[] = ["duration", "end", "timing"];

export function initial({
  months,
}: IExtensionConstructorParams): ITranchedMonthly {
  return {
    purpose: Extension.TRANCHED_MONTHLY,
    months: {
      id: "months",
      value: months,
      warning: undefined,
    },
    initial: {
      id: "initial",
      value: false,
    },
  };
}

/**
 * Override the duration and lock it to "false".
 * This will force the validation steps to look at the range (start / end).
 */

export const overrides = {
  general: (): IExtensionOverridesGeneral | undefined => {
    return {
      duration: {
        isActive: false,
      },
      isDuration: {
        value: false,
      },
    };
  },
  individual: (): IExtensionOverridesIndividual | undefined => {
    return {
      duration: {
        isActive: false,
      },
    };
  },
};

export function headers(
  _isDuration: boolean,
): IExtensionHeaders<keyof ITranchedMonthly> {
  return [{ header: "months", type: "integer" }];
}

export function warnings(extension: unknown): string[] {
  const fields = extension as ITranchedMonthly;
  const warnings: string[] = [];
  if (!_.isNilOrEmptyString(fields.months.warning)) {
    warnings.push(`Months: ${fields.months.warning}`);
  }
  return warnings;
}

export const UNLOCK_DURATION: IMilliseconds = "1000";

export const shape = LockupShapes.tranchedMonthly.id;
export const template = links.streamTemplate.tranchedMonthly;
