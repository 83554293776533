import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import useChainData from "./useChainData";

interface Props {
  chainId: number | undefined;
  type?: "address" | "nft" | "tx" | "token";
  hash?: string;
  tokenId?: number | string;
}

export default function useChainExplorer({
  chainId,
  type = "address",
  hash,
  tokenId,
}: Props) {
  const { chain } = useChainData(chainId);

  return useMemo(() => {
    if (
      !chain ||
      _.isNilOrEmptyString(hash) ||
      _.isNilOrEmptyString(chain.explorer)
    ) {
      return "";
    }

    const result = chain.explorer
      .concat("/")
      .concat(type)
      .concat("/")
      .concat(hash);
    if (type === "token" && !_.isNilOrEmptyString(tokenId)) {
      return result.concat("?a=").concat(_.toString(tokenId));
    }
    if (type === "nft" && !_.isNilOrEmptyString(tokenId)) {
      return result.concat("/").concat(_.toString(tokenId));
    }
    return result;
  }, [chain, hash, tokenId, type]);
}
