import { LockupShapes, links } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type {
  IExtensionConstructorParams,
  IExtensionHeaders,
} from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import { Extension } from "../../system/setup";

export interface ITranchedStepper {
  purpose: Extension;
  steps: {
    id: string;
    value?: string;
    warning?: string;
  };
}

export function initial({
  steps,
}: IExtensionConstructorParams): ITranchedStepper {
  return {
    purpose: Extension.TRANCHED_STEPPER,
    steps: {
      id: "steps",
      value: steps,
      warning: undefined,
    },
  };
}

export function headers(
  _isDuration: boolean,
): IExtensionHeaders<keyof ITranchedStepper> {
  return [{ header: "steps", type: "integer" }];
}

export function warnings(extension: unknown): string[] {
  const fields = extension as ITranchedStepper;
  const warnings: string[] = [];
  if (!_.isNilOrEmptyString(fields.steps.warning)) {
    warnings.push(`Steps: ${fields.steps.warning}`);
  }

  return warnings;
}

export const UNLOCK_DURATION: IMilliseconds = "1000";

export const shape = LockupShapes.tranchedStepper.id;
export const template = links.streamTemplate.tranchedStepper;
