import { useMemo } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { useRequestAirstreamOnchain } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { Airstream } from "@sablier/v2-models";
import useAirstreamCurrent from "./useAirstreamCurrent";

export default function useAirstreamCurrentOnchain() {
  const { id, isLoading, isMissing, title } = useAirstreamCurrent();

  const { address, chainId } = useMemo(
    () => Airstream.doSplitIdentifier(id),
    [id],
  );

  const isEnabled = useMemo(
    () => !_.isNilOrEmptyString(id) && isMissing,
    [id, isMissing],
  );

  const {
    data,
    error,
    isLoading: isLoadingOnchain,
    isMissing: isMissingOnchain,
  } = useRequestAirstreamOnchain({
    key: REQUEST_ID.lockupOnchain,
    id,
    isEnabled,
  });

  return {
    address,
    chainId,
    data,
    error,
    isLoading: isLoading || isLoadingOnchain,
    isMissing: isMissingOnchain,
    title,
  };
}
