import { BigNumber, _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import type { IToken } from "@sablier/v2-types";
import { C, SD, SI } from "./atoms";

export const airstreamClawback = {
  title: (t: Translate) => t("machines.airstreamClawback.title"),
  confirm: (t: Translate) => ({
    description: t("machines.airstreamClawback.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.airstreamClawback.fail"),
  }),
  success: (t: Translate, recipient = "unknown", amount = "0") => ({
    description: SD(
      t,
      t("machines.airstreamClawback.success", { amount, recipient }),
    ),
  }),
  send: (
    t: Translate,
    confirm = false,
    recipient = "unknown",
    amount = "0",
  ) => ({
    description: SI(
      t,
      t("machines.airstreamClawback.send", {
        amount,
        recipient,
        confirm: C(t, confirm),
      }),
    ),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
} as const;

export const airstreamCreate = {
  title: (t: Translate) => t("machines.airstreamCreate.title"),
  confirm: (t: Translate) => ({
    description: t("machines.airstreamCreate.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.airstreamCreate.fail"),
  }),
  success: (
    t: Translate,
    name: string,
    amount: string,
    recipients: string,
  ) => ({
    description: SD(
      t,
      t("machines.airstreamCreate.success", { name, amount, recipients }),
    ),
  }),
  send: (
    t: Translate,
    confirm = false,
    amount: string,
    recipients: string,
    name: string,
  ) => ({
    description: SI(
      t,
      t("machines.airstreamCreate.send", {
        amount,
        recipients,
        confirm: C(t, confirm),
        name,
      }),
    ),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
} as const;

export const airstreamEligibility = {
  title: (t: Translate) => t("machines.airstreamEligibility.title"),
  confirm: (t: Translate) => ({
    description: t("machines.airstreamEligibility.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.airstreamEligibility.fail"),
  }),
  success: (
    t: Translate,
    recipient = "unknown",
    amount = "0",
    duration: string,
  ) => ({
    description: SD(
      t,
      t("machines.airstreamEligibility.success", {
        amount,
        duration,
        recipient,
      }),
    ),
  }),
  send: (
    t: Translate,
    confirm = false,
    recipient = "unknown",
    amount = "0",
    duration: string,
  ) => ({
    description: SI(
      t,
      t("machines.airstreamEligibility.send", {
        amount,
        duration,
        recipient,
        confirm: C(t, confirm),
      }),
    ),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
} as const;

export const airstreamFund = {
  title: (t: Translate) => t("machines.airstreamFund.title"),
  confirm: (t: Translate) => ({
    description: t("machines.airstreamFund.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.airstreamFund.fail"),
  }),
  success: (t: Translate, campaign: string, amount: string) => ({
    description: SD(
      t,
      t("machines.airstreamFund.success", { campaign, amount }),
    ),
  }),
  send: (t: Translate, confirm = false, campaign: string, amount = "0") => ({
    description: SI(
      t,
      t("machines.airstreamFund.send", {
        amount,
        campaign,
        confirm: C(t, confirm),
      }),
    ),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
} as const;
