import { framework } from "@sablier/v2-contracts";
import { Translate } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { FlowCategory, LockupCategory } from "@sablier/v2-constants";
import type { Output } from "@sablier/v2-contracts";
import type {
  IAddress,
  IMilliseconds,
  ISigner,
  IToast,
  IToken,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import {
  validateAddress,
  validateNotAtRisk,
  validateNotSanctioned,
  validateNotToken,
} from "./address";
import { validateAmount } from "./amount";
import { validateMoment } from "./date";
import { interpret } from "./interpret";
import { validateSigner } from "./signer";
import { validateToken } from "./token";

type Query = ReturnType<typeof framework.contextualize>;

type Input =
  | {
      purpose: "end";
      options: {
        /** The guard will handle a default excess (~ 30 mins) such that durations aren't very close to the max */
        isExcess?: boolean;
        /** In strict mode, max and min will be used unreachable (< and > instead of <= and >= ) */
        isStrict?: boolean;
        value?: IMilliseconds;
      };
    }
  | {
      purpose: "recipient";
      options: { blacklist?: IAddress[]; value: IAddress | undefined };
    }
  | {
      purpose: "resolution";
      options: { resolved?: IAddress; value: string | undefined };
    }
  | {
      purpose: "signer";
      options: {
        blacklist?: IAddress[];
        chainId?: number;
        expected?: IAddress[];
        value: ISigner | undefined;
      };
    }
  | {
      purpose: "screening";
      options: {
        addresses: IAddress[];
        chainId?: number;
      };
    }
  | {
      purpose: "token";
      options: {
        owner: IAddress;
        spender: IAddress;
        token: IToken;
        /** Humanized amount */
        requested: IAddress | undefined;
      };
    }
  | {
      purpose: "withdrawable";
      options: {
        contract: IAddress;
        id: IAddress | undefined;
        purpose: LockupCategory | FlowCategory;
        /** Humanized amount */
        requested?: IAddress | undefined;
        slippage?: number;
        token: IToken;
      };
    }
  | {
      purpose: "refundable";
      options: {
        contract: IAddress;
        id: IAddress | undefined;
        purpose: FlowCategory;
        /** Humanized amount */
        requested?: IAddress | undefined;
        slippage?: number;
        token: IToken;
      };
    };

type Results = Partial<Record<Input["purpose"], unknown>>;

export async function validateInputs(
  library: IWagmiConfig,
  t: Translate,
  inputs: Input[],
  chainId: number,
  api?: { toast?: { add: (params: IToast) => void } },
) {
  const dependencies: Partial<Record<Input["purpose"], Query | undefined>> = {};

  for (let i = 0; i < inputs.length; i++) {
    const { purpose, options } = inputs[i];

    switch (purpose) {
      case "recipient":
      case "signer":
      case "screening":
        break;
      case "token": {
        dependencies["token"] = framework.contextualize(
          options.token.address,
          chainId,
          "token",
          "allowance",
          [options.owner as IWagmiAddress, options.spender as IWagmiAddress],
        );
        break;
      }
      case "withdrawable": {
        dependencies["withdrawable"] = framework.contextualize(
          options.contract,
          chainId,
          options.purpose,
          "withdrawableAmountOf",
          [_.toBigInt(options.id)],
        );
        break;
      }
      case "refundable": {
        dependencies["refundable"] = framework.contextualize(
          options.contract,
          chainId,
          options.purpose,
          "refundableAmountOf",
          [_.toBigInt(options.id)],
        );
        break;
      }
      default:
        break;
    }
  }

  const keys = Object.keys(dependencies) as Array<Input["purpose"]>;
  const previews = await framework.preview({
    queries: Object.values(dependencies),
  });
  const multicall = await framework.read(library, { previews });

  const results = keys.reduce((prev, current, index) => {
    prev[current] = multicall[index].result;
    return prev;
  }, {} as Results);

  for (let i = 0; i < inputs.length; i++) {
    const { purpose, options } = inputs[i];

    switch (purpose) {
      case "end": {
        await interpret(
          async () =>
            validateMoment({
              purpose: "datetoday",
              t,
              isStrict: options.isStrict,
              isExcess: options.isExcess,
              value: options.value,
              min: new Date().valueOf().toString(),
            }),
          api,
        );
        break;
      }
      case "signer": {
        await interpret(
          async () =>
            validateSigner({
              t,
              blacklist: options.blacklist,
              expected: options.expected,
              chainId: options.chainId,
              value: options.value,
            }),
          api,
        );
        break;
      }
      case "screening": {
        await interpret(
          async () =>
            validateNotAtRisk({
              t,
              chainId: options.chainId,
              addresses: options.addresses,
            }),
          api,
        );
        break;
      }
      case "recipient": {
        await interpret(
          () =>
            validateNotSanctioned({
              address: options.value,
              library,
              chainId,
              t,
            }),
          api,
        );

        await interpret(
          () =>
            validateNotToken({
              address: options.value,
              chainId,
              library,
              t,
            }),
          api,
        );

        await interpret(
          () =>
            validateAddress({
              t,
              blacklist: options.blacklist,
              value: options.value,
            }),
          api,
        );
        break;
      }
      case "resolution": {
        await interpret(
          () =>
            validateAddress({
              t,
              whitelist: [options.resolved || "NONE"],
              value: options.value,
            }),
          api,
        );
        break;
      }
      case "token": {
        const output = results["token"] as Output<"token", "allowance">;
        const allowance = _.toValue({
          decimals: options.token.decimals,
          raw: new BigNumber(output.toString()),
        });

        await interpret(
          () =>
            validateAmount({
              t,
              context: "allowance",
              value: options.requested,
              max: allowance.humanized.toString(),
            }),
          api,
        );

        await interpret(
          () =>
            validateToken({
              t,
              value: options.token,
            }),
          api,
        );
        break;
      }
      case "withdrawable": {
        const output = results["withdrawable"] as Output<
          typeof options.purpose,
          "withdrawableAmountOf"
        >;

        const withdrawable = _.toValue({
          decimals: options.token.decimals,
          raw: new BigNumber(output.toString()),
        });

        const max = _.toValue({
          decimals: options.token.decimals,
          humanized: withdrawable.humanized
            .times(new BigNumber(100).plus(options.slippage || 0))
            .dividedBy(100),
        });

        await interpret(
          () =>
            validateAmount({
              t,
              context: "stream",
              value: options.requested,
              max: max.humanized.toString(),
              min: "0",
            }),
          api,
        );
        break;
      }
      case "refundable": {
        const output = results["refundable"] as Output<
          typeof options.purpose,
          "refundableAmountOf"
        >;

        const refundable = _.toValue({
          decimals: options.token.decimals,
          raw: new BigNumber(output.toString()),
        });

        const max = _.toValue({
          decimals: options.token.decimals,
          humanized: refundable.humanized
            .times(new BigNumber(100).plus(options.slippage || 0))
            .dividedBy(100),
        });

        await interpret(
          () =>
            validateAmount({
              t,
              context: "stream",
              value: options.requested,
              max: max.humanized.toString(),
              min: "0",
            }),
          api,
        );
        break;
      }
      default:
        break;
    }
  }
  return results;
}
