import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { queries } from "@sablier/v2-subgraphs";
import type { IFilterFlow } from "@sablier/v2-models";
import type { ISearchOperator } from "@sablier/v2-types";

export default function useRequestFlowListQuery(
  filter: IFilterFlow,
  operator: ISearchOperator = "AND",
) {
  const isIds = useMemo(
    () =>
      !_.isNil(filter.streamIds) &&
      !filter.streamIds.find((id) => _.isNilOrEmptyString(id)),
    [filter],
  );
  const isRecipient = useMemo(
    () => !_.isNilOrEmptyString(filter.recipient),
    [filter],
  );
  const isSender = useMemo(
    () => !_.isNilOrEmptyString(filter.sender),
    [filter],
  );

  const isToken = useMemo(() => !_.isNilOrEmptyString(filter.token), [filter]);

  return useMemo(() => {
    if (operator === "OR") {
      if (isSender && isRecipient && isToken) {
        return queries.flow.getStreams_BySender_Or_ByRecipient_Or_ByToken;
      }
      if (isSender && isRecipient) {
        return queries.flow.getStreams_BySender_Or_ByRecipient;
      }
    }

    if (isSender && isRecipient && isIds && isToken) {
      return queries.flow.getStreams_BySenderByRecipientByIdsByToken;
    }

    if (isSender && isRecipient && isIds) {
      return queries.flow.getStreams_BySenderByRecipientByIds;
    }

    if (isSender && isRecipient && isToken) {
      return queries.flow.getStreams_BySenderByRecipientByToken;
    }

    if (isSender && isIds && isToken) {
      return queries.flow.getStreams_BySenderByIdsByToken;
    }

    if (isRecipient && isIds && isToken) {
      return queries.flow.getStreams_ByRecipientByIdsByToken;
    }

    if (isSender && isRecipient) {
      return queries.flow.getStreams_BySenderByRecipient;
    }

    if (isSender && isIds) {
      return queries.flow.getStreams_BySenderByIds;
    }

    if (isRecipient && isIds) {
      return queries.flow.getStreams_ByRecipientByIds;
    }

    if (isSender && isToken) {
      return queries.flow.getStreams_BySenderByToken;
    }

    if (isRecipient && isToken) {
      return queries.flow.getStreams_ByRecipientByToken;
    }

    if (isIds && isToken) {
      return queries.flow.getStreams_ByIdsByToken;
    }

    if (isIds) {
      return queries.flow.getStreams_ByIds;
    }

    if (isRecipient) {
      return queries.flow.getStreams_ByRecipient;
    }

    if (isSender) {
      return queries.flow.getStreams_BySender;
    }

    if (isToken) {
      return queries.flow.getStreams_ByToken;
    }

    return queries.flow.getStreams;
  }, [isIds, isRecipient, isSender, isToken, operator]);
}
