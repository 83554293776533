import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FLOWS_PAGE_SIZE,
  MAX_SUBGRAPH_ID,
  REQUEST_ID,
} from "@sablier/v2-constants";
import { useRequestFlowList } from "@sablier/v2-hooks";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { IFilterFlow, ISearchFlow } from "@sablier/v2-models";
import { supported } from "../constants";
import { useFlowStoreAccessor, useFlowStoreSearch } from "./store/useFlowStore";

const OPTIONS = {
  after: MAX_SUBGRAPH_ID,
  first: FLOWS_PAGE_SIZE,
  operator: "AND",
} as const; // TODO merge with Lockup Search

export default function useFlowSearch(
  filter: IFilterFlow,
  isReadyExternal?: boolean,
) {
  const { search: result, set } = useFlowStoreSearch();
  const access = useFlowStoreAccessor();

  const [options, setOptions] = useState<typeof OPTIONS>(OPTIONS);

  const isEnabled = useMemo(() => {
    if (!supported.find((chain) => chain.id !== filter.chainId)) {
      return false;
    }

    if (
      _.isNilOrEmptyString(filter.chainId) &&
      _.isNilOrEmptyString(filter.recipient) &&
      _.isNilOrEmptyString(filter.sender) &&
      _.isNilOrEmptyString(filter.token) &&
      (_.isNil(filter.streamIds) || filter.streamIds.length === 0)
    ) {
      return false;
    }

    return isReadyExternal && !result?.options.isComplete;
  }, [filter, result, isReadyExternal]);

  const onSuccess = useCallback(
    (_result: ISearchFlow) => {
      const result = _.clone(_result);
      const state = access();
      const set = state.api.setSearch;
      const search = state.search;

      const info = (result: unknown, label = "new") =>
        console.info("%c[search flows]", "color: cadetblue", `[${label}]`, {
          result,
        });

      /** If there are no results, you can set initial ones care-free */
      if (_.isNil(search)) {
        set(result);
        info(result);
      } else {
        /** If filters changed, it means we requested for a new set of flows. Override based on these new rules. */
        if (!_.isEqual(search.filter, result.filter)) {
          set(result);
          info(result);
        } else if (!_.isEqual(search.options, result.options)) {
          /**
           * Edge-case: [Search set#1, Click on more for set#2, Go to a Flow's Profile, Come Back]
           * Upon coming back, this request will ask for set#1 again. Due to the rules of this branch,
           * the results would get appended again and again. The condition below makes sure the search
           * isn't resumed from somewhere else.
           */
          if (
            !_.isNil(search.options.after) &&
            !_.isNil(result.options.after) &&
            new BigNumber(search.options.after).isLessThanOrEqualTo(
              new BigNumber(result.options.after),
            )
          ) {
            return;
          }

          /** If filters remain the same, options may have changed (e.g. pagination requested followup items). Merge results. */
          const value = _.clone(search);
          value.options = result.options;
          value.streams = _.unionBy(
            [...value.streams, ...result.streams],
            (i) => i.id,
          );
          set(value);
          info(value.streams, "more");
        }
      }
    },
    [access],
  );

  const onError = useCallback(
    (_error: unknown, result: ISearchFlow) => {
      set(result);
    },
    [set],
  );

  const onJump = useCallback(
    (result: { streams: { id: string }[] }) => {
      /**
       * An empty response can also be caused by a faulty indexer.
       * To account for such cases, re-run query with fallback when there are 0 results.
       */
      if (result.streams?.length === 0) {
        return true;
      }

      /**
       * When looking for flows by id, if the results are less than expected try again.
       * The indexer may be slow to find certain flows.
       */
      if (filter.streamIds) {
        /** Normalize the requested flows ids */
        const a = filter.streamIds
          .slice(0, options.first)
          .map((i) => i.toLowerCase());
        /** Extract the received flows ids */
        const b = result.streams
          .map((result) => result.id)
          .slice(0, options.first)
          .map((i) => i.toLowerCase());
        /** Look for requested ids not received */
        const difference = _.difference(a, b);

        return difference.length !== 0;
      }

      return false;
    },
    [filter, options],
  );

  useEffect(() => {
    setOptions(OPTIONS);
  }, [filter]);

  const { isLoading, error } = useRequestFlowList({
    key: REQUEST_ID.flowListSearch,
    filter,
    isEnabled,
    onError,
    onJump,
    onSuccess,
    options,
  });

  const doMore = useCallback(() => {
    if (!isLoading && _.isNilOrEmptyString(error)) {
      setOptions((prev) => ({
        ...prev,
        after: result?.options.after || OPTIONS.after,
      }));
    }
  }, [error, isLoading, setOptions, result]);

  return {
    doMore,
    error,
    isLoading,
    result,
  };
}
