import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Base } from "../../system";

function Ongoing({ className }: { className?: string }) {
  const { t } = useT();

  return (
    <Base
      className={className}
      description={t("warnings.ongoing.description")}
      label={{
        icon: UserCircleIcon,
        value: t("warnings.ongoing.title"),
      }}
    />
  );
}

export default Ongoing;
