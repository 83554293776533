const modalCard = {
  account: "Account",
  approval: "Approval",
  intervalPicker: "Interval Picker",
  permissions: "Permissions",
  simulator: "Simulator",
  share: "Share",
  moment: "Moment",
  options: "Options",
  history: "History",
  duration: "Duration",
  tokenPicker: "Token Picker",
  search: "Search",
  expected: "Expected",
  chainPicker: "Chain picker",
  proxy: "Proxy",
};

const modalCover = {
  renounce: "Renounce",
  cancel: "Cancel",
  withdraw: "Withdraw",
  transfer: "Transfer",
  cancelMultiple: "Cancel Multiple",
  withdrawMultiple: "Withdraw Multiple",
  pause: "Pause",
  void: "Void",
  adjust: "Adjust",
  restart: "Restart",
  refund: "Refund",
};

const modalCoverAction = {
  adjust: "Adjust",
  airstreamFund: "Fund Campaign",
  airstreamClawback: "Campaign clawback",
  renounce: "Renounce",
  cancel: "Cancel",
  cancelAll: "Cancel All",
  transfer: "Transfer",
  withdraw: "Withdraw",
  withdrawAll: "Withdraw All",
  pause: "Pause",
  restart: "Restart",
  refund: "Refund",
  void: "Void",
};

const source = {
  banner: "Banner",
  curve: "Curve",
  accountModal: "Account Modal",
  optionsModal: "Options Modal",
  streamSimulator: "Stream simulator",
  streamDetails: "Stream Details",
  createFlows: "Create flows",
  createGroupCliff: "Create Group Cliff",
  createGroupCliffExponential: "Create Group Exponential Cliff",
  table: "Table",
  createGroup: "Create Group",
  createFlowGroup: "Create Flow Group",
  createAirstream: "Create Airstream",
  dashboard: "Streams",
  tab: "Tab",
  shortcut: "shortcut",
  actionsPanel: "actions panel",
  timeline: "Timeline",
  withdrawCover: "Withdraw Cover",
  refundCover: "Refund Cover",
};

const tabs = {
  all: "All",
  created: "Created",
  recipient: "Recipient",
  search: "Search",
  sender: "Sender",
  eligible: "Eligible",
};

export interface IStaticEvents {
  clickOnFinishAccountSetup: string;
  clickOnConnect: string;
  clickOnCreateProxyAccount: string;
  clickOnDisconnect: string;
  clickStreamElement: string;
  clickStreamDurationInstant: string;
  clickAirstreams: string;
  clickCreateAirstream: string;
  clickCreateStream: string;
  clickCreateStreamShortcut: string;
  clickLogo: string;
  useDifferentWithdrawAddress: string;
  singleAllowance: string;
  groupAllowance: string;
  createGroupStream: string;
  createGroupFlow: string;
  clickAliasCopy: string;
  clickIdCopy: string;
  shareCustomImage: string;
  clickCopyIdForSearch: string;
  clickViewStream: string;
}

interface IEvents extends IStaticEvents {
  openModal: (key: keyof typeof modalCard) => string;
  openModalFrom: ({
    nameKey,
    placeKey,
  }: {
    nameKey: keyof typeof modalCard;
    placeKey: keyof typeof source;
  }) => string;
  clickOnTab: (key: keyof typeof tabs) => string;
  openCover: ({
    nameKey,
    placeKey,
  }: {
    nameKey: keyof typeof modalCover;
    placeKey: keyof typeof source;
  }) => string;
  openStreamDetails: (key: keyof typeof source) => string;
  selectToken: (token: string) => string;
  selectFlowInterval: (interval: string) => string;
  clickMax: (placeKey: keyof typeof source) => string;
  selectSingleStream: (type: string) => string;
  selectGroupStream: (type: string) => string;
  selectChain: (id: number) => string;
  clickModalCoverAction: (key: keyof typeof modalCoverAction) => string;
  performSearch: (filter: string) => string;
}

const events: IEvents = {
  clickOnConnect: `Click "Connect now"`,
  clickOnCreateProxyAccount: `Click on create special account for proxy`,
  clickOnFinishAccountSetup: "Click on finish account setup for proxy",
  clickLogo: "Click logo",
  clickOnDisconnect: `Click "Disconnect"`,
  clickCreateStream: `Click "Create Stream" from Dashboard`,
  clickCreateStreamShortcut: `Click shortcut to create stream from toolbar`,
  clickCreateAirstream: "Click 'Create Airstream' button",
  clickAirstreams: "Click 'Airstreams' (See Dashboard) button",
  clickStreamElement: "Select stream from table",
  clickStreamDurationInstant:
    "Click 'instant' in the Duration Modal for a new Stream",
  useDifferentWithdrawAddress: "Use different address for Withdraw Action",
  singleAllowance: "Allow for Create Single",
  groupAllowance: "Allow for Create Group",
  createGroupStream: `Click "Create group of streams"`,
  createGroupFlow: `Click "Create group of flows"`,
  clickAliasCopy: `Click "Alias Copy"`,
  clickIdCopy: `Click Id "Copy"`,
  shareCustomImage: `Click "Share Custom Image"`,
  clickCopyIdForSearch: `Click "Copy id(for search)`,
  clickViewStream: `Click "View Stream"`,
  selectSingleStream: (type: string) => `Select Single Stream of type: ${type}`,
  selectGroupStream: (type: string) => `Select Group Stream of type: ${type}`,
  clickMax: (placeKey: keyof typeof source) =>
    `Click amount max in ${source[placeKey]}`,
  clickOnTab: (key: keyof typeof tabs) => `Click "${tabs[key]}" Tab`,
  openModal: (key: keyof typeof modalCard) => `Open "${modalCard[key]}" Modal`,
  openModalFrom: ({
    nameKey,
    placeKey,
  }: {
    nameKey: keyof typeof modalCard;
    placeKey: keyof typeof source;
  }) => `Open "${modalCard[nameKey]}" Modal from ${source[placeKey]}`,
  openCover: ({
    nameKey,
    placeKey,
  }: {
    nameKey: keyof typeof modalCover;
    placeKey: keyof typeof source;
  }) => `Open "${modalCover[nameKey]}" Cover from ${source[placeKey]}`,
  openStreamDetails: (key: keyof typeof source) =>
    `Open "Stream Details" from ${source[key]}`,
  selectToken: (token: string) => `Select token ${token}`,
  selectFlowInterval: (interval: string) => `Select flow interval ${interval}`,

  selectChain: (id: number) => `Select chain with id: ${id}`,
  clickModalCoverAction: (key: keyof typeof modalCoverAction) =>
    `Click "${modalCoverAction[key]}"`,
  performSearch: (filter: string) => `Perform search with filter: ${filter}`,
};

export type EventKey = keyof IStaticEvents;

export { events };
export type { IEvents };
