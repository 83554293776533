import { REQUEST_ID, requests } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { QueryClient } from "@tanstack/react-query";
import type { useMachineInstantiator } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { IToken } from "@sablier/v2-types";
import type { useModalAirstreamRecipients } from "~/client/hooks";
import type { IAirstreamRecipientsCalldata } from "~/client/types";

export interface Payload {
  file: File;
  token: IToken;
  api: {
    t: Translate;
    setOpen: ReturnType<typeof useModalAirstreamRecipients>["setOpen"];
    updateData: ReturnType<typeof useModalAirstreamRecipients>["updateData"];
    onFail: (error?: unknown) => void;
    onSuccess: (file: File, response: IAirstreamRecipientsCalldata) => void;
  };
}

type Machine = Parameters<typeof useMachineInstantiator<Payload>>;

type onCreate = Parameters<Machine["0"]["onCreate"]>["0"];

export async function onCreate({ context }: onCreate): Promise<void> {
  const { api, file, token } = context.payload;

  try {
    api.updateData({
      status: "pending",
    });

    const response = await new QueryClient().fetchQuery({
      queryKey: [
        ...REQUEST_ID.maskMerkleCreate,
        { unique: [file.lastModified, token.decimals] },
      ],
      queryFn: async () =>
        requests.maskMerkleCreate({
          query: { decimals: token.decimals },
          form: { data: file },
        }),
    });

    api.setOpen(false);
    api.onSuccess(file, response);
  } catch (error) {
    vendors.crash.log(error);
    api.onFail(error);
    api.updateData({
      status: "fail",
      error: error as unknown as Error,
    });

    throw error;
  }
}
