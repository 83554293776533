import { useMemo } from "react";
import {
  ArrowDownCircleIcon,
  ArrowRightIcon,
  ArrowSmallUpIcon,
  ArrowUpCircleIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { LockupStatus, StreamFlavor } from "@sablier/v2-constants";
import { useCopy, useWindowMediaQuery } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { client } from "@sablier/v2-subgraphs";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import { pages, tabs } from "~/client/constants";
import type { Table } from "@sablier/v2-components/organisms";
import type { ISTableCell } from "@sablier/v2-components/organisms/Table";
import type { ComponentProps } from "react";
import { useModalStreamShare } from "./modals";
import { useCartStoreAccessor, useCartStoreList } from "./store";
import useAccount from "./useAccount";
import useFlags from "./useFlags";
import useLockupDashboardSearch from "./useLockupDashboardSearch";
import useLockupDashboardTab from "./useLockupDashboardTab";
import useLockupsOwned from "./useLockupsOwned";
import useToast from "./useToast";
import useTokens from "./useTokens";

function useData() {
  const { t } = useT();
  const { address, proxy } = useAccount();
  const { tab } = useLockupDashboardTab();
  const owned = useLockupsOwned();
  const searched = useLockupDashboardSearch();
  const { isWaitingInitialStreams } = useFlags();
  const isSearch = useMemo(() => {
    if (_.isNil(tab)) {
      return false;
    }

    return tab.identifier === tabs.vesting.search.identifier;
  }, [tab]);

  const isRecipient = useMemo(() => {
    if (_.isNil(tab)) {
      return false;
    }

    return tab.identifier === tabs.vesting.recipient.identifier;
  }, [tab]);

  const isSender = useMemo(() => {
    if (_.isNil(tab)) {
      return false;
    }

    return tab.identifier === tabs.vesting.sender.identifier;
  }, [tab]);

  const doMore = useMemo(() => {
    if (!_.isNil(isSearch)) {
      if (isSearch) {
        if (
          !searched.result?.options.isComplete &&
          searched.result?.streams.length
        ) {
          return searched.doMore;
        }
      } else {
        if (!owned.result?.options.isComplete && owned.result?.streams.length) {
          return owned.doMore;
        }
      }
    }

    return undefined;
  }, [isSearch, owned, searched]);

  const isLoading = useMemo(() => {
    if (isWaitingInitialStreams) {
      return true;
    }

    if (isSearch) {
      return searched.isLoading;
    }

    return owned.isLoading;
  }, [isSearch, owned, searched, isWaitingInitialStreams]);

  const error = useMemo(() => {
    if (_.isNil(isSearch)) {
      return undefined;
    }

    return isSearch ? searched.error : owned.error;
  }, [isSearch, owned, searched]);

  const list = useMemo(() => {
    if (_.isNil(isSearch)) {
      return [];
    }

    const result = isSearch ? searched.result : owned.result;

    if (_.isNil(result)) {
      return [];
    }

    return result.streams;
  }, [isSearch, owned, searched]);

  const streams = useMemo(() => {
    switch (tab?.identifier) {
      case tabs.vesting.all.identifier:
      case tabs.vesting.search.identifier:
        return list;
      case tabs.vesting.recipient.identifier:
        /**
         * Show streams where the connected account is the recipient
         */
        return list.filter((stream) => stream.recipient === address);
      case tabs.vesting.sender.identifier:
        /**
         * Show stream created either through the proxy (sender is proxy)
         * or natively without one (sender is the connected account)
         */
        return list.filter((stream) => {
          if (stream.proxied && stream.sender === proxy) {
            return true;
          }
          if (!stream.proxied && stream.sender === address) {
            return true;
          }
          return false;
        });
      default:
        return [];
    }
  }, [address, list, proxy, tab]);

  const isEmpty = useMemo(
    () => !isLoading && streams.length === 0,
    [isLoading, streams],
  );

  const options = useMemo(() => {
    const issue = client.issue(error, t);

    const empty = (() => {
      switch (tab?.identifier) {
        case tabs.vesting.recipient.identifier:
          return "vesting-recipient" as const;
        case tabs.vesting.all.identifier:
        case tabs.vesting.sender.identifier:
          return "vesting-sender" as const;
        default:
          return undefined;
      }
    })();

    return {
      empty,
      error: issue,
    };
  }, [error, t, tab]);

  return {
    doMore,
    error,
    isAnimated: false,
    isEmpty,
    isLoading,
    isSearch,
    isRecipient,
    isSender,
    options,
    streams,
  };
}

type Cells = [
  ISTableCell["TextDeck"],
  ISTableCell["AddressDeck"],
  ISTableCell["AmountDeck"],
  ISTableCell["TextDeck"],
  ISTableCell["Progress"],
  ISTableCell["Actions"],
];

type Data = ComponentProps<typeof Table>["data"];

export default function useLockupDashboardTable(): Data {
  const router = useRouter();
  const { address, proxy } = useAccount();
  const {
    doMore,
    isAnimated,
    isEmpty,
    isLoading,
    isSearch,
    isSender,
    isRecipient,
    options,
    streams,
  } = useData();

  const { list: cartList } = useCartStoreList();
  const cartAccessor = useCartStoreAccessor();
  const { maxMD: isMobile } = useWindowMediaQuery();
  const { find: findToken } = useTokens(false);
  const { setOpen: setOpenShare } = useModalStreamShare();
  const [, , doCopy] = useCopy();
  const toast = useToast();
  const { t } = useT();

  const columns = useMemo(() => {
    return [
      {
        id: "1",
        layout: "TextDeckSelect",
        title: t("words.status"),
        weight: "var(--dashboard-column-1)",
      },
      {
        id: "2",
        layout: "AddressDeck",
        title: t("structs.fromTo"),
        weight: "var(--dashboard-column-2)",
      },
      {
        id: "3",
        layout: "AmountDeck",
        title: t("words.value"),
        weight: "var(--dashboard-column-3)",
      },
      {
        id: "4",
        layout: "TextDeck",
        title: t("words.timeline"),
        weight: "var(--dashboard-column-4)",
      },
      {
        id: "5",
        info: t("descriptions.streamedAmount"),
        layout: "Progress",
        title: t("words.streamed"),
        weight: "var(--dashboard-column-5)",
      },
      {
        id: "6",
        title: t("words.actions"),
        layout: "Actions",
        weight: "var(--dashboard-column-6)",
      },
    ];
  }, [t]);

  type Instructions = Data["instructions"];

  const instructions = useMemo(() => {
    const actions: Instructions = {};

    if (_.isFunction(doMore)) {
      actions.onMore = (_event) => {
        try {
          doMore();
        } catch (error) {
          vendors.crash.log(error);
        }
      };
    }

    actions.onRowClick = (alias, _event) => {
      try {
        if (_.isWindow()) {
          if (window?.getSelection()?.toString().length) {
            return;
          }
        }
      } catch (error) {
        vendors.crash.log(error);
      }

      if (!isMobile) {
        vendors.track.log("clickStreamElement");
        void router.push(pages.payments.profile.builder(alias), undefined, {
          shallow: true,
        });
      }
    };

    actions.onRowSelect = (alias, event) => {
      try {
        void event?.stopPropagation();
        const { api, list } = cartAccessor();
        const { addItem, removeItem } = api;

        const contained = list.find((item) => item.alias === alias);

        if (contained) {
          removeItem(alias);
        } else {
          const requested = streams.find((item) => item.alias === alias);

          if (requested) {
            addItem(requested);
          }
        }
      } catch (error) {
        vendors.crash.log(error);
      }
    };

    return actions;
  }, [cartAccessor, doMore, isMobile, router, streams]);

  const rows = useMemo(
    () =>
      streams.map((stream) => {
        const token = findToken({ token: stream.token });
        const preview = stream.findPreview(t);

        const label = (() => {
          let value = _.toAlias(stream.alias) || t("words.stream");

          if (stream.isBatched && stream.batch) {
            const { label, position, size } = stream.batch;
            const prefix = `G${label} ${position + 1}/${size}`;
            value = `${prefix} ${_.toAlias(stream.alias)}`;
          }

          return {
            value,
            tooltip: {
              value,
              mouseEnterDelay: 1,
            },
          };
        })();

        const party = (() => {
          if (!isSearch) {
            if (isRecipient) {
              return {
                purpose: "recipient" as const,
                sender: stream.proxied ? stream.proxender : stream.sender,
                chainId: stream.chainId,
                label: {
                  icon: ArrowDownCircleIcon,
                  value: _.capitalize(t("words.incoming")),
                },
              };
            }

            if (isSender) {
              return {
                purpose: "sender" as const,
                recipient: stream.recipient,
                chainId: stream.chainId,
                label: {
                  icon: ArrowUpCircleIcon,
                  value: _.capitalize(t("words.outgoing")),
                },
              };
            }

            if (stream.recipient === address) {
              return {
                purpose: "recipient" as const,
                sender: stream.proxied ? stream.proxender : stream.sender,
                chainId: stream.chainId,
                label: {
                  icon: ArrowDownCircleIcon,
                  value: _.capitalize(t("words.incoming")),
                },
              };
            }

            /** Stream created through a proxy */
            if (stream.proxied && stream.sender === proxy) {
              return {
                purpose: "sender" as const,
                recipient: stream.recipient,
                chainId: stream.chainId,
                label: {
                  icon: ArrowSmallUpIcon,
                  value: _.capitalize(t("words.outgoing")),
                },
              };
            }

            /** Stream created natively, without a proxy */
            if (!stream.proxied && stream.sender === address) {
              return {
                purpose: "sender" as const,
                recipient: stream.recipient,
                chainId: stream.chainId,
                label: {
                  icon: ArrowUpCircleIcon,
                  value: _.capitalize(t("words.outgoing")),
                },
              };
            }
          }

          return {
            purpose: "public" as const,
            recipient: stream.recipient,
            sender: stream.proxied ? stream.proxender : stream.sender,
            chainId: stream.chainId,
          };
        })();

        const color = (() => {
          switch (stream.status) {
            case LockupStatus.CANCELED:
            case LockupStatus.DEPLETED_CANCELED:
              return "red";
            case LockupStatus.DEPLETED_SETTLED:
              return "gray400";
            default:
              return undefined;
          }
        })();

        const cells: Cells = [
          {
            value: {
              content: preview.status,
              label,
              contentColor: color,
            },
          },
          {
            value: party,
          },
          {
            value: {
              label: _.truncate(_.startCase(token?.name || token?.symbol), {
                length: 22,
                omission: "..",
              }),
              amount: {
                token,
                value: stream.depositAmount.humanized.toString(),
              },
            },
          },
          {
            value: {
              content: _.toDuration(stream.endTime, "date-short")[0],
              label: {
                value: _.toDuration(stream.startTime, "date-short")[0],
              },
            },
          },
          {
            value: {
              percentage: stream?.streamedAmountPercentage.toNumber(),
              purpose: preview.progressBox,
            },
          },
          {
            value: {
              buttons: [
                {
                  right: EllipsisHorizontalIcon,
                  title: "",
                  appearance: "outline",
                  accent: "iconic",
                  isMenu: true,
                  menu: [
                    {
                      group: "custom",
                      items: [
                        {
                          title: t("structs.copyForSearch"),
                          onClick: () => {
                            vendors.track.log("clickCopyIdForSearch");
                            doCopy(_.toAlias(stream.alias));
                            toast.add({
                              duration: 3500,
                              id: "identifier",
                              type: "success",
                              message: t("structs.shortCopied", {
                                id: _.toAlias(stream.alias),
                              }),
                            });
                          },
                        },
                        {
                          title: t("structs.shareURL"),
                          onClick: () => {
                            vendors.track.log((events) => {
                              return events.openModalFrom({
                                nameKey: "share",
                                placeKey: "table",
                              });
                            });
                            setOpenShare(true, {
                              stream,
                              purpose: StreamFlavor.Lockup,
                            });
                          },
                        },
                      ],
                    },
                    {
                      group: "general",
                      items: [
                        {
                          title: t("structs.viewStream"),
                          onClick: () => {
                            vendors.track.log("clickViewStream");
                          },
                          purpose: "internal",
                          to: pages.vesting.profile.builder(
                            _.toAlias(stream.alias),
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  right: ArrowRightIcon,
                  title: "",
                  appearance: "outline",
                  accent: "iconic",
                  purpose: "internal",
                  to: pages.vesting.profile.builder(_.toAlias(stream.alias)),
                },
              ],
            },
          },
        ];
        return {
          id: _.toAlias(stream.alias),
          cells,
          isSelected: !_.isNil(cartList.find((item) => item.id === stream.id)),
        };
      }),

    [
      address,
      cartList,
      doCopy,
      findToken,
      isSearch,
      isSender,
      isRecipient,
      proxy,
      toast,
      setOpenShare,
      streams,
      t,
    ],
  );

  return {
    columns,
    instructions,
    options,
    rows,
    isAnimated,
    isEmpty,
    isLoading,
  };
}
