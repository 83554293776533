import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { Variables } from "graphql-request";
import * as client from "./client";
import * as airstream from "./documents/airstream/queries";
import * as flow from "./documents/flow/queries";
import * as protocol from "./documents/protocol/queries";

type Vars<D> = D extends TypedDocumentNode<unknown, infer V extends Variables>
  ? V
  : never;

const queries = {
  airstream,
  protocol,
  flow,
};

export { default as configs } from "./configs";
export { client, queries };
export type { Vars, TypedDocumentNode, Variables };
