import styled from "styled-components";
import { Amount, Label } from "@sablier/v2-components/molecules";
import { _ } from "@sablier/v2-mixins";
import type { ThemeType } from "@sablier/v2-themes";
import type { ITokenDisplay } from "@sablier/v2-types";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span 1;
    row-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.transparent};
  }
`;

const Top = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    column-gap: 4px;
  }
`;

const Indicator = styled.div<{ color?: keyof ThemeType["colors"] }>`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: flex-start;
    height: 100%;
    &:after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${(props) =>
        props.theme.colors[props.color || "white"]};
    }
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    column-gap: 8px;
  }
`;

const Percentage = styled.div`
  padding: 6px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.dark300};
  & > p {
    ${(props) => props.theme.styles.textButton}
    & {
      color: ${(props) => props.theme.colors.gray400};
      text-align: center;
    }
  }
`;

interface Props {
  amount?: string;
  indicator?: keyof ThemeType["colors"];
  label: ComponentProps<typeof Label>;
  percentage?: string | number;
  token?: ITokenDisplay;
}

function AmountItem({ amount, indicator, label, percentage, token }: Props) {
  return (
    <Wrapper>
      <Top>
        <Label {...label} />
        {indicator && <Indicator color={indicator} />}
      </Top>
      <Content>
        <Amount value={amount} size={[30, 15]} token={token} />
        {percentage && (
          <Percentage>
            <p>{_.toPercentage(percentage)}</p>
          </Percentage>
        )}
      </Content>
    </Wrapper>
  );
}

export default AmountItem;
