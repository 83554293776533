import {
  AIRSTREAM_GRACE_DAYS,
  DEFAULT_DECIMALS,
  LockupCategory,
  LockupShape,
  LockupShapes,
  LockupVersion,
  PERCENTAGE_DECIMALS,
  chains,
} from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import type Action from "../AirstreamAction";
import type { Params } from "./Attributes";
import type { Purpose } from "@sablier/v2-contracts";
import type { Translate } from "@sablier/v2-locales";
import type {
  IAddress,
  IAirstreamCID,
  IAirstreamId,
  IOptions,
} from "@sablier/v2-types";
import Lockup from "../Lockup";
import Token from "../Token";
import Tranche from "../Tranche";
import Attributes from "./Attributes";

export interface IFilterAirstream {
  admin?: IAddress;
  airstreamIds?: IAirstreamId[];
  airstreamCIDs?: IAirstreamCID[];
  chainId?: number;
  token?: IAddress;
  isAlive?: boolean;
  name?: string;
}

export interface ISearchAirstream {
  airstreams: Functionality[];
  filter: IFilterAirstream;
  options: IOptions;
}

export type Preview = {
  aggregateAmount: string;
  cancelable: string;
  claimedAmount: string;
  pairAmount: string;
  expiresIn: string;
  expiresOn: string;
  expiresInLabel: string;
  title: (e: string) => string;
  transferable: string;
  purpose: "merkleLL" | "merkleLT";
};

/**
 * ------------------------------
 * The "Functionality" part of the Stream class will
 * include only methods that work on top of the Attributes.
 * ------------------------------
 * Inheritance is not used here for OOP purposes,
 * but for readability (smaller class parts)
 * ------------------------------
 */
export default class Functionality extends Attributes {
  constructor(params: Params, token: Token) {
    super(params, token);
    this.doUpdate();
  }

  /**
   * Test that a given string is a valid IAirstreamId
   */
  static isId(value: IAirstreamId | string | undefined) {
    if (!_.isNilOrEmptyString(value)) {
      if (value.includes("-") && value.split("-").length === 2) {
        const parts = value.split("-");
        if (
          _.isEthereumAddress(parts[0]) &&
          new BigNumber(parts[1]).isFinite()
        ) {
          if (
            Object.values(chains).find(
              (chain) => chain.chainId.toString() === parts[1],
            )
          ) {
            return true;
          }
        }
      }
    }

    return false;
  }

  static doSplitIdentifier(value: IAirstreamId | undefined): {
    address?: string;
    chainId?: number;
  } {
    try {
      if (!_.isNilOrEmptyString(value)) {
        const parts = value.split("-");
        if (parts.length === 2) {
          return {
            address: _.toAddress(parts[0]),
            chainId: _.toNumber(parts[1]),
          };
        }
      }
    } catch (error) {
      vendors.crash.log(error);
    }
    return {
      address: undefined,
      chainId: undefined,
    };
  }

  static doGenerateId(address: IAddress, chainId: number): IAirstreamId {
    return `${_.toAddress(address)}-${chainId}`;
  }

  static doFormatFilter({
    admin,
    airstreamIds,
    chainId,
    token,
    isAlive,
    airstreamCIDs,
    name,
  }: {
    admin?: IAddress | undefined;
    airstreamIds?: IAirstreamId[] | undefined;
    airstreamCIDs?: IAirstreamCID[] | undefined;
    chainId?: number | string | undefined;
    token?: IAddress | undefined;
    isAlive?: boolean | undefined;
    name?: string | undefined;
  } = {}): IFilterAirstream {
    const filter: IFilterAirstream = {
      admin: undefined,
      airstreamIds: undefined,
      airstreamCIDs: undefined,
      chainId: undefined,
      token: undefined,
      name: undefined,
      isAlive: undefined,
    };

    if (!_.isNilOrEmptyString(chainId)) {
      filter.chainId = _.toNumber(chainId) || undefined;
    }

    if (!_.isNilOrEmptyString(admin)) {
      filter.admin = _.toAddress(admin);
    }

    if (!_.isNilOrEmptyString(airstreamIds)) {
      if (_.isArray(airstreamIds) && airstreamIds.length > 0) {
        filter.airstreamIds = airstreamIds.map((item) => item || "");
      }
    }

    if (!_.isNilOrEmptyString(airstreamCIDs)) {
      if (_.isArray(airstreamCIDs) && airstreamCIDs.length > 0) {
        filter.airstreamCIDs = airstreamCIDs.map((item) => item || "");
      }
    }

    if (!_.isNilOrEmptyString(token)) {
      filter.token = _.toAddress(token);
    }

    if (!_.isNilOrEmptyString(isAlive)) {
      filter.isAlive = isAlive;
    }

    if (!_.isNilOrEmptyString(name)) {
      filter.name = name;
    }

    return filter;
  }

  /**
   * Given a specific LockupCategory, find the purpose (or ABI key) matching the Merkle Lockup flavor
   */
  static findPurpose(
    category: LockupCategory,
  ): Extract<Purpose, "merkleLT" | "merkleLL"> {
    if (category === LockupCategory.LOCKUP_LINEAR) {
      return "merkleLL";
    }
    if (category === LockupCategory.LOCKUP_TRANCHED) {
      return "merkleLT";
    }

    return "merkleLL";
  }

  /**
   * Use a set of default values to simulate a stream that could be part of this campaign.
   */

  findSimulatedStream(): Lockup {
    const amount = _.toValue({ humanized: "1000", decimals: DEFAULT_DECIMALS });
    const duration = (1000 * 60 * 24).toString();

    let previous_time = new BigNumber(Date.now());
    let previous_amount = new BigNumber(0);

    const segments =
      this.category === LockupCategory.LOCKUP_TRANCHED
        ? this.streamTranches
            .map((t, index) => {
              const tranche_amount = amount.raw.times(t.percentage.humanized);
              const tranche_end_amount = previous_amount.plus(tranche_amount);
              const tranche_end_time = previous_time.plus(t.duration);

              const tranche = new Tranche(
                {
                  id: `t-${index}`,
                  position: `${index}`,
                  amount: _.toValuePrepared({
                    raw: tranche_amount,
                    decimals: this.token.decimals,
                  }),
                  percentage: t.percentage.raw.toString(),

                  startAmount: _.toValuePrepared({
                    raw: previous_amount,
                    decimals: this.token.decimals,
                  }),
                  endAmount: _.toValuePrepared({
                    raw: tranche_end_amount,
                    decimals: this.token.decimals,
                  }),

                  startTime: _.toSeconds(previous_time),
                  endTime: _.toSeconds(tranche_end_time),
                  timestamp: _.toSeconds(tranche_end_time),
                },
                this.token,
              );

              previous_amount = tranche_end_amount;
              previous_time = tranche_end_time;

              return tranche.toSegments();
            })
            .flat()
            .map((s) => s.payload)
        : [];

    const payload = {
      ...Lockup.base(),
      category: this.category,
      depositAmount: amount.raw.toString(),
      intactAmount: amount.raw.toString(),
      startTime: _.toSeconds(new BigNumber(Date.now())),
      endTime: _.toSeconds(new BigNumber(Date.now()).plus(duration)),
      duration,
      segments,
    };

    return new Lockup(payload, this.token);
  }

  isDeformed(): boolean {
    if (this.category !== LockupCategory.LOCKUP_TRANCHED) {
      return false;
    }

    const raw = this.streamTranches.reduce(
      (p, c) => p.plus(new BigNumber(c.percentage.raw)),
      new BigNumber(0),
    );

    const { humanized } = _.toValue({
      raw,
      decimals: PERCENTAGE_DECIMALS,
    });

    return !new BigNumber(humanized).isEqualTo(1);
  }

  static findStreamShape(
    airstream: Functionality,
    transformer: (stream: Lockup) => LockupShape | string | undefined,
  ): (typeof LockupShapes)[keyof typeof LockupShapes] {
    const shape = transformer(airstream.findSimulatedStream());
    return Lockup.findShape(shape);
  }

  /**
   * Computes if the campaign is in the grace period.
   * Also known as an initial clawback period, it allows a clawback from deployment to 7 days after the first claim.
   */
  isInGrace(first: Action | undefined) {
    if (
      this.version === LockupVersion.V20 ||
      this.version === LockupVersion.V21
    ) {
      return false;
    }
    if (this.claimedCount === 0 || _.isNilOrEmptyString(first)) {
      return true;
    }

    const now = _.toDayjs(Date.now().toString());
    const claimWindowBorder = now.subtract(AIRSTREAM_GRACE_DAYS, "day");
    return claimWindowBorder.isBefore(_.toDayjs(first.timestamp));
  }

  doUpdate() {
    const now = Date.now();

    this._timeSinceExpiration = new BigNumber(now)
      .minus(new BigNumber(this.expiration || 0))
      .toString();
  }

  /**
   *
   * ----------------------------------------------------------------------------
   *
   */

  findPreview(t: Translate): Preview {
    const cancelable = this.streamCancelable
      ? t("structs.canBeCanceled")
      : t("structs.cannotBeCanceled");

    const transferable = this.streamTransferable
      ? t("structs.canBeTransferred")
      : t("structs.cannotBeTransferred");

    const title = (e: string) =>
      this.name
        ? `$${this.token.symbol} in ${this.name}`
        : `$${this.token.symbol} by ${e}`;

    const aggregateAmount = `${this.token.symbol} ${_.toNumeralPrice(
      this.aggregateAmount.humanized,
    )}`;

    const claimedAmount = `${this.token.symbol} ${_.toNumeralPrice(
      this.claimedAmount.humanized,
    )}`;

    const pairAmount = `${this.token.symbol} ${_.toNumeralPrice(
      this.claimedAmount.humanized,
    )} / ${_.toNumeralPrice(this.aggregateAmount.humanized)}`;

    const expiresOn = this.expires
      ? _.toDuration(this.expiration, "date")[0]
      : _.capitalize(t("words.never"));

    const expiresIn = this.expires
      ? new BigNumber(this.timeSinceExpiration).isPositive()
        ? _.toDuration(this.expiration, "date")[0]
        : `${t("words.in")} ${
            _.toDuration(
              new BigNumber(this.timeSinceExpiration).abs().toString(),
              "time-short",
            )[0]
          }`
      : _.capitalize(t("structs.noDeadline"));

    const expiresInLabel = this.expires
      ? new BigNumber(this.timeSinceExpiration).isPositive()
        ? _.capitalize(t("structs.claimsEnded"))
        : _.capitalize(t("structs.claimsEnd"))
      : _.capitalize(t("structs.claimsEnd"));

    const purpose = Functionality.findPurpose(this.category);

    return {
      aggregateAmount,
      claimedAmount,
      pairAmount,
      cancelable,
      expiresIn,
      expiresInLabel,
      expiresOn,
      title,
      transferable,
      purpose,
    };
  }
}
