import styled from "styled-components";
import { Duration } from "@sablier/v2-components/molecules";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-wrap: wrap;
    gap: 6px;
  }
`;

const Prefix = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
  }
`;

interface Props {
  prefix: string;
  duration: ComponentProps<typeof Duration>;
}

function Timeline({ prefix, duration }: Props) {
  return (
    <Wrapper>
      <Prefix>
        <p>{prefix}</p>
      </Prefix>
      <Duration {...duration} />
    </Wrapper>
  );
}

export default Timeline;
