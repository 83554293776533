import {
  FlowCategory,
  FlowStatus,
  FlowVersion,
  StreamFlavor,
  ZERO,
} from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type {
  IAddress,
  IMilliseconds,
  ISeconds,
  IValue,
} from "@sablier/v2-types";
import Action from "../FlowAction";
import Token from "../Token";
import Base, { Params as BaseParams } from "../abstract/Stream";

/**
 * ------------------------------
 * The "Attributes" part of the Stream class will
 * include only data fields (and getters/setters)
 * ------------------------------
 * Inheritance is not used here for OOP purposes,
 * but for readability (smaller class parts)
 * ------------------------------
 */

export type Params = BaseParams<"Flow"> & {
  availableAmount: string;
  creator: IAddress;
  depletionTime: ISeconds;
  depositedAmount: string;
  lastAdjustmentTimestamp: ISeconds;
  paused: boolean;
  pausedTime?: ISeconds;
  ratePerSecond: string;
  refundedAmount: string;
  snapshotAmount: string;
  startTime: ISeconds;

  voided: boolean;
  voidedTime?: ISeconds;
  withdrawnAmount: string;
};

export default abstract class Attributes extends Base<"Flow"> {
  /**
   * -------------------------------
   * ------- Native Attributes -------
   * -------------------------------
   */

  readonly creator: IAddress;
  readonly payload: Params;
  readonly flavor = StreamFlavor.Flow;

  /** ---- Amounts ---- */

  readonly availableAmount: IValue;
  readonly depositedAmount: IValue;
  readonly ratePerSecond: IValue;
  readonly refundedAmount: IValue;
  readonly snapshotAmount: IValue;
  readonly withdrawnAmount: IValue;

  /** ---- Times ---- */

  readonly pausedTime: IMilliseconds;
  readonly voidedTime: IMilliseconds;
  readonly depletionTime: IMilliseconds;
  readonly lastAdjustmentTimestamp: ISeconds;
  readonly startTime: IMilliseconds;

  /** ---- Percentages ---- */

  /** ---- Flags ---- */

  readonly isPaused: boolean;
  readonly isTransferable: boolean;
  readonly isVoided: boolean;

  /** ---- Components ---- */

  public actions: Action[] = [];

  /**
   * ------------------------------------------------
   * -------------- Derived Attributes --------------
   * ------------------------------------------------
   * These attributes will be kept fresh by doUpdate()
   * or can suffer changes after the initial binding.
   * ------------------------------------------------
   */

  protected _debtAmount: IValue;
  protected _returnableAmount: IValue;
  protected _status: FlowStatus;
  protected _streamedAmount: IValue;
  protected _streamedAmountEstimate: IValue;
  protected _withdrawableAmount: IValue;
  protected _withdrawnAmountPercentage: BigNumber;

  constructor(params: Params, token: Token) {
    super(params, token);

    this.payload = params;
    this.creator = _.toAddress(params.creator);
    this.category = this.category || FlowCategory.FLOW;
    this.version = this.version || FlowVersion.V10;

    /** ---- Amounts ---- */

    this.availableAmount = _.toValue({
      decimals: token.decimals,
      raw: params.availableAmount,
    });
    this.depositedAmount = _.toValue({
      decimals: token.decimals,
      raw: params.depositedAmount,
    });
    this.refundedAmount = _.toValue({
      decimals: token.decimals,
      raw: params.refundedAmount,
    });
    this.ratePerSecond = _.toValue({
      decimals: token.decimals,
      raw: params.ratePerSecond,
    });
    this.snapshotAmount = _.toValue({
      decimals: token.decimals,
      raw: params.snapshotAmount,
    });

    this.withdrawnAmount = _.toValue({
      decimals: token.decimals,
      raw: params.withdrawnAmount,
    });

    /** ---- Times ---- */

    this.depletionTime = _.toMilliseconds(params.depletionTime);
    this.lastAdjustmentTimestamp = _.toMilliseconds(
      params.lastAdjustmentTimestamp,
    );
    this.pausedTime = _.toMilliseconds(params.pausedTime);

    this.startTime = _.toMilliseconds(params.startTime);
    this.voidedTime = _.toMilliseconds(params.voidedTime);

    /** ---- Percentages ---- */

    /** ---- Flags ---- */

    this.isPaused = params.paused;
    this.isTransferable = params.transferable;
    this.isVoided = params.voided;

    /** ---- Derived ---- */

    const zero = ZERO(this.token.decimals);

    this._returnableAmount = zero;
    this._streamedAmount = zero;
    this._streamedAmountEstimate = zero;
    this._withdrawableAmount = zero;
    this._withdrawnAmountPercentage = zero.raw;
    this._debtAmount = zero;
    this._status = FlowStatus.STREAMING_FUNDED;
  }

  get debtAmount() {
    return this._debtAmount;
  }

  get returnableAmount() {
    return this._returnableAmount;
  }

  get status() {
    return this._status;
  }

  get streamedAmount() {
    return this._streamedAmount;
  }

  get streamedAmountEstimate() {
    return this._streamedAmountEstimate;
  }

  get withdrawableAmount() {
    return this._withdrawableAmount;
  }

  get withdrawnAmountPercentage() {
    return this._withdrawnAmountPercentage;
  }

  get isAlive() {
    return (
      this.status === FlowStatus.STREAMING_DEBT ||
      this.status === FlowStatus.STREAMING_FUNDED
    );
  }

  get isFrozen() {
    return (
      this.status === FlowStatus.PAUSED_DEBT ||
      this.status === FlowStatus.PAUSED_FUNDED ||
      this.status === FlowStatus.VOIDED
    );
  }
}
