import { useEffect, useMemo, useState } from "react";
import { DigitLimits, FAST_UI_POLLING } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Flow } from "@sablier/v2-models";
import { useSessionPolling } from "./store";

export default function usePollingLightFlow(stream?: Flow) {
  const { isPolling } = useSessionPolling();
  const [current, setCurrent] = useState(stream?.streamedAmountEstimate);

  useEffect(() => {
    const interval = setInterval(() => {
      if (stream?.isAlive && isPolling) {
        setCurrent(stream?.findStreamedAmount());
      }
    }, FAST_UI_POLLING);

    return () => {
      clearInterval(interval);
    };
  }, [isPolling, stream]);

  /**
   * To prevent a ghost-suffix from showing up in high-intensity
   * animations, a final decimal (0.00..01) is added to ensure
   * enough decimals are available. Instead of a placeholder
   * suffix, the final 0 will now be a real decimal (before that 0.00..01).
   *
   */
  const estimated = useMemo(() => {
    const base = current?.humanized || new BigNumber(0);

    if (base.isZero() || (stream && !stream.isAlive)) {
      return base;
    }

    const precision = Math.min(
      DigitLimits.MANTISSA_PRECISION_CEIL,
      stream?.token.decimals || 0,
    );
    const flag = base.times(10 ** precision).modulo(10);

    if (flag.integerValue().isZero() && !base.isZero()) {
      return base.plus(new BigNumber(1).dividedBy(10 ** precision));
    }

    return base;
  }, [stream, current]);

  return { estimated };
}
