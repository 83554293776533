import { memo, useMemo } from "react";
import { useWindowSize } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";
import Base from "antd/lib/popover";
import type { ThemeType } from "@sablier/v2-themes";
import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import type { CSSProperties } from "react";

const theme = getTheme();

interface Props {
  arrow?: ComponentProps<typeof Base>["arrow"];
  autoAdjustOverflow?: ComponentProps<typeof Base>["autoAdjustOverflow"];
  color?: keyof ThemeType["colors"];
  container?: CSSProperties;
  isMini?: boolean;
  maxWidth?: `${string}px` | string;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  placement?: ComponentProps<typeof Base>["placement"];
  trigger?: ComponentProps<typeof Base>["trigger"];
  triggerMobile?: ComponentProps<typeof Base>["trigger"] | false;
  value?: string | ReactNode;
}

const base: CSSProperties = {
  borderRadius: "10px",
};

const mini: CSSProperties = {
  padding: 4,
  borderRadius: "4px",
};

/**
 * Make sure the child component accepts all the necessary props.
 * It's best to introduce a normal `div` as a child wrapper to make sure the tooltip appears.
 *
 * https://ant.design/components/tooltip#why-sometime-not-work-on-hoc
 */

function Tooltip({
  arrow = true,
  autoAdjustOverflow = true,
  color = "dark050",
  children,
  isMini = false,
  maxWidth = "300px",
  mouseEnterDelay = 0,
  mouseLeaveDelay = 0.1,
  placement,
  trigger = "hover",
  triggerMobile = "click",
  value = null,
  container = {},
}: PropsWithChildren<Props>) {
  const { width } = useWindowSize();
  const isMobile = useMemo(
    () => width < parseInt(theme.sizes.deviceLGBreak, 10),
    [width],
  );

  const background = useMemo(() => theme.colors[color], [color]);
  const style = useMemo(
    () => ({ ...base, maxWidth, ...(isMini ? mini : {}), ...container }),
    [container, isMini, maxWidth],
  );

  if (
    _.isNilOrEmptyString(value) ||
    (isMobile && _.isNilOrEmptyStringOrFalse(triggerMobile))
  ) {
    return <>{children}</>;
  }

  return (
    <Base
      arrow={arrow}
      color={background}
      content={value}
      trigger={isMobile && triggerMobile ? triggerMobile : trigger}
      overlayInnerStyle={style}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      autoAdjustOverflow={autoAdjustOverflow}
      placement={placement}
      zIndex={theme.sizes.zIndexTooltip}
    >
      {children}
    </Base>
  );
}

export default memo(Tooltip);
