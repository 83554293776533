import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { Lockup, Token } from "@sablier/v2-models";
import { useShallow } from "zustand/react/shallow";
import useStore from "~/client/stores/cart";

export function useCartStoreList() {
  const set = useStore(useShallow((state) => state.api.setList));
  const reset = useStore(useShallow((state) => state.api.reset));
  const list = useStore(useShallow((state) => state.list));

  const casted = useMemo(() => {
    return list.map((item) => {
      const token = new Token(item.token.payload);
      const stream = new Lockup(item.payload, token);
      return stream;
    });
  }, [list]);

  return {
    list: casted,
    reset,
    set,
  };
}

export function useCartStoreItem(alias?: string) {
  const { add, remove, update } = useStore((state) => ({
    add: state.api.addItem,
    remove: state.api.removeItem,
    update: state.api.updateItem,
  }));

  const item = useStore(
    useShallow((state) =>
      alias
        ? state.list.find(
            (item) => item.alias.toLowerCase() === alias.toLowerCase(),
          )
        : undefined,
    ),
  );

  const casted = useMemo(() => {
    if (item) {
      const token = new Token(item.token.payload);
      const stream = new Lockup(item.payload, token);
      return stream;
    }
    return undefined;
  }, [item]);

  return {
    add,
    item: casted,
    remove,
    update,
  };
}

export function useCartStoreAccessor() {
  return useStore.getState;
}
