const links = {
  canny: "https://sablier.canny.io/feature-requests",
  airstreamTemplate:
    "https://files.sablier.com/templates/airstream-template.csv",
  flowTemplate: "https://files.sablier.com/templates/flow-template.csv",
  captcha: "https://www.google.com/recaptcha/api/siteverify",
  streamTemplate: {
    cliff: {
      duration:
        "https://files.sablier.com/templates/cliff-duration-template.csv",
      range: "https://files.sablier.com/templates/cliff-range-template.csv",
    },
    linear: {
      duration:
        "https://files.sablier.com/templates/linear-duration-template.csv",
      range: "https://files.sablier.com/templates/linear-range-template.csv",
    },
    dynamicCliffExponential: {
      duration:
        "https://files.sablier.com/templates/exponentialCliff-duration-template.csv",
      range:
        "https://files.sablier.com/templates/exponentialCliff-range-template.csv",
    },
    dynamicExponential: {
      duration:
        "https://files.sablier.com/templates/exponential-duration-template.csv",
      range:
        "https://files.sablier.com/templates/exponential-range-template.csv",
    },
    dynamicUnlockCliff: {
      duration:
        "https://files.sablier.com/templates/unlockCliff-duration-template.csv",
      range:
        "https://files.sablier.com/templates/unlockCliff-range-template.csv",
    },
    dynamicUnlockLinear: {
      duration:
        "https://files.sablier.com/templates/unlockLinear-duration-template.csv",
      range:
        "https://files.sablier.com/templates/unlockLinear-range-template.csv",
    },
    tranchedBackweighted: {
      duration:
        "https://files.sablier.com/templates/backWeighted-range-template.csv",
      range:
        "https://files.sablier.com/templates/backWeighted-range-template.csv",
    },
    tranchedMonthly: {
      duration:
        "https://files.sablier.com/templates/monthly-range-template.csv",
      range: "https://files.sablier.com/templates/monthly-range-template.csv",
    },
    tranchedTimelock: {
      duration:
        "https://files.sablier.com/templates/timelock-duration-template.csv",
      range: "https://files.sablier.com/templates/timelock-range-template.csv",
    },
    tranchedStepper: {
      duration:
        "https://files.sablier.com/templates/stepper-duration-template.csv",
      range: "https://files.sablier.com/templates/stepper-range-template.csv",
    },
  },
  customers: {
    astaria: "https://twitter.com/AstariaXYZ",
    exactly: "https://blog.sablier.com/case-study-exactly-protocol/",
    maple: "https://blog.sablier.com/case-study-maple/",
    nouns: "https://nouns.wtf/vote/143",
    reflexer: "https://reflexer.finance/",
    rook: "https://blog.sablier.com/how-rook-labs-uses-sablier-for-payroll/",
    shapeshift: "https://blog.sablier.com/case-study-shapeshift/",
    steakhut: "https://twitter.com/steakhut_fi",
    tokensight:
      "https://blog.sablier.com/how-tokensight-leverages-sabliers-vesting-solution/",
    uniswap: "https://www.tally.xyz/gov/uniswap/proposal/63",
    fjord: "https://x.com/FjordFoundry/status/1795564491251609912",
  },
  discord: "https://discord.gg/bSwRCwWRsT",
  docs: {
    guides: "https://docs.sablier.com/apps/guides/how-to",
    airstreamClaim:
      "https://docs.sablier.com/apps/guides/how-to#how-to-claim-from-an-airstream-campaign",
    airstreamCreate:
      "https://docs.sablier.com/apps/guides/how-to#how-to-create-an-airstream-campaign",
    streamCreateCSV:
      "https://docs.sablier.com/apps/guides/how-to#how-to-create-an-airstream-campaign",
    approve: "https://ethereum.stackexchange.com/a/48932",
    proxy: "https://github.com/PaulRBerg/prb-proxy",
    v1: "https://docs.sablier.com",
    v2: "https://docs.sablier.com",
    v2Fees: "https://docs.sablier.com/concepts/protocol/fees",
    v2Proxy:
      "https://docs.sablier.com/contracts/v2/reference/diagrams#set-up-proxy",
    v2Streaming: "https://docs.sablier.com/concepts/protocol/streaming",
  },
  email: {
    contact: "contact@sablier.com",
    legal: "legal@sablier.com",
    sales: "tech@sablier.com",
    tech: "tech@sablier.com",
  },
  forms: {
    call: "https://forms.gle/6z3XyfSLXDoRghG99",
    frens: "https://frenreviews.com/project/sablier/431",
    onboarding: "https://forms.gle/6z3XyfSLXDoRghG99",
  },
  github: {
    organization: "https://github.com/sablier-labs",
    communityList: "https://github.com/sablier-labs/community-token-list",
    raw: {
      chains:
        "https://raw.githubusercontent.com/sablier-labs/assets/main/chains/",
    },
    merkle: "https://github.com/sablier-labs/v2-services",
  },
  ipfs: {
    gateway: {
      cloudflare: "https://cloudflare-ipfs.com/ipfs/",
    },
  },
  labs: {
    landing: "https://sablierlabs.co.uk",
  },
  legal: {
    privacy: "https://files.sablier.com/privacy-policy.pdf",
    risk: "https://files.sablier.com/risk-notice.pdf",
    terms: "https://files.sablier.com/terms-of-service.pdf",
  },

  investor: {
    a16zcrypto: "https://a16zcrypto.com/",
    acapital: "https://acapital.com/",
    gd1: "https://www.gd1.vc/gd1-crypto",
    factor: "https://f.actor/",
    evanvanness: "https://x.com/evan_van_ness",
    litocoen: "https://x.com/litocoen",
    charles: "https://www.linkedin.com/in/charlessonghurst/",
    fenbushi: "https://fenbushi.vc/",
    danielbar: "https://x.com/danieltbar",
  },

  linktree: "https://linktr.ee/SablierLabs",
  medium: "https://blog.sablier.com/",

  nft: {
    opensea: (
      chain: string | undefined,
      contract: string | undefined,
      id: string | undefined,
    ) => `https://opensea.io/assets/${chain}/${contract}/${id}`,
  },
  tools: {
    swissKnife: {
      calldata: (value: string) =>
        `https://calldata.swiss-knife.xyz/decoder?calldata=${value}`,
    },
    tenderly: {
      dashboard: (params: string) =>
        `https://dashboard.tenderly.co/Sablier/project/simulator/new?${params}`,
    },
  },
  security: {
    audits: "https://github.com/sablier-labs/audits",
    bounty: "https://github.com/sablier-labs/v2-core/blob/main/SECURITY.md",
    code: "https://github.com/sablier-labs/v2-core",
  },
  trm_banned_screening_endpoint:
    "https://api.trmlabs.com/public/v1/sanctions/screening",
  trm_address_screening_endpoint:
    "https://api.trmlabs.com/public/v2/screening/addresses",
  twitter: {
    product: "https://twitter.com/Sablier",
    username: "@Sablier",
  },
  article: {
    free: "https://blog.sablier.com/why-and-how-sablier-is-free-to-use/",
  },
  v1: {
    app: "https://v1-app.sablier.com",
    landing: "https://v1-landing.sablier.com",
    pay: "https://v1-pay.sablier.com",
    safe: "https://app.safe.global/share/safe-app?appUrl=https%3A%2F%2Fapp.sablier.com%2F",
  },
  v2: {
    localhost: "http://localhost:4001",
    client: "https://app.sablier.com",
    clientAirstream: "https://app.sablier.com/airstreams",
    clientDemo: "https://demo.sablier.com",
    clientDemoAirstream:
      "https://app.sablier.com/airstream/0x7a4e9da9250512929a6fcba79ea2fd62c39232c9-11155111/",
    landing: "https://sablier.com",
    landingLabs: "https://sablierlabs.co.uk",
    safe: "https://app.safe.global/share/safe-app?appUrl=https%3A%2F%2Fapp.sablier.com%2F",
  },
  video: {
    intro: "https://youtu.be/0XvJ112Jf1k",
  },
  vendor: {
    crisp: "https://crisp.chat/en/",
    mixpanel: "https://mixpanel.com/",
    sentry: "https://sentry.io/welcome/",
    vercel: "https://vercel.com/docs/concepts/analytics/privacy-policy",
    unstable_connector:
      "https://wagmi.sh/react/api/transports/unstable_connector",
  },
  logo: {
    icon: "https://files.sablier.com/icon-256x256.png",
  },
};

export default links;
