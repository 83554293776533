import { useCallback, useEffect, useMemo } from "react";
import { LockupShape, REQUEST_ID } from "@sablier/v2-constants";
import { useRequestAirstreamItem } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Airstream } from "@sablier/v2-models";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import extensions from "~/client/extensions";
import type { IPreviewAirstream, ISearchAirstream } from "@sablier/v2-models";
import {
  useAirstreamStoreAccessor,
  useAirstreamStoreEligible,
  useAirstreamStoreOwned,
  useAirstreamStorePreview,
  useAirstreamStoreSearch,
} from "./store";
import useAccount from "./useAccount";
import useAirstreamsOwned from "./useAirstreamsOwned";
import useToken from "./useToken";

function useLocal(id?: string) {
  const access = useAirstreamStoreAccessor();
  const client = useQueryClient();

  const { isLoading: isLoadingOwned } = useAirstreamsOwned();
  const { search: owned } = useAirstreamStoreOwned();
  const { search: searched } = useAirstreamStoreSearch();
  const { search: eligibleCampaigns } = useAirstreamStoreEligible();

  const isCached = useMemo(() => {
    const key = [
      ...REQUEST_ID.airstreamItemPreview,
      { unique: { airstreamId: id } },
    ];
    const entry = client.getQueryCache().find({ queryKey: key });

    if (_.isNil(entry) || _.isNil(entry.state.data)) {
      return false;
    }

    return true;
  }, [id, client]);

  const included = useMemo(() => {
    if (isCached) {
      return undefined;
    }

    const own = owned?.airstreams.find((airstream) => airstream.id === id);
    const search = searched?.airstreams.find(
      (airstream) => airstream.id === id,
    );
    const eligible = eligibleCampaigns?.airstreams.find(
      (airstream) => airstream.id === id,
    );

    if (!_.isNil(own)) {
      return own;
    }

    if (!_.isNil(search)) {
      return search;
    }

    if (!_.isNil(eligible)) {
      return eligible;
    }

    return undefined;
  }, [id, isCached, owned, searched, eligibleCampaigns]);

  /**
   * Enable the network query for the stream item if:
   * 1. The cache is empty (cache hasn't been hydrated)
   * 2. The client store stopped loading and doesn't include the item in question
   */

  const isEnabled = useMemo(() => {
    if (isCached) {
      return true;
    }

    if (isLoadingOwned) {
      return false;
    } else if (!_.isNil(included)) {
      return false;
    }

    return true;
  }, [isCached, isLoadingOwned, included]);

  const isLoading = useMemo(() => {
    if (!isCached && isLoadingOwned) {
      return true;
    }

    return false;
  }, [isCached, isLoadingOwned]);

  /**
   * ------------------------------------------------------------
   * If the airstream campaign is included, eagerly push it to the preview store.
   * The "isEnabled" flag will stop the remote query from happening.
   * -----------------------------------------------------------
   */

  const onSuccess = useCallback(
    (local: Airstream) => {
      const state = access();
      const set = state.api.setPreview;
      const stored = state.preview;

      /**
       * Only overwrite if the "included" data is new and not yet stored
       */

      if (_.isNil(stored) || stored.airstreams[0]?.id !== id) {
        const clone = _.clone(local!);
        const result: ISearchAirstream = {
          filter: {
            chainId: clone.chainId,
            airstreamIds: [clone.id],
          },
          options: stored?.options || {
            first: 1,
            isComplete: true,
          },
          airstreams: [clone],
        };
        set(result);
        console.info(
          "%c[preview airstream]",
          "color: cornflowerblue",
          `[included]`,
          {
            result,
          },
        );
      }
    },
    [access, id],
  );

  useEffect(() => {
    if (!_.isNil(included)) {
      onSuccess(included);
    }
  }, [included, onSuccess]);

  return {
    isEnabled,
    isLoading,
    included,
  };
}

function useRemote(id?: string, isEnabled = true) {
  const { preview: result } = useAirstreamStorePreview();
  const access = useAirstreamStoreAccessor();

  const onError = useCallback(
    (error: unknown, result: ISearchAirstream) => {
      const state = access();
      const set = state.api.setPreview;
      const preview = state.preview;

      const info = () =>
        console.info("%c[preview airstream]", "color: red", `[error]`, {
          error,
          result,
        });

      if (_.isNil(preview)) {
        set(result);
        info();
      } else {
        if (!_.isEqual(preview.filter, result.filter)) {
          set(result);
          info();
        }
      }
    },
    [access],
  );

  const onSuccess = useCallback(
    (_result: ISearchAirstream) => {
      const result = _.clone(_result);

      const state = access();
      const set = state.api.setPreview;
      const preview = state.preview;

      result.airstreams.forEach((airstream) => {
        const resolved = Airstream.findStreamShape(airstream, (stream) =>
          extensions.identify(stream),
        );
        if (!resolved.isUnknown) {
          airstream.streamShape = resolved.id as LockupShape;
        }
      });

      if (!_.isNil(result) && result.airstreams.length === 0) {
        const payload = _.clone(result);
        payload.options.error = "Item not found.";
        onError(payload.options.error, payload);
      } else {
        const info = (type = "new") =>
          console.info(
            "%c[preview airstream]",
            "color: cornflowerblue",
            `[${type}]`,
            {
              result,
            },
          );
        if (_.isNil(preview)) {
          set(result);
          info();
        } else {
          if (!_.isEqual(preview.filter, result.filter)) {
            set(result);
            info();
          }
        }
      }
    },
    [access, onError],
  );

  const { isLoading, error } = useRequestAirstreamItem({
    id,
    key: REQUEST_ID.airstreamItemPreview,
    isEnabled,
    onSuccess,
    onError,
  });

  return {
    error,
    isLoading,
    result,
  };
}

export default function useAirstreamCurrent() {
  const { address } = useAccount();
  const { query } = useRouter();
  const { t } = useT();

  const id = useMemo(() => {
    const parameter = _.toString(_.get(query, "id")).toLowerCase();
    if (_.isNilOrEmptyString(parameter)) {
      return undefined;
    }

    const { address, chainId } = Airstream.doSplitIdentifier(parameter);
    if (_.isNilOrEmptyString(address) || _.isNilOrEmptyString(chainId)) {
      return undefined;
    }
    return Airstream.doGenerateId(address, chainId);
  }, [query]);

  const { isEnabled } = useLocal(id);
  const { error } = useRemote(id, isEnabled);

  const { preview: stored } = useAirstreamStorePreview();

  /**
   * Prepare additional flags.
   * The end state for every system (local or remote) will fill in data in the preview-campaign storage slot.
   */

  const isLoading = useMemo(() => {
    return _.isNil(stored);
  }, [stored]);

  const isReady = useMemo(
    () => !isLoading && (!_.isNil(stored) || !_.isNil(error)),
    [error, isLoading, stored],
  );

  const airstream = useMemo(() => stored?.airstreams[0], [stored]);

  const isMissing = useMemo(
    () => (isReady && _.isNil(airstream)) || !_.isNil(error),
    [airstream, error, isReady],
  );

  const preview: IPreviewAirstream | Partial<IPreviewAirstream> =
    useMemo(() => {
      if (!_.isNil(airstream)) {
        return airstream.findPreview(t);
      }

      return {};
    }, [airstream, t]);

  const isDeformed = useMemo(() => {
    if (isLoading || _.isNil(airstream)) {
      return false;
    }

    return airstream.isDeformed();
  }, [isLoading, airstream]);

  const isAdmin = useMemo(() => {
    if (isReady && airstream) {
      return airstream.admin === address;
    }
    return false;
  }, [address, airstream, isReady]);

  const title = useMemo(() => {
    const prefix = _.capitalize(t("words.airstream"));
    if (_.isNil(preview.title)) {
      return prefix;
    }

    return [prefix, preview.title(_.toShortAddress(airstream?.admin, 6))]
      .filter((p) => p)
      .join(" ");
  }, [airstream, preview, t]);

  const token = useToken({ token: airstream?.token });

  return {
    airstream,
    error,
    id,
    isAdmin,
    isDeformed,
    isLoading,
    isMissing,
    isReady,
    preview,
    title,
    token,
  };
}
