import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useSessionStore as useStore } from "~/client/stores/session";

export function useSessionPolling() {
  const [isPolling, api] = useStore(
    useShallow((state) => [state.isPolling, state.api]),
  );

  return useMemo(
    () => ({
      isPolling,
      setPolling: api.setPolling,
    }),
    [api, isPolling],
  );
}

/**
 * Preference for the table layout in /payments
 */

export function useSessionLayout() {
  const [layout, api] = useStore(
    useShallow((state) => [state.layout, state.api]),
  );

  return useMemo(
    () => ({
      layout,
      setLayout: api.setLayout,
    }),
    [api, layout],
  );
}

export function useSessionStoreAccessor() {
  return useStore.getState;
}
