import { useMemo } from "react";
import { useWindowSize } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";

const theme = getTheme();

export default function useStreamFigureSize() {
  const workspace = useWindowSize();

  const sizes = useMemo(() => {
    const edge = parseInt(theme.sizes.edge, 10);

    if (workspace.width < theme.sizes.streamCircle + edge * 2) {
      return {
        height: workspace.width - edge,
        width: workspace.width - edge,
      };
    }

    return {
      height: theme.sizes.streamCircle,
      width: theme.sizes.streamCircle,
    };
  }, [workspace]);

  return {
    sizes,
  };
}
