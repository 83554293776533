import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { IFlow } from "@sablier/v2-models";
import type { IMilliseconds } from "@sablier/v2-types";
import useAccount from "./useAccount";

type Warning =
  | {
      state: "early";
      permission: string;
      start: {
        value?: IMilliseconds;
        label?: string;
      };
    }
  | {
      state: "ended";
      permission: string;
      end: {
        value?: IMilliseconds;
        label?: string;
      };
    }
  | {
      state: "connect";
    }
  | {
      state: "chain";
      chainId?: number;
    }
  | {
      state: "ready";
    }
  | {
      state: "banned";
    }
  | {
      state: "nonTransferable";
    }
  | {
      state: "ongoing";
    }
  | {
      state: "adjustSuspended";
    }
  | {
      state: "nonRefundable";
    }
  | {
      state: "nonVoidable";
    }
  | {
      state: "whitelist";
      whitelist: { address: string; label: string }[];
    };

export default function useFlowWarning(
  purpose:
    | "transfer"
    | "pause"
    | "withdraw"
    | "void"
    | "restart"
    | "refund"
    | "adjust",
  stream?: IFlow,
): Warning {
  const { address, chainId: fromChainId, isDisconnected } = useAccount();
  const { t } = useT();

  const whitelist = useMemo(() => {
    if (_.isNil(stream)) {
      return undefined;
    }

    const anyone = {
      /** Any public connected address*/
      address: _.toAddress(address),
      label: t("structs.connectedAccount"),
    };

    const sender = {
      address: _.toAddress(stream?.sender),
      label: t("words.sender"),
    };

    /**
     * The client app supports recipient actions from any type of account, including EOAs.
     * This means the recipient is expected to be the connected_account itself.
     */

    const recipient = {
      address: _.toAddress(stream?.recipient),
      label: t("words.recipient"),
    };

    const expected = {
      adjust: [sender],
      transfer: [recipient],
      pause: [sender],
      restart: [sender],
      void: [sender, recipient],
      refund: [sender],
      withdraw: [anyone],
    }[purpose];

    if (
      expected.some((e) => {
        if (e.label === t("words.sender")) {
          if (e.address === _.toAddress(address)) {
            return true;
          }
        }

        if (
          e.label === t("words.recipient") &&
          e.address === _.toAddress(address)
        ) {
          return true;
        }

        if (
          e.label === t("structs.connectedAccount") &&
          e.address === _.toAddress(address)
        ) {
          return true;
        }

        return false;
      })
    ) {
      /** Found an expected party (!_.isNil(expected.some)) - don't build the whitelist */
      return undefined;
    }

    return expected;
  }, [address, purpose, stream, t]);

  return useMemo(() => {
    if (!_.isNil(stream) && stream) {
      const permission = {
        adjust: t("permissions.adjust"),
        restart: t("permissions.restart"),
        refund: t("permissions.refund"),
        void: t("permissions.void"),
        pause: t("permissions.pause"),
        transfer: t("permissions.transfer"),
        withdraw: t("permissions.withdraw"),
      }[purpose];

      if (purpose === "transfer") {
        if (!stream.isTransferable) {
          return {
            state: "nonTransferable",
          };
        }
      }

      if (purpose === "pause") {
        if (stream.isPaused) {
          return {
            state: "ended",
            permission,
            end: {
              value: stream?.pausedTime,
              label: t("form.label.streamPauseDate"),
            },
          };
        }
      }

      if (purpose === "void") {
        if (stream.isVoided) {
          return {
            state: "ended",
            permission,
            end: {
              value: stream?.voidedTime,
              label: t("form.label.streamVoidDate"),
            },
          };
        }

        if (!stream.debtAmount.raw.isGreaterThan(new BigNumber(0))) {
          return {
            state: "nonVoidable",
          };
        }
      }

      if (purpose === "refund") {
        if (stream.debtAmount.raw.isGreaterThan(new BigNumber(0))) {
          return {
            state: "nonRefundable",
          };
        }
      }

      if (purpose === "adjust") {
        if (!stream.isAlive) {
          return {
            state: "adjustSuspended",
          };
        }
      }

      if (purpose === "restart") {
        if (stream.isAlive) {
          return {
            state: "ongoing",
          };
        }
      }

      if (whitelist) {
        return { state: "whitelist", whitelist };
      }

      if (fromChainId !== stream?.chainId) {
        return { state: "chain", chainId: stream.chainId };
      }
    }

    if (isDisconnected) {
      return { state: "connect" };
    }

    return { state: "ready" };
  }, [fromChainId, isDisconnected, purpose, stream, t, whitelist]);
}
