import { useMemo } from "react";
import { LockupStatus, LockupVersion } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ILockup } from "@sablier/v2-models";
import type { IMilliseconds } from "@sablier/v2-types";
import useAccount from "./useAccount";

type Warning =
  | {
      state: "canceled";
      permission: string;
      end: {
        value?: IMilliseconds;
        label?: string;
      };
    }
  | {
      state: "early";
      permission: string;
      start: {
        value?: IMilliseconds;
        label?: string;
      };
    }
  | {
      state: "ended";
      permission: string;
      end: {
        value?: IMilliseconds;
        label?: string;
      };
    }
  | {
      state: "depleted";
      permission: string;
    }
  | {
      state: "connect";
    }
  | {
      state: "chain";
      chainId?: number;
    }
  | {
      state: "ready";
    }
  | {
      state: "banned";
    }
  | {
      state: "nonTransferable";
    }
  | {
      state: "whitelist";
      whitelist: { address: string; label: string }[];
    };

export default function useLockupWarning(
  purpose: "cancel" | "transfer" | "renounce" | "withdraw",
  stream?: ILockup,
): Warning {
  const { address, chainId: fromChainId, isDisconnected } = useAccount();
  const { t } = useT();

  const whitelist = useMemo(() => {
    if (_.isNil(stream)) {
      return undefined;
    }

    /**
     * The client app supports sender actions with native senders or through proxies.
     * This means the sender is expected to be either a proxy a.k.a. proxies[connected_account] or a native account.
     */

    const anyone = {
      /** Any public connected address*/
      address: _.toAddress(address),
      label: t("structs.connectedAccount"),
    };

    const sender = {
      /** Native sender (no proxy) */
      address: _.toAddress(stream?.sender),
      label: t("words.sender"),
    };

    const proxender = {
      /** Proxied sender (through proxy) */
      address: _.toAddress(stream?.proxender),
      label: t("words.sender"),
    };

    /**
     * The client app supports recipient actions from any type of account, including EOAs.
     * This means the recipient is expected to be the connected_account itself.
     */

    const recipient = {
      address: _.toAddress(stream?.recipient),
      label: t("words.recipient"),
    };

    const expected = {
      renounce: stream.proxied ? [proxender] : [sender],
      cancel: stream.proxied ? [proxender] : [sender],
      transfer: [recipient],
      withdraw:
        LockupVersion.V20 === stream.version && stream.proxied
          ? [recipient, proxender]
          : LockupVersion.V20 === stream.version ||
            LockupVersion.V21 === stream.version
          ? [recipient, sender]
          : [anyone] /** LockupVersion.V22 enabled public withdrawals */,
    }[purpose];

    if (
      expected.some((e) => {
        if (e.label === t("words.sender")) {
          if (e.address === _.toAddress(address)) {
            return true;
          }
        }

        if (
          e.label === t("words.recipient") &&
          e.address === _.toAddress(address)
        ) {
          return true;
        }

        if (
          e.label === t("structs.connectedAccount") &&
          e.address === _.toAddress(address)
        ) {
          return true;
        }

        return false;
      })
    ) {
      /** Found an expected party (!_.isNil(expected.some)) - don't build the whitelist */
      return undefined;
    }

    return expected;
  }, [address, purpose, stream, t]);

  return useMemo(() => {
    if (!_.isNil(stream) && stream) {
      const permission = {
        renounce: t("permissions.renounce"),
        cancel: t("permissions.cancel"),
        transfer: t("permissions.transfer"),
        withdraw: t("permissions.withdraw"),
      }[purpose];

      if (purpose === "withdraw") {
        if (stream.status === LockupStatus.PENDING) {
          return {
            state: "early",
            permission,
            start: {
              value: stream.startTime,
              label: t("form.label.startTime"),
            },
          };
        }

        if (stream.isDepleted) {
          return {
            state: "depleted",
            permission,
          };
        }

        if (stream.isCliffing) {
          return {
            state: "early",
            permission,
            start: {
              value: stream.cliffTime,
              label: t("form.label.endOfCliff"),
            },
          };
        }
      }

      if (purpose === "transfer") {
        if (!stream.isTransferable) {
          return {
            state: "nonTransferable",
          };
        }
      }

      if (purpose === "cancel" || purpose === "renounce") {
        if (stream.isCancelable === false) {
          return {
            state: "ended",
            permission,
            end: {
              value: stream?.renounceTime,
              label: t("form.label.streamRenounceDate"),
            },
          };
        }

        if (stream.isCanceled) {
          return {
            state: "ended",
            permission,
            end: {
              value: stream?.canceledTime,
              label: t("form.label.streamCancelDate"),
            },
          };
        }

        if (stream.isSettled) {
          return {
            state: "ended",
            permission,
            end: {
              value: stream.endTime,
              label: t("form.label.streamEndDate"),
            },
          };
        }
      }

      if (whitelist) {
        return { state: "whitelist", whitelist };
      }

      if (fromChainId !== stream?.chainId) {
        return { state: "chain", chainId: stream.chainId };
      }
    }

    if (isDisconnected) {
      return { state: "connect" };
    }

    return { state: "ready" };
  }, [fromChainId, isDisconnected, purpose, stream, t, whitelist]);
}
