import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { useLockupCreateField } from "~/client/hooks";
import Fields from "./fields";

export function Group({ streamId }: { streamId: string }) {
  const streams = useLockupCreateField("streams");

  const isDuration = useMemo(
    () =>
      streams.field.value?.find((s) => s.id === streamId)?.duration.isActive,
    [streamId, streams],
  );

  return (
    <>
      <Fields.GroupUnlock streamId={streamId} />
      {isDuration ? (
        <Fields.GroupDuration streamId={streamId} />
      ) : (
        <Fields.GroupEnd streamId={streamId} />
      )}
    </>
  );
}

const Form = {
  Group,
};

export default Form;
