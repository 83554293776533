import { LockupShapes, links } from "@sablier/v2-constants";
import type { IExtensionConstructorParams } from "../../system/setup";
import { Extension } from "../../system/setup";

export interface IDynamicExponential {
  purpose: Extension;
  exponent: string;
}

export function initial(
  _params: IExtensionConstructorParams,
): IDynamicExponential {
  return {
    purpose: Extension.DYNAMIC_EXPONENTIAL,
    exponent: "3",
  };
}

export const EXPECTED_SEGMENTS = 1;
export const shape = LockupShapes.dynamicExponential.id;
export const template = links.streamTemplate.dynamicExponential;
