import { FlowStatus } from "./stream";

const mock = {
  DAI: {
    symbol: "DAI",
    name: "Dai Mockcoin",
    address: "0x776b6fc2ed15d6bb5fc32e0c89de68683118c62a",
    image: "https://files.sablier.com/tokens/DAI.png",
  },
  get stream() {
    return {
      alias: "FL-1-1",
      chainId: 11155111,
      token: mock.DAI,
      contract: "0xf111aF93B0A5A111222f285a3B5355B8579998aa",
      recipient: "0xf976aF93B0A5A9F55A7f285a3B5355B8575Eb5bc",
      sender: "0x9ad7CAD4F10D0c3f875b8a2fd292590490c9f491",

      streamedAmount: "14314.9",
      withdrawnAmount: "8453.21",
      withdrawableAmount: "2100",
      debtAmount: "1761.69",

      startTime: "1727190638552",

      status: FlowStatus.STREAMING_DEBT as FlowStatus,
    };
  },

  preview: {
    shape: "Flow stream",
    status: "Streaming",
    rate: "2345",
    fundedToTime: "1727195638552",
    inDebtSince: "1727195638552",
  },
};

export default mock;
