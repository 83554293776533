import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

export interface Props
  extends Omit<ISInput, "left" | "right" | "onChange" | "onClick" | "value"> {
  onChange: () => void;
  value?: string;
}

function Interval(props: Props) {
  const { t } = useT();
  const value = useMemo(() => {
    if (_.isNilOrEmptyString(props.value)) {
      return undefined;
    }
    return props.value;
  }, [props.value]);

  const formatted = useMemo(() => {
    const right: ISInput["right"] = [];
    const left: ISInput["left"] = [];

    left.push(sides.slash());
    right.push(sides.arrowDown(props.onChange));

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      placeholder: t("form.placeholder.duration"),
      ...props,
      isPreview: "soft" as const,
      right,
      left,
      onClick: props.onChange,
      value,
    };
  }, [props, value, t]);

  return <Template.Text {...formatted} />;
}

export default Interval;
