import { useMemo } from "react";
import { QUERY_CACHE_TIME, REQUEST_ID } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Token } from "@sablier/v2-models";
import { useQuery } from "@tanstack/react-query";
import { useConfig, usePublicClient } from "wagmi";
import type { IAddress, IToken } from "@sablier/v2-types";

interface Props {
  chainId: number | undefined;
  owner: IAddress | undefined;
  token: IToken | undefined;
}

export default function useTokenBalance({ chainId, owner, token }: Props) {
  const library = useConfig();
  const provider = usePublicClient({ chainId });

  const isEnabled = useMemo(
    () =>
      !_.isNilOrEmptyString(chainId) &&
      !_.isNilOrEmptyString(owner) &&
      !_.isNilOrEmptyString(token?.address),
    [chainId, owner, token],
  );

  const unique = useMemo(
    () => ({
      chainId,
      token: token?.address,
      owner,
    }),
    [chainId, token, owner],
  );

  const {
    data: raw,
    error,
    isLoading,
  } = useQuery({
    queryKey: [...REQUEST_ID.tokenBalance, { unique }],
    queryFn: async () => {
      const result = await Token.getBalanceFor(library, {
        address: token!.address,
        owner: owner!,
        provider: provider!,
      });

      const value = _.toValue({
        raw: !_.isNilOrEmptyString(result) ? result : "0",
        decimals: token?.decimals,
      });

      console.info(
        "%c[balance]",
        "color: green",
        "[async]",
        _.toString(token?.address || "").substring(0, 5) || undefined,
        _.toString(owner || "").substring(0, 5) || undefined,
        value.humanized.toString() || undefined,
        result?.toString() || undefined,
      );

      return result;
    },
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    enabled: isEnabled,
  });

  const value = useMemo(
    () =>
      _.toValue({
        raw: !_.isNilOrEmptyString(raw) ? raw : "0",
        decimals: token?.decimals,
      }),
    [raw, token],
  );

  return {
    ...value,
    error,
    isLoading,
  };
}
