import {
  FlowCategory,
  FlowStatus,
  FlowVersion,
  LockupCategory,
  LockupStatus,
  LockupVersion,
  StreamFlavor,
  chains,
} from "@sablier/v2-constants";
import { Translate } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import type {
  IAddress,
  IAlias,
  IMilliseconds,
  IOptions,
  ISeconds,
  IStreamAlias,
  IStreamId,
  IValue,
} from "@sablier/v2-types";
import Segment from "../../Segment";
import Token from "../../Token";
import Action from "../StreamAction";

export type StreamAction<Flavor extends StreamFlavor> = Action<Flavor>;
export type StreamCategory<Flavor extends StreamFlavor> = Flavor extends "Flow"
  ? FlowCategory
  : LockupCategory;
export type StreamVersion<Flavor extends StreamFlavor> = Flavor extends "Flow"
  ? FlowVersion
  : LockupVersion;
export type StreamStatus<Flavor extends StreamFlavor> = Flavor extends "Flow"
  ? FlowStatus
  : LockupStatus;

export type Identity = {
  /** The address of the stream contract */
  address?: IAddress;
  /** The alias of the stream contract */
  alias?: IAlias;
  /** Identifier with the contract address */
  withAddress?: IStreamId;
  /** Identifier with the contract alias */
  withAlias?: IStreamAlias;
};

export type IFilterStream = {
  chainId?: number;
  streamIds?: IStreamId[];
  sender?: IAddress;
  recipient?: IAddress;
  token?: IAddress;
};

export interface ISearchStream<
  IStream extends Stream<StreamFlavor> = Stream<StreamFlavor>,
> {
  filter: IFilterStream;
  options: IOptions;
  streams: IStream[];
}

export type Params<
  Flavor extends StreamFlavor,
  Category = StreamCategory<Flavor>,
  Version = StreamVersion<Flavor>,
> = {
  alias: string;
  batch?: {
    label?: string | null;
    size: string;
  };
  chainId: number;
  category?: Category | string; // TODO remove optional when the flow subgraph gets updated
  contract: {
    address: IAddress;
  };
  hash: string | undefined;
  id: string;
  position: string | number | null | undefined;
  recipient: IAddress;
  sender: string;
  subgraphId?: string;
  timestamp?: ISeconds;
  transferable: boolean;
  tokenId: string;
  version: Version | string;
};

export default abstract class Stream<
  Flavor extends StreamFlavor,
  Category = StreamCategory<Flavor>,
  Status = StreamStatus<Flavor>,
  Version = StreamVersion<Flavor>,
> {
  public payload: Params<Flavor>;

  readonly alias: IStreamAlias;
  public category: Category; // TODO make readonly when the flow subgraph gets updated
  readonly chainId: number;
  readonly contract: IAddress;
  public flavor: StreamFlavor | undefined = undefined;
  readonly id: IStreamId;
  readonly instantiated: IMilliseconds;
  readonly recipient: IAddress;
  readonly sender: IAddress;
  readonly token: Token;
  readonly tokenId: string;
  public version: Version;

  public segments: Segment[] = [];

  readonly batch:
    | {
        label: string | undefined;
        size: number;
        position: number;
      }
    | undefined = undefined;
  readonly hash: string | undefined = undefined;
  readonly subgraphId: string | undefined = undefined;
  readonly timestamp: IMilliseconds | undefined = undefined;

  constructor(params: Params<Flavor>, token: Token) {
    this.payload = params;

    this.alias = _.toAlias(params.alias);
    this.category = params.category as Category;
    this.chainId = _.toNumber(params.chainId);
    this.contract = params.contract.address;
    this.hash = params.hash;
    this.id = params.id.toLowerCase();
    this.instantiated = Date.now().toString();
    this.recipient = _.toAddress(params.recipient);
    this.sender = _.toAddress(params.sender);
    this.subgraphId = params.subgraphId;
    this.timestamp = _.toMilliseconds(params.timestamp);
    this.token = token;
    this.tokenId = params.tokenId;
    this.version = params.version as Version;

    this.batch =
      params.batch && !_.isNilOrEmptyString(params.batch.label)
        ? {
            label: params.batch.label || undefined,
            size: _.toNumber(params.batch.size),
            position: _.toNumber(params.position),
          }
        : undefined;
  }

  /**
   * ---------------------------------
   * ------- Abstract Methods -------
   * ---------------------------------
   */

  abstract doUpdate(): void;
  abstract findAccent(): string;
  abstract findStatus(time?: number): Status;
  abstract findStreamedAmount(
    time: IMilliseconds,
    isSimulated: boolean,
  ): IValue;
  abstract findPreview(t: Translate): object;
  abstract get isAlive(): boolean;

  /**
   * ---------------------------------
   * ------- Concrete Methods --------
   * ---------------------------------
   */

  get isBatched() {
    return !_.isNil(this.batch) && !_.isNilOrEmptyString(this.batch.position);
  }

  /**
   * ------------------------------------
   * ---------- Static Methods ----------
   * ------------------------------------
   * These share a common implementation.
   * ------------------------------------
   */

  static isId(value: IStreamId | string | undefined) {
    if (!_.isNilOrEmptyString(value)) {
      if (value.includes("-") && value.split("-").length === 3) {
        const parts = value.split("-");
        if (
          _.isEthereumAddress(parts[0]) &&
          new BigNumber(parts[1]).isFinite() &&
          new BigNumber(parts[2]).isFinite()
        ) {
          if (
            Object.values(chains).find(
              (chain) => chain.chainId.toString() === parts[1],
            )
          ) {
            return true;
          }
        }
      }
    }

    return false;
  }

  static isAlias(
    value: IStreamAlias | string | undefined,
    supported?: { alias: IAlias }[],
  ) {
    if (!_.isNilOrEmptyString(value)) {
      if (
        value.includes("-") &&
        (value.split("-").length === 3 || value.split("-").length === 4)
      ) {
        const parts = value.split("-");
        if (
          _.isString(parts[0]) &&
          new BigNumber(parts[1]).isFinite() &&
          new BigNumber(parts[2]).isFinite()
        ) {
          if (!_.isNilOrEmptyString(supported)) {
            return !_.isNilOrEmptyString(
              supported.find(
                (item) => item.alias.toLowerCase() === parts[0].toLowerCase(),
              ),
            );
          }
        }
      }
    }

    return false;
  }

  static doFormatFilter({
    chainId,
    sender,
    recipient,
    streamIds,
    token,
  }: {
    chainId?: number | string | undefined;
    sender?: IAddress | undefined;
    recipient?: IAddress | undefined;
    streamIds?: IStreamId[] | undefined;
    token?: IAddress | undefined;
  } = {}): IFilterStream {
    const filter: IFilterStream = {
      chainId: undefined,
      sender: undefined,
      recipient: undefined,
      streamIds: undefined,
      token: undefined,
    };

    if (!_.isNilOrEmptyString(chainId)) {
      filter.chainId = _.toNumber(chainId) || undefined;
    }

    if (!_.isNilOrEmptyString(sender)) {
      filter.sender = _.toAddress(sender);
    }

    if (!_.isNilOrEmptyString(recipient)) {
      filter.recipient = _.toAddress(recipient);
    }

    if (!_.isNilOrEmptyString(streamIds)) {
      if (_.isArray(streamIds) && streamIds.length > 0) {
        filter.streamIds = streamIds.map((item) => item || "");
      }
    }

    if (!_.isNilOrEmptyString(token)) {
      filter.token = _.toAddress(token);
    }

    return filter;
  }

  static doSplitIdentifier(value: IStreamAlias | IStreamId | undefined): {
    source?: string;
    chainId?: number;
    tokenId?: string;
  } {
    try {
      if (!_.isNilOrEmptyString(value)) {
        const parts = value.split("-");
        if (parts.length === 3) {
          return {
            source: _.toString(parts[0]).toLowerCase(),
            chainId: _.toNumber(parts[1]),
            tokenId: _.toString(parts[2]),
          };
        }
      }
    } catch (error) {
      vendors.crash.log(error);
    }
    return {
      source: undefined,
      chainId: undefined,
      tokenId: undefined,
    };
  }

  static doIdentify(
    value: IStreamAlias | IStreamId,
    aliases: { alias: IAlias; address: IAddress }[],
  ): Identity {
    if (Stream.isAlias(value, aliases)) {
      const withAlias = _.toAlias(value);
      const parts = Stream.doSplitIdentifier(value);
      const alias = parts.source;

      const address = aliases.find(
        (item) => item.alias.toLowerCase() === alias?.toLowerCase(),
      )?.address;
      const withAddress = address
        ? `${_.toAddress(address)}-${parts.chainId}-${parts.tokenId}`
        : undefined;

      return {
        address,
        alias,
        withAddress,
        withAlias,
      };
    } else {
      const parts = Stream.doSplitIdentifier(value);
      const withAddress = value.toLowerCase();
      const address = _.toAddress(parts.source);

      const alias = aliases?.find(
        (item) => _.toAddress(item.address) === address,
      )?.alias;
      const withAlias = alias
        ? `${alias.toLowerCase()}-${parts.chainId}-${parts.tokenId}`
        : undefined;

      return {
        address,
        alias,
        withAddress,
        withAlias,
      };
    }
  }

  static doGenerateId(
    address: IAddress,
    chainId: number,
    tokenId: string,
  ): IStreamId {
    return `${_.toAddress(address)}-${chainId}-${tokenId}`;
  }

  static doGenerateAlias(
    alias: IAlias,
    chainId: number,
    tokenId: string,
  ): IStreamAlias {
    return _.toAlias(`${alias}-${chainId}-${tokenId}`)!;
  }

  static findAliases(
    chainId: number | undefined,
    contracts:
      | Record<number, Record<string, { alias: IAlias; address: IAddress }>>
      | undefined,
  ) {
    if (_.isNilOrEmptyString(chainId) || _.isNil(contracts)) {
      return [];
    }

    return Object.values(contracts[chainId] || {}).map((item) => ({
      address: _.toAddress(item.address),
      alias: item.alias.toLowerCase(),
    }));
  }
}
