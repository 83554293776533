import airstreamConfig, { endpoint as airstreamEndpoint } from "./airstream";
import flowConfig, { endpoint as flowEndpoint } from "./flow";
import protocolConfig, { endpoint as protocolEndpoint } from "./protocol";

const configs = {
  protocol: {
    config: protocolConfig,
    endpoint: protocolEndpoint,
  },
  airstream: {
    config: airstreamConfig,
    endpoint: airstreamEndpoint,
  },
  flow: {
    config: flowConfig,
    endpoint: flowEndpoint,
  },
};

export default configs;
