import { FlowStatus, ZERO } from "@sablier/v2-constants";
import { Translate } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { hslToColorString } from "polished";
import { encodePacked, keccak256 } from "viem";
import type { IFilterStream, ISearchStream } from "../abstract/Stream";
import type { Params } from "./Attributes";
import type {
  IAddress,
  IAlias,
  IValue,
  IWagmiAddress,
} from "@sablier/v2-types";
import Token from "../Token";
import Attributes from "./Attributes";

export type Preview = {
  status: string;
  significantDateOnly: string;
  significantDateShort: string;
  title: string;
  transferable: string;
  ratePerMonth: string;
};

export type IFilterFlow = IFilterStream;
export type ISearchFlow = ISearchStream<Functionality>;

export type IFlowChip =
  | "voided"
  | "debt"
  | "done"
  | "returnable"
  | "withdrawable";

/**
 * ------------------------------
 * The "Functionality" part of the Stream class will
 * include only methods that work on top of the Attributes.
 * ------------------------------
 * Inheritance is not used here for OOP purposes,
 * but for readability (smaller class parts)
 * ------------------------------
 */
export default class Functionality extends Attributes {
  constructor(params: Params, token: Token) {
    super(params, token);
    this.doUpdate();
  }

  doUpdate() {
    this._streamedAmount = this.findStreamedAmount();
    this._streamedAmountEstimate = this.findStreamedAmount();
    this._returnableAmount = _.toValue({
      decimals: this.token.decimals,
      raw: this.availableAmount.raw.isGreaterThan(this.streamedAmount.raw)
        ? this.availableAmount.raw.minus(this.streamedAmount.raw)
        : "0",
    });

    const notWithdrawnAmount = this._streamedAmount.raw.minus(
      this.withdrawnAmount.raw,
    );
    this._withdrawableAmount = _.toValue({
      decimals: this.token.decimals,
      raw: BigNumber.minimum(notWithdrawnAmount, this.availableAmount.raw),
    });
    this._withdrawnAmountPercentage = this.withdrawnAmount.raw.isZero()
      ? ZERO().raw
      : this._streamedAmount.raw
          .dividedBy(this.withdrawnAmount.raw)
          .times(new BigNumber(100));
    this._debtAmount = _.toValue({
      decimals: this.token.decimals,
      raw: BigNumber.maximum(
        "0",
        notWithdrawnAmount.minus(this.availableAmount.raw),
      ),
    });

    this._status = this.findStatus();
  }

  static findAliases(
    chainId: number | undefined,
    contracts:
      | Record<number, Record<string, { alias: IAlias; address: IAddress }>>
      | undefined,
  ) {
    if (_.isNilOrEmptyString(chainId) || _.isNil(contracts)) {
      return [];
    }

    return Object.values(contracts[chainId] || {}).map((item) => ({
      address: _.toAddress(item.address),
      alias: item.alias.toLowerCase(),
    }));
  }

  findChips() {
    const set: IFlowChip[] = [];
    switch (this.status) {
      case FlowStatus.VOIDED: {
        if (
          !this.withdrawableAmount.humanized.isZero() ||
          !this.returnableAmount.humanized.isZero()
        ) {
          set.push("voided");
        } else {
          set.push("done");
        }

        break;
      }
      case FlowStatus.STREAMING_FUNDED: {
        if (!this.withdrawableAmount.humanized.isZero()) {
          set.push("withdrawable");
        }
        if (!this.returnableAmount.humanized.isZero()) {
          set.push("returnable");
        }
        break;
      }
      case FlowStatus.STREAMING_DEBT: {
        if (!this.debtAmount.humanized.isZero()) {
          set.push("debt");
        }
        if (!this.withdrawableAmount.humanized.isZero()) {
          set.push("withdrawable");
        }

        break;
      }
      case FlowStatus.PAUSED_FUNDED: {
        if (
          this.withdrawableAmount.humanized.isZero() &&
          this.returnableAmount.humanized.isZero()
        ) {
          set.push("done");
        } else {
          if (!this.withdrawableAmount.humanized.isZero()) {
            set.push("withdrawable");
          }
          if (!this.returnableAmount.humanized.isZero()) {
            set.push("returnable");
          }
        }
        break;
      }
      case FlowStatus.PAUSED_DEBT: {
        if (!this.debtAmount.humanized.isZero()) {
          set.push("debt");
        }
        if (!this.withdrawableAmount.humanized.isZero()) {
          set.push("withdrawable");
        }

        break;
      }
    }
    return set;
  }

  findTips(t: Translate) {
    const chips = this.findChips();

    const tips = [];

    chips.forEach((chip) => {
      switch (chip) {
        case "debt": {
          tips.push(t("descriptions.flow.tips.debt"));
          break;
        }
        case "withdrawable": {
          tips.push(t("descriptions.flow.tips.withdrawable"));
          break;
        }
        case "done": {
          tips.push(t("descriptions.flow.tips.voided.done"));
          break;
        }
        case "voided": {
          tips.push(t("descriptions.flow.tips.voided.debt"));
          break;
        }
      }
    });

    switch (this.status) {
      case FlowStatus.STREAMING_FUNDED: {
        tips.push(t("descriptions.flow.tips.streaming.funded"));
        break;
      }
      case FlowStatus.STREAMING_DEBT: {
        tips.push(t("descriptions.flow.tips.streaming.debt"));
        break;
      }
      case FlowStatus.PAUSED_FUNDED: {
        tips.push(t("descriptions.flow.tips.paused.funded"));
        break;
      }
      case FlowStatus.PAUSED_DEBT: {
        tips.push(t("descriptions.flow.tips.paused.debt"));
        break;
      }
      default: {
        break;
      }
    }

    return tips;
  }

  findStatus(): FlowStatus {
    if (this.isVoided) {
      return FlowStatus.VOIDED;
    }

    if (this.debtAmount.raw.isGreaterThan(new BigNumber(0))) {
      if (this.isPaused) {
        return FlowStatus.PAUSED_DEBT;
      }
      return FlowStatus.STREAMING_DEBT;
    } else {
      if (this.isPaused) {
        return FlowStatus.PAUSED_FUNDED;
      }
      return FlowStatus.STREAMING_FUNDED;
    }
  }

  /**
   * Generates a pseudo-random HSL color by hashing together the `chainId`, the `sablier` address,
   * and the `streamId`. This will be used as the accent color for the SVG.
   *
   * See v2-core/.../SablierV2NFTDescriptor.sol - generateAccentColor for context
   */

  findAccent() {
    /**
     * Hash the parameters to generate a pseudo-random bit field, which will be used as entropy.
     * | Hue     | Saturation | Lightness | -> Roles
     * | [31:16] | [15:8]     | [7:0]     | -> Bit positions
     */

    const bitField = new BigNumber(
      keccak256(
        encodePacked(
          ["uint256", "address", "uint256"],
          [
            _.toBigInt(this.chainId),
            this.contract as IWagmiAddress,
            _.toBigInt(this.tokenId),
          ],
        ),
      ),
    ).mod(2 ** 32);

    /**
     * The hue is a degree on a color wheel, so its range is [0, 360).
     * Shifting 16 bits to the right means using the bits at positions [31:16].
     */

    const hue = new BigNumber(bitField.dividedToIntegerBy(2 ** 16).modulo(360))
      .mod(2 ** 256)
      .toNumber();

    /**
     * The saturation is a percentage where 0% is grayscale and 100%, but here the
     * range is bounded to [20,100] to make the colors more lively.
     * Shifting 8 bits to the risk and applying an 8-bit mask means using the bits at positions [15:8].
     */

    const saturation = new BigNumber(
      bitField.dividedToIntegerBy(2 ** 8).mod(2 ** 8),
    )
      .modulo(80)
      .plus(20)
      .toNumber();

    /**
     * The lightness is typically a percentage between 0% (black) and 100% (white), but here the range
     * is bounded to [30,100] to avoid dark colors.
     * Applying an 8-bit mask means using the bits at positions [7:0].
     */

    const lightness = new BigNumber(bitField.mod(2 ** 8))
      .modulo(70)
      .plus(30)
      .toNumber();

    /**
     * Convert HSL to RGB to pack is easier as a URI parameter
     */

    const set = {
      hue: Math.trunc(hue),
      saturation: Math.trunc(saturation) / 100,
      lightness: Math.trunc(lightness) / 100,
    } as const;

    return hslToColorString(set);
  }

  /**
   * Due to the possibility of changing the rate per second,
   * findStreamedAmount should always calculate the amount
   * for "now" timestamp.
   *
   * // TODO add calculations based on historical rate/second adjustments
   */
  findStreamedAmount(): IValue {
    const now = Date.now();
    const nowBN = new BigNumber(now);
    const lastAdjustmentTimestampBN = new BigNumber(
      this.lastAdjustmentTimestamp,
    );
    const snapshotAmountBN = this.snapshotAmount.raw;
    const ratePerSecondBN = this.ratePerSecond.raw;
    const ratePerMillisecondBN = ratePerSecondBN.dividedBy(1000);

    const elapsed = nowBN.minus(lastAdjustmentTimestampBN);
    /** Compute the rate per millisecond for the entire stream */
    const streamedBN = snapshotAmountBN.plus(
      elapsed.times(ratePerMillisecondBN),
    );

    return _.toValue({
      decimals: this.token.decimals,
      raw: streamedBN,
    });
  }

  /**
   *
   * ----------------------------------------------------------------------------
   *
   */

  findPreview(t: Translate): Preview {
    const transferable = this.isTransferable
      ? t("structs.canBeTransferred")
      : t("structs.cannotBeTransferred");

    const ratePerMonth = _.toValue({
      decimals: this.token.decimals,
      raw: _.fromRatePerSecond(this.ratePerSecond.raw.toString(), "month"),
    }).humanized.toString();

    const status = (() => {
      switch (this.status) {
        case FlowStatus.STREAMING_FUNDED:
        case FlowStatus.STREAMING_DEBT:
          return _.capitalize(t("words.streaming"));
        case FlowStatus.PAUSED_DEBT:
        case FlowStatus.PAUSED_FUNDED:
          return _.capitalize(t("words.paused"));
        case FlowStatus.VOIDED:
          return _.capitalize(t("words.voided"));
        default:
          return "";
      }
    })();

    const significant = (dateFormat: "date-short" | "date-only") => {
      switch (this.status) {
        case FlowStatus.STREAMING_FUNDED: {
          const depletionDate = _.toDuration(this.depletionTime, dateFormat)[0];
          return `${t("structs.fundedTo")} ${depletionDate}`;
        }
        case FlowStatus.STREAMING_DEBT: {
          const now = Date.now();
          const debtDuration = _.toDuration(
            new BigNumber(now)
              .minus(new BigNumber(this.depletionTime))
              .toString(),
            "time-short",
          )[0];
          return `${t("structs.inDebtFor")} ${debtDuration}`;
        }
        case FlowStatus.PAUSED_DEBT:
          return t("structs.inDebtPaused");
        case FlowStatus.PAUSED_FUNDED: {
          const pausedDate = _.toDuration(this.pausedTime, dateFormat)[0];
          return `${t("structs.pausedOn")} ${pausedDate}`;
        }
        case FlowStatus.VOIDED: {
          const pausedDate = _.toDuration(this.voidedTime, dateFormat)[0];
          return `${t("structs.voidedOn")} ${pausedDate}`;
        }
        default:
          return "";
      }
    };

    const significantDateShort = significant("date-short");
    const significantDateOnly = significant("date-only");

    const title = !_.isNil(this.alias)
      ? `#${this.alias}`
      : `#${this.chainId}-${this.tokenId}`;

    return {
      ratePerMonth,
      status,
      title,
      transferable,
      significantDateShort,
      significantDateOnly,
    };
  }
}
