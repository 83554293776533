import { useCallback, useEffect } from "react";
import { RecursiveSlug } from "@sablier/v2-types/utils";
import { pages } from "~/client/constants";
import { useSessionStoreAccessor } from "./store/useSessionStore";

interface Props {
  condition?: boolean | undefined;
  page: RecursiveSlug<typeof pages> | RecursiveSlug<typeof pages>[];
}

export default function usePollingAwareness({
  condition = undefined,
  page: _page,
}: Props) {
  const session = useSessionStoreAccessor();

  const stop = useCallback(() => {
    session().api.setPolling(false);
  }, [session]);

  const start = useCallback(() => {
    session().api.setPolling(true);
  }, [session]);

  useEffect(() => {
    const enter = start;
    const exit = stop;

    if (condition) {
      exit();
    } else {
      enter();
    }

    return () => {
      enter();
    };
  }, [condition, start, stop]);

  return { start, stop };
}
