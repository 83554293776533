import { useEffect, useMemo } from "react";
import { useIsSanctioned } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { useModalSanctionStream } from "./modals";
import useFlowCurrent from "./useFlowCurrent";

export default function useIsSanctionedFlow() {
  const { stream, isLoading } = useFlowCurrent();
  const { setOpen } = useModalSanctionStream();

  const { isSanctioned: isSenderSanctioned } = useIsSanctioned(stream?.sender);
  const { isSanctioned: isRecipientSanctioned } = useIsSanctioned(
    stream?.recipient,
  );
  const { isSanctioned: isCreatorSanctioned } = useIsSanctioned(
    stream?.creator,
  );
  const { isSanctioned: isTokenSanctioned } = useIsSanctioned(
    stream?.token.address,
  );

  const isSanctioned = useMemo(
    () =>
      isSenderSanctioned ||
      isRecipientSanctioned ||
      isCreatorSanctioned ||
      isTokenSanctioned,
    [
      isCreatorSanctioned,
      isRecipientSanctioned,
      isSenderSanctioned,
      isTokenSanctioned,
    ],
  );

  useEffect(() => {
    if (_.isNil(stream) || isLoading) {
      return;
    }

    setOpen(isSanctioned);
  }, [isLoading, isSanctioned, setOpen, stream]);

  return {
    isSanctioned,
  };
}
