import { BigNumber, _ } from "@sablier/v2-mixins";
import type { IValue } from "@sablier/v2-types";
import chains from "./chains";

export const BASE_INPUT_PRECISION = 6;
export const DEFAULT_BROKER_ADDRESS =
  "0x0000000000000000000000000000000000000000";

export const DEFAULT_BROKER_FEE_PERCENTAGE = "0";
export const DEFAULT_CHAIN_ID = chains.mainnet.chainId;
export const DEFAULT_GAS_LIMIT = 300_000;
export const DEFAULT_MAX_GAS_LIMIT = 200_000_000; // TODO make 15M in production
export const DEFAULT_GAS_BUMP = 50_000;
export const DEFAULT_DECIMALS = 18;

export const MAX_AMOUNT = new BigNumber(10).pow(20).toString();
export const MAX_AMOUNT_PADDED = (decimals = DEFAULT_DECIMALS, level = 15) =>
  new BigNumber(10).pow(decimals + level).toString();
export const DEFAULT_RESET_SLEEP = 9 * 1000;
export const DEFAULT_SAFE_POLLING_SLEEP = 5 * 1000;
export const DEFAULT_WITHDRAW_SLIPPAGE = 5;

export const DEFAULT_CLIFF_EXCESS = 59 * 1000; /* ~ 1 minute */
export const DEFAULT_END_DATE_EXCESS = 29 * 60 * 1000; /* ~ 30 minutes */

export const QUERY_CACHE_BLOCK_TIME = 13 * 1000;
export const QUERY_CACHE_ENS_TIME = 60 * 60 * 1000; /* ~ 60 minutes */
export const QUERY_CACHE_TIME = 24 * 60 * 1000;
export const QUERY_PERSIST_TIME = 30 * 24 * 60 * 60 * 1000;

export const EXPONENT_DECIMALS = 18;
export const PERCENTAGE_DECIMALS = 18;

export const DEFAULT_UI_POLLING = 5 * 1000;
export const FAST_UI_POLLING = 0.1 * 1000;

export const HISTORY_PAGE_SIZE = 21;
export const STREAMS_PAGE_SIZE = 31;
export const FLOWS_PAGE_SIZE = 31;
export const AIRSTREAMS_PAGE_SIZE = 31;
export const AIRSTREAMS_FEW_RECIPIENTS = 10;
export const AIRSTREAMS_RECIPIENTS_PAGE_SIZE = 100;
export const AIRSTREAMS_RECIPIENTS_MAX_SIZE =
  AIRSTREAMS_RECIPIENTS_PAGE_SIZE * 6;
export const AIRSTREAMS_RECIPIENTS_VALIDATION_THRESHOLD = 200;

export const AIRSTREAM_GRACE_DAYS = 7;

export const MAX_CANCEL_STREAM_SET = 60;
export const MAX_WITHDRAW_STREAM_SET = 60;
export const MAX_GROUP_STREAM_SIZE = 300; // TODO change this in production back to whatever it was
export const MAX_GROUP_FLOW_SIZE = 60;

export const MAX_SUBGRAPH_ID = new BigNumber(10).pow(9).toString();
export const MAX_SAFE_TX_ATTEMPTS = 600;

export const TOKEN_FAUCET_AMOUNT = 10_000; /** The app assumes the testnet token is using 18 decimals. */

export const MODAL_PORTAL_ID = "sablier-modal__container";
export const STEPPER_MAX_STEPS_SINGLE = 150;
export const STEPPER_MAX_STEPS_GROUP = 60;
export const STEPPER_MIN_STEPS = 1;

export const MONTHLY_MAX_UNLOCKS_GROUP = 60;
export const MONTHLY_MAX_UNLOCKS_SINGLE = 150;
export const MONTHLY_MIN_UNLOCKS = 1;

export const BACKWEIGHTED_YEARS_MIN = 2;
export const BACKWEIGHTED_YEARS_MAX = 10;

export const MAX_AIRSTREAM_CAMPAIGN_NAME = 32;

export const SUBGRAPH_FALLBACK_PARTY = "0x0611B";
export const GROUP_STREAM_DATE_FORMATS_CSV = [
  "YYYY-MM-DD HH:mm",
  "YYYY-M-D HH:mm",
  "YYYY-M-DD HH:mm",
  "YYYY-MM-D HH:mm",
];
export const FILE_TYPE_ACCEPTED_CSV =
  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
export const RATE_LIMITED_EXCEPTION = "Rate limited";
export const RATE_LIMITED_CAP = 200;

export const REQUEST_ID = {
  /** ---- Airstreams ---- */

  airstream: ["airstream"],
  airstreamActions: ["airstream", "actions"],
  airstreamDetails: ["airstream", "details"],
  airstreamItem: ["airstream", "item"],
  airstreamOnchain: ["airstream", "onchain"],
  airstreamItemMetadata: ["airstream", "metadata"],
  airstreamItemPreview: ["airstream", "item", "preview"],
  airstreamItemPreviewMetadata: ["airstream", "item", "preview", "metadata"],
  airstreamList: ["airstream", "list"],
  airstreamListEligible: ["airstream", "list", "eligible"],
  airstreamListHidden: ["airstream", "list", "hidden"],
  airstreamListClaimable: ["airstream", "list", "claimable"],
  airstreamListOwned: ["airstream", "list", "owned"],
  airstreamListSearch: ["airstream", "list", "search"],
  airstreamRecipientsFromFile: ["_airstream", "recipients", "file"],

  /** ---- Stream: Flow ---- */

  flow: ["stream", "flow"],
  flowActions: ["stream", "flow", "actions"],
  flowItem: ["stream", "flow", "item"],
  flowItemPreview: ["flow", "item", "preview"],
  flowItemWithdrawable: ["stream", "flow", "item", "withdraw"],
  flowItemRefundable: ["stream", "flow", "item", "refund"],
  flowList: ["stream", "flow", "list"],
  flowListOwned: ["stream", "flow", "list", "owned"],
  flowListSearch: ["stream", "flow", "list", "search"],
  flowListIdentifiers: ["stream", "flow", "list", "identifiers"],
  flowOnchain: ["stream", "flow", "onchain"],

  /** ---- Stream: Lockup ---- */

  lockup: ["stream", "lockup"],
  lockupActions: ["stream", "lockup", "actions"],
  lockupItem: ["stream", "lockup", "item"],
  lockupItemPreview: ["stream", "lockup", "item", "preview"],
  lockupItemWithdrawable: ["stream", "lockup", "item", "withdraw"],
  lockupListWithdrawable: ["stream", "lockup", "list", "withdraw"],
  lockupList: ["stream", "lockup", "list"],
  lockupListOwned: ["stream", "lockup", "list", "owned"],
  lockupListSearch: ["stream", "lockup", "list", "search"],
  lockupOnchain: ["stream", "lockup", "onchain"],

  /** ---- Stream: Common ---- */

  stream: ["stream"],
  streamItemNFT: ["stream", "item", "nft"],

  /** ---- System ---- */

  expected: ["expected"],
  geolocation: "geolocation",
  multisigOwners: ["multisig", "owners"],
  polling: ["polling"],
  proxy: ["proxy"],
  reCaptcha: ["reCaptcha"],
  tokenList: ["token", "list"],
  tokenBalance: ["token", "balance"],
  trm: ["trm"],
  trmScreening: ["trmScreening"],
  withdrawable: ["withdrawable"],

  /** ---- Requests masked by the app's server-side api ---- */

  maskMerkleCreate: ["mask", "merkle", "create"],
  maskMerkleEligible: ["mask", "merkle", "eligible"],
  maskMerkleValidity: ["mask", "merkle", "validity"],
  maskMerkleUpdate: ["mask", "merkle", "update"],

  /** ---- Dependencies ---- */

  wagmiBalance: ["balance", { entity: "balance" }],

  /** ---- Persisted Requests ---- */
  /** ---------------------------- */
  /** ---------------------------- */

  persisted: ["trm", "trmScreening"],
};

export enum DigitPurpose {
  ABBREVIATION = "abbreviation",
  CHARACTERISTIC = "characteristic",
  DIVIDER = "divider",
  MANTISSA = "mantissa",
  PREFIX = "prefix",
  SUFFIX = "suffix",
}

export const DigitLimits = {
  ABBREVIATE_PRECISION: 10,
  CHARACTERISTIC_PRECISION_FLOOR: 5,
  MANTISSA_PRECISION_CEIL: 8,
  MANTISSA_PRECISION_FLOOR: 4,
};

export const ZERO = (
  decimals: BigNumber | number | string = DEFAULT_DECIMALS,
): IValue => _.toValue({ decimals, raw: "0" });
