import nextDynamic from "next/dynamic";

const LockupCancel = nextDynamic(() => import("./Lockup/Cancel"), {
  ssr: false,
});

const LockupRenounce = nextDynamic(() => import("./Lockup/Renounce"), {
  ssr: false,
});
const LockupTransfer = nextDynamic(() => import("./Lockup/Transfer"), {
  ssr: false,
});
const LockupWithdraw = nextDynamic(() => import("./Lockup/Withdraw"), {
  ssr: false,
});

const StreamCart = nextDynamic(() => import("./Stream/Cart"), {
  ssr: false,
});
const AirstreamClawback = nextDynamic(() => import("./Airstream/Clawback"), {
  ssr: false,
});

const AirstreamFund = nextDynamic(() => import("./Airstream/Fund"), {
  ssr: false,
});

const AirstreamEligibility = nextDynamic(
  () => import("./Airstream/Eligibility"),
  {
    ssr: false,
  },
);

const FlowTransfer = nextDynamic(() => import("./Flow/Transfer"), {
  ssr: false,
});
const FlowPause = nextDynamic(() => import("./Flow/Pause"), {
  ssr: false,
});
const FlowVoid = nextDynamic(() => import("./Flow/Void"), {
  ssr: false,
});
const FlowAdjust = nextDynamic(() => import("./Flow/Adjust"), {
  ssr: false,
});
const FlowRestart = nextDynamic(() => import("./Flow/Restart"), {
  ssr: false,
});
const FlowRefund = nextDynamic(() => import("./Flow/Refund"), {
  ssr: false,
});
const FlowWithdraw = nextDynamic(() => import("./Flow/Withdraw"), {
  ssr: false,
});

function All() {
  return (
    <>
      <AirstreamClawback />
      <AirstreamEligibility />
      <AirstreamFund />

      <LockupCancel />
      <LockupRenounce />
      <LockupTransfer />
      <LockupWithdraw />

      <FlowAdjust />
      <FlowTransfer />
      <FlowPause />
      <FlowVoid />
      <FlowRestart />
      <FlowRefund />
      <FlowWithdraw />

      <StreamCart />
    </>
  );
}

export default All;
