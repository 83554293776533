import { LockupShapes } from "@sablier/v2-constants";
import type { IExtensionConstructorParams } from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import { Extension } from "../../system/setup";

export interface IDynamicStepper {
  purpose: Extension;
  steps: {
    id: string;
    value?: string;
    warning?: string;
  };
}

export function initial({
  steps,
}: IExtensionConstructorParams): IDynamicStepper {
  return {
    purpose: Extension.DYNAMIC_STEPPER,
    steps: {
      id: "steps",
      value: steps,
      warning: undefined,
    },
  };
}

export const shape = LockupShapes.dynamicStepper.id;
export const UNLOCK_DURATION: IMilliseconds = "1000";
