import nextDynamic from "next/dynamic";

/** ------- General ------- */

const Account = nextDynamic(() => import("./Account"), { ssr: false });
const Calldata = nextDynamic(() => import("./Calldata"), {
  ssr: false,
});
const ChainPicker = nextDynamic(() => import("./ChainPicker"), {
  ssr: false,
});
const ChainSwitcher = nextDynamic(() => import("./ChainSwitcher"), {
  ssr: false,
});
const CountryPicker = nextDynamic(() => import("./CountryPicker"), {
  ssr: false,
});
const DurationPicker = nextDynamic(() => import("./DurationPicker"), {
  ssr: false,
});
const Expected = nextDynamic(() => import("./Expected"), { ssr: false });
const IntervalPicker = nextDynamic(() => import("./IntervalPicker"), {
  ssr: false,
});
const MomentPicker = nextDynamic(() => import("./MomentPicker"), {
  ssr: false,
});
const Permissions = nextDynamic(() => import("./Permissions"), { ssr: false });
const Preflight = nextDynamic(() => import("./Preflight"), { ssr: false });
const SanctionAccount = nextDynamic(() => import("./SanctionAccount"), {
  ssr: false,
});
const SanctionAirstream = nextDynamic(() => import("./SanctionAirstream"), {
  ssr: false,
});
const SanctionStream = nextDynamic(() => import("./SanctionStream"), {
  ssr: false,
});
const TokenPicker = nextDynamic(() => import("./TokenPicker"), { ssr: false });
const Transaction = nextDynamic(() => import("./Transaction"), { ssr: false });
const UKDisclaimer = nextDynamic(() => import("./UKDisclaimer"), {
  ssr: false,
});

/** ------- Airstream ------- */

const AirstreamBypass = nextDynamic(() => import("./Airstream/Bypass"), {
  ssr: false,
});
const AirstreamEdit = nextDynamic(() => import("./Airstream/Edit"), {
  ssr: false,
});
const AirstreamHistory = nextDynamic(() => import("./Airstream/History"), {
  ssr: false,
});
const AirstreamRecipients = nextDynamic(
  () => import("./Airstream/Recipients"),
  {
    ssr: false,
  },
);
const AirstreamPrefund = nextDynamic(() => import("./Airstream/Prefund"), {
  ssr: false,
});
const AirstreamSearch = nextDynamic(() => import("./Airstream/Search"), {
  ssr: false,
});
const AirstreamTemplate = nextDynamic(() => import("./Airstream/Template"), {
  ssr: false,
});

const AirstreamGuide = nextDynamic(() => import("./Airstream/Guide"), {
  ssr: false,
});

/** ------- Flow ------- */

const FlowTemplate = nextDynamic(() => import("./Flow/Template"), {
  ssr: false,
});

const FlowHistory = nextDynamic(() => import("./Flow/History"), {
  ssr: false,
});

const FlowOptions = nextDynamic(() => import("./Flow/Options"), {
  ssr: false,
});

/** ------- Lockup ------- */

const LockupHistory = nextDynamic(() => import("./Lockup/History"), {
  ssr: false,
});

const LockupOptions = nextDynamic(() => import("./Lockup/Options"), {
  ssr: false,
});
const LockupPreviewCSV = nextDynamic(() => import("./Lockup/PreviewCSV"), {
  ssr: false,
});
const LockupSimulator = nextDynamic(() => import("./Lockup/Simulator"), {
  ssr: false,
});

/** ------- Stream (Common) ------- */

const StreamGuide = nextDynamic(() => import("./Stream/Guide"), {
  ssr: false,
});

const StreamLegend = nextDynamic(() => import("./Stream/Legend"), {
  ssr: false,
});

const StreamSearch = nextDynamic(() => import("./Stream/Search"), {
  ssr: false,
});
const StreamShare = nextDynamic(() => import("./Stream/Share"), { ssr: false });

const StreamTemplate = nextDynamic(() => import("./Stream/Template"), {
  ssr: false,
});

/** ------- Targeted ------- */

function PageCreateCSV() {
  return (
    <>
      <LockupPreviewCSV />
    </>
  );
}

/** ------- All ------- */

function All() {
  return (
    <>
      <Account />
      <Calldata />
      <ChainPicker />
      <ChainSwitcher />
      <CountryPicker />
      <DurationPicker />
      <Expected />
      <IntervalPicker />
      <MomentPicker />
      <Permissions />
      <Preflight />
      <SanctionAccount />
      <SanctionAirstream />
      <SanctionStream />
      <TokenPicker />
      <Transaction />
      <UKDisclaimer />

      <AirstreamBypass />
      <AirstreamGuide />
      <AirstreamEdit />
      <AirstreamHistory />
      <AirstreamPrefund />
      <AirstreamRecipients />
      <AirstreamSearch />
      <AirstreamTemplate />

      <FlowTemplate />
      <FlowHistory />
      <FlowOptions />

      <LockupHistory />
      <LockupOptions />
      <LockupSimulator />

      <StreamLegend />
      <StreamSearch />
      <StreamShare />
      <StreamGuide />
      <StreamTemplate />
    </>
  );
}

export { PageCreateCSV };

export default All;
