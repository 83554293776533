import { useMemo } from "react";
import { QUERY_CACHE_TIME, REQUEST_ID, requests } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { useQuery } from "@tanstack/react-query";
import type { IAddress } from "@sablier/v2-types";

interface Props {
  address: IAddress | undefined;
  chainId: number | undefined;
  isEnabled: boolean;
}

export default function useRequestAirstreamListClaimable({
  address,
  chainId,
  isEnabled,
}: Props) {
  const { error, data, isLoading } = useQuery({
    queryKey: [...REQUEST_ID.airstreamListClaimable, { unique: address }],
    queryFn: async () =>
      requests.claimableCampaignCIDs({
        query: { address: address!, chainId: chainId! },
      }),
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    retry: false,
    enabled:
      !_.isNilOrEmptyString(address) &&
      !_.isNilOrEmptyString(chainId) &&
      isEnabled,
  });

  const CIDs = useMemo(() => _.get(data, "cids"), [data]);

  return { CIDs, error, isLoading };
}
