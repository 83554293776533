import { DEFAULT_RESET_SLEEP } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { zeroAddress } from "viem";
import { isHostSafe } from "~/client/contexts/Web3";
import { toast } from "~/client/hooks/useToast";
import { peripheral } from "~/client/utils";
import type { useMachineForm } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { ILockup } from "@sablier/v2-models";
import type {
  IAddress,
  ISigner,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import type { useModalTransaction } from "~/client/hooks/modals";
import helper from "../helper";
import { lockupCancelMultiple as wording } from "../helper/wording";

export interface Check {
  signer: ISigner | undefined;
  streams: ILockup[];
  proxy: IAddress | undefined;
  api: {
    t: Translate;
  };
}
export interface Create extends Check {
  library: IWagmiConfig | undefined;
  signer: ISigner | undefined;
  streams: ILockup[];
  proxy: IAddress | undefined;
  api: {
    reset: () => void;
    t: Translate;
    setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
    updateData: ReturnType<typeof useModalTransaction>["updateData"];
  };
}

export interface Result {
  message?: string;
}

type Machine = Parameters<typeof useMachineForm<Check, Create, Result>>;

type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];
type onProcess = Parameters<Machine["0"]["onProcess"]>["0"];
type onValidate = Parameters<Machine["0"]["onValidate"]>["0"];

export async function onCheck({ event }: onCheck): Promise<void> {
  const { signer, streams, proxy } = event.payload;
  const { t } = event.payload.api;

  if (_.isNil(signer)) {
    throw new Error(policy.signer.missing(t));
  }

  const address = _.toAddress(signer.account!.address);
  const chainId = signer.chain!.id;
  const stream = streams[0];

  const actor = helper.identify({ address, proxy, stream });

  const guard = guards.validateLockupsCancelable({
    t,
    actor,
    chainId,
    owner: actor === "sender-proxy" ? proxy : address,
    streams,
    isHostSafe,
  });

  if (guard) {
    throw new Error(guard);
  }
}

export async function onValidate({ context }: onValidate): Promise<undefined> {
  const { api, library, streams, signer } = context.payload;
  const { t } = api;

  api.setOpen(true, {
    status: "verify",
    title: wording.title(t),
    description: wording.confirm(t).description,
    isNotClosable: true,
  });

  try {
    await onCheck({ event: context });

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const stream = streams[0];
    const chainId = stream.chainId;

    await guards.validateInputs(
      library,
      t,
      [
        {
          purpose: "signer",
          options: {
            expected: [stream.sender, stream.proxender || zeroAddress],
            chainId,
            value: signer,
          },
        },
        {
          purpose: "screening",
          options: {
            chainId,
            addresses: [signer.account!.address],
          },
        },
      ],
      chainId,
      { toast },
    );
  } catch (error) {
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: _.toString(error),
        data: error,
      },
      isNotClosable: false,
    });
    throw error;
  }
  return undefined;
}

/**
 *  Machine state that actually triggers the transaction.
 *  It relies on defined, pre-validated values checked within the `onValidate` step.
 */

export async function onProcess({ context }: onProcess): Promise<void> {
  const { api, proxy, library, streams, signer } = context.payload;
  const { t } = api;

  let query: ReturnType<typeof framework.contextualize> | undefined = undefined;

  try {
    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = streams[0].chainId;

    api.updateData({
      status: "confirm",
      description: wording.send(t, true).description,
      isNotClosable: true,
    });

    const onContextualizeStreams = async (streams: ILockup[]) => {
      const stream = streams[0];
      const address = _.toAddress(signer.account?.address);
      const chainId = stream.chainId;
      const contract = stream.contract;
      const actor = helper.identify({ address, stream, proxy });
      const target = peripheral(chainId, "targetApprove").address;

      return await (async () => {
        if (actor === "sender-proxy") {
          const data = framework.contextualize(
            target,
            chainId,
            "targetApprove",
            "cancelMultiple",
            [
              contract as IWagmiAddress,
              streams.map((stream) => stream.token.address as IWagmiAddress),
              streams.map((stream) => _.toBigInt(stream.tokenId)),
            ],
          );

          const calldata = await framework.encode(
            "targetApprove",
            "cancelMultiple",
            data.inputs,
          );

          return framework.contextualize(proxy!, chainId!, "proxy", "execute", [
            target as IWagmiAddress,
            calldata,
          ]);
        } else if (actor === "sender-native") {
          return framework.contextualize(
            contract,
            chainId,
            streams[0].category,
            "cancelMultiple",
            [streams.map((stream) => _.toBigInt(stream.tokenId))],
          );
        }
        throw new Error(policy.error.unidentified(t));
      })();
    };

    const onExecuteSafe = async () => {
      const groupedStreams = new Map<string, ILockup[]>();
      streams.forEach((stream) => {
        const contractKey = stream.contract;
        const existingStreamsForContract =
          groupedStreams.get(contractKey) || [];
        groupedStreams.set(contractKey, [
          ...existingStreamsForContract,
          stream,
        ]);
      });

      const queriesPromises = Array.from(groupedStreams).flatMap(
        ([_key, streams]) => {
          // Split streams into proxied and non-proxied
          const proxiedStreams = streams.filter((stream) => stream.proxied);
          const nativeStreams = streams.filter((stream) => !stream.proxied);

          // Create separate promises for proxied and non-proxied streams
          const promises: ReturnType<typeof onContextualizeStreams>[] = [];
          if (proxiedStreams.length > 0) {
            promises.push(onContextualizeStreams(proxiedStreams));
          }
          if (nativeStreams.length > 0) {
            promises.push(onContextualizeStreams(nativeStreams));
          }
          return promises;
        },
      );

      const queries = await Promise.all(queriesPromises);

      console.info("%c[pre-transaction] SAFE", "color: mediumslateblue", {
        queries,
      });

      const transaction = await framework.safeWrite(library, { queries });

      api.updateData({
        status: "pending",
        description: wording.send(t, false).description,
        hash: undefined,
        isNotClosable: false,
      });

      const receipt = await framework.safeWait(library, { hash: transaction });

      return { receipt, transaction };
    };

    const onExecuteWallet = async () => {
      query = await onContextualizeStreams(streams);

      const prepared = await helper.configure(library, {
        chainId,
        query,
        signer,
      });

      console.info("%c[pre-transaction]", "color: mediumslateblue", {
        query,
        prepared,
      });

      const transaction = await framework.write(library, { prepared });

      api.updateData({
        status: "pending",
        description: wording.send(t, false).description,
        hash: transaction,
        isNotClosable: false,
      });

      const receipt = await framework.wait(library, {
        hash: transaction,
        onReplaced: (replaced) => {
          api.updateData({
            hash: replaced.transaction.hash,
          });
        },
      });

      return { receipt, transaction };
    };

    const { receipt, transaction } = isHostSafe
      ? await onExecuteSafe()
      : await onExecuteWallet();

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }
    await _.sleep(DEFAULT_RESET_SLEEP);

    api.updateData({
      status: "success",
      description: wording.success(t).description,
      hash: receipt.transactionHash,
      isNotClosable: false,
    });

    api.reset();
  } catch (error) {
    void helper.debug(
      {
        query,
        signer,
      },
      vendors.crash.log(error),
    );

    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: policy.error.message(t, error),
        data: error,
      },
      isNotClosable: false,
    });

    throw error;
  }
}
