import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import { DEFAULT_RESET_SLEEP, FlowCategory } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Lockup } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { pages, tabs } from "~/client/constants";
import { isHostSafe } from "~/client/contexts/Web3";
import { toast } from "~/client/hooks/useToast";
import { contractor_flow } from "~/client/utils";
import type { useExpected } from "../../hooks";
import type { ISystem } from "../helper";
import type { useMachineForm } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { ISigner, IWagmiAddress, IWagmiConfig } from "@sablier/v2-types";
import type { IForm } from "~/client/contexts/Form/Flow/Create";
import type {
  useModalCalldata,
  useModalTransaction,
} from "~/client/hooks/modals";
import helper from "../helper";
import { flowCreate as wording } from "../helper/wording";

export interface Check {
  system: ISystem;
  fields: IForm;
  api: {
    t: Translate;
  };
}
export type Create = Check & {
  fields: IForm;
  signer: ISigner | undefined;
  library: IWagmiConfig | undefined;
  api: {
    t: Translate;
  };
} & (
    | {
        system?: "create";
        api: Check["api"] & {
          doClose: ReturnType<typeof useModalTransaction>["doClose"];
          setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
          reset: () => void;
          track: ReturnType<typeof useExpected>["track"];
          updateData: ReturnType<typeof useModalTransaction>["updateData"];
        };
      }
    | {
        system: "calldata";
        api: Check["api"] & {
          updateCalldata: ReturnType<typeof useModalCalldata>["updateData"];
        };
      }
  );

export interface Result {
  message?: string;
}

type Machine = Parameters<typeof useMachineForm<Check, Create, Result>>;

type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];
type onProcess = Parameters<Machine["0"]["onProcess"]>["0"];
type onValidate = Parameters<Machine["0"]["onValidate"]>["0"];

export async function onCheck({ event }: onCheck): Promise<void> {
  const { streams, ...fields } = event.payload.fields;
  const { system } = event.payload;
  const { t } = event.payload.api;

  const flags = guards.validateFormFlags({
    t,
    isLoadingIncluded: true,
    isWarningIncluded: true,
    value:
      system !== "simulation"
        ? fields
        : {
            token: fields.token,
          },
  });

  if (!_.isNilOrEmptyString(flags)) {
    throw new Error(flags);
  }

  /** Simulation: This mode doesn't require all fields to be valid (for the chart to work) #692 */
  const ids: (keyof typeof fields)[] =
    system !== "simulation" ? ["max", "token"] : ["token"];

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: fields,
  });

  if (!_.isNilOrEmptyString(required)) {
    throw new Error(required);
  }

  if (_.isNil(streams.value) || !streams.value.length) {
    throw new Error(policy.stream.elements(t));
  }

  streams.value.forEach((stream) => {
    const { id: _index, ...fields } = stream;
    const flags = guards.validateFormFlags({
      t,
      isLoadingIncluded: true,
      isWarningIncluded: true,
      value:
        system !== "simulation"
          ? fields
          : {
              rate: fields.rate,
            },
    });
    if (!_.isNilOrEmptyString(flags)) {
      throw new Error(flags);
    }

    /** Simulation: This mode doesn't require all fields to be valid (for the chart to work) #692 */
    const ids: (keyof typeof fields)[] =
      system !== "simulation"
        ? ["address", "rate", "initialDeposit"]
        : ["rate"];

    const required = guards.validateFormRequired({
      t,
      required: ids,
      value: fields,
    });
    if (!_.isNilOrEmptyString(required)) {
      throw new Error(required);
    }
  });
}

export async function onValidate({ context }: onValidate): Promise<undefined> {
  const { api, library, signer, system } = context.payload;
  const { streams, ...fields } = context.payload.fields;
  const { t } = api;

  if (system === "create") {
    api.setOpen(true, {
      description: wording.confirm(t, streams.value.length > 9).description,
      isNotClosable: true,
      status: "verify",
      title: wording.title(t),
    });
  }

  try {
    await onCheck({ event: context });

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const total = streams.value
      .map((f) => new BigNumber(f.initialDeposit.value || 0))
      .reduce((t, v) => t.plus(v), new BigNumber(0));

    /** Check: The sum of each flows's initial deposit should be available (prior checks deal with individual amounts) #438 */
    const overflow = guards.validateAmount({
      t,
      context: "wallet",
      max: fields.max.value,
      value: total.toString(),
    });

    if (!_.isNilOrEmptyString(overflow)) {
      throw new Error(overflow);
    }

    const sender = _.toAddress(signer.account!.address);
    const chainId = signer.chain!.id;
    const purpose = FlowCategory.FLOW;
    const contract = contractor_flow(chainId, purpose);

    const inputs: Parameters<typeof guards.validateInputs>["2"] = [
      {
        purpose: "signer",
        options: {
          value: signer,
        },
      },
    ];

    /** Safe: Skip allowance checks for Safe's. These are now included in the tx-batch functionality #803 */
    if (!isHostSafe && system === "create") {
      inputs.push({
        purpose: "token",
        options: {
          owner: sender,
          requested: total.toString(),
          spender: contract.address,
          token: fields.token.value!,
        },
      });
    }

    await guards.validateInputs(
      library,
      t,
      inputs,
      chainId,
      system === "create" ? { toast } : undefined,
    );

    /**
     * Check: Perform local validations for each flow.
     * It will not trigger a separate multicall due to auto-batching: wagmi.sh/core/api/createConfig#batch
     */

    for (let i = 0; i < streams.value.length; i++) {
      const stream = streams.value[i];
      const isAddress = _.isEthereumAddress(stream.address.value);

      await guards.validateInputs(
        library,
        t,
        [
          {
            purpose: "recipient",
            options: {
              value: stream.address.resolution?.address,
            },
          },
          {
            purpose: "screening",
            options: {
              chainId,
              addresses: [
                signer.account!.address,
                stream.address.resolution?.address ?? "",
              ],
            },
          },
          ...(isAddress
            ? [
                {
                  purpose: "resolution" as const,
                  options: {
                    value: stream.address.value,
                    resolved: stream.address.resolution?.address,
                  },
                },
              ]
            : []),
        ],
        chainId,
        system === "create" ? { toast } : undefined,
      );
    }
  } catch (error) {
    vendors.crash.log(error);

    if (system === "create") {
      api.updateData({
        status: "fail",
        description: wording.fail(t).description,
        error: {
          message: _.toString(error),
          data: error,
        },
        isNotClosable: false,
      });
    }

    if (system === "calldata") {
      api.updateCalldata({
        calldata: undefined,
        error: _.toString(error),
      });
    }

    throw error;
  }

  return undefined;
}

/**
 *  Machine state that actually triggers the transaction.
 *  It relies on defined, pre-validated values checked within the `onValidate` step.
 */

export type IQueryResult = ReturnType<
  typeof framework.contextualize<"flow", "batch">
>;

export async function onProcess({ context }: onProcess): Promise<void> {
  const { api, fields, library, signer, system } = context.payload;
  const { t } = api;
  const purpose = FlowCategory.FLOW;

  let query: IQueryResult | undefined = undefined;

  try {
    if (system === "calldata") {
      api.updateCalldata({
        calldata: undefined,
        error: undefined,
      });
    }

    if (system === "create") {
      api.updateData({
        status: "sign",
        description: wording.sign(t).description,
        isNotClosable: true,
      });
    }

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = signer.chain!.id;
    const token = fields.token.value!;
    const transferability = fields.transferability.value!;
    const sender = _.toAddress(signer.account!.address) as IWagmiAddress;
    const contract = contractor_flow(chainId, purpose);
    const total = fields.streams.value
      .map((f) => new BigNumber(f.initialDeposit.value || 0))
      .reduce((t, v) => t.plus(v), new BigNumber(0));

    const calldata = await Promise.all(
      fields.streams.value.map(async (stream) => {
        const recipient = stream.address.resolution?.address as IWagmiAddress;
        const rateInterval = stream.rateInterval.value;
        const rate = _.toValuePrepared({
          decimals: token!.decimals,
          humanized: stream.rate.value,
        });
        const ratePerSecond = _.toBigInt(_.toRatePerSecond(rate, rateInterval));
        const initialDeposit = _.toBigInt(
          _.toValuePrepared({
            decimals: token!.decimals,
            humanized: stream.initialDeposit.value ?? "0",
          }),
        );

        return initialDeposit > 0
          ? await framework.encode(purpose, "createAndDeposit", [
              sender,
              recipient,
              ratePerSecond,
              token!.address as IWagmiAddress,
              transferability,
              initialDeposit,
            ])
          : await framework.encode(purpose, "create", [
              sender,
              recipient,
              ratePerSecond,
              token!.address as IWagmiAddress,
              transferability,
            ]);
      }),
    );

    query = framework.contextualize(
      contract.address as IWagmiAddress,
      chainId!,
      "flow",
      "batch",
      [calldata],
    );
    const { label } = wording.prepare(t, {
      count: fields.streams.value?.length ?? 0,
      token,
      total: total.toString(),
    });

    if (_.isNil(query)) {
      throw new Error(policy.error.extension(t));
    }

    if (system === "calldata") {
      api.updateCalldata({
        calldata: await framework.encodeQuery(query),
        error: undefined,
      });

      return;
    }

    api.updateData({
      status: "confirm",
      description: wording.send(t, true, label).description,
      isNotClosable: true,
    });

    const onExecuteSafe = async () => {
      /** Safe: Check if an allowance request has to be batched with the create transaction #803 */
      const allowance = await _.attemptAsync(async () =>
        guards.validateInputs(
          library,
          t,
          [
            {
              purpose: "token",
              options: {
                owner: sender,
                requested: total.toString(),
                spender: query!.address,
                token: fields.token.value!,
              },
            },
          ],
          chainId,
        ),
      );

      const isAllowed = !_.isError(allowance);

      console.info("%c[pre-transaction] [SAFE]", "color: mediumslateblue", {
        query,
      });

      const transaction = isAllowed
        ? await framework.safeWrite(library, { queries: [query!] })
        : await framework.safeAllowAndWrite(library, {
            queries: [query!],
            spender: query!.address,
            token,
          });

      api.updateData({
        status: "pending",
        description: wording.send(t, false, label).description,
        hash: undefined,
        isNotClosable: false,
      });

      const receipt = await framework.safeWait(library, {
        hash: transaction,
      });

      //   api.track<"group">(receipt.transactionHash, label, dependencies);

      return { receipt, transaction };
    };

    const onExecuteWallet = async () => {
      const prepared = await helper.configure(library, {
        chainId,
        query: query!,
        signer,
      });

      console.info("%c[pre-transaction]", "color: mediumslateblue", {
        query,
        prepared,
      });

      const transaction = await framework.write(library, { prepared });

      api.updateData({
        status: "pending",
        description: wording.send(t, false, label).description,
        hash: transaction,
        isNotClosable: false,
      });

      //   api.track<"group">(transaction, label, dependencies);

      const receipt = await framework.wait(library, {
        hash: transaction,
        onReplaced: (replaced) => {
          api.updateData({
            hash: replaced.transaction.hash,
          });
        },
      });

      return { receipt, transaction };
    };

    const { receipt, transaction } = isHostSafe
      ? await onExecuteSafe()
      : await onExecuteWallet();

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }

    const tokenIds = await helper.extractStreamIds({
      receipt,
      purpose,
    });

    const aliases = tokenIds
      ? tokenIds.map((tokenId) =>
          Lockup.doGenerateAlias(contract.alias, chainId, tokenId),
        )
      : ["unknown"];

    if (aliases.length === 1) {
      const alias = aliases[0];
      await _.sleep(DEFAULT_RESET_SLEEP);

      api.updateData({
        status: "success",
        description: wording.success(t, label, alias).description,
        footer: [
          {
            to: pages.payments.profile.builder(alias),
            title: _.startCase(t("structs.viewStream")),
          },
          {
            to: tabs.payments.sender.builder(),
            title: _.capitalize(t("words.dashboard")),
            right: ArrowUpCircleIcon,
          },
        ],
        hash: receipt.transactionHash,
        isNotClosable: true,
      });
    } else {
      const identifiers = tokenIds
        ? tokenIds.map((tokenId) =>
            Lockup.doGenerateId(contract.address, chainId, tokenId),
          )
        : ["unknown"];

      await _.sleep(DEFAULT_RESET_SLEEP);

      api.updateData({
        status: "success",
        description: wording.success(t, label, aliases.join(", ")).description,
        footer: [
          {
            to: tabs.payments.search.builder({
              i: identifiers,
              c: _.toString(chainId),
            }),
            title: _.startCase(t("structs.viewStreams")),
          },
          {
            to: tabs.payments.sender.builder(),
            title: _.capitalize(t("words.dashboard")),
            right: ArrowUpCircleIcon,
          },
        ],
        hash: receipt.transactionHash,
        isNotClosable: true,
      });
    }

    api.reset();
  } catch (error) {
    vendors.crash.log(error);
    if (system === "create") {
      void helper.debug(
        {
          query,
          signer,
        },
        vendors.crash.isBenign(error),
      );

      api.updateData({
        status: "fail",
        description: wording.fail(t).description,
        error: {
          message: policy.error.message(t, error),
          data: error,
        },
        isNotClosable: false,
      });
    }

    if (system === "calldata") {
      api.updateCalldata({
        calldata: undefined,
        error: _.toString(error),
      });
    }

    throw error;
  }
}
