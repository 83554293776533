import { useMemo } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { useRequestLockupOnchain } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Lockup } from "@sablier/v2-models";
import { contracts } from "~/client/constants";
import { classifier_lockup } from "~/client/utils";
import useLockupCurrent from "./useLockupCurrent";

export default function useLockupCurrentOnchain() {
  const { t } = useT();
  const { id, isLoading, isMissing } = useLockupCurrent();

  const { address, purpose, chainId, tokenId } = useMemo(() => {
    const { source: address, chainId, tokenId } = Lockup.doSplitIdentifier(id);
    return {
      address,
      purpose: classifier_lockup(chainId, address, "core"),
      chainId,
      tokenId,
    };
  }, [id]);

  const isEnabled = useMemo(
    () =>
      !_.isNilOrEmptyString(id) && !_.isNilOrEmptyString(purpose) && isMissing,
    [id, purpose, isMissing],
  );

  const title = useMemo(() => {
    if (!_.isNilOrEmptyString(id)) {
      const aliases = Lockup.findAliases(chainId, contracts);
      const { withAlias, withAddress } = Lockup.doIdentify(id, aliases);

      if (
        !(_.isNilOrEmptyString(withAddress) && _.isNilOrEmptyString(withAlias))
      ) {
        return `${_.capitalize(t("words.stream"))} ${_.toUpper(
          withAlias || withAddress,
        )}`;
      }
    }
    return _.capitalize(t("words.stream"));
  }, [chainId, id, t]);

  const {
    data,
    error,
    isLoading: isLoadingOnchain,
    isMissing: isMissingOnchain,
  } = useRequestLockupOnchain({
    key: REQUEST_ID.lockupOnchain,
    id,
    purpose: purpose!,
    isEnabled,
  });

  return {
    address,
    chainId,
    data,
    error,
    isLoading: isLoading || isLoadingOnchain,
    isMissing: isMissingOnchain,
    title,
    tokenId,
  };
}
