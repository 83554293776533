import { useMemo } from "react";
import styled from "styled-components";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ISInput } from "../system/templates";
import type { ArrayItem, ITokenDisplay } from "@sablier/v2-types";
import Template, { sides } from "../system/templates";

const Templated = styled(Template.Numeric)`
  *[data-component="sides"][data-on="right"] {
    *[data-component="side"][data-purpose="token"] {
      p {
        color: ${(props) => props.theme.colors.gray100};
      }
    }
  }
`;

export interface Props extends Omit<ISInput, "left" | "right"> {
  decimals: number | undefined;
  onMax?: () => void;
  max?: {
    label?: string;
    value?: number | string;
  };
  onSuggest?: () => void;
  suggestion?: {
    label?: string;
  };
  token?: Partial<ITokenDisplay>;
}

function Amount({ decimals = 18, ...props }: Props) {
  const { t } = useT();
  const formatted = useMemo(() => {
    const right: ISInput["right"] = [];

    if (props.token) {
      const token: ArrayItem<ISInput["right"]> = sides.token(
        props.token.symbol,
        props.token.image,
      );
      right.push(token);
    }

    if (props.max && !_.isNilOrEmptyString(props.max.value)) {
      const max: ArrayItem<ISInput["right"]> = sides.max(
        Object.values(props.max)
          .filter((i) => !_.isNilOrEmptyString(i))
          .join(" "),
        props.onMax,
      );
      right.push(max);
    }

    if (props.suggestion && !_.isNilOrEmptyString(props.suggestion.label)) {
      const suggestion: ArrayItem<ISInput["right"]> = sides.suggestion(
        props.suggestion.label,
        props.onSuggest,
      );
      right.push(suggestion);
    }

    if (props.isLoading) {
      right.unshift(sides.loader());
    }
    return {
      placeholder: t("form.placeholder.text"),
      ...props,
      decimals,
      max: undefined,
      min: undefined,
      right,
      type: "number" as const,
    };
  }, [decimals, props, t]);

  return <Templated {...formatted} />;
}

export default Amount;
