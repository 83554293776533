/**
 * ----------------------
 * ------- Lockup -------
 * ----------------------
 */

export const LockupCategory = {
  LOCKUP_LINEAR: "LockupLinear",
  LOCKUP_DYNAMIC: "LockupDynamic",
  LOCKUP_TRANCHED: "LockupTranched",
} as const;

export const LockupStatus = {
  PENDING: "Pending",
  STREAMING: "Streaming",
  CANCELED: "Canceled",
  SETTLED: "Settled",
  /** --- END STATES --- */
  DEPLETED_SETTLED: "DepletedSettled",
  DEPLETED_CANCELED: "DepletedCanceled ",
} as const;

export const LockupVersion = {
  V20: "V20",
  V21: "V21",
  V22: "V22",
} as const;

export const LockupVersionDisplay = {
  [LockupVersion.V20]: { label: "V2.0" },
  [LockupVersion.V21]: { label: "V2.1" },
  [LockupVersion.V22]: { label: "V2.2" },
} as const;

export type LockupCategory =
  (typeof LockupCategory)[keyof typeof LockupCategory];

export type LockupVersion = (typeof LockupVersion)[keyof typeof LockupVersion];
export type LockupStatus = (typeof LockupStatus)[keyof typeof LockupStatus];

/**
 * ----------------------
 * -------- Flow --------
 * ----------------------
 */

export const FlowCategory = {
  FLOW: "Flow",
} as const;

export const FlowStatus = {
  STREAMING_FUNDED: "StreamingFunded",
  STREAMING_DEBT: "StreamingDebt",
  PAUSED_FUNDED: "PausedFunded",
  PAUSED_DEBT: "PausedDebt",
  /** --- END STATES (can be restarted) --- */
  VOIDED: "Voided",
} as const;

export const FlowVersion = {
  V10: "V10",
} as const;

export const FlowVersionDisplay = {
  [FlowVersion.V10]: { label: "V1.0" },
} as const;

export type FlowCategory = (typeof FlowCategory)[keyof typeof FlowCategory];
export type FlowStatus = (typeof FlowStatus)[keyof typeof FlowStatus];
export type FlowVersion = (typeof FlowVersion)[keyof typeof FlowVersion];

/**
 * -------------------------------
 * ------- Stream (Common) -------
 * -------------------------------
 */

export const CoreCategory = {
  LOCKUP_LINEAR_20: "LockupLinear20",
  LOCKUP_DYNAMIC_20: "LockupDynamic20",

  LOCKUP_LINEAR_21: "LockupLinear21",
  LOCKUP_DYNAMIC_21: "LockupDynamic21",

  LOCKUP_LINEAR_22: "LockupLinear22",
  LOCKUP_DYNAMIC_22: "LockupDynamic22",
  LOCKUP_TRANCHED_22: "LockupTranched22",

  FLOW_10: "Flow10",
} as const;

export const PeripheryCategory = {
  BATCH: "batch",
  REGISTRY: "registry",
  TARGET_APPROVE: "targetApprove",
  MERKLE_LOCKUP_FACTORY: "merkleLockupFactory",
} as const;

export const StreamFlavor = {
  Flow: "Flow",
  Lockup: "Lockup",
} as const;

export type StreamFlavor = (typeof StreamFlavor)[keyof typeof StreamFlavor];
export type CoreCategory = (typeof CoreCategory)[keyof typeof CoreCategory];
export type PeripheryCategory =
  (typeof PeripheryCategory)[keyof typeof PeripheryCategory];
