import { _ } from "@sablier/v2-mixins";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { ISearchLockup } from "@sablier/v2-models";
import type { IStoreLockup } from "~/client/types";
import { STORE_ID } from "../constants";

const initial: Omit<IStoreLockup, "api"> = {
  owned: undefined,
  preview: undefined,
  search: undefined,
};

const useStore = create<IStoreLockup>()(
  devtools(
    (set) => ({
      ...initial,
      api: {
        setSearch: (search: ISearchLockup | undefined) =>
          set((_prev) => {
            return { search };
          }),
        setPreview: (search: ISearchLockup | undefined) =>
          set((_prev) => {
            return { preview: search };
          }),
        setOwned: (search: ISearchLockup | undefined) =>
          set((_prev) => {
            return { owned: search };
          }),
        reset: (omit?: (keyof IStoreLockup)[]) =>
          set(() => _.omit(initial, omit || [])),
      },
    }),
    {
      name: STORE_ID.lockup,
      store: STORE_ID.lockup,
    },
  ),
);

export default useStore;
