import type { Locale } from "@sablier/v2-locales";

function builder(
  base: string,
  params: { [key: string]: string | string[] | undefined },
) {
  return base.concat("?").concat(
    Object.keys(params)
      .map((item) => {
        if (String(params[item]) === params[item]) {
          return `${item}=${params[item]}`;
        }
        if (Array.isArray(params[item])) {
          return `${item}=${(params[item] as string[]).join(",")}`;
        }
      })
      .filter((item) => !(item === undefined || item === null || item === ""))
      .join("&"),
  );
}

export const pages = {
  drops: {
    create: {
      locales: "pages.drops.create" as Locale,
      localesH: "pages.drops.heading.create" as Locale,
      route: "/drops/create",
      builder: (params: { [key: string]: string } = {}) =>
        builder(`/drops/create`, params),
      depth: 2,
    },
    dashboard: {
      locales: "pages.drops.dashboard" as Locale,
      localesH: "pages.drops.heading.dashboard" as Locale,
      route: "/drops",
      builder: () => "/drops",
      depth: 2,
    },
    profile: {
      locales: "pages.drops.profile" as Locale,
      localesH: "pages.drops.heading.profile" as Locale,
      route: "/drops/campaign/[id]",
      builder: (id: string | undefined) => `/drops/campaign/${id}`,
      depth: 3,
    },
  },
  payments: {
    create: {
      locales: "pages.payments.create" as Locale,
      localesH: "pages.payments.heading.create" as Locale,
      route: "/payments/create",
      builder: () => "/payments/create",
      depth: 2,
    },
    createCSV: {
      locales: "pages.payments.createCSV" as Locale,
      localesH: "pages.payments.heading.createCSV" as Locale,
      route: "/payments/create/csv",
      builder: () => "/payments/create/csv",
      depth: 3,
    },
    dashboard: {
      locales: "pages.payments.dashboard" as Locale,
      localesH: "pages.payments.heading.dashboard" as Locale,
      route: "/payments",
      builder: () => "/payments",
      depth: 1,
    },
    profile: {
      locales: "pages.payments.profile" as Locale,
      localesH: "pages.payments.heading.profile" as Locale,
      route: "/payments/stream/[id]",
      builder: (id: string | undefined) => `/payments/stream/${id}`,
      depth: 3,
    },
  },
  vesting: {
    create: {
      locales: "pages.vesting.create" as Locale,
      localesH: "pages.vesting.heading.create" as Locale,
      route: "/vesting/create",
      builder: (params: { shape: string }) =>
        builder("/vesting/create", params),
      depth: 2,
    },
    createCSV: {
      locales: "pages.vesting.createCSV" as Locale,
      localesH: "pages.vesting.heading.createCSV" as Locale,
      route: "/vesting/create/csv",
      builder: (params: { shape: string }) =>
        builder("/vesting/create/csv", params),
      depth: 3,
    },
    dashboard: {
      locales: "pages.vesting.dashboard" as Locale,
      localesH: "pages.vesting.heading.dashboard" as Locale,
      route: "/vesting",
      builder: () => "/vesting",
      depth: 1,
    },
    gallery: {
      locales: "pages.vesting.gallery" as Locale,
      localesH: "pages.vesting.heading.gallery" as Locale,
      route: "/vesting/gallery",
      builder: () => "/vesting/gallery",
      depth: 2,
    },
    profile: {
      locales: "pages.vesting.profile" as Locale,
      localesH: "pages.vesting.heading.profile" as Locale,
      route: "/vesting/stream/[id]",
      builder: (id: string | undefined) => `/vesting/stream/${id}`,
      depth: 3,
    },
  },
  dashboard: {
    locales: "pages.dashboard" as Locale,
    localesHeading: "pages.heading.dashboard" as Locale,
    route: "/",
    builder: () => "/",
    depth: 0,
  },
};

export const api = {
  frame: {
    latest: {
      chain: {
        frog: "/api/frame/latest/:chain/",
        route: "/api/frame/latest/[chain]",
        builder: (chainId: string) => `/api/frame/latest/${chainId}`,
      },
      home: {
        route: "/api/frame/latest/home/",
        builder: () => "/api/frame/latest/home/",
      },
    },
    stream: {
      id: {
        frog: "/api/frame/stream/:id/",
        route: "/api/frame/stream/[id]",
        builder: (id: string) => `/api/frame/stream/${id}`,
      },
    },
  },
  geolocation: {
    route: "/api/geolocation",
    builder: () => "/api/geolocation/",
  },
  meta: {
    query: {
      /** Keeping for backwards compatibility, mirrors /meta/stream/by-id */
      route: "/api/meta/query",
      builder: (id: string) => builder("/api/meta/query", { id }),
    },
    stream: {
      id: {
        route: "/api/meta/stream/by-id",
        builder: (id: string) => builder("/api/meta/stream/by-id", { id }),
      },
      latest: {
        route: "/api/meta/stream/by-latest",
        builder: (chainId: string) =>
          builder("/api/meta/stream/by-latest", { chainId }),
      },
      params: {
        route: "/api/meta/stream/by-params",
        builder: (params: Record<string, string>) =>
          builder("/api/meta/stream/by-params", params),
      },
    },
    latest: {
      home: {
        route: "/api/meta/latest/home",
        builder: (chains: string) =>
          builder("/api/meta/latest/home", { chains }),
      },
    },
  },
  stream: {
    latest: {
      route: "/api/stream/by-latest",
      builder: (chainId: string) =>
        builder("/api/stream/by-latest", { chainId }),
    },
    id: {
      route: "/api/stream/by-id",
      builder: (id: string) => builder("/api/stream/by-id", { id }),
    },
  },
  merkle: {
    claimable: {
      route: "/api/merkle/claimable",
      builder: (chainId: string, address: string) =>
        builder("/api/merkle/claimable", { chainId, address }),
    },
    eligibility: {
      route: "/api/merkle/eligibility",
      builder: (cid: string, address: string) =>
        builder("/api/merkle/eligibility", { cid, address }),
    },
    details: {
      route: "/api/merkle/details",
      builder: (chainId: string, address: string) =>
        builder("/api/merkle/details", { chainId, address }),
    },
    hidden: {
      route: "/api/merkle/hidden",
      builder: (chainId: string) => builder("/api/merkle/hidden/", { chainId }),
    },
    update: {
      route: "/api/merkle/update",
      builder: (chainId: string, address: string) =>
        builder("/api/merkle/update", { chainId, address }),
    },
  },
  recaptcha: {
    route: "/api/captcha",
    builder: (token: string) => builder("/api/captcha", { token }),
  },
  trm: {
    route: "/api/screening",
    builder: () => "/api/screening",
  },
};

export const tabs = {
  drops: {
    created: {
      identifier: "created",
      route: pages.drops.dashboard.route,
      builder: () => pages.drops.dashboard.builder(),
      locales: "tabs.drops.created" as Locale,
      params: { identifier: "t" },
    },
    eligible: {
      identifier: "eligible",
      route: pages.drops.dashboard.route,
      builder: () =>
        builder(pages.drops.dashboard.builder(), { t: "eligible" }),
      locales: "tabs.drops.claimable" as Locale,
      params: { identifier: "t" },
    },
    search: {
      identifier: "search",
      route: pages.drops.dashboard.route,
      locales: "tabs.drops.search" as Locale,
      builder: (params: {
        /** chain */
        c: string;
        /** asset / token */
        a?: string;
        /** campaign manager / admin */
        m?: string;
        /** ongoing /alive */
        o?: string;
        /** campaign name */
        n?: string;
      }) =>
        builder(pages.drops.dashboard.builder(), {
          t: "search",
          ...params,
        }),
      params: {
        identifier: "t",
        chainId: "c",
        token: "a",
        admin: "m",
        isAlive: "o",
        name: "n",
      } as const,
    },
  },
  vesting: {
    all: {
      identifier: "all",
      route: pages.vesting.dashboard.route,
      builder: () => pages.vesting.dashboard.builder(),
      locales: "tabs.vesting.all" as Locale,

      params: { identifier: "t" },
    },
    recipient: {
      identifier: "recipient",
      route: pages.vesting.dashboard.route,
      builder: () =>
        builder(pages.vesting.dashboard.builder(), { t: "recipient" }),
      locales: "tabs.vesting.recipient" as Locale,
      localesShort: "tabs.vesting.recipientShort" as Locale,
      params: { identifier: "t" },
    },
    search: {
      identifier: "search",
      route: pages.vesting.dashboard.route,
      builder: (params: {
        /** chain */
        c: string;
        /** identifiers */
        i?: string[];
        /** sender */
        s?: string;
        /** recipient */
        r?: string;
        /** asset / token */
        a?: string;
      }) =>
        builder(pages.vesting.dashboard.builder(), { t: "search", ...params }),
      locales: "tabs.vesting.search" as Locale,
      params: {
        identifier: "t",
        chainId: "c",
        ids: "i",
        sender: "s",
        recipient: "r",
        token: "a",
      } as const,
    },
    sender: {
      identifier: "sender",
      route: pages.vesting.dashboard.route,
      builder: () =>
        builder(pages.vesting.dashboard.builder(), { t: "sender" }),
      locales: "tabs.vesting.sender" as Locale,
      localesShort: "tabs.vesting.senderShort" as Locale,
      params: { identifier: "t" },
    },
  },
  payments: {
    all: {
      identifier: "all",
      route: pages.payments.dashboard.route,
      builder: () => pages.payments.dashboard.builder(),
      locales: "tabs.payments.all" as Locale,
      params: { identifier: "t" },
    },
    recipient: {
      identifier: "recipient",
      route: pages.payments.dashboard.route,
      builder: () =>
        builder(pages.payments.dashboard.builder(), { t: "recipient" }),
      locales: "tabs.payments.recipient" as Locale,
      localesShort: "tabs.payments.recipientShort" as Locale,
      params: { identifier: "t" },
    },
    search: {
      identifier: "search",
      route: pages.payments.dashboard.route,
      builder: (params: {
        /** chain */
        c: string;
        /** identifiers */
        i?: string[];
        /** sender */
        s?: string;
        /** recipient */
        r?: string;
        /** asset / token */
        a?: string;
      }) =>
        builder(pages.payments.dashboard.builder(), { t: "search", ...params }),
      locales: "tabs.payments.search" as Locale,
      params: {
        identifier: "t",
        chainId: "c",
        ids: "i",
        sender: "s",
        recipient: "r",
        token: "a",
      } as const,
    },
    sender: {
      identifier: "sender",
      route: pages.payments.dashboard.route,
      builder: () =>
        builder(pages.payments.dashboard.builder(), { t: "sender" }),
      locales: "tabs.payments.sender" as Locale,
      localesShort: "tabs.payments.senderShort" as Locale,
      params: { identifier: "t" },
    },
  },
};
