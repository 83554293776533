import type { Locale } from "@sablier/v2-locales";

/**
 * ------------------------------------------------------------
 * STREAM SHAPE - Naming conventions
 *
 * - LockupLinear streams will have simple names ["linear", "cliff"]
 * - LockupDynamic streams will be prefixed with the "dynamic" particle
 * - LockupDynamic streams without bespoke names (e.g. "timelock") will follow the order of their segments in the name (e.g. "cliff" + "exponential")
 * ------------------------------------------------------------
 */
export type LockupShape =
  | "cliff"
  | "linear"
  | "dynamicMonthly"
  | "dynamicStepper"
  | "dynamicTimelock"
  | "dynamicUnlockLinear"
  | "dynamicUnlockCliff"
  | "dynamicCliffExponential"
  | "dynamicExponential"
  | "tranchedBackweighted"
  | "tranchedStepper"
  | "tranchedMonthly"
  | "tranchedTimelock"
  | "unknown";

export const LockupShapes: Record<
  LockupShape,
  {
    description: Locale;
    id: LockupShape | string;
    isUnknown?: true;
    title: Locale;
  }
> = {
  cliff: {
    id: "cliff",
    description: "shapes.cliff.description",
    title: "shapes.cliff.title",
  },
  linear: {
    id: "linear",
    description: "shapes.linear.description",
    title: "shapes.linear.title",
  },
  dynamicExponential: {
    id: "dynamicExponential",
    description: "shapes.dynamicExponential.description",
    title: "shapes.dynamicExponential.title",
  },
  dynamicCliffExponential: {
    id: "dynamicCliffExponential",
    description: "shapes.dynamicCliffExponential.description",
    title: "shapes.dynamicCliffExponential.title",
  },
  dynamicMonthly: {
    id: "dynamicMonthly",
    description: "shapes.dynamicMonthly.description",
    title: "shapes.dynamicMonthly.title",
  },
  dynamicStepper: {
    id: "dynamicStepper",
    description: "shapes.dynamicStepper.description",
    title: "shapes.dynamicStepper.title",
  },
  dynamicUnlockCliff: {
    id: "dynamicUnlockCliff",
    description: "shapes.dynamicUnlockCliff.description",
    title: "shapes.dynamicUnlockCliff.title",
  },
  dynamicUnlockLinear: {
    id: "dynamicUnlockLinear",
    description: "shapes.dynamicUnlockLinear.description",
    title: "shapes.dynamicUnlockLinear.title",
  },
  dynamicTimelock: {
    id: "dynamicTimelock",
    description: "shapes.dynamicTimelock.description",
    title: "shapes.dynamicTimelock.title",
  },
  tranchedBackweighted: {
    id: "tranchedBackweighted",
    description: "shapes.tranchedBackweighted.description",
    title: "shapes.tranchedBackweighted.title",
  },
  tranchedStepper: {
    id: "tranchedStepper",
    description: "shapes.tranchedStepper.description",
    title: "shapes.tranchedStepper.title",
  },
  tranchedMonthly: {
    id: "tranchedMonthly",
    description: "shapes.tranchedMonthly.description",
    title: "shapes.tranchedMonthly.title",
  },
  tranchedTimelock: {
    id: "tranchedTimelock",
    description: "shapes.tranchedTimelock.description",
    title: "shapes.tranchedTimelock.title",
  },
  unknown: {
    id: "unknown",
    isUnknown: true,
    description: "structs.notDefined",
    title: "words.stream",
  },
};
