import { memo } from "react";
import styled from "styled-components";
import { LockupShapes } from "@sablier/v2-constants";
import { useContainerSize } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { IImage, StaticImageData } from "@sablier/v2-types";
import Image from "next/image";
import type { LockupShape } from "@sablier/v2-constants";
import type { RefObject } from "react";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Frame = styled.svg.attrs((props) => props.theme.attributes.base)`
  width: 100%;
  height: 100%;
`;

const Imager = styled.div`
  ${(props) => props.theme.styles.rowCentered}

  & {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      object-fit: contain;
    }
  }
`;

const Path = styled.path.attrs((props) => props.theme.attributes.base)`
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke: ${(props) => props.theme.colors.primaryMiddle};

  &[data-flow="true"] {
    stroke: ${(props) => props.theme.colors.white};
  }
`;

const Filler = styled.path.attrs((props) => {
  return {
    ...props.theme.attributes.base,
  };
})`
  fill: ${(props) => `url("#${props.theme.gradients.svgId.thumbnail}")`};
  &[data-flow="true"] {
    fill: ${(props) => `url("#${props.theme.gradients.svgId.thumbnailWhite}")`};
  }
`;

export interface ItemProps {
  sizes: {
    height: number;
    width: number;
  };
}

export function Cliff({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <g>
        <Path
          d={`
         M 1 ${sizes.height - 1}
         L ${sizes.width / 4} ${sizes.height - 1}
         L ${sizes.width / 4} ${(sizes.height * 2) / 3}
         L ${sizes.width - 1} 1`}
        />
        <Filler
          d={`M 0 ${sizes.height}
        L ${sizes.width / 4} ${sizes.height}
        L ${sizes.width / 4} ${(sizes.height * 2) / 3}
        L ${sizes.width} 1
        L ${sizes.width} ${sizes.height}
        Z`}
        />
      </g>
    </Frame>
  );
}

export function DynamicUnlockCliff({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <g>
        <Path
          d={`
         M 1 ${sizes.height - 1}
         L 1 ${(sizes.height * 3) / 4}
         L ${sizes.width / 4} ${(sizes.height * 3) / 4}
         L ${sizes.width / 4} ${(sizes.height * 2) / 4}
         L ${sizes.width - 1} 1`}
        />
        <Filler
          d={`M 0 ${sizes.height}
          L 0 ${(sizes.height * 3) / 4}
        L ${sizes.width / 4} ${(sizes.height * 3) / 4}
        L ${sizes.width / 4} ${(sizes.height * 2) / 4}
        L ${sizes.width} 1
        L ${sizes.width} ${sizes.height}
        Z`}
        />
      </g>
    </Frame>
  );
}

export function DynamicCliffExponential({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        d={`
          M 1 ${sizes.height - 1}
          L ${sizes.width / 4} ${sizes.height - 1}
          L ${sizes.width / 4} ${(sizes.height * 2) / 3 + 10}
          S ${(sizes.width * 3) / 4} ${sizes.height - 1 - 20},
          ${sizes.width - 1} 1
          `}
      />
      <Filler
        d={`
          M 1 ${sizes.height}
          L ${sizes.width / 4} ${sizes.height}
          L ${sizes.width / 4} ${(sizes.height * 2) / 3 + 10}
          S ${(sizes.width * 3) / 4} ${sizes.height - 20},
          ${sizes.width} 0
          L ${sizes.width} ${sizes.height}
          L 1 ${sizes.height}
          Z`}
      />
    </Frame>
  );
}

export function DynamicExponentialSlow({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        d={`
        M 1 ${(sizes.height * 3) / 4 + 10}
        S ${(sizes.width * 2) / 4} ${sizes.height - 1 - 10},
            ${sizes.width - 1} 1
        `}
      />
      <Filler
        d={`
        M 1 ${(sizes.height * 3) / 4 + 10}
        S ${(sizes.width * 2) / 4} ${sizes.height - 10},
            ${sizes.width} 0
        L ${sizes.width} ${sizes.height}
        L 1 ${sizes.height}
        Z`}
      />
    </Frame>
  );
}

export function DynamicExponentialFast({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        d={`
          M 1 ${(sizes.height * 3) / 4 + 10}
          S ${(sizes.width * 2) / 4} ${sizes.height - 1 - 10},
              ${(sizes.width * 3) / 4} 1
          `}
      />
      <Filler
        d={`
          M 1 ${(sizes.height * 3) / 4 + 10}
          S ${(sizes.width * 2) / 4} ${sizes.height - 10},
            ${(sizes.width * 3) / 4} 1
          L ${sizes.width} ${sizes.height}
          L 1 ${sizes.height}
          Z`}
      />
    </Frame>
  );
}

export function DynamicMonthly({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        d={`
           M 1 ${sizes.height - 1}
           L ${sizes.width / 6} ${sizes.height - 1}

           L ${sizes.width / 6} ${(sizes.height * 4) / 5}

           L ${(sizes.width * 2) / 6} ${(sizes.height * 4) / 5}
           L ${(sizes.width * 2) / 6} ${(sizes.height * 3) / 5}

           L ${(sizes.width * 3) / 6} ${(sizes.height * 3) / 5}
           L ${(sizes.width * 3) / 6} ${(sizes.height * 2) / 5}

           L ${(sizes.width * 4) / 6} ${(sizes.height * 2) / 5}
           L ${(sizes.width * 4) / 6} ${(sizes.height * 1) / 5}

           L ${(sizes.width * 5) / 6} ${(sizes.height * 1) / 5}
           L ${(sizes.width * 5) / 6} 1

           L ${sizes.width - 1} 1`}
      />
      <Filler
        d={`M 1 ${sizes.height - 1}
            L ${sizes.width / 6} ${sizes.height - 1}

            L ${sizes.width / 6} ${(sizes.height * 4) / 5}

            L ${(sizes.width * 2) / 6} ${(sizes.height * 4) / 5}
            L ${(sizes.width * 2) / 6} ${(sizes.height * 3) / 5}

            L ${(sizes.width * 3) / 6} ${(sizes.height * 3) / 5}
            L ${(sizes.width * 3) / 6} ${(sizes.height * 2) / 5}

            L ${(sizes.width * 4) / 6} ${(sizes.height * 2) / 5}
            L ${(sizes.width * 4) / 6} ${(sizes.height * 1) / 5}

            L ${(sizes.width * 5) / 6} ${(sizes.height * 1) / 5}
            L ${(sizes.width * 5) / 6} 1

            L ${sizes.width - 1} 1

            L ${sizes.width} 0
            L ${sizes.width} ${sizes.height}
            Z
            `}
      />
    </Frame>
  );
}

export function DynamicStepper({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        d={`
           M 1 ${sizes.height - 1}
           L ${sizes.width / 4} ${sizes.height - 1}

           L ${sizes.width / 4} ${(sizes.height * 2) / 3}

           L ${(sizes.width * 2) / 4} ${(sizes.height * 2) / 3}
           L ${(sizes.width * 2) / 4} ${(sizes.height * 1) / 3}

           L ${(sizes.width * 3) / 4} ${(sizes.height * 1) / 3}
           L ${(sizes.width * 3) / 4} 1

           L ${sizes.width - 1} 1`}
      />
      <Filler
        d={`M 1 ${sizes.height - 1}
            L ${sizes.width / 4} ${sizes.height - 1}

            L ${sizes.width / 4} ${(sizes.height * 2) / 3}

            L ${(sizes.width * 2) / 4} ${(sizes.height * 2) / 3}
            L ${(sizes.width * 2) / 4} ${(sizes.height * 1) / 3}

            L ${(sizes.width * 3) / 4} ${(sizes.height * 1) / 3}
            L ${(sizes.width * 3) / 4} 1

            L ${sizes.width - 1} 1

            L ${sizes.width} 0
            L ${sizes.width} ${sizes.height}
            Z
            `}
      />
    </Frame>
  );
}

export function DynamicBackweighted({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        d={`
           M 1 ${sizes.height - 1}
           L ${sizes.width / 4} ${sizes.height - 1}

           L ${sizes.width / 4} ${(sizes.height * 5) / 6}

           L ${(sizes.width * 2) / 4} ${(sizes.height * 5) / 6}
           L ${(sizes.width * 2) / 4} ${(sizes.height * 3.5) / 6}

           L ${(sizes.width * 3) / 4} ${(sizes.height * 3.5) / 6}
           L ${(sizes.width * 3) / 4} 1

           L ${sizes.width - 1} 1`}
      />
      <Filler
        d={`M 1 ${sizes.height - 1}
            L ${sizes.width / 4} ${sizes.height - 1}

            L ${sizes.width / 4} ${(sizes.height * 5) / 6}

            L ${(sizes.width * 2) / 4} ${(sizes.height * 5) / 6}
            L ${(sizes.width * 2) / 4} ${(sizes.height * 3.5) / 6}

            L ${(sizes.width * 3) / 4} ${(sizes.height * 3.5) / 6}
            L ${(sizes.width * 3) / 4} 1

            L ${sizes.width - 1} 1

            L ${sizes.width} 0
            L ${sizes.width} ${sizes.height}
            Z
            `}
      />
    </Frame>
  );
}

function Flow({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        data-flow={true}
        d={`
          M 1 ${sizes.height - 1}
          L ${sizes.width / 3} ${(sizes.height * 2) / 3}
          L ${(sizes.width * 3) / 4} ${(sizes.height * 2) / 3}
          L ${sizes.width - 1} 1`}
      />
      <Filler
        data-flow={true}
        d={`
          M 0 ${sizes.height}
          L ${sizes.width / 3} ${(sizes.height * 2) / 3}
          L ${(sizes.width * 3) / 4} ${(sizes.height * 2) / 3}
          L ${sizes.width} 1
          L ${sizes.width} ${sizes.height}
          Z`}
      />
    </Frame>
  );
}

function Linear({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path d={`M 1 ${sizes.height - 1} L ${sizes.width - 1} 1`} />
      <Filler
        d={`M 0 ${sizes.height} L ${sizes.width} 0 L ${sizes.width} ${sizes.height} Z`}
      />
    </Frame>
  );
}

function DynamicUnlockLinear({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        d={`M 1 ${sizes.height - 1}
                L 1 ${(sizes.height * 2) / 3}
                L ${sizes.width - 1} 1`}
      />
      <Filler
        d={`M 0 ${sizes.height}
        L 0 ${(sizes.height * 2) / 3}
        L ${sizes.width} 1
        L ${sizes.width} ${sizes.height} Z`}
      />
    </Frame>
  );
}

export function DynamicTimelock({ sizes }: ItemProps) {
  return (
    <Frame
      viewBox={`0 0 ${sizes.width} ${sizes.height}`}
      width={sizes.width}
      height={sizes.height}
    >
      <Path
        d={`
           M 1 ${sizes.height - 1}

           L ${(sizes.width * 7) / 8} ${sizes.height - 1}
           L ${(sizes.width * 7) / 8} 1

           L ${sizes.width} 1`}
      />
      <Filler
        d={`M 1 ${sizes.height - 1}
            L ${(sizes.width * 7) / 8} ${sizes.height - 1}

            L ${(sizes.width * 7) / 8} 1

            L ${sizes.width} 0
            L ${sizes.width} ${sizes.height - 1}
            Z
            `}
      />
    </Frame>
  );
}

export interface Props {
  className?: string;
  image?: IImage;
  parent?: RefObject<HTMLDivElement>;
  sizes?: { height: number; width: number };
  shape: LockupShape | "flow" | string;
}

function Art({ sizes, shape }: Required<Pick<Props, "sizes" | "shape">>) {
  if (shape === "flow") {
    return <Flow sizes={sizes} />;
  }
  switch (shape as LockupShape) {
    case LockupShapes.linear.id:
      return <Linear sizes={sizes} />;
    case LockupShapes.cliff.id:
      return <Cliff sizes={sizes} />;
    case LockupShapes.dynamicStepper.id:
    case LockupShapes.tranchedStepper.id:
      return <DynamicStepper sizes={sizes} />;
    case LockupShapes.dynamicMonthly.id:
    case LockupShapes.tranchedMonthly.id:
      return <DynamicMonthly sizes={sizes} />;
    case LockupShapes.tranchedBackweighted.id:
      return <DynamicBackweighted sizes={sizes} />;
    case LockupShapes.dynamicTimelock.id:
    case LockupShapes.tranchedTimelock.id:
      return <DynamicTimelock sizes={sizes} />;

    case LockupShapes.dynamicUnlockLinear.id:
      return <DynamicUnlockLinear sizes={sizes} />;
    case LockupShapes.dynamicUnlockCliff.id:
      return <DynamicUnlockCliff sizes={sizes} />;
    case LockupShapes.dynamicExponential.id:
      return <DynamicExponentialSlow sizes={sizes} />;
    case LockupShapes.dynamicCliffExponential.id:
      return <DynamicCliffExponential sizes={sizes} />;
    default:
      return false;
  }
}

function StreamThumbnail({ className, image, sizes: preset, shape }: Props) {
  const { ref: container, sizes } = useContainerSize({ preset });

  return (
    <Wrapper className={className} ref={container}>
      {!_.isNilOrEmptyString(image) ? (
        <Imager>
          <Image
            alt={""}
            src={image as StaticImageData}
            fill
            sizes={"(max-width: 500px) 100vw, 30vw"}
            quality={100}
          />
        </Imager>
      ) : (
        <Art shape={shape} sizes={sizes} />
      )}
    </Wrapper>
  );
}

export default memo(StreamThumbnail);
