import { ComponentProps, useMemo } from "react";
import { CheckIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Table from "@sablier/v2-components/organisms/Table";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";
import type { ISTableCell } from "@sablier/v2-components/organisms/Table";
import { useFlowCreateField } from "./store";

const theme = getTheme();

type Cells = [
  ISTableCell["Label"],
  ISTableCell["Label"],
  ISTableCell["Text"],
  ISTableCell["Amount"],
  ISTableCell["Text"],
  ISTableCell["Amount"],
];

type Data = ComponentProps<typeof Table>["data"];

export default function useFlowCSVTable(): Data {
  const streams = useFlowCreateField("streams");
  const token = useFlowCreateField("token");
  const { t } = useT();

  const columns = useMemo(() => {
    const base = [
      {
        id: "1",
        layout: "Label",
        title: "#",
        weight: "var(--dashboard-column-index)",
      },
      {
        id: "2",
        layout: "Label",
        title: "Validity",
        weight: "var(--dashboard-column-valid)",
      },
      {
        id: "3",
        layout: "Text",
        title: _.capitalize(t("words.recipient")),
        weight: "var(--dashboard-column-address)",
      },
      {
        id: "4",
        layout: "Amount",
        title: _.capitalize(t("words.rate")),
        weight: "var(--dashboard-column-amount)",
      },
      {
        id: "5",
        layout: "Text",
        title: _.capitalize(t("words.rate")),
        weight: "var(--dashboard-column-interval)",
      },
      {
        id: "6",
        layout: "Amount",
        title: _.capitalize(t("structs.initialDeposit")),
        weight: "var(--dashboard-column-amount)",
      },
    ];
    return base;
  }, [t]);

  const rows = useMemo(() => {
    return streams.field.value.map((row, index) => {
      const warning = [
        token.field,
        row.address,
        row.rate,
        row.initialDeposit,
      ].find((item) => !_.isNilOrEmptyString(item.warning))?.warning;

      const cells = [
        {
          value: {
            value: `${index + 1}.`,
          },
        },
        {
          value: _.isNilOrEmptyString(warning)
            ? {
                isIconLast: true,
                value: "Valid",
                icon: CheckIcon,
              }
            : {
                isIconLast: true,
                value: "Invalid",
                color: "yellow",
                icon: ExclamationCircleIcon,
                tooltip: {
                  value: warning,
                },
              },
        },
        {
          value: _.toShortAddress(row.address.value, 14, -12),
        },
        {
          value: {
            token: token.field.value,
            value: row.rate.value,
          },
        },
        {
          value: _.capitalize(row.rateInterval.value),
        },
        {
          value: {
            token: token.field.value,
            value: row.initialDeposit.value,
          },
        },
      ] satisfies Partial<Cells>;

      return {
        id: row.address.value! + `${index}`,
        cells,
      };
    });
  }, [streams, token]);

  return {
    columns,
    rows,
    instructions: {},
    isLoading: false,
    isEmpty: rows.length === 0,
    options: {
      row: theme.sizes.tableRowSlim,
    },
  };
}
