import { ArrowUturnDownIcon } from "@heroicons/react/24/outline";
import { DEFAULT_RESET_SLEEP } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { Airstream } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { pages } from "~/client/constants";
import { isHostSafe } from "~/client/contexts/Web3";
import extensions, { categorize } from "~/client/extensions";
import { toast } from "~/client/hooks/useToast";
import {
  IExtensionDependencies,
  IExtensionResultAirstream,
} from "~/client/types";
import type { ISystem } from "../helper";
import type { useMachineForm } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { ISigner, IWagmiConfig } from "@sablier/v2-types";
import type { IForm } from "~/client/contexts/Form/Airstream/Create";
import type { useModalCalldata, useModalTransaction } from "~/client/hooks";
import helper from "../helper";
import { airstreamCreate as wording } from "../helper/wording";

export interface Check {
  system: Extract<ISystem, "create" | "calldata">;
  fields: IForm;
  step: number;
  api: {
    t: Translate;
  };
}
export type Create = Check & {
  fields: IForm;
  library: IWagmiConfig | undefined;
  signer: ISigner | undefined;
  step: number;
  api: {
    t: Translate;
  };
} & (
    | {
        system: "create";
        api: {
          reset: () => void;
          setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
          doClose: ReturnType<typeof useModalTransaction>["doClose"];
          updateData: ReturnType<typeof useModalTransaction>["updateData"];
          cache: (data: { airstream: string; hash: string }) => void;
        };
      }
    | {
        system: "calldata";
        api: Check["api"] & {
          updateCalldata: ReturnType<typeof useModalCalldata>["updateData"];
        };
      }
  );

export interface Result {
  message?: string;
}

type Machine = Parameters<typeof useMachineForm<Check, Create, Result>>;

type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];
type onProcess = Parameters<Machine["0"]["onProcess"]>["0"];
type onValidate = Parameters<Machine["0"]["onValidate"]>["0"];

export async function onCheck({ event }: onCheck): Promise<void> {
  const { extension, ...fields } = event.payload.fields;
  const { t } = event.payload.api;

  const flags = guards.validateFormFlags({
    t,
    isLoadingIncluded: true,
    isWarningIncluded: true,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    throw new Error(flags);
  }

  const ids: (keyof typeof fields)[] = [
    "cancelability",
    "chain",
    "duration",
    "name",
    "shape",
    "token",
    "transferability",
  ];

  if (fields.expiration.isActive) {
    ids.push("expiration");
  }

  if (event.payload.step >= 2) {
    /**
     * Starting with step 2, we'll have to validate
     * the recipients file and resulting calldata.
     */
    ids.push("calldata");
  }

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: fields,
  });

  if (!_.isNilOrEmptyString(required)) {
    throw new Error(required);
  }

  const check = extensions.check({
    t,
    data: extension,
    /** Locked to duration due to airstreams being duration only */
    timing: "duration",
    isLoadingIncluded: true,
    isWarningIncluded: true,
  });

  if (!_.isNilOrEmptyString(check)) {
    throw new Error(check);
  }
}

export async function onValidate({ context }: onValidate): Promise<undefined> {
  const { api, fields, library, signer, system } = context.payload;
  const { t } = api;

  if (system === "create") {
    api.setOpen(true, {
      status: "verify",
      title: wording.title(t),
      description: wording.confirm(t).description,
      isNotClosable: true,
    });
  }

  try {
    await onCheck({ event: context });

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = signer.chain!.id;

    _.expect(fields.calldata.value?.cid, "IPFS CID");
    _.expect(fields.calldata.value?.recipients, "recipients");
    _.expect(fields.calldata.value?.root, "root");
    _.expect(fields.calldata.value?.total, "total");

    await guards.validateInputs(
      library,
      t,
      [
        {
          purpose: "signer",
          options: {
            chainId: fields.chain.value,
            value: signer,
          },
        },
        {
          purpose: "screening",
          options: {
            chainId,
            addresses: [signer.account!.address],
          },
        },
        ...(fields.expiration.isActive
          ? [
              {
                purpose: "end" as const,
                options: {
                  isExcess: true,
                  value: fields.expiration.value,
                },
              },
            ]
          : []),
      ],
      chainId,
      system === "create" ? { toast } : undefined,
    );
  } catch (error) {
    vendors.crash.log(error);

    if (system === "create") {
      api.updateData({
        status: "fail",
        description: wording.fail(t).description,
        error: {
          message: _.toString(error),
          data: error,
        },
        isNotClosable: false,
      });
    }

    if (system === "calldata") {
      api.updateCalldata({
        calldata: undefined,
        error: _.toString(error),
      });
    }

    throw error;
  }

  return undefined;
}

/**
 *  Machine state that actually triggers the transaction.
 *  It relies on defined, pre-validated values checked within the `onValidate` step.
 */

export async function onProcess({ context }: onProcess): Promise<void> {
  const { api, fields, library, signer, system } = context.payload;
  const { t } = api;
  let query: IExtensionResultAirstream | undefined = undefined;
  try {
    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = fields.chain.value!;
    const calldata = fields.calldata.value!;
    const duration = _.toValuePrepared({
      humanized: fields.duration.value,
      decimals: -3,
    });
    const expiration = fields.expiration.isActive
      ? _.toValuePrepared({ humanized: fields.expiration.value, decimals: -3 })
      : "0";
    const token = fields.token.value!;
    const name = fields.name.value!;

    const requested = _.toValuePrepared({
      raw: calldata.total,
      decimals: token.decimals,
    });

    const preview = wording.prepare(token, requested);

    const dependencies = {
      calldata,
      cancelability: !!fields.cancelability.value,
      chainId,
      duration,
      expiration,
      name,
      sender: signer.account?.address,
      token,
      transferability: !!fields.transferability.value,
      signer,
    } satisfies IExtensionDependencies<"airstream">;

    query = await extensions.process.airstream({
      dependencies,
      extras: fields.extension,
      library,
    });

    if (_.isNil(query)) {
      throw new Error("This extension/shape might not be supported yet.");
    }
    if (system === "calldata") {
      api.updateCalldata({
        calldata: await framework.encodeQuery(query.factory),
        error: undefined,
      });

      return;
    }

    api.updateData({
      status: "confirm",
      description: wording.send(
        t,
        true,
        preview.amount,
        calldata.recipients,
        name,
      ).description,
      isNotClosable: true,
    });

    const onExecuteSafe = async () => {
      console.info("%c[pre-transaction] [SAFE]", "color: mediumslateblue", {
        query,
      });

      const transaction = await framework.safeWrite(library, {
        queries: [query!.factory],
      });
      if (system === "create") {
        api.updateData({
          status: "pending",
          description: wording.send(
            t,
            false,
            preview.amount,
            calldata.recipients,
            name,
          ).description,
          hash: undefined,
          isNotClosable: false,
        });
      }

      const receipt = await framework.safeWait(library, {
        hash: transaction,
      });

      return { receipt, transaction };
    };

    const onExecuteWallet = async () => {
      const prepared = await helper.configure(library, {
        chainId,
        query: query!.factory,
        signer,
      });

      console.info("%c[pre-transaction]", "color: mediumslateblue", {
        query,
        prepared,
      });

      const transaction = await framework.write(library, { prepared });

      if (system === "create") {
        api.updateData({
          status: "pending",
          description: wording.send(
            t,
            false,
            preview.amount,
            calldata.recipients,
            name,
          ).description,
          hash: transaction,
          isNotClosable: false,
        });
      }

      const receipt = await framework.wait(library, {
        hash: transaction,
        onReplaced: (replaced) => {
          api.updateData({
            hash: replaced.transaction.hash,
          });
        },
      });

      return { receipt, transaction };
    };

    const { receipt, transaction } = isHostSafe
      ? await onExecuteSafe()
      : await onExecuteWallet();

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }

    const category = categorize(fields.extension.purpose);

    const airstreamId =
      (await helper.extractAirstreamId({
        receipt,
        category,
      })) || "";

    await _.sleep(DEFAULT_RESET_SLEEP);

    api.cache({ airstream: airstreamId, hash: receipt.transactionHash });
    api.updateData({
      status: "success",
      description: wording.success(t, name, preview.amount, calldata.recipients)
        .description,
      hash: receipt.transactionHash,
      footer: [
        {
          to: pages.drops.profile.builder(
            Airstream.doGenerateId(airstreamId, chainId),
          ),
          title: _.capitalize(t("words.campaign")),
        },
        {
          onClick: api.doClose,
          title: _.capitalize(t("words.continue")),
          accent: "iconicPurple",
          appearance: "outline",
          right: ArrowUturnDownIcon,
        },
      ],
      isNotClosable: false,
    });

    api.reset();
  } catch (error) {
    vendors.crash.log(error);
    if (system === "create") {
      void helper.debug(
        {
          query: query?.factory,
          signer,
        },
        vendors.crash.isBenign(error),
      );

      api.updateData({
        status: "fail",
        description: wording.fail(t).description,
        error: {
          message: policy.error.message(t, error),
          data: _.toString(error),
        },
        isNotClosable: false,
      });
    }

    if (system === "calldata") {
      api.updateCalldata({
        calldata: undefined,
        error: _.toString(error),
      });
    }

    throw error;
  }
}
