import { useCallback, useMemo } from "react";
import { StreamFlavor } from "@sablier/v2-constants/stream";
import { _ } from "@sablier/v2-mixins";
import { api, pages } from "~/client/constants";
import type { IStream } from "@sablier/v2-models";

function useStreamMeta(stream?: IStream, identifier?: string) {
  const { profile } = useMemo(
    () =>
      stream?.flavor === StreamFlavor.Lockup ? pages.vesting : pages.payments,
    [stream],
  );

  const title = useMemo(
    () =>
      `Stream${
        !_.isNilOrEmptyString(identifier)
          ? ` #${_.truncate(identifier, { length: 20 })}`
          : ""
      }`,
    [identifier],
  );

  const canonical = useCallback(
    (domain?: string) => {
      if (_.isNilOrEmptyString(domain) && _.isWindow()) {
        domain = window.location.origin;
      }

      if (_.isNil(stream?.alias)) {
        return undefined;
      }

      return new URL(
        `${domain}${profile.builder(_.toAlias(stream?.alias))}`,
      ).toString();
    },
    [profile, stream?.alias],
  );

  const image = useCallback(
    (domain?: string) => {
      if (_.isNilOrEmptyString(domain) && _.isWindow()) {
        domain = window.location.origin;
      }

      if (_.isNilOrEmptyString(identifier) && _.isNil(stream)) {
        return new URL(`${domain}/meta/twitter.png`).toString();
      }

      const id = identifier ?? stream?.id;
      return new URL(
        `${domain}${api.meta.stream.id.builder(id || "")}`,
      ).toString();
    },
    [identifier, stream],
  );

  return useMemo(
    () => ({ canonical, image, title }),
    [canonical, image, title],
  );
}

export default useStreamMeta;
