import {
  AIRSTREAMS_RECIPIENTS_VALIDATION_THRESHOLD,
  GROUP_STREAM_DATE_FORMATS_CSV,
} from "@sablier/v2-constants";
import { BigNumber, Papa, _, dayjs } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import type { ICSVCell } from "@sablier/v2-types";
import policy from "./policy";

interface FileFormatValidProps {
  t: Translate;
  purpose: "csv";
  max?: {
    size?: string;
  };
  value?: File;
}

export function validateFileFormat({
  t,
  purpose,
  max,
  value,
}: FileFormatValidProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.file.missing(t);
    }

    if (max && !_.isNilOrEmptyString(max?.size)) {
      const size = new BigNumber(value.size);
      const limit = new BigNumber(max?.size);

      if (size.isGreaterThan(limit)) {
        return policy.file.tooBig(t, max?.size);
      }
    }

    if (purpose == "csv") {
      const type = value.type;
      if (
        ![
          "text/csv",
          "application/csv",
          "application/excel",
          "application/vnd.ms-excel",
        ].includes(type.toLowerCase())
      ) {
        return policy.file.type(t, "csv, xlsx");
      }
    }
  } catch (error) {
    return policy.file.missing(t);
  }

  return undefined;
}

type FileContentValidProps = {
  t: Translate;
  value?: File;
} & {
  format: {
    columns: number;
    /** Array of column names found in the header (e.g. name) */
    header: string[];
    /** Array of value types for each cell on every row (e.g. address, text, amount) */
    row: ICSVCell[];
  };
  purpose: "csv";
  /** Number of fields to pre-read */
  preview?: number;
  min?: {
    rows?: number;
  };
  max?: {
    rows?: number;
    size?: string;
  };
};

export async function validateFileContent({
  t,
  format,
  purpose,
  preview = 2,
  max,
  min,
  value,
}: FileContentValidProps): Promise<string | undefined> {
  if (_.isNilOrEmptyString(value)) {
    return policy.file.missing(t);
  }

  try {
    if (purpose === "csv") {
      const { results } = await Papa.parseAsync(value, {
        header: true,
        // A function to execute before parsing the first chunk https://www.papaparse.com/docs#config
        beforeFirstChunk: (chunk) => {
          const lines = chunk.split("\n");
          // We want to check if the first line contains some info (For instance if it points to our docs) and if that is the case we want to remove it for the parsing process.
          if (lines[0].startsWith("Note")) {
            lines.shift(); // Remove the first line
          }
          return lines.join("\n");
        },
        /** Fast mode will also catch quotes fields: https://www.papaparse.com/docs#config */
        fastMode: true,
        preview,
        skipEmptyLines: true,
        transform: (value) => value.trim(),
        transformHeader: (value) => value.trim(),
      });

      /** Check general file integrity */
      if (!_.isNilOrEmptyString(results.errors) || results.errors.length) {
        return policy.file.parseGeneral(t);
      }

      /** Check column headers (number and name) */
      if (_.isNilOrEmptyString(results.meta.fields)) {
        return policy.file.parseHeader(t, format.header.join(", "));
      }

      if (results.meta.fields.length !== format.header.length) {
        return policy.file.parseHeaderColumns(
          t,
          results.meta.fields.length,
          format.header.length,
        );
      }

      if (max && !_.isNil(max?.rows) && results.data.length > max.rows) {
        return policy.file.parseMaxRows(t, max.rows);
      }

      if (min && !_.isNil(min?.rows) && results.data.length < min.rows) {
        return policy.file.parseMinRows(t, min.rows);
      }

      if (
        results.meta.fields.some(
          (value, index) => format.header[index] !== value,
        )
      ) {
        return policy.file.parseHeader(t, format.header.join(", "));
      }

      if (
        results.meta.fields.find((value) => _.toString(value).includes('"'))
      ) {
        return policy.file.parseHeaderQuotes(t);
      }

      /** Check rows (number and value) */

      if (_.isNilOrEmptyString(results.data) || results.data.length === 0) {
        return policy.file.parseContent(t);
      }

      try {
        results.data.forEach((row, index) => {
          format.header.forEach((column, position) => {
            const type = format.row[position];
            const value = _.get(row, column);
            const humanized_row = index + 1;

            if (_.isNilOrEmptyString(value)) {
              return policy.file.parseContentRow(t, column, humanized_row);
            }

            if (_.toString(value).includes('"')) {
              return policy.file.parseContentQuotes(t);
            }

            switch (type) {
              case "address": {
                if (!_.isEthereumAddress(value)) {
                  throw policy.file.parseContentRow(t, column, humanized_row);
                }
                break;
              }
              case "amount": {
                if (!new RegExp(/^[0-9.-]+$/).test(value)) {
                  throw policy.file.parseContentRow(t, column, humanized_row);
                }
                break;
              }
              case "integer": {
                if (!new RegExp(/^[1-9][0-9]*$/).test(value)) {
                  throw policy.file.parseContentRow(t, column, humanized_row);
                }
                break;
              }
              case "date": {
                if (
                  !dayjs(
                    String(value),
                    GROUP_STREAM_DATE_FORMATS_CSV,
                    true,
                  ).isValid()
                ) {
                  throw policy.file.parseContentRow(t, column, humanized_row);
                }
                break;
              }
              case "duration": {
                if (
                  !new RegExp(
                    /^((\d+\syears?)?\s*((\d+\sdays?)?\s*(\d+\shours?)?)?)$/i,
                  ).test(value)
                ) {
                  throw policy.file.parseContentRow(t, column, humanized_row);
                }
                break;
              }
              case "interval": {
                const intervalValues = ["hour", "day", "week", "month", "year"];
                if (!intervalValues.includes(value.trim().toLowerCase())) {
                  throw policy.file.parseContentRow(t, column, humanized_row);
                }
                break;
              }
              case "percentages": {
                // The years column has been previously validated
                const years = _.toNumber(_.get(row, "years"));
                const positiveNumberPattern = /^\d+(\.\d+)?$/;

                const percentages = _.toString(value).split(";");
                // Check if the number of percentages matches the number of years
                if (percentages.length !== years) {
                  throw policy.file.parseYearsPercentagesMismatch(
                    t,
                    humanized_row,
                  );
                }

                // Validate each element to ensure it matches the positive number pattern
                let percentageSum = 0;
                for (const percentage of percentages) {
                  if (!positiveNumberPattern.test(percentage.trim())) {
                    throw policy.file.parsePercentages(t, humanized_row);
                  }
                  percentageSum += _.toNumber(percentage);
                }

                // Ensure that the sum of all percentages equal to 100%
                if (percentageSum !== 100) {
                  throw policy.file.parsePercentages(t, humanized_row);
                }

                break;
              }
              default: {
                break;
              }
            }
          });
        });
      } catch (error) {
        return _.isString(error) ? error : policy.file.invalid(t);
      }
    }
  } catch (error) {
    return policy.file.invalid(t);
  }
  return undefined;
}

export async function validateFileCSV({
  min,
  max,
  t,
  value,
  format,
  preview = AIRSTREAMS_RECIPIENTS_VALIDATION_THRESHOLD,
}: FileContentValidProps): Promise<string | undefined> {
  const isFormatValid = validateFileFormat({ t, purpose: "csv", max, value });

  if (!_.isNilOrEmptyString(isFormatValid)) {
    return isFormatValid;
  }

  const isContentValid = await validateFileContent({
    t,
    format,
    max,
    min,
    purpose: "csv",
    value,
    preview: Math.max(preview, max?.rows || 0),
  });

  if (!_.isNilOrEmptyString(isContentValid)) {
    return isContentValid;
  }

  return undefined;
}
