import { EXPONENT_DECIMALS } from "@sablier/v2-constants";
import {
  DEFAULT_BROKER_ADDRESS,
  DEFAULT_BROKER_FEE_PERCENTAGE,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { _ } from "@sablier/v2-mixins";
import { contractor_lockup, peripheral } from "~/client/utils";
import type { ITranchedTimelock } from "./config";
import type { IAddress, IWagmiAddress } from "@sablier/v2-types";
import type {
  IExtensionParamsGroup,
  IExtensionParamsSingle,
  IExtensionResultDurationsLT,
  IExtensionResultTimestampsLT,
} from "~/client/types";
import { precompute } from "./setup";

type IExtension = ITranchedTimelock;

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

async function processSingle(
  params: IExtensionParamsSingle & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLT;
}>;
async function processSingle(
  params: IExtensionParamsSingle & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLT;
}>;
async function processSingle(params: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLT | IExtensionResultDurationsLT;
}>;

async function processSingle({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLT | IExtensionResultDurationsLT;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose } = extras as IExtension;
  const {
    address,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    sender,
    start,
    token,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup: IAddress = contractor_lockup(chainId!, purpose).address;

  const { amount: deposit } = precompute.single({ dependencies });
  const endTime = _.toSeconds(end);
  const startTime = _.toSeconds(start);
  const totalDuration = _.toSeconds(duration);

  /**
   * ------------------------------
   * Setup dependencies: DYNAMIC
   * ------------------------------
   */

  if (timing === "duration") {
    type Inputs = IExtensionResultDurationsLT["inputs"];

    const tranches = [
      {
        amount: _.toBigInt(deposit),
        duration: _.toNumber(totalDuration),
      },
    ] as const;

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      [
        {
          sender: sender as IWagmiAddress,
          recipient: address as IWagmiAddress,
          totalAmount: _.toBigInt(deposit),
          tranches,
          cancelable: !!cancelability,
          transferable: !!transferability,
          broker: {
            account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
            fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
          },
        },
      ],
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithDurationsLT",
      inputs,
    );

    return {
      batch: data,
    };
  } else {
    type Inputs = IExtensionResultTimestampsLT["inputs"];

    const tranches = [
      {
        amount: _.toBigInt(deposit),
        timestamp: _.toNumber(endTime),
      },
    ] as const;

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      [
        {
          sender: sender as IWagmiAddress,
          recipient: address as IWagmiAddress,
          totalAmount: _.toBigInt(deposit),
          startTime: _.toNumber(startTime),
          tranches,
          cancelable: !!cancelability,
          transferable: !!transferability,
          broker: {
            account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
            fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
          },
        },
      ],
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithTimestampsLT",
      inputs,
    );

    return {
      batch: data,
    };
  }
}

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

type IResultGroup = {
  batch: IExtensionResultDurationsLT | IExtensionResultTimestampsLT;
};

async function processGroup(
  params: IExtensionParamsGroup & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLT;
}>;
async function processGroup(
  params: IExtensionParamsGroup & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLT;
}>;
async function processGroup(
  params: IExtensionParamsGroup,
): Promise<IResultGroup>;

async function processGroup({
  dependencies,
  purpose,
  timing,
  library,
}: IExtensionParamsGroup): Promise<IResultGroup> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const {
    cancelability,
    transferability,
    chainId,
    sender,
    token,
    streams,
    signer,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup = contractor_lockup(chainId, purpose).address;
  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  if (timing === "duration") {
    type Inputs = IExtensionResultDurationsLT["inputs"];

    const params: Inputs["2"] = await Promise.all(
      streams?.map(async (stream) => {
        const { address, amount, duration, end, start } = stream;

        const single = await processSingle({
          dependencies: {
            address,
            amount,
            cancelability,
            transferability,
            duration,
            end,
            token,
            start,
            chainId,
            sender,
            signer,
          },
          extras: stream.extension,
          timing: "duration",
          library,
        });

        return single.batch.inputs[2][0];
      }) || [],
    );

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      params,
    ] as const;

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithDurationsLT",
      inputs,
    );

    return {
      batch: data,
    };
  } else {
    type Inputs = IExtensionResultTimestampsLT["inputs"];

    const params: Inputs["2"] = await Promise.all(
      streams?.map(async (stream) => {
        const { address, amount, duration, end, start } = stream;

        const single = await processSingle({
          dependencies: {
            address,
            amount,
            cancelability,
            transferability,
            duration,
            end,
            token,
            start,
            chainId,
            sender,
            signer,
          },
          extras: stream.extension,
          timing: "range",
          library,
        });

        return single.batch.inputs[2][0];
      }) || [],
    );

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      params,
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithTimestampsLT",
      inputs,
    );

    return {
      batch: data,
    };
  }
}

export const process = { group: processGroup, single: processSingle };
