import { DigitPurpose } from "@sablier/v2-constants";
import { LockupStatus } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import numeral from "numeral";
import { assign, createMachine } from "xstate";
import type { Context } from "./context";
import { Initial, Limits } from "./context";
import { addPadding } from "./helper";

const machine = createMachine(
  {
    tsTypes: {} as import("./amount.typegen").Typegen0,
    id: "amountMachine",
    initial: "initial",
    context: Initial,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as { type: "FORMAT"; source: string; status?: LockupStatus },
    },
    states: {
      initial: {
        on: {
          FORMAT: {
            target: "prepare",
            actions: "doPrepare",
          },
        },
      },
      prepare: {
        always: [
          {
            cond: "isAbbreviationRequired",
            target: "abbreviate",
          },
          {
            target: "characteristics",
          },
        ],
      },
      abbreviate: {
        entry: "doAbbreviate",
        always: [
          {
            actions: "doAbbreviatePrefix",
            target: "initial",
          },
        ],
      },
      characteristics: {
        entry: "doCharacteristics",
        always: [
          {
            cond: "isPrefixRequired",
            actions: "doCharacteristicsPrefix",
            target: "mantissa_prepare",
          },
          {
            target: "mantissa_prepare",
          },
        ],
      },
      mantissa_prepare: {
        entry: "doMantissaPrepare",
        always: [
          {
            target: "mantissa",
          },
        ],
      },

      mantissa: {
        entry: "doMantissa",
        always: [
          {
            cond: "isSuffixRequired",
            actions: "doMantissaSuffix",
            target: "divide_and_highlight",
          },
          {
            target: "divide_and_highlight",
          },
        ],
      },
      divide_and_highlight: {
        entry: "doDivideAndHighlight",
        always: [
          {
            target: "initial",
          },
        ],
      },
    },
  },

  {
    actions: {
      /** Prepare the necessary context variables */
      doPrepare: assign({
        source: (_context, event) => event.source,
        computed: (_context, event) => {
          const { source } = event;

          /** Format the source such that it's separated by "." and gets cut after a certain precision */

          const isZero = new BigNumber(source).isZero();

          /**
           * Decimals
           * - max: Limits.MANTISSA_PRECISION_CEIL
           * - min: Limits.MANTISSA_PRECISION_FLOOR
           * - untouched if already in between
           */

          const decimals = isZero
            ? 0
            : BigNumber.min(
                new BigNumber(source).decimalPlaces() ||
                  Limits.MANTISSA_PRECISION_FLOOR,
                Limits.MANTISSA_PRECISION_CEIL,
              ).toNumber();

          const sanitized = new BigNumber(source).toFormat(
            decimals,
            BigNumber.ROUND_DOWN,
            {
              decimalSeparator: ".",
              groupSeparator: "",
            },
          );

          const parts = sanitized.split(".");
          /** Extract number slice before the decimal point */
          const characteristic = new BigNumber(parts[0]);
          /** Extra number slice after the decimal point and remove unnecessary padding (zeroes) */
          const mantissa = parts[1] || "0";
          const characteristicDigits = characteristic.toString().split("");
          const mantissaDigits = mantissa.toString().split("");

          return {
            ..._.cloneDeep(Initial).computed,
            definition: [],
            characteristic,
            characteristicDigits,
            mantissa: new BigNumber(mantissa),
            mantissaDigits,
            sanitized,
            source,
          };
        },
      }),
      doAbbreviate: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { definition } = computed;

          const formatted = numeral(
            context.computed.sanitized.toString(),
          ).format("(0,0000 a)");

          formatted.split("").forEach((item) => {
            if (new RegExp(/^[0-9]+$/).test(item)) {
              definition.push({ d: item, p: DigitPurpose.CHARACTERISTIC });
            } else if ([".", ","].includes(item)) {
              definition.push({ d: item, p: DigitPurpose.DIVIDER });
            } else if (new RegExp(/^[a-zA-Z]+$/).test(item)) {
              definition.push({
                d: item.toUpperCase(),
                p: DigitPurpose.ABBREVIATION,
              });
            }
          });

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      doAbbreviatePrefix: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { definition } = computed;

          let digits = 0;
          let foundDivider = false;

          definition.forEach(({ d }) => {
            if (!foundDivider) {
              if (d === ".") {
                foundDivider = true;
              } else {
                digits++;
              }
            }
          });

          addPadding(
            definition,
            digits,
            Limits.CHARACTERISTIC_PRECISION_FLOOR,
            true,
          );

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      /** Paint the characteristic digits (those before the decimals point) */
      doCharacteristics: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { characteristicDigits, definition } = computed;

          /**  Use all characteristic digits */
          characteristicDigits.forEach((d) => {
            definition.push({ d, p: DigitPurpose.CHARACTERISTIC });
          });

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      /** Paint some prefix 0s if the characteristic digits are too few */
      doCharacteristicsPrefix: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { characteristicDigits, definition } = computed;

          addPadding(
            definition,
            characteristicDigits.length,
            Limits.CHARACTERISTIC_PRECISION_FLOOR,
            true,
          );
          return {
            ...context.computed,
            definition,
          };
        },
      }),
      /**
       * Prepare the mantissa size based on how many characteristic
       * have already been painted (those are more important)
       */
      doMantissaPrepare: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { characteristicDigits, definition } = computed;

          let mantissaDigits = _.cloneDeep(computed.mantissaDigits);

          /**
           * Adapt the mantissa digits based on how many characteristics we have.
           *
           * CASE #1: Lots of C(s) -> For every 2 C(s), Remove(M)
           * CASE #2: Few C(s) -> For every PC, remove 1 PC and Add 2 M(s)
           */

          const difference =
            characteristicDigits.length - Limits.CHARACTERISTIC_PRECISION_FLOOR;

          if (difference > 0) {
            /**
             * CASE #1
             * ------------------------------------------------------
             * Cut down the digits up to our preset maximum precision
             */
            [...Array(difference).keys()].forEach(() => {
              if (mantissaDigits.length - 2 > 0) {
                mantissaDigits = mantissaDigits.slice(0, -2);
              }
            });

            mantissaDigits = mantissaDigits.slice(
              0,
              Limits.MANTISSA_PRECISION_FLOOR,
            );
          } else if (difference <= 0 && difference >= -1) {
            /**
             * CASE #1.5 [SPECIAL]
             * ------------------------------------------------------
             * In case of exactly 4 or 5 characteristics available
             * (difference = [-1, 0]), cap the mantissa(s)
             *
             */
            mantissaDigits = mantissaDigits.slice(
              0,
              Limits.MANTISSA_PRECISION_FLOOR,
            );
          } else if (difference < -1) {
            /**
             * CASE #2
             * ------------------------------------------------------
             * Remove one characteristic prefix and add 2 more mantissa(s)
             */

            const extra = Math.min(
              computed.definition.filter((d) => d.p === DigitPurpose.PREFIX)
                .length,
              2,
            );

            for (let i = 0; i < extra; i++) {
              const index = definition.findIndex(
                (d) => d.p === DigitPurpose.PREFIX,
              );
              definition.splice(index, 1);
            }

            mantissaDigits = mantissaDigits.slice(
              0,
              Math.max(
                Limits.MANTISSA_PRECISION_FLOOR + 2 * extra,
                Limits.MANTISSA_PRECISION_CEIL,
              ),
            );
          }

          return {
            ...context.computed,
            definition,
            mantissaDigits,
          };
        },
      }),
      /** Paint the mantissa digits (those after the decimal point) */
      doMantissa: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { mantissaDigits, definition } = computed;

          /**  Use all remaining mantissa digits */

          mantissaDigits.forEach((d) => {
            definition.push({ d, p: DigitPurpose.MANTISSA });
          });

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      /** Paint some suffix 0s if mantissa digits are too few */
      doMantissaSuffix: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { definition, mantissaDigits } = computed;

          addPadding(
            definition,
            mantissaDigits.length,
            Limits.MANTISSA_PRECISION_FLOOR,
            false,
          );

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      /** Paint the divider (decimal point) and add final highlights */
      doDivideAndHighlight: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { definition } = computed;

          const lastCharacteristicIndex = definition.findIndex(
            (item, index) =>
              [DigitPurpose.CHARACTERISTIC, DigitPurpose.PREFIX].includes(
                item.p,
              ) &&
              index + 1 < definition.length &&
              [DigitPurpose.MANTISSA].includes(definition[index + 1].p),
          );

          if (lastCharacteristicIndex !== -1) {
            definition[lastCharacteristicIndex].p ===
              DigitPurpose.CHARACTERISTIC;
            definition.splice(lastCharacteristicIndex + 1, 0, {
              d: ".",
              p: DigitPurpose.DIVIDER,
            });
          }

          return {
            ...context.computed,
            definition,
          };
        },
      }),
    },
    guards: {
      isAbbreviationRequired: (context) => {
        const { computed } = context;
        return (
          computed.characteristic.toString().length >=
          Limits.ABBREVIATE_PRECISION
        );
      },
      isPrefixRequired: (context) => {
        const { computed } = context;
        return (
          computed.characteristicDigits.length <
          Limits.CHARACTERISTIC_PRECISION_FLOOR
        );
      },
      isSuffixRequired: (context) => {
        const { computed } = context;
        return computed.mantissaDigits.length < Limits.MANTISSA_PRECISION_CEIL;
      },
    },
  },
);

/**
 * A single instances of this machine is necessary.
 */

export default machine;
