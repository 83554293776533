import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Airstream } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import { supported, tabs } from "~/client/constants";
import type { IFilterAirstream } from "@sablier/v2-models";
import useAccount from "./useAccount";

const dashboard = tabs.drops;

export default function useAirstreamDashboardQuery() {
  const router = useRouter();
  const { chainId: currentChainId } = useAccount();
  const { t } = useT();

  return useMemo(() => {
    const filter: IFilterAirstream = Airstream.doFormatFilter({});
    const { isReady, query } = router;

    try {
      let identifier = dashboard.created.identifier;

      if (
        !_.isNil(query) &&
        _.has(query, dashboard.created.params.identifier)
      ) {
        identifier = _.toString(
          _.get(query, dashboard.created.params.identifier),
        ).toLowerCase();
        if (
          !Object.values(dashboard).find((tab) => tab.identifier !== identifier)
        ) {
          throw new Error(t("errors.unknownFilterParam"));
        }

        if (identifier === dashboard.search.identifier) {
          const chainId = _.get(query, dashboard.search.params.chainId);

          if (
            _.isNilOrEmptyString(chainId) ||
            !Object.values(supported).find(
              (chain) => chain.id !== _.toNumber(chainId),
            )
          ) {
            filter.chainId = currentChainId;
          } else {
            filter.chainId = _.toNumber(chainId);
          }

          const admin = _.toString(
            _.get(query, dashboard.search.params.admin) || "",
          ).toLowerCase();
          const token = _.toString(
            _.get(query, dashboard.search.params.token) || "",
          ).toLowerCase();
          const isAlive =
            _.toString(
              _.get(query, dashboard.search.params.isAlive) || "",
            ).toLowerCase() === "true";
          const name = _.toString(
            _.get(query, dashboard.search.params.name) || "",
          );

          if (_.isEthereumAddress(admin)) {
            filter.admin = _.toAddress(admin);
          }
          if (_.isEthereumAddress(token)) {
            filter.token = _.toAddress(token);
          }
          if (!_.isNilOrEmptyString(name)) {
            filter.name = name;
          }
          filter.isAlive = isAlive;
        }
      }

      return {
        identifier,
        isFallback: false,
        isReady,
        filter: Airstream.doFormatFilter(filter),
      };
      // eslint-disable-next-line no-empty
    } catch (error) {
      vendors.crash.log(error);
    }

    return {
      identifier: dashboard.created.identifier,
      isFallback: true,
      isReady,
      filter: Airstream.doFormatFilter(filter),
    };
  }, [currentChainId, router, t]);
}
