const filters = {
  svgId: {
    glow: "glow",
    glow6: "glow6",
    glow9: "glow9",
    glowShadow: "glowShadow",
    dropShadow: "dropShadow",
  },
  svgUrl: {
    glow: "url(#glow)",
    glow6: "url(#glow6)",
    glow9: "url(#glow9)",
    glowShadow: "url(#glowShadow)",
    dropShadow: "url(#dropShadow)",
  },
};

export function Definitions() {
  return (
    <svg
      style={{
        height: 0,
        width: 0,
        position: "absolute",
        pointerEvents: "none",
      }}
    >
      <filter id={filters.svgId.glow}>
        <feGaussianBlur in={"SourceGraphic"} stdDeviation={"3"} />
      </filter>
      <filter id={filters.svgId.glow6}>
        <feGaussianBlur in={"SourceGraphic"} stdDeviation={"6"} />
      </filter>
      <filter id={filters.svgId.glow9}>
        <feGaussianBlur in={"SourceGraphic"} stdDeviation={"9"} />
      </filter>
      <filter id={filters.svgId.glowShadow}>
        <feGaussianBlur in={"SourceAlpha"} stdDeviation={"6"} />
        <feOffset dx={"0"} dy={"0"} />
        <feMerge>
          <feMergeNode />
          <feMergeNode in={"SourceGraphic"} />
        </feMerge>
      </filter>
      <filter id={filters.svgId.dropShadow}>
        <feDropShadow
          dx={"0"}
          dy={"0"}
          stdDeviation={0.4}
          floodColor={"currentColor"}
          floodOpacity={0.8}
        />
      </filter>
    </svg>
  );
}

export default filters;
