import { useCallback } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Token } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { useQueryClient } from "@tanstack/react-query";
import type { IAddress } from "@sablier/v2-types";
import { useFlowStoreAccessor } from "./store";
import { useAirstreamStoreAccessor } from "./store/useAirstreamStore";
import { useLockupStoreAccessor } from "./store/useLockupStore";

export default function useResetter() {
  const airstream = useAirstreamStoreAccessor();
  const lockup = useLockupStoreAccessor();
  const flow = useFlowStoreAccessor();
  const client = useQueryClient();

  const all = useCallback(async () => {
    try {
      /** Cleanup: token allowances, balances (see wagmi's useBalance) */
      Token.reset();
      await client.resetQueries({ queryKey: REQUEST_ID.tokenBalance });

      /** Cleanup: lockup store, lockup query cache */
      lockup().api.reset([]);
      await client.resetQueries({ queryKey: REQUEST_ID.lockup });

      /** Cleanup: flow store, flow query cache */
      flow().api.reset([]);
      await client.resetQueries({ queryKey: REQUEST_ID.flow });

      /** Cleanup: common stream query cache */
      await client.resetQueries({ queryKey: REQUEST_ID.stream });

      /** Cleanup: airstream store, airstream query cache */
      airstream().api.reset([]);
      await client.resetQueries({ queryKey: REQUEST_ID.airstream });
    } catch (error) {
      vendors.crash.log(error);
    }
  }, [airstream, lockup, flow, client]);

  const own = useCallback(async () => {
    try {
      /** Cleanup: token allowances, balances (see wagmi's useBalance) */
      Token.reset();
      await client.resetQueries({ queryKey: REQUEST_ID.tokenBalance });

      /** Cleanup: owned lockup store (omit general results like search), owned lockup query cache */
      lockup().api.reset(["search", "preview"]);
      await client.resetQueries({ queryKey: REQUEST_ID.lockupListOwned });

      /** Cleanup: owned flow store (omit general results like search), owned flow query cache */
      flow().api.reset(["search", "preview"]);
      await client.resetQueries({ queryKey: REQUEST_ID.flowListOwned });

      /** Cleanup: owned airstream store (omit general results like search), owned airstreams query cache */
      airstream().api.reset(["search", "preview"]);
      await client.resetQueries({ queryKey: REQUEST_ID.airstreamListOwned });
      await client.resetQueries({ queryKey: REQUEST_ID.airstreamListEligible });
      await client.resetQueries({
        queryKey: REQUEST_ID.airstreamListClaimable,
      });
    } catch (error) {
      vendors.crash.log(error);
    }
  }, [airstream, lockup, flow, client]);

  const search = useCallback(
    async (feature: "lockups" | "airstreams" | "flows") => {
      try {
        /** Cleanup: token allowances, balances (see wagmi's useBalance) */
        Token.reset();
        await client.resetQueries({ queryKey: REQUEST_ID.tokenBalance });

        if (feature === "lockups") {
          /** Cleanup: searched stream store (omit owned or preview results), searched streams query cache */
          lockup().api.reset(["owned", "preview"]);
          await client.resetQueries({ queryKey: REQUEST_ID.lockupListSearch });
        }

        if (feature === "flows") {
          /** Cleanup: searched stream store (omit owned or preview results), searched streams query cache */
          flow().api.reset(["owned", "preview"]);
          await client.resetQueries({ queryKey: REQUEST_ID.flowListSearch });
        }

        if (feature === "airstreams") {
          /** Cleanup: searched stream store (omit owned or preview results), searched streams query cache */
          airstream().api.reset(["owned", "eligible", "preview"]);
          await client.resetQueries({
            queryKey: REQUEST_ID.airstreamListSearch,
          });
        }
      } catch (error) {
        vendors.crash.log(error);
      }
    },
    [airstream, lockup, flow, client],
  );

  const searchAirstreams = useCallback(() => search("airstreams"), [search]);
  const searchLockups = useCallback(() => search("lockups"), [search]);
  const searchFlows = useCallback(() => search("flows"), [search]);

  const detailsAirstream = useCallback(
    async (address: IAddress, chainId: number | string) => {
      await client.resetQueries({
        queryKey: [
          ...REQUEST_ID.airstreamDetails,
          { unique: [chainId, address] },
        ],
      });
    },
    [client],
  );

  const proxy = useCallback(async () => {
    try {
      await own();
      await client.resetQueries({ queryKey: REQUEST_ID.proxy });
    } catch (error) {
      vendors.crash.log(error);
    }
  }, [client, own]);

  const token = useCallback(async () => {
    try {
      /** Cleanup: token allowances, balances (see wagmi's useBalance) */
      Token.reset();
      await client.resetQueries({ queryKey: REQUEST_ID.tokenBalance });
    } catch (error) {
      vendors.crash.log(error);
    }
  }, [client]);

  return {
    all,
    detailsAirstream,
    own,
    searchAirstreams,
    searchLockups,
    searchFlows,
    proxy,
    token,
  };
}
