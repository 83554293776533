import { useEffect, useMemo } from "react";
import { useIsSanctioned } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { useModalSanctionStream } from "./modals";
import useLockupCurrent from "./useLockupCurrent";

export default function useIsSanctionedStream() {
  const { stream, isLoading } = useLockupCurrent();
  const { setOpen } = useModalSanctionStream();

  const { isSanctioned: isSenderSanctioned } = useIsSanctioned(stream?.sender);
  const { isSanctioned: isRecipientSanctioned } = useIsSanctioned(
    stream?.recipient,
  );
  const { isSanctioned: isFunderSanctioned } = useIsSanctioned(stream?.funder);
  const { isSanctioned: isTokenSanctioned } = useIsSanctioned(
    stream?.token.address,
  );

  const isSanctioned = useMemo(
    () =>
      isSenderSanctioned ||
      isRecipientSanctioned ||
      isFunderSanctioned ||
      isTokenSanctioned,
    [
      isFunderSanctioned,
      isRecipientSanctioned,
      isSenderSanctioned,
      isTokenSanctioned,
    ],
  );

  useEffect(() => {
    if (_.isNil(stream) || isLoading) {
      return;
    }

    setOpen(isSanctioned);
  }, [isLoading, isSanctioned, setOpen, stream]);

  return {
    isSanctioned,
  };
}
