/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment ContractFragment on Contract {\n    id\n    address\n    version\n  }\n": types.ContractFragmentFragmentDoc,
    "\n  fragment ActionFragment on Action {\n    id\n    chainId\n    subgraphId\n    stream {\n      id\n    }\n    category\n    hash\n    block\n    timestamp\n    from\n    addressA\n    addressB\n    amountA\n    amountB\n    contract {\n      ...ContractFragment\n    }\n  }\n": types.ActionFragmentFragmentDoc,
    "\n  fragment BatchFragment on Batch {\n    id\n    label\n    size\n  }\n": types.BatchFragmentFragmentDoc,
    "\n  fragment AssetFragment on Asset {\n    id\n    address\n    chainId\n    decimals\n    name\n    symbol\n  }\n": types.AssetFragmentFragmentDoc,
    "\n  fragment StreamFragment on Stream {\n    id\n    tokenId\n    subgraphId\n    chainId\n    alias\n    creator\n    sender\n    recipient\n    hash\n    timestamp\n    startTime\n    depletionTime\n    transferable\n    paused\n    pausedTime\n    voided\n    voidedTime\n    lastAdjustmentTimestamp\n    availableAmount\n    ratePerSecond\n    depositedAmount\n    refundedAmount\n    withdrawnAmount\n    snapshotAmount\n    protocolFeeAmount\n    position\n    version\n    asset {\n      ...AssetFragment\n    }\n    batch {\n      ...BatchFragment\n    }\n    contract {\n      ...ContractFragment\n    }\n  }\n": types.StreamFragmentFragmentDoc,
    "\n  query getStreams_BySenderByRecipientByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySenderByRecipientByIdsByTokenDocument,
    "\n  query getStreams_BySenderByRecipientByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySenderByRecipientByIdsDocument,
    "\n  query getStreams_BySenderByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySenderByIdsByTokenDocument,
    "\n  query getStreams_ByRecipientByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        asset: $token\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_ByRecipientByIdsByTokenDocument,
    "\n  query getStreams_BySenderByRecipientByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySenderByRecipientByTokenDocument,
    "\n  query getStreams_ByRecipientByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_ByRecipientByIdsDocument,
    "\n  query getStreams_BySenderByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySenderByIdsDocument,
    "\n  query getStreams_BySenderByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySenderByRecipientDocument,
    "\n  query getStreams_ByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_ByIdsByTokenDocument,
    "\n  query getStreams_ByRecipientByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        asset: $token\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_ByRecipientByTokenDocument,
    "\n  query getStreams_BySenderByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySenderByTokenDocument,
    "\n  query getStreams_BySender_Or_ByRecipient_Or_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        or: [\n          {\n            and: [\n              { sender: $sender }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { recipient: $recipient }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { asset: $token }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySender_Or_ByRecipient_Or_ByTokenDocument,
    "\n  query getStreams_BySender_Or_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        or: [\n          {\n            and: [\n              { sender: $sender }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { recipient: $recipient }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySender_Or_ByRecipientDocument,
    "\n  query getStreams_BySender(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { sender: $sender, subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_BySenderDocument,
    "\n  query getStreams_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_ByRecipientDocument,
    "\n  query getStreams_ByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_ByIdsDocument,
    "\n  query getStreams_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { asset: $token, subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreams_ByTokenDocument,
    "\n  query getStreams(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n": types.GetStreamsDocument,
    "\n  query getStreamIdentifiers($first: Int!, $skip: Int!, $chainId: BigInt!) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: timestamp\n      orderDirection: desc\n      where: { chainId: $chainId }\n    ) {\n      id\n      alias\n    }\n  }\n": types.GetStreamIdentifiersDocument,
    "\n  query getActions_ByStream(\n    $first: Int!\n    $streamId: String!\n    $subgraphId: BigInt!\n  ) {\n    actions(\n      first: $first\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { stream: $streamId, subgraphId_lt: $subgraphId }\n    ) {\n      ...ActionFragment\n      stream {\n        id\n        asset {\n          ...AssetFragment\n        }\n      }\n    }\n  }\n": types.GetActions_ByStreamDocument,
    "\n  query getStream_ById($streamId: ID!) {\n    stream(id: $streamId) {\n      ...StreamFragment\n    }\n  }\n": types.GetStream_ByIdDocument,
    "\n  query getStreamIds_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { asset: $token, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n": types.GetStreamIds_ByTokenDocument,
    "\n  query getStreamIds_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { recipient: $recipient, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n": types.GetStreamIds_ByRecipientDocument,
    "\n  query getStreamIds_BySender(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { sender: $sender, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n": types.GetStreamIds_BySenderDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ContractFragment on Contract {\n    id\n    address\n    version\n  }\n"): (typeof documents)["\n  fragment ContractFragment on Contract {\n    id\n    address\n    version\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ActionFragment on Action {\n    id\n    chainId\n    subgraphId\n    stream {\n      id\n    }\n    category\n    hash\n    block\n    timestamp\n    from\n    addressA\n    addressB\n    amountA\n    amountB\n    contract {\n      ...ContractFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ActionFragment on Action {\n    id\n    chainId\n    subgraphId\n    stream {\n      id\n    }\n    category\n    hash\n    block\n    timestamp\n    from\n    addressA\n    addressB\n    amountA\n    amountB\n    contract {\n      ...ContractFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BatchFragment on Batch {\n    id\n    label\n    size\n  }\n"): (typeof documents)["\n  fragment BatchFragment on Batch {\n    id\n    label\n    size\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AssetFragment on Asset {\n    id\n    address\n    chainId\n    decimals\n    name\n    symbol\n  }\n"): (typeof documents)["\n  fragment AssetFragment on Asset {\n    id\n    address\n    chainId\n    decimals\n    name\n    symbol\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StreamFragment on Stream {\n    id\n    tokenId\n    subgraphId\n    chainId\n    alias\n    creator\n    sender\n    recipient\n    hash\n    timestamp\n    startTime\n    depletionTime\n    transferable\n    paused\n    pausedTime\n    voided\n    voidedTime\n    lastAdjustmentTimestamp\n    availableAmount\n    ratePerSecond\n    depositedAmount\n    refundedAmount\n    withdrawnAmount\n    snapshotAmount\n    protocolFeeAmount\n    position\n    version\n    asset {\n      ...AssetFragment\n    }\n    batch {\n      ...BatchFragment\n    }\n    contract {\n      ...ContractFragment\n    }\n  }\n"): (typeof documents)["\n  fragment StreamFragment on Stream {\n    id\n    tokenId\n    subgraphId\n    chainId\n    alias\n    creator\n    sender\n    recipient\n    hash\n    timestamp\n    startTime\n    depletionTime\n    transferable\n    paused\n    pausedTime\n    voided\n    voidedTime\n    lastAdjustmentTimestamp\n    availableAmount\n    ratePerSecond\n    depositedAmount\n    refundedAmount\n    withdrawnAmount\n    snapshotAmount\n    protocolFeeAmount\n    position\n    version\n    asset {\n      ...AssetFragment\n    }\n    batch {\n      ...BatchFragment\n    }\n    contract {\n      ...ContractFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySenderByRecipientByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySenderByRecipientByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySenderByRecipientByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySenderByRecipientByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySenderByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySenderByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_ByRecipientByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        asset: $token\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_ByRecipientByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        asset: $token\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySenderByRecipientByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySenderByRecipientByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $recipient: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_ByRecipientByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_ByRecipientByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySenderByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySenderByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySenderByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySenderByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        sender: $sender\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_ByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_ByIdsByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_ByRecipientByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        asset: $token\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_ByRecipientByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        asset: $token\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySenderByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySenderByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $token\n        sender: $sender\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySender_Or_ByRecipient_Or_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        or: [\n          {\n            and: [\n              { sender: $sender }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { recipient: $recipient }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { asset: $token }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySender_Or_ByRecipient_Or_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        or: [\n          {\n            and: [\n              { sender: $sender }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { recipient: $recipient }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { asset: $token }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySender_Or_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        or: [\n          {\n            and: [\n              { sender: $sender }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { recipient: $recipient }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySender_Or_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        or: [\n          {\n            and: [\n              { sender: $sender }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n          {\n            and: [\n              { recipient: $recipient }\n              { subgraphId_lt: $subgraphId }\n              { chainId: $chainId }\n            ]\n          }\n        ]\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_BySender(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { sender: $sender, subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_BySender(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { sender: $sender, subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        recipient: $recipient\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_ByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_ByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $streamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        id_in: $streamIds\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n      }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { asset: $token, subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { asset: $token, subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreams(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStreams(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { subgraphId_lt: $subgraphId, chainId: $chainId }\n    ) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreamIdentifiers($first: Int!, $skip: Int!, $chainId: BigInt!) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: timestamp\n      orderDirection: desc\n      where: { chainId: $chainId }\n    ) {\n      id\n      alias\n    }\n  }\n"): (typeof documents)["\n  query getStreamIdentifiers($first: Int!, $skip: Int!, $chainId: BigInt!) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: timestamp\n      orderDirection: desc\n      where: { chainId: $chainId }\n    ) {\n      id\n      alias\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getActions_ByStream(\n    $first: Int!\n    $streamId: String!\n    $subgraphId: BigInt!\n  ) {\n    actions(\n      first: $first\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { stream: $streamId, subgraphId_lt: $subgraphId }\n    ) {\n      ...ActionFragment\n      stream {\n        id\n        asset {\n          ...AssetFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getActions_ByStream(\n    $first: Int!\n    $streamId: String!\n    $subgraphId: BigInt!\n  ) {\n    actions(\n      first: $first\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { stream: $streamId, subgraphId_lt: $subgraphId }\n    ) {\n      ...ActionFragment\n      stream {\n        id\n        asset {\n          ...AssetFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStream_ById($streamId: ID!) {\n    stream(id: $streamId) {\n      ...StreamFragment\n    }\n  }\n"): (typeof documents)["\n  query getStream_ById($streamId: ID!) {\n    stream(id: $streamId) {\n      ...StreamFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreamIds_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { asset: $token, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query getStreamIds_ByToken(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $token: String!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { asset: $token, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreamIds_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { recipient: $recipient, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query getStreamIds_ByRecipient(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $recipient: Bytes!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { recipient: $recipient, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStreamIds_BySender(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { sender: $sender, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query getStreamIds_BySender(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $sender: Bytes!\n  ) {\n    streams(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { sender: $sender, chainId: $chainId }\n    ) {\n      id\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;