import nextDynamic from "next/dynamic";

const DetailsAirstream = nextDynamic(() => import("./Airstream/Details"), {
  ssr: false,
});
const DetailsLockup = nextDynamic(() => import("./Lockup/Details"), {
  ssr: false,
});
const DetailsFlow = nextDynamic(() => import("./Flow/Details"), {
  ssr: false,
});

function All() {
  return (
    <>
      <DetailsAirstream />
      <DetailsLockup />
      <DetailsFlow />
    </>
  );
}

export default All;
