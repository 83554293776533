import {
  DEFAULT_SAFE_POLLING_SLEEP,
  MAX_AMOUNT_PADDED,
  MAX_SAFE_TX_ATTEMPTS,
  macros,
} from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import SafeAppsSDK, {
  BaseTransaction,
  TransactionStatus,
} from "@safe-global/safe-apps-sdk";
import { getSafeMessage } from "@safe-global/safe-gateway-typescript-sdk";
import {
  EstimateGasExecutionError,
  TransactionExecutionError,
  UserRejectedRequestError,
  decodeEventLog,
  encodeFunctionData,
} from "viem";
import {
  getPublicClient,
  readContracts,
  signTypedData,
  signMessage as wagmiSignMessage,
  waitForTransactionReceipt,
  writeContract,
} from "wagmi/actions";
import type { Name, PrimitiveInputs, Purpose } from "../types/helper";
import type { preview } from "./remap";
import type {
  IAddress,
  IToken,
  ITransactionReceipt,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import type { EIP712TypedData } from "@safe-global/safe-apps-sdk";
import type { Abi } from "abitype";
import type { WaitForTransactionReceiptParameters } from "viem";
import { contextualize, prepare } from "./remap";

const P = new Error("<<< Provider not configured in Framework >>>");
const MG = new Error(
  "<<< Gas consumption is higher than the maximum limit allowed by the blockchain. Please reach out in the help chat. >>>",
);

/* eslint-disable @typescript-eslint/ban-ts-comment */

/**
 * ------------------------------------------------------------
 * The abi-to-method-to-inputs resolution in `viem` seems to
 * be incompatible with the resolver we constructed using `abitype`.
 *
 * Unfortunately, until we find a fix for this we'll ask typescript to ignore it.
 * The functionality is mirrored 1:1 by our system so misusing it
 * will still cause clear errors.
 * ------------------------------------------------------------
 */

/**
 * Returns the number of on-chain confirmations
 * for any given transaction hash
 */

export async function confirmations(
  library: IWagmiConfig,
  { hash }: { hash: string },
) {
  const provider = getPublicClient(library);

  if (!provider) {
    throw P;
  }

  const confirmations = await provider.getTransactionConfirmations({
    hash: hash as IWagmiAddress,
  });

  return confirmations;
}

/**
 * Encode a function call from an existing whitelisted ABI into
 * hex calldata (perfect for usage with a proxy)
 */
export async function encode<P extends Purpose, M extends Name<P>>(
  purpose: P,
  method: M,
  inputs: PrimitiveInputs<P, M>,
) {
  const abis = (await import("../abis")).default;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const encoded = encodeFunctionData({
    abi: abis[purpose],
    functionName: method,
    args: inputs,
  });

  return encoded;
}

export async function encodeQuery(query: ReturnType<typeof contextualize>) {
  return encode(query.purpose, query.method, query.inputs);
}

/**
 * Male a number of calls to read-only functions
 * (from whitelisted ABIs) through a multicall
 */

export async function read(
  library: IWagmiConfig,
  { previews }: { previews: Awaited<ReturnType<typeof preview>> },
) {
  /**
   * This method now throws a TS error: "Type instantiation is excessively deep and possibly infinite."
   * https://stackoverflow.com/a/76796080/5106700
   */

  // @ts-ignore
  return readContracts(library, { contracts: previews }) as ReturnType<
    typeof readContracts<IWagmiConfig, Awaited<ReturnType<typeof preview>>>
  >;
}

/**
 * Send a transaction with a write method to a specified
 * contract (with a whitelisted ABIs)
 */

export async function write(
  library: IWagmiConfig,
  { prepared }: { prepared: Awaited<ReturnType<typeof prepare>> },
) {
  try {
    /** The result has to be explicitly awaited in order for the try/catch to be considered. */
    const result = await writeContract(library, prepared.request);
    return result;
  } catch (error) {
    throw erroneous(error);
  }
}

/**
 * Send a transaction with a write method to a specified
 * contract (with a whitelisted ABIs), batched with an allowance request
 * using the Safe SDK.
 */

export async function safeAllowAndWrite(
  library: IWagmiConfig,
  {
    queries,
    spender,
    token,
  }: {
    queries: Parameters<typeof prepare>["1"]["query"][];
    spender: IAddress;
    token: Pick<IToken, "address" | "chainId" | "decimals">;
  },
) {
  try {
    const limit = MAX_AMOUNT_PADDED(token.decimals).toString();

    const allowance = contextualize(
      token.address,
      token.chainId,
      "token",
      "approve",
      [spender as IWagmiAddress, _.toBigInt(limit)],
    );

    const result = await safeWrite(library, {
      queries: [allowance, ...queries],
    });
    return result;
  } catch (error) {
    throw erroneous(error);
  }
}

/**
 * Send a transaction with a write method to a specified
 * contract (with a whitelisted ABIs) using the Safe SDK.
 */

export async function safeWrite(
  _library: IWagmiConfig,
  {
    queries,
  }: {
    queries: Parameters<typeof prepare>["1"]["query"][];
  },
) {
  try {
    const sdk = new SafeAppsSDK({ debug: true });

    const txs: BaseTransaction[] = [];

    for (const query of queries) {
      txs.push({
        to: query.address,
        value: "0",
        data: await encode(query.purpose, query.method, query.inputs),
      });
    }

    /** The result has to be explicitly awaited in order for the try/catch to be considered. */
    const result = await sdk.txs.send({ txs });
    return result.safeTxHash;
  } catch (error) {
    throw erroneous(error);
  }
}

/**
 * Wait for the result of a broadcasted transaction.
 * Reacts to transaction replacement and finishes when the
 * first confirmation is sent.
 */

export async function wait(
  library: IWagmiConfig,
  {
    hash,
    onReplaced,
  }: {
    hash: string;
    onReplaced?: WaitForTransactionReceiptParameters["onReplaced"];
  },
) {
  return waitForTransactionReceipt(library, {
    hash: hash as IWagmiAddress,
    onReplaced,
  });
}

/**
 * Estimate gas for any given "write-mode"
 * transaction (query) and handle limit tweaks.
 */

export async function fuel(
  library: IWagmiConfig,
  {
    fallback,
    query,
    signer,
    maxGas,
  }: {
    fallback: number;
    maxGas: number;
    query: Parameters<typeof prepare>["1"]["query"];
    signer: Parameters<typeof prepare>["1"]["signer"];
  },
) {
  const abis = (await import("../abis")).default;
  const provider = getPublicClient(library);

  if (!provider) {
    throw P;
  }

  let fueled = undefined;

  try {
    const estimated = await provider.estimateContractGas({
      // @ts-ignore
      abi: abis[query.purpose],
      address: query.address,
      // @ts-ignore
      args: query.inputs,
      // @ts-ignore
      functionName: query.method,
      account: signer.account!.address,
    });
    console.log({ estimated, maxGas }); // TODO remove in production
    if (estimated >= maxGas) {
      throw MG;
    }

    fueled = await prepare(library, { query, signer });
    fueled.request.gas = [_.toBigInt(fallback), estimated].reduce((p, c) =>
      p > c ? p : c,
    );
  } catch (error) {
    if (_.get(error, "name") === EstimateGasExecutionError.name) {
      fueled = await prepare(library, { query, signer, gasLimit: fallback });
    } else {
      throw error;
    }
  }

  if (fueled.request.gas) {
    fueled.request.gas =
      (fueled.request.gas || 0n) + _.toBigInt(macros.DEFAULT_GAS_BUMP);
  }

  return fueled;
}

export async function delog({
  abi,
  log,
  receipt,
  search,
}: {
  abi: Abi;
  receipt: ITransactionReceipt;
  log: string;
  search: string;
}) {
  const create = receipt.logs
    .map((item) => {
      item.topics;
      /**
       * The lockup ABIs will not contain all emitted events (e.g. ERC20 transfers or Comptroller fee events).
       * Therefore, some will fail parsing and require filtering out;
       */
      const parsed = _.attempt(() =>
        decodeEventLog({ abi, data: item.data, topics: item.topics }),
      );

      return !_.isError(parsed) && parsed.eventName === log
        ? parsed
        : undefined;
    })
    .filter((item) => item);

  if (create && create.length) {
    return create
      .map((item) => _.get(item!.args, search))
      .filter((data) => !_.isNilOrEmptyString(data));
  }

  return [];
}

export async function sign(
  library: IWagmiConfig,
  { data }: { data: Parameters<typeof signTypedData>["1"] },
) {
  try {
    /** The result has to be explicitly awaited in order for the try/catch to be considered. */
    const result = await signTypedData(library, data);
    return result;
  } catch (error) {
    throw erroneous(error);
  }
}

export async function signMessage(
  library: IWagmiConfig,
  message: Parameters<typeof wagmiSignMessage>["1"],
) {
  try {
    /** The result has to be explicitly awaited in order for the try/catch to be considered. */
    const result = await wagmiSignMessage(library, message);
    return result;
  } catch (error) {
    throw erroneous(error);
  }
}

export class CustomError extends Error {
  cause: Error | unknown;

  constructor(message: string, cause: Error | unknown) {
    super(message);
    this.cause = cause;
  }
}

export function erroneous(error: unknown): Error | unknown {
  const name = _.get(error, "name") || "";
  const message = _.get(error, "message") || "";

  if (
    name === UserRejectedRequestError.name ||
    message.includes("User denied message signature") ||
    message.includes("User denied transaction signature")
  ) {
    return new CustomError("User rejected the request.", error);
  } else if (
    name === TransactionExecutionError.name &&
    message.includes(`An unknown RPC error occurred.`)
  ) {
    return new CustomError(
      `Please try to submit the transaction again. If you are using Wallet Connect, a faulty connection may be causing the error. For maximum compatibility, use a desktop device with Chrome/Brave/Firefox and Metamask/Rabby.`,
      error,
    );
  } else {
    return error;
  }
}

/**
 * Wait for the result of a broadcasted transaction using the Safe SDK.
 * It resolves a safe hash to a real on-chain hash and awaits for a
 * result.
 *
 * Reference: https://github.com/safe-global/safe-apps-sdk/tree/main/packages/safe-apps-sdk
 */

export async function safeWait(
  library: IWagmiConfig,
  {
    hash,
  }: {
    /** The *Gnosis Safe* internal hash, provided by the connector/client */
    hash: string;
  },
): Promise<ITransactionReceipt> {
  const sdk = new SafeAppsSDK({ debug: true });
  let tries = MAX_SAFE_TX_ATTEMPTS;

  while (tries > 0) {
    tries -= 1;
    let queued = undefined;

    /** This is need to try refetching the transaction when the safe sdk throws exceptions.  */
    try {
      queued = await sdk.txs.getBySafeTxHash(hash);
    } catch (error) {
      await _.sleep(DEFAULT_SAFE_POLLING_SLEEP);
      continue;
    }

    console.info("%c[watching-transaction]", "color: mediumslateblue", {
      queued,
    });

    if (
      queued.txStatus === TransactionStatus.AWAITING_CONFIRMATIONS ||
      queued.txStatus === TransactionStatus.AWAITING_EXECUTION
    ) {
      await _.sleep(DEFAULT_SAFE_POLLING_SLEEP);
    }

    if (
      !_.isNilOrEmptyString(queued.txHash) &&
      (queued.txStatus === TransactionStatus.SUCCESS ||
        queued.txStatus === TransactionStatus.CANCELLED ||
        queued.txStatus === TransactionStatus.FAILED)
    ) {
      const receipt = await wait(library, { hash: queued.txHash });
      return receipt;
    }
  }

  throw new Error(
    "Safe transaction watcher could not follow the on-chain transaction for long enough. Please consider refreshing the page.",
  );
}

export async function safeSignatureExtract(
  sdk: SafeAppsSDK,
  {
    chainId,
    messageHash,
  }: {
    chainId: string;
    messageHash: string;
  },
): Promise<string | undefined> {
  /** First ping the client SDK for the signature. This may fail when signing */
  const signature = await sdk.safe.getOffChainSignature(messageHash);

  if (_.isNilOrEmptyString(signature)) {
    const response = await getSafeMessage(chainId, messageHash);
    return response?.preparedSignature;
  }

  return signature;
}

/**
 * Wait for the result of a broadcasted signature request using the Safe SDK.
 *
 * Reference: https://github.com/safe-global/safe-apps-sdk/tree/main/packages/safe-apps-sdk
 */

export async function safeSign(
  data: EIP712TypedData & { domain: { chainId: number | string | bigint } },
) {
  const sdk = new SafeAppsSDK({ debug: true });
  const settings = await sdk.eth.setSafeSettings([{ offChainSigning: true }]);

  if (settings.offChainSigning) {
    const proposal = await sdk.txs.signTypedMessage(data);
    const messageHash = _.get(proposal, "messageHash");

    if (_.isNilOrEmptyString(messageHash)) {
      throw new Error("Safe signature failed (no message hash).");
    }

    const signature = await safeSignatureExtract(sdk, {
      chainId: data.domain.chainId.toString(),
      messageHash,
    });

    if (_.isNilOrEmptyString(signature)) {
      throw new Error(
        "Safe signature failed. Missing final signature. Please refresh and try again!",
      );
    }

    return signature as IWagmiAddress;
  } else {
    const signature = sdk.safe.calculateTypedMessageHash(data);
    return signature as IWagmiAddress;
  }
}
