import { _ } from "@sablier/v2-mixins";
import { validateAccount, validateAddress, validateName } from "./address";
import { validateAmount } from "./amount";
import { validateDuration, validateMoment } from "./date";
import {
  validateFileCSV,
  validateFileContent,
  validateFileFormat,
} from "./file";
import { validateFormFlags, validateFormRequired } from "./form";
import { isValid } from "./helper";
import { validateInputs } from "./inputs";
import { interpret } from "./interpret";
import policy, { errors } from "./policy";
import { validateSigner } from "./signer";
import {
  validateLockupsCancelable,
  validateLockupsWithdrawable,
  validateStreamAlias,
  validateStreamChain,
  validateStreamId,
  validateStreamIdentifier,
} from "./stream";
import { validateText, validateURL } from "./text";
import { validateToken } from "./token";

export const guards = {
  interpret,
  isValid,
  validateAccount,
  validateAddress,
  validateAmount,
  validateDuration,
  validateFileCSV,
  validateFileContent,
  validateFileFormat,
  validateFormFlags,
  validateFormRequired,
  validateInputs,
  validateMoment,
  validateName,
  validateStreamAlias,
  validateStreamChain,
  validateStreamId,
  validateStreamIdentifier,
  validateLockupsCancelable,
  validateLockupsWithdrawable,
  validateSigner,
  validateText,
  validateToken,
  validateURL,
};

export { errors, interpret, policy };
