import { useEffect, useMemo } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Airstream, Token } from "@sablier/v2-models";
import { client, queries } from "@sablier/v2-subgraphs";
import { useQuery } from "@tanstack/react-query";
import type { ISearchAirstream } from "@sablier/v2-models";
import type { IAirstreamId } from "@sablier/v2-types";

interface Props {
  id: IAirstreamId | undefined;
  key?: string[];
  isEnabled?: boolean;
  onSuccess: (result: ISearchAirstream) => void;
  onError?: (error: unknown, result: ISearchAirstream) => void;
  networkMode?: "always" | "online" | "offlineFirst";
}

export default function useRequestAirstreamItem({
  id,
  key = REQUEST_ID.airstreamItem,
  onError = _.noop,
  onSuccess = _.noop,
  isEnabled = false,
  networkMode = "online",
}: Props) {
  const filter = useMemo(
    () =>
      Airstream.doFormatFilter({
        chainId: Airstream.doSplitIdentifier(id).chainId,
        airstreamIds: [id || "_"],
      }),
    [id],
  );

  const endpoint = useMemo(
    () =>
      client.getEndpointByChain({
        chainId: filter.chainId,
        feature: "airstream",
      }),
    [filter],
  );

  const variables = useMemo(() => {
    const chainId = filter.chainId;
    const airstreamId = _.isNil(filter.airstreamIds)
      ? "_"
      : filter.airstreamIds[0].toLowerCase();

    return {
      chainId,
      airstreamId,
    };
  }, [filter]);

  const { data, error, isLoading } = useQuery({
    queryKey: [...key, { unique: variables }],
    queryFn: async () => {
      if (!Airstream.isId(variables.airstreamId)) {
        throw new Error("Misconfigured (airstream)");
      }
      return client.request(
        endpoint,
        queries.airstream.getAirstream_ById,
        variables,
        {},
        (data) => _.isNilOrEmptyString(data?.campaign),
      );
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: isEnabled,
    networkMode,
    retry: false,
  });

  /** -------------- On Success -------------- */
  /** Checks should run against a valid `data` */

  useEffect(() => {
    if (!_.isNilOrEmptyString(data)) {
      const search: ISearchAirstream = {
        filter,
        options: {
          first: 1,
          isComplete: true,
        },
        airstreams: [],
      };

      if (!_.isNil(data) && !_.isNil(data.campaign)) {
        const token = new Token(data.campaign.asset);
        const airstream = new Airstream(data.campaign, token);
        search.airstreams.push(airstream);
      }

      onSuccess(search);
    }
  }, [data, error, filter, onSuccess]);

  /** -------------- On Error -------------- */
  /** Checks should run against the `error` */

  useEffect(() => {
    if (!_.isNilOrEmptyString(error)) {
      const search: ISearchAirstream = {
        filter,
        options: {
          first: 1,
          error: _.get(error, "message") ?? _.toString(error),
          isComplete: true,
        },
        airstreams: [],
      };

      onError(error, search);
    }
  }, [error, filter, onError]);

  return useMemo(
    () => ({
      error,
      isLoading: isLoading && isEnabled,
    }),
    [error, isLoading, isEnabled],
  );
}
