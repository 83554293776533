import { useCallback, useMemo } from "react";
import { DEFAULT_UI_POLLING, REQUEST_ID } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { pages } from "~/client/constants";
import type { RecursiveSlug } from "@sablier/v2-types";
import type { IStoreFlow } from "~/client/types";
import {
  useFlowStoreAccessor,
  useSessionPolling,
  useSessionStoreAccessor,
} from "./store";

interface Prepare {
  accessor: {
    stream: ReturnType<typeof useFlowStoreAccessor>;
    session: ReturnType<typeof useSessionStoreAccessor>;
  };
  client: ReturnType<typeof useQueryClient>;
  include: {
    owned: boolean;
    preview: boolean;
  };
}

function prepare({ accessor, include }: Prepare) {
  const state = accessor.stream();

  if (include.preview && state.preview) {
    const search = state.preview;
    const set = state.api.setPreview;

    let changes = false;
    const streams = search?.streams.map((stream) => {
      if (stream.isAlive) {
        changes = true;
        const clone = _.clone(stream);
        clone.doUpdate();
        return clone;
      }
      return stream;
    });

    if (changes) {
      set({ ...search, streams });
    }
  }

  if (include.owned && state.owned) {
    const search = state.owned;
    const set = state.api.setOwned;

    let changes = false;
    const streams = search?.streams.map((stream) => {
      if (stream.isAlive) {
        changes = true;
        const clone = _.clone(stream);
        clone.doUpdate();
        return clone;
      }
      return stream;
    });

    if (changes) {
      set({ ...search, streams });
    }
  }

  return accessor.stream();
}

interface Props {
  owned: boolean;
  preview: boolean;
  page: RecursiveSlug<typeof pages, "-">;
}

function usePollingFlow({ owned = true, preview = true, page }: Props) {
  const client = useQueryClient();
  const stream = useFlowStoreAccessor();
  const session = useSessionStoreAccessor();
  const { isPolling } = useSessionPolling();

  const condition = useMemo(
    () => isPolling && (owned || preview),
    [isPolling, owned, preview],
  );

  const payload = useMemo(
    () => ({
      accessor: { session, stream },
      include: { owned, preview },
      client,
    }),
    [client, owned, preview, session, stream],
  );

  const queryFn = useCallback(async () => prepare(payload), [payload]);

  void useQuery<IStoreFlow>({
    queryKey: [...REQUEST_ID.polling, page],
    queryFn,
    staleTime: DEFAULT_UI_POLLING * 2,
    gcTime: DEFAULT_UI_POLLING * 2,
    refetchInterval: DEFAULT_UI_POLLING,
    notifyOnChangeProps: "all",
    retry: false,
    enabled: condition,
  });
}

export default usePollingFlow;
