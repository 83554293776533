import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DEFAULT_CHAIN_ID,
  MAX_SUBGRAPH_ID,
  REQUEST_ID,
  STREAMS_PAGE_SIZE,
} from "@sablier/v2-constants";
import { useRequestLockupList } from "@sablier/v2-hooks";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Lockup } from "@sablier/v2-models";
import extensions from "~/client/extensions";
import type { LockupShape } from "@sablier/v2-constants";
import type { IFilterLockup, ISearchLockup } from "@sablier/v2-models";
import {
  useLockupStoreAccessor,
  useLockupStoreOwned,
} from "./store/useLockupStore";
import useAccount from "./useAccount";

const OPTIONS = {
  after: MAX_SUBGRAPH_ID,
  first: STREAMS_PAGE_SIZE,
  operator: "OR",
} as const;

export default function useLockupsOwned() {
  const { chainId, address, isConfigured } = useAccount();
  const { search: result } = useLockupStoreOwned();
  const access = useLockupStoreAccessor();

  const [options, setOptions] = useState<typeof OPTIONS>(OPTIONS);

  const isEnabled = useMemo(
    () => isConfigured && !result?.options.isComplete,
    [isConfigured, result],
  );

  const filter: IFilterLockup = useMemo(
    () =>
      Lockup.doFormatFilter({
        chainId: chainId || DEFAULT_CHAIN_ID,
        sender: address,
        recipient: address,
      }),
    [address, chainId],
  );

  const onSuccess = useCallback(
    (_result: ISearchLockup) => {
      const result = _.clone(_result);
      const state = access();
      const set = state.api.setOwned;
      const owned = state.owned;

      const info = (result: unknown, label = "new") =>
        console.info(
          "%c[owned streams]",
          "color: cornflowerblue",
          `[${label}]`,
          {
            result,
          },
        );

      result.streams.forEach((stream) => {
        const resolved = Lockup.findShape(extensions.identify(stream));
        if (!resolved.isUnknown) {
          stream.shape = resolved.id as LockupShape;
        }
      });

      /** If there are no results, you can set initial ones care-free */
      if (_.isNil(owned)) {
        set(result);
        info(result);
      } else {
        /**
         * Edge-case: [Search set#1, Click on more for set#2, Go to a Stream's Profile, Come Back]
         * Upon coming back, this request will ask for set#1 again. Due to the rules of this branch,
         * the results would get appended again and again. The condition below makes sure the search
         * isn't resumed from somewhere else.
         */
        if (
          !_.isNil(owned.options.after) &&
          !_.isNil(result.options.after) &&
          new BigNumber(owned.options.after).isLessThanOrEqualTo(
            new BigNumber(result.options.after),
          )
        ) {
          return;
        }

        /** If filters changed, it means we requested for a new set of streams. Override based on these new rules. */
        if (!_.isEqual(owned.filter, result.filter)) {
          set(result);
          info(result);
        } else if (!_.isEqual(owned.options, result.options)) {
          /** If filters remain the same, options may have changed (e.g. pagination requested followup items). Merge results. */
          const value = _.clone(owned);
          value.options = result.options;
          value.streams = _.uniqBy(
            [...value.streams, ...result.streams],
            (i) => i.id,
          );
          set(value);
          info(value.streams, "more");
        }
      }
    },
    [access],
  );

  useEffect(() => {
    setOptions(OPTIONS);
  }, [filter]);

  const { isLoading, error } = useRequestLockupList({
    key: REQUEST_ID.lockupListOwned,
    filter,
    isEnabled,
    onSuccess,
    options,
  });

  const doMore = useCallback(() => {
    if (!isLoading && _.isNilOrEmptyString(error)) {
      setOptions((prev) => ({
        ...prev,
        after: result?.options.after || OPTIONS.after,
      }));
    }
  }, [error, isLoading, setOptions, result]);

  return {
    doMore,
    error,
    isLoading,
    result,
  };
}
