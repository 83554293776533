import { useMemo } from "react";
import { LockupCategory, REQUEST_ID } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { _ } from "@sablier/v2-mixins";
import { Lockup } from "@sablier/v2-models";
import { useQuery } from "@tanstack/react-query";
import { useConfig } from "wagmi";
import type { Output } from "@sablier/v2-contracts";
import type { IStreamId, IWagmiConfig } from "@sablier/v2-types";

interface Props {
  key?: string[];
  id: IStreamId | undefined;
  purpose: LockupCategory;
  isEnabled?: boolean;
}

async function request({
  id,
  library,
  purpose,
}: Props & { library: IWagmiConfig }) {
  const { chainId, source, tokenId } = Lockup.doSplitIdentifier(id);

  if (
    !_.expect(id, "id") ||
    !_.expect(chainId, "chainId") ||
    !_.expect(tokenId, "tokenId") ||
    !_.expect(source, "contract")
  ) {
    throw new Error("Misconfigured (stream onchain).");
  }

  const queries = [
    framework.contextualize(source, chainId, purpose, "getSender", [
      _.toBigInt(tokenId),
    ]),
    framework.contextualize(source, chainId, purpose, "getRecipient", [
      _.toBigInt(tokenId),
    ]),
    framework.contextualize(source, chainId, purpose, "getAsset", [
      _.toBigInt(tokenId),
    ]),
  ];

  const previews = await framework.preview({ queries });
  const results = await framework.read(library, { previews });

  const sender = results[0].result as
    | Output<LockupCategory, "getSender">
    | undefined;

  const recipient = results[1].result as
    | Output<LockupCategory, "getRecipient">
    | undefined;

  const asset = results[2].result as
    | Output<LockupCategory, "getAsset">
    | undefined;

  return {
    sender,
    recipient,
    asset,
  };
}

export default function useRequestLockupOnchain({
  key = REQUEST_ID.lockupOnchain,
  id,
  isEnabled,
  purpose,
}: Props) {
  const library = useConfig();

  const { data, error, isLoading } = useQuery({
    queryKey: [...key, { unique: { id, purpose } }],
    queryFn: async () => request({ id, purpose, library }),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: isEnabled,
    retry: false,
  });

  const isMissing = useMemo(() => {
    if (isLoading) {
      return false;
    }

    return (
      !_.isNilOrEmptyString(error) ||
      _.isNilOrEmptyString(data) ||
      _.isNilOrEmptyString(data?.sender)
    );
  }, [data, error, isLoading]);

  return {
    data,
    error,
    isLoading,
    isMissing,
  };
}
