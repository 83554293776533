import { EXPONENT_DECIMALS } from "@sablier/v2-constants";
import { SEGMENT_DIVISIONS } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";
import type IFlow from "../Flow";
import type ILockup from "../Lockup";
import type { IMilliseconds, ISeconds, IValue } from "@sablier/v2-types";
import type { ChartDataset } from "chart.js";
import Token from "../Token";

type IStream = ILockup | IFlow;

const theme = getTheme();

type Dataset = ChartDataset<"line", { x: string; y: number }[]>;

export type Params = {
  id: string;
  position: string;
  /** --------------- */
  amount: string;
  exponent: string;
  milestone: ISeconds;
  /** --------------- */
  endAmount: string;
  endTime: ISeconds;
  startAmount: string;
  startTime: ISeconds;
};

function getTicks(start: BigNumber, end: BigNumber) {
  const ticks: BigNumber[] = [];
  const duration = end.minus(start);
  const tick = duration.dividedBy(new BigNumber(SEGMENT_DIVISIONS));
  for (let i = 0; i <= SEGMENT_DIVISIONS; i++) {
    const x = start.plus(tick.multipliedBy(new BigNumber(i)));
    ticks.push(x);
  }
  return ticks;
}

export default class Segment {
  readonly id: string;
  readonly position: number;
  readonly token: Token;
  /** --------------- */
  readonly amount: IValue;
  readonly exponent: IValue;
  readonly milestone: ISeconds;

  /** --------------- */
  readonly endAmount: IValue;
  readonly endTime: IMilliseconds;
  readonly startAmount: IValue;
  readonly startTime: IMilliseconds;
  readonly duration: IMilliseconds;

  readonly payload: Params;
  readonly isAugmented: boolean;

  constructor(params: Params, token: Token, isAugmented = false) {
    this.payload = params;

    this.id = params.id;
    this.position = new BigNumber(params.position).toNumber();
    this.token = token;

    /** --------------- */
    this.amount = _.toValue({
      decimals: token.decimals,
      raw: params.amount,
    });
    this.exponent = _.toValue({
      decimals: EXPONENT_DECIMALS,
      raw: params.exponent,
    });
    this.milestone = params.milestone;

    /** --------------- */
    this.endAmount = _.toValue({
      decimals: token.decimals,
      raw: params.endAmount,
    });
    this.startAmount = _.toValue({
      decimals: token.decimals,
      raw: params.startAmount,
    });

    this.endTime = _.toMilliseconds(params.endTime);
    this.startTime = _.toMilliseconds(params.startTime);

    this.duration = new BigNumber(this.endTime)
      .minus(new BigNumber(this.startTime))
      .toString();

    this.isAugmented = isAugmented;
  }

  createHorizontalSegment(endTime: BigNumber): Dataset {
    const theme = getTheme();
    const xAxis = getTicks(new BigNumber(this.startTime), endTime);

    const data = xAxis.map((x) => {
      return {
        x: x.toString(),
        y: this.endAmount.humanized.toNumber(),
      };
    });

    return {
      label: "Streamed",
      data: this.isAugmented ? data.slice(0, 2) : data,
      backgroundColor: theme.colors.orange,
      borderColor: theme.colors.orange,
      fill: false,
    };
  }

  createVerticalSegment(): Dataset {
    return {
      label: "Streamed",
      data: [
        {
          x: new BigNumber(this.endTime).toString(),
          y: this.startAmount.humanized.toNumber(),
        },
        {
          x: new BigNumber(this.endTime).toString(),
          y: this.endAmount.humanized.toNumber(),
        },
      ],
      backgroundColor: theme.colors.orange,
      borderColor: theme.colors.orange,
      fill: false,
    };
  }

  createSegment(stream: IStream): Dataset {
    const xAxis = getTicks(
      new BigNumber(this.startTime),
      new BigNumber(this.endTime),
    );
    return {
      label: "Streamed",
      data: xAxis.map((x) => {
        return {
          x: x.toString(),
          y: stream.findStreamedAmount(x.toString(), true).humanized.toNumber(),
        };
      }),
      backgroundColor: theme.colors.orange,
      borderColor: theme.colors.orange,
      fill: false,
    };
  }

  getDataset(
    stream: ILockup,
    endTime?: BigNumber,
  ): ChartDataset<"line", { x: string; y: number }[]> {
    if (new BigNumber(this.duration).isLessThanOrEqualTo(new BigNumber(1000))) {
      return this.createVerticalSegment();
    }
    if (this.amount.raw.isZero()) {
      return this.createHorizontalSegment(
        endTime || new BigNumber(this.endTime),
      );
    }
    return this.createSegment(stream);
  }

  static generateLinearSet(stream: ILockup) {
    return [
      new Segment(
        {
          id: "1",
          position: "1",
          startAmount: "0",
          endAmount: stream.depositAmount.raw.toString(),
          amount: stream.depositAmount.raw.toString(),
          startTime: _.toSeconds(stream.startTime),
          endTime: _.toSeconds(stream.endTime),
          exponent: _.toValue({
            humanized: "1",
            decimals: EXPONENT_DECIMALS,
          }).raw.toString(),
          milestone: "0",
        },
        stream.token,
      ),
    ];
  }

  static generateCliffSet(
    stream: ILockup,
    computed: {
      cliff: BigNumber;
    },
  ) {
    return [
      new Segment(
        {
          id: "1",
          position: "1",
          startAmount: "0",
          endAmount: "0",
          amount: "0",
          startTime: _.toSeconds(stream.startTime),
          endTime: _.toSeconds(stream.cliffTime),
          exponent: _.toValue({
            humanized: "1",
            decimals: EXPONENT_DECIMALS,
          }).raw.toString(),
          milestone: "0",
        },
        stream.token,
      ),
      new Segment(
        {
          id: "2",
          position: "2",
          startAmount: "0",
          endAmount: computed.cliff.toString(),
          amount: computed.cliff.toString(),
          startTime: _.toSeconds(stream.cliffTime),
          endTime: _.toSeconds(stream.cliffTime),
          exponent: _.toValue({
            humanized: "1",
            decimals: EXPONENT_DECIMALS,
          }).raw.toString(),
          milestone: "0",
        },
        stream.token,
      ),
      new Segment(
        {
          id: "3",
          position: "3",
          startAmount: stream.cliffAmount.raw.toString(),
          endAmount: stream.depositAmount.raw.toString(),
          amount: stream.depositAmount.raw.minus(computed.cliff).toString(),
          startTime: _.toSeconds(stream.cliffTime),
          endTime: _.toSeconds(stream.endTime),
          exponent: _.toValue({
            humanized: "1",
            decimals: EXPONENT_DECIMALS,
          }).raw.toString(),
          milestone: "0",
        },
        stream.token,
      ),
    ];
  }
}
