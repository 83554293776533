import type { Translate } from "@sablier/v2-locales";

export function C(t: Translate, confirm: boolean) {
  if (confirm) {
    return t("machines.general.confirm");
  }
  return "";
}

export function SI(t: Translate, beginning: string) {
  return `${beginning} ${t("machines.slow.index")}`;
}

export function SD(t: Translate, beginning: string) {
  return `${beginning} ${t("machines.slow.done")}`;
}
