import { _ } from "@sablier/v2-mixins";
import { persistNSync } from "persist-and-sync";
import { create } from "zustand";
import type { ILockup } from "@sablier/v2-models";
import type { IStreamAlias, RecursivePartial } from "@sablier/v2-types";
import type { IStoreCart } from "~/client/types";
import { CART_ID } from "../constants";

const initial: Omit<IStoreCart, "api"> = {
  list: [],
};

const useStore = create<IStoreCart>()(
  persistNSync(
    (set) => ({
      ...initial,
      api: {
        removeItem: (alias: IStreamAlias) =>
          set((prev) => {
            return {
              list: prev.list.filter(
                (item) => item.alias.toLowerCase() !== alias.toLowerCase(),
              ),
            };
          }),
        addItem: (item: ILockup) =>
          set((prev) => {
            return { list: [...prev.list, item] };
          }),
        setList: (list: ILockup[]) =>
          set((_prev) => {
            return { list };
          }),
        updateItem: (
          update: RecursivePartial<ILockup> & { alias: IStreamAlias },
        ) =>
          set((prev) => {
            return {
              list: prev.list.map((item) => {
                if (item.alias.toLowerCase() !== update.alias.toLowerCase()) {
                  return item;
                }
                return _.merge(item, update);
              }),
            };
          }),
        reset: (omit?: (keyof IStoreCart)[]) =>
          set(() => _.omit(initial, omit || [])),
      },
    }),
    {
      name: CART_ID.streams,
      exclude: ["api"],
    },
  ),
);

export default useStore;
