import { useCallback, useEffect, useMemo } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { guards } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import {
  useLockupCreateAccessor,
  useLockupCreateField,
  useModalDurationPicker,
  useModalMomentPicker,
} from "~/client/hooks";
import type { IDynamicUnlockCliff } from "./config";
import { setup } from "../../system/setup";

type IExtension = IDynamicUnlockCliff;

function useExtendedGroupAccess() {
  return useLockupCreateAccessor<IExtension>();
}

function useExtendedStream(id: string) {
  const streams = useLockupCreateField("streams");
  const stream = useMemo(
    () => streams.field.value.find((s) => s.id === id),
    [id, streams],
  );

  return stream as typeof stream & { extension: IExtension };
}

export function useGroupFieldDuration(id: string) {
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const { setOpen } = useModalDurationPicker();
  const { t } = useT();

  const linkedDuration = useMemo(() => stream.duration, [stream]);
  const unlockDuration = useMemo(
    () => ({ field: stream.extension.unlockDuration }),
    [stream],
  );

  const onConfirm = useCallback(
    (value?: string) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }

      let warning = undefined;
      if (!_.isNilOrEmptyString(value)) {
        warning = guards.validateDuration({
          t,
          purpose: "cliff",
          max: stream.duration.value,
          min: "0",
          value,
        });
      }

      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          unlockDuration: {
            ...stream.extension.unlockDuration,
            value,
            warning,
          },
        },
      };

      const newStreams = [...streams];
      newStreams.splice(index, 1, edited);
      update({
        streams: {
          value: newStreams,
        },
      });
    },
    [access, id, t],
  );

  const onClick = useCallback(() => {
    vendors.track.log((events) =>
      events.openModalFrom({
        nameKey: "duration",
        placeKey: "createGroupCliffExponential",
      }),
    );
    setOpen(true, {
      milliseconds: unlockDuration.field?.value,
      purpose: "cliff",
      onConfirm,
    });
  }, [unlockDuration, onConfirm, setOpen]);

  /** React to linked fields: total duration influences cliff unlockDuration */

  useEffect(() => {
    if (linkedDuration.isActive) {
      const { isValid, stream: item } = setup(access, id);
      if (isValid) {
        onConfirm(item.extension.unlockDuration.value);
      }
    }
  }, [access, id, linkedDuration, onConfirm]);

  return { unlockDuration, onClick };
}

export function useGroupFieldEnd(id: string) {
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const { setOpen } = useModalMomentPicker();
  const { t } = useT();

  const linkedDuration = useMemo(() => stream.duration, [stream]);
  const linkedEnd = useMemo(() => stream.end, [stream]);

  const unlockEnd = useMemo(
    () => ({ field: stream.extension.unlockEnd }),
    [stream],
  );

  const label = useMemo(
    () => ({
      icon: InformationCircleIcon,
      isIconLast: true,
      tooltip: {
        value: t("descriptions.dates.settings", { zone: _.timezone().zone }),
      },
      value: t("form.label.cliffDate"),
    }),
    [t],
  );

  const onConfirm = useCallback(
    (value?: string) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }

      let warning = undefined;
      if (!_.isNilOrEmptyString(value)) {
        warning = guards.validateMoment({
          t,
          purpose: "cliff",
          min: stream.start.value,
          value,
          max: stream.end.value,
        });
      }

      const edited = {
        ...stream,
        extension: {
          ...stream.extension!,
          unlockEnd: {
            ...stream.extension.unlockEnd,
            value,
            warning,
          },
        },
      };

      const newStreams = [...streams];
      newStreams.splice(index, 1, edited);

      update({
        streams: {
          value: newStreams,
        },
      });
    },
    [access, id, t],
  );

  const onClick = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "moment",
        placeKey: "createGroupCliffExponential",
      });
    });
    setOpen(true, {
      milliseconds: unlockEnd.field?.value,
      onConfirm,
      purpose: t("structs.endDate"),
    });
  }, [onConfirm, setOpen, unlockEnd, t]);
  /** React to linked fields: stream end date influences cliff date */

  useEffect(() => {
    if (!linkedDuration.isActive) {
      const { isValid, stream: item } = setup(access, id);
      if (isValid) {
        onConfirm(item.extension.unlockEnd.value);
      }
    }
  }, [access, id, linkedDuration, linkedEnd, onConfirm]);

  return { unlockEnd, onClick, label };
}

export function useGroupFieldUnlock(id: string) {
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const token = useLockupCreateField("token");
  const amount = useMemo(() => ({ field: stream.amount }), [stream]);
  const extension = useMemo(() => stream.extension, [stream]);
  const { t } = useT();

  const unlock = useMemo(() => ({ field: stream.extension.unlock }), [stream]);

  const maximum = useMemo(
    () => ({
      label: `${t("words.max")}:`,
      value:
        !_.isNilOrEmptyString(amount.field.value) && amount.field.value !== "0"
          ? _.toNumeralPrice(amount.field.value, false)
          : undefined,
    }),
    [amount, t],
  );

  const onBlur = useCallback(() => {
    const { index, isValid, stream, streams, update } = setup(access, id);
    if (!isValid) {
      return;
    }

    const amount = stream.amount.value;
    const extension = stream.extension;
    const unlock = stream.extension.unlock.value;
    const edited = _.isNilOrEmptyString(unlock)
      ? {
          ...stream,
          extension: {
            ...extension,
            unlock: {
              ...extension.unlock,
              warning: undefined,
            },
          },
        }
      : {
          ...stream,
          extension: {
            ...extension,
            unlock: {
              ...extension.unlock,
              warning: guards.validateAmount({
                t,
                context: "wallet",
                max: amount,
                value: unlock,
                min: "0",
              }),
            },
          },
        };

    const cloned = [...streams];
    cloned.splice(index, 1, edited);

    update({
      streams: {
        value: cloned,
      },
    });
  }, [access, id, t]);

  const onChange = useCallback(
    (value: string | undefined) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }
      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          unlock: {
            ...stream.extension.unlock,
            warning: undefined,
            value,
          },
        },
      };

      const newStreams = [...streams];
      newStreams.splice(index, 1, edited);
      update({
        streams: {
          value: newStreams,
        },
      });
    },
    [access, id],
  );

  const onMax = useCallback(() => {
    const { index, isValid, stream, streams, update } = setup(access, id);

    if (!isValid) {
      return;
    }

    const edited = {
      ...stream,
      extension: {
        ...extension,
        unlock: {
          ...extension.unlock,
          value: stream.amount.value,
        },
      },
    };

    const cloned = [...streams];
    cloned.splice(index, 1, edited);

    update({
      streams: {
        value: cloned,
      },
    });

    onBlur();
  }, [access, extension, id, onBlur]);

  useEffect(() => {
    onBlur();
  }, [onBlur]);

  return {
    unlock,
    maximum,
    onBlur,
    onChange,
    onMax,
    token,
  };
}
