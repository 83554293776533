import {
  CoreCategory,
  FlowCategory,
  PeripheryCategory,
} from "@sablier/v2-constants";
import chains from "@sablier/v2-constants/chains";

/**
 * The official set of contracts used and recognized by the app.
 * -------------------------------------------------------------
 *
 * The contracts have been deployed from the following commits:
 *
 * | Repo         | Commit  |
 * | ------------ | ------- |
 * | v2-core      | https://github.com/sablier-labs/v2-core/tree/133f878 |
 * | v2-periphery | https://github.com/sablier-labs/v2-periphery/tree/cc9434f |
 * | prb-proxy    | https://github.com/PaulRBerg/prb-proxy/tree/6fbb6df |
 *
 * -------------------------------------------------------------
 *
 * Rules and Naming Conventions
 * ----------------------------
 *
 * 1. The default contracts used by the app will be labeled with the official stream category as names
 * 2. Addresses will be saved in lower-case format
 * 3. Aliases should not use "-" (hyphen)
 * 4. Flash loan contracts will be aliased with a suffixed "F" and need to be handled in the `contractor` utility
 * 5. Flash loan contracts will use keys derived from the base category and the Flash suffix: `${LockupCategory.<LINEAR or DYNAMIC>}Flash`
 * 6.
 *
 * Notes
 * ----------------------------
 * The "Withdrawer Target" is currently sourced from https://github.com/PaulRBerg/prb-proxy/blob/ae45af597a177647a3be28710d18ac62b769fed6/src/targetWithdrawer.sol
 */

const contracts: Record<
  number,
  Record<
    CoreCategory | FlowCategory | string,
    {
      alias: string;
      address: string;
      methods?: Record<string, { min: number; max: number }>;
    }
  >
> = {
  [chains.mainnet.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0xb10daee1fcf62243ae27776d7a92d39dc8740f95",
      methods: {
        withdraw: {
          min: 250_000,
          max: 2_000_000,
        },
        cancel: {
          min: 250_000,
          max: 2_000_000,
        },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0x39efdc3dbb57b2388ccc4bb40ac4cb1226bc9e44",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0xafb979d9afad1ad27c5eff4e27226e3ab9e5dcc9",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0x7cc7e125d83a581ff438608490cc0f7bdff79127",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x3962f6585946823440d274ad7c719b02b49de51e",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0x9deabf7815b42bf4e9a03eec35a486ff74ee7459",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0xf86b359035208e4529686a1825f2d5bee38c28a8",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.polygon.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0x67422c3e36a908d5c3237e9cffeb40bde7060f6e",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0x7313addb53f96a4f710d3b91645c62b434190725",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0x5f0e1dea4a635976ef51ec2a2ed41490d1eba003",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0xb194c7278c627d52e440316b74c5f24fc70c1565",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x8d87c5eddb5644d1a714f85930ca940166e465f0",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0x8d4ddc187a73017a5d7cef733841f55115b13762",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0xbf67f0a1e847564d0efad475782236d3fa7e9ec2",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.arbitrum.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0x197d655f3be03903fd25e7828c3534504bfe525e",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0xa9efbef1a35ff80041f567391bdc9813b2d50197",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0xfdd9d122b451f549f48c4942c6fa6646d849e8c1",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0xf390ce6f54e4dc7c5a5f7f8689062b7591f7111d",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x05a323a4c936fed6d02134c5f0877215cd186b51",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0x53f5eeb133b99c6e59108f35bcc7a116da50c5ce",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0x0da2c7aa93e7cd43e6b8d043aab5b85cfddf3818",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.avalanche.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0x610346e9088afa70d6b03e96a800b3267e75ca19",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0x665d1c8337f1035cfbe13dd94bb669110b975f5f",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0xb24b65e015620455bb41deaad4e1902f1be9805f",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0x0310da0d8ff141166ed47548f00c96464880781f",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0xc0bf14afb95ca4c049bdc19e06a3531d8065f6fd",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xe3826241e5eebb3f5fede33f9f677047674d3fbf",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0xfa536049652bfb5f57ba8dcfbec1b2b2dd9803d3",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.gnosis.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0x685e92c9ca2bb23f1b596d0a7d749c0603e88585",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0xeb148e4ec13aaa65328c0ba089a278138e9e53f9",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0xce49854a647a1723e8fb7cc3d190cab29a44ab48",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0x1df83c7682080b0f0c26a20c6c9cb8623e0df24e",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0xf1caeb104ab29271463259335357d57772c90758",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0x555eb55cbc477aebbe5652d25d0fea04052d3971",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0x59a4b7255a5d01247837600e7828a6f77f664b34",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.bsc.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0x3fe4333f62a75c2a85c8211c6aefd1b9bfde6e51",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0xf2f3fef2454dca59eca929d2d8cd2a8669cc6214",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0x14c35e126d75234a90c9fb185bf8ad3edb6a90d2",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0xf900c5e3aa95b59cc976e6bc9c0998618729a5fa",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x88ad3b5c62a46df953a5d428d33d70408f53c408",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xeb6d84c585bf8aea34f05a096d6faa3b8477d146",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0xab5f007b33edda56962a0fc428b15d544ea46591",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.optimism.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0xb923abdca17aed90eb5ec5e407bd37164f632bfd",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0x6f68516c21e248cddfaf4898e66b2b0adee0e0d6",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0x4b45090152a5731b5bc71b5baf71e60e05b33867",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0xd6920c1094eabc4b71f3dc411a1566f64f4c206e",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x5c22471a86e9558ed9d22235dd5e0429207ccf4b",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0x4994325f8d4b4a36bd643128beb3ec3e582192c0",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0x90952912a50079bef00d5f49c975058d6573acdc",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.base.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0x6b9a46c8377f21517e65fa3899b3a9fab19d17f5",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0x645b00960dc352e699f89a81fc845c0c645231cf",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0xfcf737582d167c7d20a336532eb8bcca8cf8e350",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0x461e13056a3a3265cef4c593f01b2e960755de91",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x4cb16d4153123a74bc724d161050959754f378d8",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xf9e9ed67dd2fab3b3ca024a2d66fcf0764d36742",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0xf4937657ed8b3f3cb379eed47b8818ee947beb1e",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.blast.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0xcb099efc90e88690e287259410b9ae63e1658cc6",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0xdf578c2c70a86945999c65961417057363530a1c",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x9b1468d29b4a5869f00c92517c57f8656e928b93",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xa705de617673e2fe63a4ea0e58c26897601d32a5",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0x91fb72e5297e2728c10fde73bde74a4888a68570",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.iotex.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x84f092cf4d7d36c2d4987f672df81a39200a7146",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0x6fcab41e3b62d05ab4fc729586cb06af2a2662d0",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0x179536f3289fb50076968b339c7ef0dc0b38e3af",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.lightlink.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0x17c4f98c40e69a6A0D5c42B11E3733f076A99E20",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0x49d753422ff05daa291A9efa383E4f57daEAd889",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x6329591464fa6721c8e1c1271e4c6c41531aea6b",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xaa05e418fb7851c211351c65435f1b17cbfa88bf",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0x83403c6426E6D044bF3B84EC1C007Db211AaA140",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.linea.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0xb5d39049510f47ee7f74c528105d225e42747d63",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xf2e46b249cfe09c2b3a2022dc81e0bb4be3336f1",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0xc46ce4b77cbc46d17a2eceb2cc8e2ee23d96529f",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.sepolia.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0xd4300c5bc0b9e27c73ebabdc747ba990b1b570db",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0x421e1E7a53FF360f70A2D02037Ee394FA474e035",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0x7a43f8a888fa15e68c103e18b0439eb1e98e4301",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0xc9940ad8f43aad8e8f33a4d5dbbf0a8f7ff4429a",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x3e435560fd0a03ddf70694b35b673c25c65abb6c",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0x73bb6dd3f5828d60f8b3dbc8798eb10fba2c5636",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0x3a1bea13a8c24c0ea2b8fae91e4b2762a59d7af5",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.FLOW_10]: {
      alias: "FL", // SablierV2LockupTranched
      address: "0xbf85cd17ca59b7a2b81d3d776ce1602a7c0af9d9",
    },
  },
  [chains.scroll.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_20]: {
      alias: "LL", // SablierV2LockupLinear
      address: "0x80640ca758615ee83801ec43452feea09a202d33",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_20]: {
      alias: "LD", // SablierV2LockupDynamic
      address: "0xde6a30d851efd0fc2a9c922f294801cfd5fcb3a1",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0x57e14ab4dad920548899d86b54ad47ea27f00987",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0xaaff2d11f9e7cd2a9cdc674931fac0358a165995",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0xbc5dc6d77612e636da32af0d85ca3179a57330fd",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xac199bfea92aa4d4c3d8a49fd463ead99c7a6a8f",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0xb0f78ddc01d829d8b567821eb193de8082b57d9d",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.zksync.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_21]: {
      alias: "LL2", // SablierV2LockupLinear
      address: "0x2fca69fa0a318efdf4c15ee8f13a873347a8a8d4",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_21]: {
      alias: "LD2", // SablierV2LockupDynamic
      address: "0xe6c7324bea8474209103e407779eec600c07cf3f",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x8cb69b514e97a904743922e1adf3d1627deeee8d",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xf03f4bf48b108360baf1597fb8053ebe0f5245da",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0x1fb145a47eb9b8bf565273e137356376197b3559",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
  [chains.zksyncSepolia.chainId]: {
    [CoreCategory.LOCKUP_LINEAR_22]: {
      alias: "LL3", // SablierV2LockupLinear
      address: "0x43864c567b89fa5fee8010f92d4473bf19169bba",
      methods: {
        withdraw: { min: 250_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_DYNAMIC_22]: {
      alias: "LD3", // SablierV2LockupDynamic
      address: "0xc4311a5913953162111bf75530f7bb14ec24e014",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
    [CoreCategory.LOCKUP_TRANCHED_22]: {
      alias: "LT3", // SablierV2LockupTranched
      address: "0xf6e869b73e20b812dcf0e850aa8822f74f67f670",
      methods: {
        withdraw: { min: 800_000, max: 2_000_000 },
        cancel: { min: 250_000, max: 2_000_000 },
      },
    },
  },
} as const;

const peripheries: Record<
  number,
  Record<
    PeripheryCategory,
    {
      address: string;
      alias: string;
      methods?: Record<
        string,
        | { min: number; max: number }
        | ((i: number) => { min: number; max: number })
      >;
    }
  >
> = {
  [chains.mainnet.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0xb5ec9706c3be9d22326d208f491e5deef7c8d9f0",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.1
      address: "0xf35ab407cf28012ba57caf5ee2f6d6e4420253bc",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0x638a7aC8315767cEAfc57a6f5e3559454347C3f6",
    },
  },
  [chains.polygon.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0xd29ec4b9203f2d1c9cd4ba8c68fcfe4ecd85f6f5",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0xc28872e0c1f3633eed467907123727ac0155029d",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0xccA6dd77bA2cfcccEdA01A82CB309e2A17901682",
    },
  },
  [chains.arbitrum.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0x785edf1e617824a78efe76295e040b1ae06002bf",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0xc9a5a0bc2d8e217bdbdfe7486e9e72c5c3308f01",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0x90Cc23DC3E12E80f27C05b8137b5f0d2b1EdFA20",
    },
  },
  [chains.avalanche.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0xabcdf4dcdba57a04889784a670b862540758f9e7",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0x0430ed39ea2789acdf27b89268117ebabc8176d1",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0x817fE1364A9d57d1fB951945B53942234163Ef10",
    },
  },
  [chains.bsc.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0x70998557980cb6e8e63c46810081262b6c343051",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0x96aa12809cac29bba4944feca1dfdc8e1704e6c1",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0xc9bf2A6bD467A813908d836c1506efE61E465761",
    },
  },
  [chains.gnosis.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0x0f324e5cb01ac98b2883c8ac4231aca7efd3e750",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0x5f12318fc6cca518a950e2ee16063a6317c2a9ef",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0x89AfE038714e547C29Fa881029DD4B5CFB008454",
    },
  },
  [chains.optimism.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0x6cd7bb0f63afcc9f6cedd1bf1e3bd4ed078cd019",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0xe041629d99730b3ee4d6518097c45b4e3591992b",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0x8a6974c162fdc7Cb67996F7dB8bAAFb9a99566e0",
    },
  },
  [chains.base.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0xc1c548f980669615772dadcbfebc29937c29481a",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0x58a51e5382318eea6065bb7721eecdf4331c0b90",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0xf19576Ab425753816eCbF98aca8132A0f693aEc5",
    },
  },
  [chains.blast.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0xdc988d7ad6f186ea4a236f3e61a45a7851edf84e",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.1
      address: "0x3abcdda756d069cf3c7a17410602343966eaff27",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "",
    },
  },
  [chains.iotex.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2ProxyTargetAllowance
      address: "0x711900e5f55d427cd88e5e3fcae54ccf02de71f4",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory",
      address: "0xf978034bb3cab5fe88d23db5cb38d510485dab90",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry
      address: "",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance
      address: "",
    },
  },
  [chains.lightlink.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0x5c847244649bd74ab41f09c893af792ad87d32aa",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.1
      address: "0x278ac15622846806bd46fbdbdb8db8d09614173a",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "",
    },
  },
  [chains.linea.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2ProxyTargetAllowance
      address: "0x4259557f6665ecf5907c9019a30f3cb009c20ae7",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory",
      address: "0x35e9c3445a039b258eb7112a5eea259a825e8ac0",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry
      address: "",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance
      address: "",
    },
  },
  [chains.scroll.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.1
      address: "0x4b8bf9cd3274517609e7fe905740fa151c9aa711",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0x344afe8ad5dba3d55870dc398e0f53b635b2ed0d",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0x71CeA9c4d15fed2E58785cE0C05165CE34313A74",
    },
  },
  [chains.sepolia.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2Batch V2.2
      address: "0x04a9c14b7a000640419ad5515db4ef4172c00e31",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory", // SablierV2MerkleLockupFactory V2.2
      address: "0x56e9180a8d2c35c99f2f8a1a5ab8abe79e876e8c",
    },
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry V2.0
      address: "0x584009e9ede26e212182c9745f5c000191296a78",
    },
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance V2.0
      address: "0xAB281bbc2bC34a1F202DdFF17ffD1C00EdF73F3a",
    },
  },
  [chains.zksync.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2ProxyTargetAllowance
      address: "0xae1a55205a0499d6bbb0cf0f1210641957e9cb7e",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory",
      address: "0x8a84fCF962163A7E98Bf0daFD918973c846fa5C8",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry
      address: "",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance
      address: "",
    },
  },
  [chains.zksyncSepolia.chainId]: {
    [PeripheryCategory.BATCH]: {
      alias: "batch", // SablierV2ProxyTargetAllowance
      address: "0x1d68417ff71855eb0237ff03a8fff02ef67e4afb",
    },
    [PeripheryCategory.MERKLE_LOCKUP_FACTORY]: {
      alias: "merkleLockupFactory",
      address: "0x2cef8c06ddf7a1440ad2561c53821e43addbfa31",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.REGISTRY]: {
      alias: "registry", // PRBProxyRegistry
      address: "",
    },
    /** @deprecated V2.0 Proxy Support */
    [PeripheryCategory.TARGET_APPROVE]: {
      alias: "targetApprove", // SablierV2ProxyTargetAllowance
      address: "",
    },
  },
} as const;

export { contracts, peripheries };
