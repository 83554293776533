import {
  DEFAULT_BROKER_ADDRESS,
  DEFAULT_BROKER_FEE_PERCENTAGE,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { contractor_lockup, peripheral } from "~/client/utils";
import type { ITranchedMonthly } from "./config";
import type {
  IAddress,
  IMilliseconds,
  ISeconds,
  IWagmiAddress,
} from "@sablier/v2-types";
import type {
  IExtensionParamsGroup,
  IExtensionParamsSingle,
  IExtensionResultDurationsLT,
  IExtensionResultTimestampsLT,
} from "~/client/types";
import { precompute } from "./setup";

type IExtension = ITranchedMonthly;

/**
 * ------------------------------
 * Explicit function overloads not needed.
 * ------------------------------
 */

async function processSingle(
  params: IExtensionParamsSingle & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLT;
}>;
async function processSingle(
  params: IExtensionParamsSingle & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLT;
}>;
async function processSingle(params: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLT | IExtensionResultDurationsLT;
}>;

async function processSingle({
  dependencies,
  extras,
}: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLT | IExtensionResultDurationsLT;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose, ...extended } = extras as IExtension;
  const {
    address,
    amount,
    cancelability,
    transferability,
    chainId,
    sender,
    start,
    token,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup: IAddress = contractor_lockup(chainId!, purpose).address;
  const hasInitialUnlock = extended.initial.value ?? false;

  const months = new BigNumber(extended.months.value || 0);
  const raw = _.toValuePrepared({
    humanized: amount,
    decimals: token!.decimals,
  });
  // If the user opt in for having an initial unlock we should properly adjust the increment value
  const increment = hasInitialUnlock
    ? _.toValuePrepared({
        raw: new BigNumber(raw).dividedBy(months.plus(new BigNumber(1))),
        decimals: token?.decimals,
      })
    : _.toValuePrepared({
        raw: new BigNumber(raw).dividedBy(months),
        decimals: token?.decimals,
      });
  const { amount: deposit } = precompute.single({ dependencies, extras });

  /**
   * ------------------------------
   * Setup dependencies: DYNAMIC
   * ------------------------------
   */

  const N = [...Array(months.toNumber()).keys()];
  const trancheAmounts = N.map(() =>
    _.toBigInt(
      _.toValuePrepared({
        raw: increment,
        decimals: token!.decimals,
      }),
    ),
  );

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  type Inputs = IExtensionResultTimestampsLT["inputs"];

  const startTime: ISeconds = _.toValuePrepared({
    humanized: start,
    decimals: -3,
  });

  const trancheTimestamps = N.map((i) => {
    const milestone: IMilliseconds = _.addCalendarUnit(
      start!,
      (i + 1).toString(),
      "month",
    );
    const horizontal: ISeconds = _.toValuePrepared({
      humanized: milestone,
      decimals: -3,
    });

    return _.toNumber(horizontal);
  }).flat();

  let tranches = N.map((i) => ({
    amount: trancheAmounts[i],
    timestamp: trancheTimestamps[i],
  }));
  if (hasInitialUnlock) {
    const initialTranche = {
      amount: _.toBigInt(
        _.toValuePrepared({
          raw: increment,
          decimals: token!.decimals,
        }),
      ),
      // The first tranche corresponding to the initial unlock will be performed after 1 second
      timestamp: _.toNumber(startTime) + 1,
    };
    tranches = [initialTranche, ...tranches];
  }

  const inputs: Inputs = [
    lockup as IWagmiAddress,
    token!.address as IWagmiAddress,
    [
      {
        sender: sender as IWagmiAddress,
        recipient: address as IWagmiAddress,
        totalAmount: _.toBigInt(deposit),
        startTime: _.toNumber(startTime),
        tranches,
        cancelable: !!cancelability,
        transferable: !!transferability,
        broker: {
          account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
          fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
        },
      },
    ],
  ];

  const data = framework.contextualize(
    batch,
    chainId!,
    "batch",
    "createWithTimestampsLT",
    inputs,
  );

  return {
    batch: data,
  };
}

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

async function processGroup(
  params: IExtensionParamsGroup & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLT;
}>;
async function processGroup(
  params: IExtensionParamsGroup & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLT;
}>;
async function processGroup(params: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLT | IExtensionResultTimestampsLT;
}>;

async function processGroup({
  dependencies,
  purpose,
  library,
}: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLT | IExtensionResultTimestampsLT;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const {
    cancelability,
    transferability,
    chainId,
    sender,
    token,
    streams,
    signer,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup = contractor_lockup(chainId, purpose).address;
  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  type Inputs = IExtensionResultTimestampsLT["inputs"];

  const params: Inputs["2"] = await Promise.all(
    streams?.map(async (stream) => {
      const { address, amount, duration, end, start } = stream;

      const single = await processSingle({
        dependencies: {
          address,
          amount,
          cancelability,
          transferability,
          duration,
          end,
          token,
          start,
          chainId,
          sender,
          signer,
        },
        extras: stream.extension,
        timing: "range",
        library,
      });

      return single.batch.inputs[2][0];
    }) || [],
  );

  const inputs: Inputs = [
    lockup as IWagmiAddress,
    token!.address as IWagmiAddress,
    params,
  ];

  const data = framework.contextualize(
    batch,
    chainId!,
    "batch",
    "createWithTimestampsLT",
    inputs,
  );

  return {
    batch: data,
  };
}

export const process = {
  group: processGroup,
  single: processSingle,
};
