import { useCallback, useEffect, useMemo } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { useRequestFlowItem } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Flow } from "@sablier/v2-models";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { contracts } from "~/client/constants";
import type { IPreviewFlow, ISearchFlow } from "@sablier/v2-models";
import {
  useFlowStoreAccessor,
  useFlowStoreOwned,
  useFlowStorePreview,
  useFlowStoreSearch,
} from "./store";
import useAccount from "./useAccount";
import useFlowsOwned from "./useFlowsOwned";
import useToken from "./useToken";

function useLocal(id?: string) {
  const access = useFlowStoreAccessor();
  const client = useQueryClient();

  const { isLoading: isLoadingOwned } = useFlowsOwned();
  const { search: owned } = useFlowStoreOwned();
  const { search: searched } = useFlowStoreSearch();

  const isCached = useMemo(() => {
    const key = [...REQUEST_ID.flowItemPreview, { unique: { flowId: id } }];
    const entry = client.getQueryCache().find({ queryKey: key });

    if (_.isNil(entry) || _.isNil(entry.state.data)) {
      return false;
    }

    return true;
  }, [id, client]);

  const included = useMemo(() => {
    if (isCached) {
      return undefined;
    }

    const own = owned?.streams.find((stream) => stream.id === id);
    const search = searched?.streams.find((stream) => stream.id === id);

    if (!_.isNil(own)) {
      return own;
    }

    if (!_.isNil(search)) {
      return search;
    }

    return undefined;
  }, [id, isCached, owned, searched]);

  /**
   * Enable the network query for the stream item if:
   * 1. The cache is empty (cache hasn't been hydrated)
   * 2. The client store stopped loading and doesn't include the item in question
   */

  const isEnabled = useMemo(() => {
    if (isCached) {
      return true;
    }

    if (isLoadingOwned) {
      return false;
    } else if (!_.isNil(included)) {
      return false;
    }

    return true;
  }, [isCached, isLoadingOwned, included]);

  const isLoading = useMemo(() => {
    if (!isCached && isLoadingOwned) {
      return true;
    }

    return false;
  }, [isCached, isLoadingOwned]);

  /**
   * ------------------------------------------------------------
   * If the stream is included, eagerly push it to the preview store.
   * The "isEnabled" flag will stop the remote query from happening.
   * -----------------------------------------------------------
   */

  const onSuccess = useCallback(
    (local: Flow) => {
      const state = access();
      const set = state.api.setPreview;
      const stored = state.preview;

      /**
       * Only overwrite if the "included" data is new and not yet stored
       */

      if (_.isNil(stored) || stored.streams[0]?.id !== id) {
        const clone = _.clone(local!);
        const result: ISearchFlow = {
          filter: {
            chainId: clone.chainId,
            streamIds: [clone.id],
          },
          options: stored?.options || {
            first: 1,
            isComplete: true,
          },
          streams: [clone],
        };
        set(result);
        console.info(
          "%c[preview flow]",
          "color: cornflowerblue",
          `[included]`,
          {
            result,
          },
        );
      }
    },
    [access, id],
  );

  useEffect(() => {
    if (!_.isNil(included)) {
      onSuccess(included);
    }
  }, [included, onSuccess]);

  return {
    isEnabled,
    isLoading,
    included,
  };
}

function useRemote(id?: string, isEnabled = true) {
  const { preview: result } = useFlowStorePreview();
  const access = useFlowStoreAccessor();

  const onError = useCallback(
    (error: unknown, result: ISearchFlow) => {
      const state = access();
      const set = state.api.setPreview;
      const preview = state.preview;

      const info = () =>
        console.info("%c[preview flow]", "color: red", `[error]`, {
          error,
          result,
        });

      if (_.isNil(preview)) {
        set(result);
        info();
      } else {
        if (!_.isEqual(preview.filter, result.filter)) {
          set(result);
          info();
        }
      }
    },
    [access],
  );

  const onSuccess = useCallback(
    (_result: ISearchFlow) => {
      const result = _.clone(_result);

      const state = access();
      const set = state.api.setPreview;
      const preview = state.preview;

      if (!_.isNil(result) && result.streams.length === 0) {
        const payload = _.clone(result);
        payload.options.error = "Item not found.";
        onError(payload.options.error, payload);
      } else {
        const info = (type = "new") =>
          console.info(
            "%c[preview flow]",
            "color: cornflowerblue",
            `[${type}]`,
            {
              result,
            },
          );
        if (_.isNil(preview)) {
          set(result);
          info();
        } else {
          if (!_.isEqual(preview.filter, result.filter)) {
            set(result);
            info();
          }
        }
      }
    },
    [access, onError],
  );

  const { isLoading, error } = useRequestFlowItem({
    id,
    key: REQUEST_ID.flowItemPreview,
    isEnabled,
    onSuccess,
    onError,
  });

  return {
    error,
    isLoading,
    result,
  };
}

export default function useFlowCurrent() {
  const { address } = useAccount();
  const { query } = useRouter();
  const { t } = useT();

  const id = useMemo(() => {
    const parameter = _.toString(_.get(query, "id")).toLowerCase();
    if (_.isNilOrEmptyString(parameter)) {
      return undefined;
    }

    const { chainId } = Flow.doSplitIdentifier(parameter);
    const aliases = Flow.findAliases(chainId, contracts);
    return Flow.doIdentify(parameter, aliases)?.withAddress;
  }, [query]);

  const { isEnabled } = useLocal(id);
  const { error } = useRemote(id, isEnabled);

  const { preview: stored } = useFlowStorePreview();

  /**
   * Prepare additional flags.
   * The end state for every system (local or remote) will fill in data in the preview-flow storage slot.
   */

  const isLoading = useMemo(() => {
    return _.isNil(stored);
  }, [stored]);

  const isReady = useMemo(
    () => !isLoading && (!_.isNil(stored) || !_.isNil(error)),
    [error, isLoading, stored],
  );

  const stream = useMemo(() => stored?.streams[0], [stored]);

  const isMissing = useMemo(
    () => (isReady && _.isNil(stream)) || !_.isNil(error),
    [error, stream, isReady],
  );

  const preview: IPreviewFlow | Partial<IPreviewFlow> = useMemo(() => {
    if (!_.isNil(stream)) {
      return stream.findPreview(t);
    }

    return {};
  }, [stream, t]);

  const isRecipient = useMemo(() => {
    if (isReady && stream) {
      return stream.recipient === address;
    }
    return false;
  }, [address, isReady, stream]);

  const isSender = useMemo(() => {
    if (isReady && stream) {
      return stream.sender === address;
    }

    return false;
  }, [isReady, stream, address]);

  const token = useToken({ token: stream?.token });

  return {
    id,
    error,
    isLoading,
    isMissing,
    isReady,
    isRecipient,
    isSender,
    preview,
    stream,
    token,
  };
}
