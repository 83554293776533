import { useCallback } from "react";
import { _ } from "@sablier/v2-mixins";
import type { ISigner, IToken } from "@sablier/v2-types";

interface Props {
  signer: ISigner | undefined;
  token: IToken | undefined;
}
/**
 *
 * Push a request to the connected wallet for a
 * new ERC20 token to be added to its list.
 *
 */

function useTokenToWallet({ signer, token }: Props) {
  const add = useCallback(async () => {
    try {
      if (
        _.isNil(signer) ||
        _.isNil(token) ||
        _.isNilOrEmptyString(token?.address) ||
        _.isNilOrEmptyString(token?.decimals) ||
        _.isNilOrEmptyString(token?.symbol)
      ) {
        return;
      }

      await signer?.watchAsset({
        type: "ERC20",
        options: {
          address: token.address,
          decimals: token.decimals,
          symbol: token.symbol,
          image: token.image?.toString(),
        },
      });
    } catch (_e) {
      /** Not doing anything with the error as it could be triggered by wallets not implementing the feature. */
    }
  }, [signer, token]);

  return { add };
}

export default useTokenToWallet;
