import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { Flow, Token } from "@sablier/v2-models";
import type { Params } from "@sablier/v2-models/Flow/Attributes";
import type { IStreamAlias, IStreamId } from "@sablier/v2-types/stream";
import useFlowCurrent from "./useFlowCurrent";

interface Props {
  stream: Params;
  id?: IStreamId | IStreamAlias;
}

export default function useFlowCurrentEager(props: Props) {
  const { stream: current } = useFlowCurrent();

  const eager = useMemo(() => {
    if (_.isNilOrEmptyString(_.get(props, "stream.id"))) {
      return undefined;
    }

    const token = new Token(_.get(props, "stream.asset")!);
    const entity = new Flow(_.get(props, "stream")!, token);
    entity.doUpdate();

    return entity;
  }, [props]);

  const stream = useMemo(() => current || eager, [current, eager]);

  return { stream };
}
