import { useCallback, useEffect, useMemo } from "react";
import {
  HashtagIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  MONTHLY_MAX_UNLOCKS_GROUP,
  MONTHLY_MIN_UNLOCKS,
} from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { guards } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import vendors from "@sablier/v2-utils/vendors";
import {
  useLockupCreateAccessor,
  useLockupCreateField,
  useModalMomentPicker,
} from "~/client/hooks";
import type { ITranchedMonthly } from "./config";
import { setup } from "../../system/setup";

type IExtension = ITranchedMonthly;

function useExtendedGroupAccess() {
  return useLockupCreateAccessor<IExtension>();
}

function useExtendedStream(id: string) {
  const streams = useLockupCreateField("streams");
  const stream = useMemo(
    () => streams.field.value.find((s) => s.id === id),
    [id, streams],
  );

  return stream as typeof stream & { extension: IExtension };
}

export function useGroupFieldMonths(id: string) {
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const { t } = useT();
  const months = useMemo(() => ({ field: stream.extension.months }), [stream]);

  const onBlur = useCallback(() => {
    const { index, isValid, stream, streams, update } = setup(access, id);

    if (!isValid) {
      return;
    }

    const value = stream.extension.months.value;
    const end = _.addCalendarUnit(
      stream.start.value || "0",
      value || "0",
      "month",
    );

    if (_.isNilOrEmptyString(value)) {
      const edited = {
        ...stream,
        end: {
          ...stream.end,
          warning: undefined,
        },
        extension: {
          ...stream.extension,
          months: {
            ...stream.extension.months,
            warning: undefined,
          },
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    } else {
      const warning = {
        /** Check the end date is not in the past (e.g. past start date + too few months) */
        end: guards.validateMoment({
          isExcess: true,
          purpose: "datetoday",
          t,
          value: end,
          min: new Date().valueOf().toString(),
        }),
        months: guards.validateAmount({
          t,
          context: "steps",
          max: (MONTHLY_MAX_UNLOCKS_GROUP + 1).toString() /** Strict < */,
          value,
          min: (MONTHLY_MIN_UNLOCKS - 1).toString() /** Strict > */,
        }),
      };

      const error =
        warning.months ||
        (warning.end
          ? `${_.capitalize(t("structs.endDate"))}: ${_.lowerFirst(
              warning.end,
            )}`
          : undefined);

      const edited = {
        ...stream,
        end: {
          ...stream.end,
          value: end,
          warning: error,
        },
        extension: {
          ...stream.extension,
          months: {
            ...stream.extension.months,
            warning: error,
          },
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    }
  }, [access, id, t]);

  const onChange = useCallback(
    (value: string | undefined) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }

      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          months: {
            ...stream.extension.months,
            value,
            warning: undefined,
          },
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    },
    [access, id],
  );

  const warning = useMemo(
    () => stream.extension.months.warning || stream.end.warning,
    [stream.end.warning, stream.extension.months.warning],
  );

  const measure = useMemo(() => {
    if (
      !_.isNilOrEmptyString(stream.extension.months.value) &&
      !_.isNilOrEmptyString(stream.start.value)
    ) {
      return `${t("words.ending")} ${
        _.toDuration(stream.end.value, "date-only")[0]
      }`;
    }
    return undefined;
  }, [stream.end.value, stream.extension.months.value, stream.start.value, t]);

  const label = useMemo(
    () => ({
      value: t("form.label.months"),
    }),
    [t],
  );

  /** React to linked fields: stream linked-start time influences stream linked-end (through months) */
  useEffect(() => {
    onBlur();
  }, [stream.start.value, onBlur]);

  return {
    label,
    months,
    measure,

    onChange,
    onBlur,
    warning,
  };
}

export function useGroupFieldStart(id: string) {
  const { t } = useT();
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const start = useMemo(() => ({ field: stream.start }), [stream]);
  const { setOpen } = useModalMomentPicker();

  const label = useMemo(
    () => ({
      icon: InformationCircleIcon,
      isIconLast: true,
      tooltip: {
        value: t("descriptions.dates.settings", { zone: _.timezone().zone }),
      },
      value: t("structs.startDate"),
    }),
    [t],
  );

  const onConfirm = useCallback(
    (value?: string) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }

      const edited = {
        ...stream,
        start: {
          ...stream.start,
          value,
          warning: undefined,
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    },
    [access, id],
  );

  const onClick = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "moment",
        placeKey: "createGroup",
      });
    });
    setOpen(true, {
      milliseconds: start.field.value,
      onConfirm,
      purpose: t("structs.startDate"),
    });
  }, [onConfirm, setOpen, start.field.value, t]);

  return { onClick, start, label };
}

export function useGroupFieldInitial(id: string) {
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const { t } = useT();
  const initial = useMemo(
    () => ({ field: stream.extension.initial }),
    [stream],
  );
  const start = useMemo(() => ({ field: stream.start }), [stream]);
  const months = useMemo(() => ({ field: stream.extension.months }), [stream]);

  const initialOnValue = useMemo(() => {
    return _.isNilOrEmptyString(start.field.value)
      ? t("structs.atStart")
      : _.toDuration(start.field.value, "date")[0];
  }, [start.field.value, t]);

  const initialOffValue = useMemo(() => {
    return _.isNilOrEmptyString(start.field.value)
      ? t("structs.endOfFirstMonth")
      : _.toDuration(
          _.addCalendarUnit(start.field.value!, "1", "month"),
          "date",
        )[0];
  }, [start.field.value, t]);

  const isDisabled = useMemo(() => {
    return _.isNilOrEmptyString(start.field.value);
  }, [start.field.value]);

  const placeholder = useMemo(
    () => (isDisabled ? t("form.placeholder.monthlyUnlock") : undefined),
    [isDisabled, t],
  );

  const value = useMemo(() => {
    const title = (() => {
      if (isDisabled) {
        return undefined;
      }

      if (initial.field.value) {
        return initialOnValue;
      }

      return initialOffValue;
    })();

    return {
      isChecked: initial.field.value,
      title,
    };
  }, [initial, initialOnValue, initialOffValue, isDisabled]);

  const info = useMemo(() => {
    if (isDisabled) {
      return undefined;
    }

    if (!initial.field.value) {
      return undefined;
    }

    return {
      icon: HashtagIcon,
      value: t("form.info.monthlyUnlockStart", {
        count: _.toNumber(months.field.value || 0),
        unlocks: _.toNumber(months.field.value || 0) + 1,
      }),
    };
  }, [isDisabled, t, initial, months]);

  const onChange = useCallback(
    (value: boolean) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (isDisabled) {
        return;
      }

      if (!isValid) {
        return;
      }

      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          initial: {
            ...stream.extension.initial,
            value,
          },
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    },
    [access, id, isDisabled],
  );
  return {
    initial,
    info,
    isDisabled,
    onChange,
    placeholder,
    value,
  };
}
