import { LockupShapes, links } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type {
  IExtension,
  IExtensionConstructorParams,
  IExtensionHeaders,
} from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import { Extension } from "../../system/setup";

export interface ICliff extends IExtension {
  purpose: Extension;
  cliffDuration: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  cliffEnd: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
}

export function initial({
  cliffDuration,
  cliffEnd,
}: IExtensionConstructorParams): ICliff {
  return {
    purpose: Extension.CLIFF,
    cliffDuration: {
      id: "cliffDuration",
      value: cliffDuration,
      warning: undefined,
    },
    cliffEnd: {
      id: "cliffEnd",
      value: cliffEnd,
      warning: undefined,
    },
  };
}

export function headers(isDuration: boolean): IExtensionHeaders<keyof ICliff> {
  return isDuration
    ? [{ header: "cliffDuration", type: "duration" }]
    : [{ header: "cliffEnd", type: "date" }];
}

export function warnings(extension: unknown): string[] {
  const fields = extension as ICliff;
  const warnings: string[] = [];
  if (!_.isNilOrEmptyString(fields.cliffDuration.warning)) {
    warnings.push(`Cliff duration: ${fields.cliffDuration.warning}`);
  }
  if (!_.isNilOrEmptyString(fields.cliffEnd.warning)) {
    warnings.push(`Cliff end: ${fields.cliffEnd.warning}`);
  }
  return warnings;
}

export const shape = LockupShapes.cliff.id;
export const template = links.streamTemplate.cliff;
