import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AIRSTREAMS_PAGE_SIZE,
  MAX_SUBGRAPH_ID,
  REQUEST_ID,
} from "@sablier/v2-constants";
import {
  useRequestAirstreamList,
  useRequestAirstreamListHidden,
} from "@sablier/v2-hooks";
import { BigNumber, _ } from "@sablier/v2-mixins";
import {
  Airstream,
  type IFilterAirstream,
  type ISearchAirstream,
} from "@sablier/v2-models";
import extensions from "~/client/extensions";
import type { LockupShape } from "@sablier/v2-constants";
import { supported } from "../constants";
import {
  useAirstreamStoreAccessor,
  useAirstreamStoreSearch,
} from "./store/useAirstreamStore";
import useAccount from "./useAccount";

const OPTIONS = {
  after: MAX_SUBGRAPH_ID,
  first: AIRSTREAMS_PAGE_SIZE,
};

export default function useAirstreamsSearch(
  filter: IFilterAirstream,
  isReadyExternal?: boolean,
) {
  const { isReady } = useAccount();
  const { search: result, set } = useAirstreamStoreSearch();
  const access = useAirstreamStoreAccessor();

  const { IDs: excludeIds, isLoading: isLoadingHidden } =
    useRequestAirstreamListHidden({
      chainId: filter.chainId,
      isEnabled: isReady,
    });

  const [options, setOptions] = useState<typeof OPTIONS>(OPTIONS);

  const isEnabled = useMemo(() => {
    if (!supported.find((chain) => chain.id !== filter.chainId)) {
      return false;
    }

    if (
      _.isNilOrEmptyString(filter.chainId) &&
      _.isNilOrEmptyString(filter.admin) &&
      _.isNilOrEmptyString(filter.token)
    ) {
      return false;
    }

    /**
     * We need to know what has to be hidden
     * before launching a search.
     */
    if (isLoadingHidden || !isReady) {
      return false;
    }

    return !!(isReadyExternal && !result?.options.isComplete);
  }, [filter, result, isReady, isReadyExternal, isLoadingHidden]);

  const onSuccess = useCallback(
    (_result: ISearchAirstream) => {
      const result = _.clone(_result);
      const state = access();
      const set = state.api.setSearch;
      const search = state.search;

      const info = (result: unknown, label = "new") =>
        console.info(
          "%c[search airstreams]",
          "color: cadetblue",
          `[${label}]`,
          {
            result,
          },
        );

      result.airstreams.forEach((airstream) => {
        const resolved = Airstream.findStreamShape(airstream, (stream) =>
          extensions.identify(stream),
        );
        if (!resolved.isUnknown) {
          airstream.streamShape = resolved.id as LockupShape;
        }
      });

      /** If there are no results, you can set initial ones care-free */
      if (_.isNil(search)) {
        set(result);
        info(result);
      } else {
        /** If filters changed, it meas we requested for a new set of airstreams. Override based on these new rules. */
        if (!_.isEqual(search.filter, result.filter)) {
          set(result);
          info(result);
        } else if (!_.isEqual(search.options, result.options)) {
          /**
           * Edge-case: [Search set#1, Click on more for set#2, Go to a Stream's Profile, Come Back]
           * Upon coming back, this request will ask for set#1 again. Due to the rules of this branch,
           * the results would get appended again and again. The condition below makes sure the search
           * isn't resumed from somewhere else.
           */
          if (
            !_.isNil(search.options.after) &&
            !_.isNil(result.options.after) &&
            new BigNumber(search.options.after).isLessThanOrEqualTo(
              new BigNumber(result.options.after),
            )
          ) {
            return;
          }

          /** If filters remain the same, options may have changed (e.g. pagination requested followup items). Merge results. */
          const value = _.clone(search);
          value.options = result.options;
          value.airstreams = _.uniqBy(
            [...value.airstreams, ...result.airstreams],
            (i) => i.id,
          );
          set(value);
          info(value.airstreams, "more");
        }
      }
    },
    [access],
  );

  const onError = useCallback(
    (_error: unknown, result: ISearchAirstream) => {
      set(result);
    },
    [set],
  );

  const onJump = useCallback(
    (result: { campaigns: { id: string }[] }) => {
      /**
       * An empty response can also be caused by a faulty indexer.
       * To account for such cases, re-run query with fallback when there are 0 results.
       */
      if (result.campaigns?.length === 0) {
        return true;
      }

      /**
       * When looking for airstreams by id, if the results are less than expected try again.
       * The indexer may be slow to find certain airstreams.
       */
      if (filter.airstreamIds) {
        /** Normalize the requested stream ids */
        const a = filter.airstreamIds
          .slice(0, options.first)
          .map((i) => i.toLowerCase());
        /** Extract the received stream ids */
        const b = result.campaigns
          .map((result) => result.id)
          .slice(0, options.first)
          .map((i) => i.toLowerCase());
        /** Look for requested ids not received */
        const difference = _.difference(a, b);

        return difference.length !== 0;
      }

      return false;
    },
    [filter, options],
  );

  useEffect(() => {
    setOptions(OPTIONS);
  }, [filter]);

  const { isLoading: isLoadingList, error } = useRequestAirstreamList({
    key: REQUEST_ID.airstreamListSearch,
    filter,
    isEnabled,
    onError,
    onJump,
    onSuccess,
    options,
    excludeIds,
  });

  const doMore = useCallback(() => {
    if (!isLoadingList && _.isNilOrEmptyString(error)) {
      setOptions((prev) => ({
        ...prev,
        after: result?.options.after || OPTIONS.after,
      }));
    }
  }, [error, isLoadingList, setOptions, result]);

  const isLoading = useMemo(
    () => isLoadingList || isLoadingHidden || !isReady,
    [isLoadingList, isLoadingHidden, isReady],
  );

  return {
    doMore,
    error,
    isLoading,
    result,
  };
}
