import { BigNumber, _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import type { IMilliseconds, IToken } from "@sablier/v2-types";
import type { IExtensionDependencies } from "~/client/types";
import { C, SD, SI } from "./atoms";

export const lockupCancel = {
  title: (t: Translate) => t("machines.cancel.title"),
  confirm: (t: Translate) => ({
    description: t("machines.cancel.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.cancel.fail"),
  }),
  send: (t: Translate, confirm = false) => ({
    description: SI(t, t("machines.cancel.send", { confirm: C(t, confirm) })),
  }),
  success: (t: Translate) => ({
    description: SD(t, t("machines.cancel.success")),
  }),
} as const;

export const lockupCancelMultiple = {
  title: (t: Translate) => t("machines.cancelMultiple.title"),
  confirm: (t: Translate) => ({
    description: t("machines.cancelMultiple.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.cancelMultiple.fail"),
  }),
  send: (t: Translate, confirm = false) => ({
    description: SI(
      t,
      t("machines.cancelMultiple.send", {
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate) => ({
    description: SD(t, t("machines.cancelMultiple.success")),
  }),
} as const;

export const lockupCreate = {
  title: (t: Translate) => t("machines.createGroup.title"),
  confirm: (t: Translate, large: boolean) => ({
    description: large
      ? `${t("machines.createGroup.confirm")} ${t(
          "machines.createGroup.large",
        )}`
      : t("machines.createGroup.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.createGroup.fail"),
  }),
  prepare: (t: Translate, dependencies: IExtensionDependencies<"group">) => {
    const count = dependencies.streams?.length || 0;

    const plural = count > 1 ? "s" : "";

    const total = _.toNumeralPrice(
      dependencies.streams?.reduce(
        (sum, stream) => sum.plus(new BigNumber(stream.amount || "0")),
        new BigNumber(0),
      ) || "0",
    );

    const label = t("machines.createGroup.prepare", {
      count,
      plural,
      symbol: dependencies.token?.symbol,
      total,
    });

    return { label };
  },
  send: (t: Translate, confirm = false, label: string) => ({
    description: SI(
      t,
      t("machines.create.send", {
        created: `${label}. ${C(t, confirm)}`,
      }),
    ),
  }),
  sign: (t: Translate) => ({
    description: t("machines.create.sign"),
  }),
  success: (t: Translate, label: string, ids?: string) => ({
    description: SD(
      t,
      `${t("machines.createGroup.success", { label })}${
        !_.isNil(ids) ? t("structs.newStreamIds", { ids }) : ""
      }`,
    ),
  }),
} as const;

export const lockupRenounce = {
  title: (t: Translate) => t("machines.renounce.title"),
  confirm: (t: Translate) => ({
    description: t("machines.renounce.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.renounce.fail"),
  }),
  success: (t: Translate, end: IMilliseconds) => ({
    description: SD(
      t,
      t("machines.renounce.success", {
        endDate: _.toDuration(end, "date-full")[0],
      }),
    ),
  }),
  send: (t: Translate, confirm = false) => ({
    description: SI(t, t("machines.renounce.send", { confirm: C(t, confirm) })),
  }),
} as const;

export const lockupTransfer = {
  title: (t: Translate) => t("machines.transfer.title"),
  confirm: (t: Translate) => ({
    description: t("machines.transfer.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.transfer.fail"),
  }),
  send: (t: Translate, confirm = false, recipient = "unknown") => ({
    description: SI(
      t,
      t("machines.transfer.send", {
        recipient,
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate, recipient = "unknown") => ({
    description: SD(t, t("machines.transfer.success", { recipient })),
  }),
} as const;

export const lockupWithdraw = {
  title: (t: Translate) => t("machines.withdraw.title"),
  confirm: (t: Translate) => ({
    description: t("machines.withdraw.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.withdraw.fail"),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
  send: (
    t: Translate,
    confirm = false,
    recipient = "unknown",
    amount = "0",
  ) => ({
    description: SI(
      t,
      t("machines.withdraw.send", {
        amount,
        recipient,
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate, recipient = "unknown", amount = "0") => ({
    description: SD(t, t("machines.withdraw.success", { amount, recipient })),
  }),
} as const;

export const lockupWithdrawMultiple = {
  title: (t: Translate) => t("machines.withdrawMultiple.title"),
  confirm: (t: Translate) => ({
    description: t("machines.withdrawMultiple.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.withdrawMultiple.fail"),
  }),
  send: (t: Translate, confirm = false) => ({
    description: SI(
      t,
      t("machines.withdrawMultiple.send", {
        confirm: C(t, confirm),
      }),
    ),
  }),
  success: (t: Translate) => ({
    description: SD(t, t("machines.withdrawMultiple.success")),
  }),
} as const;
