import { useLockupWarning } from "~/client/hooks";
import type { ILockup } from "@sablier/v2-models";
import Account from "../../individual/Account";
import Banned from "../../individual/Banned";
import Chain from "../../individual/Chain";
import Connect from "../../individual/Connect";
import Depleted from "../../individual/Depleted";
import Early from "../../individual/Early";
import Expired from "../../individual/Expired";
import NonTransferable from "../../individual/NonTransferable";

interface Props {
  purpose: Parameters<typeof useLockupWarning>[0];
  stream: ILockup | undefined;
}

function Bundle({ purpose, stream }: Props) {
  const data = useLockupWarning(purpose, stream);

  if (data.state === "canceled") {
    return <Expired end={data.end} permission={data.permission} />;
  }

  if (data.state === "ended") {
    return <Expired end={data.end} permission={data.permission} />;
  }

  if (data.state === "early") {
    return <Early start={data.start} permission={data.permission} />;
  }

  if (data.state === "depleted") {
    return <Depleted permission={data.permission} />;
  }

  if (data.state === "connect") {
    return <Connect />;
  }

  if (data.state === "chain") {
    return <Chain toChainId={data.chainId} />;
  }

  if (data.state === "whitelist") {
    return <Account whitelist={data.whitelist} />;
  }

  if (data.state === "banned") {
    return <Banned />;
  }

  if (data.state === "nonTransferable") {
    return <NonTransferable />;
  }

  return false;
}

export default Bundle;
