import { useMemo } from "react";
import { HISTORY_PAGE_SIZE } from "@sablier/v2-constants";
import { useRequestFlowActions } from "@sablier/v2-hooks";
import { FlowAction, Token } from "@sablier/v2-models";
import { useFlowCurrent } from "~/client/hooks";

export default function useFlowCurrentActions() {
  const { stream } = useFlowCurrent();

  const id = useMemo(() => stream?.id, [stream]);

  const {
    error,
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useRequestFlowActions({
    id,
  });

  const list = useMemo(
    () =>
      data?.pages.flatMap((page) =>
        page.actions.map((item) => {
          const token = new Token(item.stream!.asset);
          return new FlowAction(item, token);
        }),
      ) || [],
    [data],
  );

  const isShort = useMemo(() => list.length < HISTORY_PAGE_SIZE, [list]);

  return {
    list,
    error,
    isLoading,
    isShort,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
}
