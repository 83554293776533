import {
  CoreCategory,
  DEFAULT_GAS_LIMIT,
  DEFAULT_MAX_GAS_LIMIT,
  FlowCategory,
  FlowVersion,
  LockupCategory,
  LockupVersion,
  PeripheryCategory,
} from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { contracts, peripheries } from "~/client/constants";
import type { Extension } from "../extensions";
import type { IAddress } from "@sablier/v2-types";
import { dynamic, linear, tranched } from "../extensions";

/**
 * Resolve an address to a certain core lockup category (e.g. 0x123... is a lockup_linear)
 */
export function classifier_lockup(
  chainId: keyof typeof contracts | number | undefined,
  address: IAddress | undefined,
  context: "core",
): LockupCategory | undefined {
  if (_.isNilOrEmptyString(chainId) || !_.isEthereumAddress(address)) {
    return undefined;
  }

  if (context === ("core" as const)) {
    if (!_.get(contracts, chainId)) {
      return undefined;
    }

    if (
      [
        CoreCategory.LOCKUP_LINEAR_20,
        CoreCategory.LOCKUP_LINEAR_21,
        CoreCategory.LOCKUP_LINEAR_22,
      ].some(
        (category) =>
          _.toAddress(contracts[chainId][category]?.address) ===
          _.toAddress(address),
      )
    ) {
      return LockupCategory.LOCKUP_LINEAR;
    }

    if (
      [
        CoreCategory.LOCKUP_DYNAMIC_20,
        CoreCategory.LOCKUP_DYNAMIC_21,
        CoreCategory.LOCKUP_DYNAMIC_22,
      ].some(
        (category) =>
          _.toAddress(contracts[chainId][category]?.address) ===
          _.toAddress(address),
      )
    ) {
      return LockupCategory.LOCKUP_DYNAMIC;
    }

    if (
      [CoreCategory.LOCKUP_TRANCHED_22].some(
        (category) =>
          _.toAddress(contracts[chainId][category]?.address) ===
          _.toAddress(address),
      )
    ) {
      return LockupCategory.LOCKUP_TRANCHED;
    }
  }

  return undefined;
}

/**
 * Pick a contract definition based on
 * - a chain
 * - a category (e.g. "flow")
 * - a version (e.g. V1X, falls back to the most recent canonical version)
 */
export function contractor_flow(
  chainId: keyof typeof contracts | number | undefined,
  _purpose: FlowCategory | (string & {}) = FlowCategory.FLOW,
  version: FlowVersion = FlowVersion.V10,
) {
  try {
    if (
      !_.isNilOrEmptyString(chainId) &&
      !_.isNilOrEmptyString(contracts?.[chainId])
    ) {
      const flavor = (() => {
        const category = FlowCategory.FLOW;

        return {
          [FlowVersion.V10]: {
            [FlowCategory.FLOW]: CoreCategory.FLOW_10,
          },
        }[version][category];
      })();

      return contracts[chainId][flavor];
    }
  } catch (error) {
    vendors.crash.log(error);
  }
  return { alias: "", address: "", methods: {} };
}

/**
 * Pick a contract definition based on
 * - a chain
 * - a category (e.g. "lockup_linear") or extension (e.g. "monthly")
 * - a version (e.g. V2X, falls back to the most recent canonical version)
 */
export function contractor_lockup(
  chainId: keyof typeof contracts | number | undefined,
  purpose:
    | Extension
    | LockupCategory
    | (string & {}) = LockupCategory.LOCKUP_LINEAR,
  version: LockupVersion = LockupVersion.V22,
) {
  try {
    if (
      !_.isNilOrEmptyString(chainId) &&
      !_.isNilOrEmptyString(contracts?.[chainId])
    ) {
      const flavor = (() => {
        const category = (() => {
          if (purpose.toLowerCase() === FlowCategory.FLOW.toLowerCase()) {
            return FlowCategory.FLOW;
          }
          if (
            [
              ...linear.map((shape) => shape.toLowerCase()),
              LockupCategory.LOCKUP_LINEAR.toLowerCase(),
            ].includes(purpose.toLowerCase())
          ) {
            return LockupCategory.LOCKUP_LINEAR;
          }

          if (
            [
              ...tranched.map((shape) => shape.toLowerCase()),
              LockupCategory.LOCKUP_TRANCHED.toLowerCase(),
            ].includes(purpose.toLowerCase())
          ) {
            return LockupCategory.LOCKUP_TRANCHED;
          }

          if (
            [
              ...dynamic.map((shape) => shape.toLowerCase()),
              LockupCategory.LOCKUP_DYNAMIC.toLowerCase(),
            ].includes(purpose.toLowerCase())
          ) {
            return LockupCategory.LOCKUP_DYNAMIC;
          }

          return LockupCategory.LOCKUP_DYNAMIC;
        })();

        return {
          [LockupVersion.V20]: {
            [LockupCategory.LOCKUP_LINEAR]: CoreCategory.LOCKUP_LINEAR_20,
            [LockupCategory.LOCKUP_DYNAMIC]: CoreCategory.LOCKUP_DYNAMIC_20,
            [LockupCategory.LOCKUP_TRANCHED]: "",
            [FlowCategory.FLOW]: "",
          },
          [LockupVersion.V21]: {
            [LockupCategory.LOCKUP_LINEAR]: CoreCategory.LOCKUP_LINEAR_21,
            [LockupCategory.LOCKUP_DYNAMIC]: CoreCategory.LOCKUP_DYNAMIC_21,
            [LockupCategory.LOCKUP_TRANCHED]: "",
            [FlowCategory.FLOW]: "",
          },
          [LockupVersion.V22]: {
            [LockupCategory.LOCKUP_LINEAR]: CoreCategory.LOCKUP_LINEAR_22,
            [LockupCategory.LOCKUP_DYNAMIC]: CoreCategory.LOCKUP_DYNAMIC_22,
            [LockupCategory.LOCKUP_TRANCHED]: CoreCategory.LOCKUP_TRANCHED_22,
            [FlowCategory.FLOW]: "",
          },
          [FlowVersion.V10]: {
            [LockupCategory.LOCKUP_LINEAR]: "",
            [LockupCategory.LOCKUP_DYNAMIC]: "",
            [LockupCategory.LOCKUP_TRANCHED]: "",
            [FlowCategory.FLOW]: CoreCategory.FLOW_10,
          },
        }[version][category];
      })();

      return contracts[chainId][flavor];
    }
  } catch (error) {
    vendors.crash.log(error);
  }
  return { alias: "", address: "", methods: {} };
}

/**
 * Pick a periphery contract based on
 * - a chain
 * - a category (e.g. "batch")
 */
export function peripheral(
  chainId: keyof typeof peripheries | number | undefined,
  purpose: PeripheryCategory,
) {
  try {
    if (
      !_.isNilOrEmptyString(chainId) &&
      !_.isNilOrEmptyString(peripheries?.[chainId]?.[purpose])
    ) {
      return peripheries[chainId][purpose];
    }
  } catch (error) {
    vendors.crash.log(error);
  }
  return { address: "", alias: "", methods: {} };
}

/**
 * Pull gas spectrum (min and max) for a certain contract.
 * Supported: Flow, Lockup and Periphery
 */
export function gasser(
  chainId?: keyof typeof contracts | number,
  purpose?:
    | Extension
    | LockupCategory
    | PeripheryCategory
    | FlowCategory
    | undefined,
  method?: string,
  multiplier?: number,
): { min: number; max: number } {
  try {
    if (
      !_.isNilOrEmptyString(purpose) &&
      !_.isNilOrEmptyString(chainId) &&
      !_.isNilOrEmptyString(method)
    ) {
      const isPeriphery = Object.values(PeripheryCategory).find(
        (p) => p === purpose,
      );

      const contract = isPeriphery
        ? peripheral(chainId, purpose as PeripheryCategory)
        : purpose === FlowCategory.FLOW
        ? contractor_flow(chainId, purpose)
        : contractor_lockup(chainId, purpose);

      if (
        !_.isNil(contract) &&
        !_.isNil(contract.methods) &&
        _.has(contract.methods, method)
      ) {
        const limit = contract.methods[method];
        if (_.isFunction(limit)) {
          return limit(multiplier || 1);
        }

        return { min: limit.min, max: limit.max };
      }
    }
  } catch (error) {
    vendors.crash.log(error);
  }

  return { min: DEFAULT_GAS_LIMIT, max: DEFAULT_MAX_GAS_LIMIT };
}
