import { useMemo } from "react";
import { REQUEST_ID, links, requests } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Token } from "@sablier/v2-models";
import { useQuery } from "@tanstack/react-query";
import type { IToken, UniswapTokenList } from "@sablier/v2-types";

interface Props {
  url: string | undefined;
  fallback?: string;
}

async function request({ url, fallback }: Props): Promise<UniswapTokenList> {
  try {
    /** Await result for try/catch to handle asynchronous errors */
    const primary = await requests.tokenList({ url });
    return primary;
  } catch (error) {
    if (!_.isNilOrEmptyString(fallback)) {
      const secondary = await requests.tokenList({ url: fallback });
      return secondary;
    }
    throw error;
  }
}

export default function useRequestTokenList({ url, fallback }: Props) {
  const {
    data: raw,
    error,
    isLoading,
  } = useQuery<UniswapTokenList | undefined>({
    queryKey: [...REQUEST_ID.tokenList, { unique: { url, fallback } }],
    queryFn: async () => request({ url, fallback }),
    staleTime: Infinity,
    gcTime: Infinity,
    retry: false,
    enabled: !_.isNilOrEmptyString(url),
  });

  const list: IToken[] = useMemo(() => {
    if (!raw) {
      return [];
    }
    const augmented = raw.tokens.map((item) => {
      const image = _.toString(_.get(item, "logoURI"));

      const token = Token.fromUniswapToken(item);
      token.image = image.startsWith("https")
        ? image
        : image.startsWith("ipfs://")
        ? image.replace("ipfs://", links.ipfs.gateway.cloudflare)
        : undefined;
      return token;
    });

    return [...augmented]
      .sort((a, b) => a.symbol.localeCompare(b.symbol))
      .map((item) => new Token(item));
  }, [raw]);

  return {
    error,
    isLoading,
    list,
    raw,
    request: requests.tokenList,
  };
}
