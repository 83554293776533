import { DigitPurpose, DigitLimits as Limits } from "@sablier/v2-constants";
import { BigNumber } from "@sablier/v2-mixins";

export type Definition = { d: string; p: DigitPurpose }[];

export type Context = {
  computed: {
    characteristic: BigNumber;
    characteristicDigits: string[];
    definition: Definition;
    mantissa: BigNumber;
    mantissaDigits: string[];
    sanitized: string;
  };
  source: string;
};

export const Initial: Context = {
  computed: {
    characteristic: new BigNumber(0),
    characteristicDigits: [],
    definition: [],
    mantissa: new BigNumber(0),
    mantissaDigits: [],
    sanitized: "0",
  },
  source: "0",
};

export { Limits };
