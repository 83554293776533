import { useFlowWarning } from "~/client/hooks";
import type { IFlow } from "@sablier/v2-models";
import Account from "../../individual/Account";
import Banned from "../../individual/Banned";
import Chain from "../../individual/Chain";
import Connect from "../../individual/Connect";
import Early from "../../individual/Early";
import Expired from "../../individual/Expired";
import NonRefundable from "../../individual/NonRefundable";
import NonTransferable from "../../individual/NonTransferable";
import NonVoidable from "../../individual/NonVoidable";
import Ongoing from "../../individual/Ongoing";

interface Props {
  purpose: Parameters<typeof useFlowWarning>[0];
  stream: IFlow | undefined;
}

function Bundle({ purpose, stream }: Props) {
  const data = useFlowWarning(purpose, stream);

  if (data.state === "ended") {
    return <Expired end={data.end} permission={data.permission} />;
  }

  if (data.state === "early") {
    return <Early start={data.start} permission={data.permission} />;
  }

  if (data.state === "connect") {
    return <Connect />;
  }

  if (data.state === "chain") {
    return <Chain toChainId={data.chainId} />;
  }

  if (data.state === "whitelist") {
    return <Account whitelist={data.whitelist} />;
  }

  if (data.state === "banned") {
    return <Banned />;
  }

  if (data.state === "nonTransferable") {
    return <NonTransferable />;
  }

  if (data.state === "adjustSuspended") {
    return <Ongoing />;
  }

  if (data.state === "ongoing") {
    return <Ongoing />;
  }

  if (data.state === "nonVoidable") {
    return <NonVoidable />;
  }
  if (data.state === "nonRefundable") {
    return <NonRefundable />;
  }

  return false;
}

export default Bundle;
