import { REQUEST_ID, requests } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { QueryClient } from "@tanstack/react-query";
import type { useMachineInstantiator } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { useModalAirstreamBypass } from "~/client/hooks";

export interface Payload {
  cid: string;
  api: {
    t: Translate;
    setOpen: ReturnType<typeof useModalAirstreamBypass>["setOpen"];
    updateData: ReturnType<typeof useModalAirstreamBypass>["updateData"];
  };
}

type Machine = Parameters<typeof useMachineInstantiator<Payload>>;

type onCreate = Parameters<Machine["0"]["onCreate"]>["0"];

export async function onCreate({ context }: onCreate): Promise<void> {
  const { cid, api } = context.payload;

  try {
    api.updateData({
      status: "pending",
    });

    const response = await new QueryClient().fetchQuery({
      queryKey: [...REQUEST_ID.maskMerkleValidity, { unique: [cid] }],
      queryFn: async () =>
        requests.maskMerkleValidity({
          query: { cid },
        }),
    });

    api.updateData({
      calldata: response,
      status: "success",
    });
  } catch (error) {
    vendors.crash.log(error);

    api.updateData({
      status: "fail",
      error: error as unknown as Error,
    });

    throw error;
  }
}
