import { useCallback, useEffect, useId, useMemo, useRef } from "react";
import * as machines from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { useMachine } from "@xstate/react";
import type {
  IAddress,
  IFlags,
  ISigner,
  IToken,
  IWagmiConfig,
} from "@sablier/v2-types";
import useDebounce from "./useDebounce";

/**
 * There's a weird bug between hooks and machines where ISigner is somehow different.
 * ISigner is (in both cases) imported from "@sablier/v2-types" so it doesn't make any sense.
 * The error is only picked up at build time. We need to use ts-ignore for the build-packages step to pass.
 */

/* eslint-disable @typescript-eslint/ban-ts-comment */
interface Props {
  allowance?: string;
  chainId: number | undefined;
  flags: IFlags;
  owner: IAddress | undefined;
  onSuccess?: () => void;
  library: IWagmiConfig;
  signer: ISigner | undefined;
  spender: IAddress | undefined;
  token: IToken | undefined;
}

export default function useTokenAllowance({
  allowance,
  chainId,
  flags,
  owner,
  onSuccess,
  library,
  signer,
  spender,
  token,
}: Props) {
  const id = useId();
  const reference = useRef(machines.allowance.create({ id }));
  const [machine, send] = useMachine(reference.current);

  const state = useMemo(() => _.toString(machine.value), [machine.value]);
  const error = useMemo(() => machine.context.error, [machine.context.error]);
  const debounced = useDebounce(state, 300);

  const isApprovable = useMemo(() => {
    if (_.isNil(signer) || _.isNil(token) || _.isNilOrEmptyString(spender)) {
      return false;
    }

    if (["denied"].includes(machine.value as string)) {
      return true;
    }

    return false;
  }, [machine.value, spender, signer, token]);

  const isLoading = useMemo(
    () => ["idle", "checking", "approving"].includes(state as string),
    [state],
  );

  const isApproving = useMemo(
    () => ["approving"].includes(state as string),
    [state],
  );

  const isApproved = useMemo(
    () => ["allowed"].includes(state as string),
    [state],
  );

  const doApprove = useCallback(() => {
    if (
      isApprovable &&
      !_.isNil(signer) &&
      !_.isNil(token) &&
      !_.isNil(spender) &&
      !_.isNilOrEmptyString(token.address) &&
      !_.isNilOrEmptyString(token.decimals)
    ) {
      const callback = () => {
        if (onSuccess && _.isFunction(onSuccess)) {
          onSuccess();
        }
        send({ type: "RESET" });
      };

      send({
        type: "APPROVE",
        payload: {
          flags,
          library,
          // @ts-ignore
          signer,
          spender,
          token,
          onSuccess: callback,
        },
      });
    }
  }, [flags, isApprovable, library, onSuccess, send, signer, spender, token]);

  /**
   * ------------------------------
   * Logging allowance/approval status
   * ------------------------------
   */

  useEffect(() => {
    (async () => {
      if (!signer || !token) {
        return;
      }
      console.info(
        "%c[allowance]",
        "color: darkOrchid",
        "[async]",
        _.toString(token?.address || "").substring(0, 5) || undefined,
        _.toString(spender || "").substring(0, 5) || undefined,
        _.toString(owner || "").substring(0, 5) || undefined,
      );
    })();
  }, [owner, signer, spender, token]);

  useEffect(() => {
    console.info(
      "%c[allowance]",
      "color: darkOrchid",
      "[flag]",
      debounced ?? "",
    );
  }, [debounced]);
  /**
   * ------------------------------
   * Automated side-effects
   * ------------------------------
   */

  /** If dependencies change (either account or token address) the machine has to be reset */
  useEffect(() => {
    send({ type: "RESET" });
  }, [allowance, chainId, send, signer, spender, token]);

  /** If dependencies are in order, start transitioning through the states */
  useEffect(() => {
    (async () => {
      if (
        _.isNil(signer) ||
        _.isNil(token) ||
        _.isNilOrEmptyString(chainId) ||
        _.isNilOrEmptyString(spender)
      ) {
        return;
      }

      if (state === "idle" && owner) {
        send({
          type: "CHECK",
          payload: {
            allowance,
            owner,
            library,
            // @ts-ignore
            signer,
            spender,
            token,
          },
        });
      }
    })();
  }, [allowance, chainId, library, state, owner, send, signer, spender, token]);

  return {
    error,
    debounced,
    doApprove,
    isApprovable,
    isApproved,
    isApproving,
    isLoading,
    state,
  };
}
