import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import useLockupDashboardQuery from "./useLockupDashboardQuery";
import useLockupsSearch from "./useLockupsSearch";

export default function useLockupDashboardSearch() {
  const { filter, isReady, isFallback } = useLockupDashboardQuery();

  const { doMore, error, isLoading, result } = useLockupsSearch(
    filter,
    isReady && !isFallback,
  );

  return useMemo(
    () => ({
      doMore,
      error,
      isLoading,
      result,
    }),
    [doMore, error, isLoading, result],
  );
}
