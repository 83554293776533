import { useCallback, useMemo } from "react";
import {
  DEFAULT_RESET_SLEEP,
  QUERY_CACHE_TIME,
  REQUEST_ID,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { useQuery } from "@tanstack/react-query";
import type { IExpected, IWagmiConfig } from "@sablier/v2-types";
import { useExpectedStoreAccessor } from "./store";
import { useLockupStoreAccessor } from "./store/useLockupStore";
import useAccount from "./useAccount";
import useExpected from "./useExpected";
import useLockupsOwned from "./useLockupsOwned";

interface Props {
  expected: IExpected;
  storedStreams: ReturnType<typeof useLockupStoreAccessor>;
}

async function check(
  library: IWagmiConfig,
  { storedStreams, expected }: Props,
): Promise<IExpected["status"]> {
  const state = storedStreams();
  const { hash, status } = expected;

  if (
    new BigNumber(Date.now())
      .minus(new BigNumber(expected.createdOn))
      .isGreaterThan(10 * 24 * 60 * 60 * 1000)
  ) {
    /**
     * Discard streams that have been waiting in the "expected pool" for more than 10 days
     */
    return "failed";
  }

  if (
    state.owned?.streams.find(
      (item) => item.hash?.toLowerCase() === hash.toLowerCase(),
    )
  ) {
    return "done";
  }

  if (
    state.search?.streams.find(
      (item) => item.hash?.toLowerCase() === hash.toLowerCase(),
    )
  ) {
    return "done";
  }

  if (
    state.preview?.streams.find(
      (item) => item.hash?.toLowerCase() === hash.toLowerCase(),
    )
  ) {
    return "done";
  }

  if (status === "pending") {
    try {
      if (
        new BigNumber(Date.now())
          .minus(new BigNumber(expected.createdOn))
          .isLessThan(DEFAULT_RESET_SLEEP - 2 * 1000)
      ) {
        /**
         * Don't track streams that have been added less than `DEFAULT_RESET_SLEEP - 2s` seconds ago.
         * Leave some time for them to be parsed by the RPC (avoid viem's "Transaction with hash ... could not be found").
         */
        return "pending";
      }

      const confirmations = await framework.confirmations(library, { hash });
      if (_.isNilOrEmptyString(confirmations) || confirmations === 0n) {
        /**
         * Transaction has not been minded, keep item in "pending" mode
         */
        return "pending";
      }

      /**
       * For transactions that have been successfully minted, we're now waiting for the
       * subgraph to index our transaction.
       */
      return "indexing";
    } catch (error) {
      console.error(error); // Do not track with vendors.
      /**
       * We don't currently support replaced transactions (replaced/repriced) so any
       * replace/revert will mark this tx as failed and remove its trackers.
       */
      return "failed";
    }
  }

  return status;
}

function useExpectedCheck() {
  const storedStreams = useLockupStoreAccessor();
  const storedExpected = useExpectedStoreAccessor();

  const { address, chainId, isConfigured, library } = useAccount();
  const { result: owned, isLoading: isLoadingOwned } = useLockupsOwned();
  const { configured } = useExpected();

  const condition = useMemo(
    () => isConfigured && !isLoadingOwned,
    [isConfigured, isLoadingOwned],
  );

  const queryFn = useCallback(async () => {
    if (_.isNil(library)) {
      throw new Error("Misconfigured library.");
    }

    const state = storedExpected();
    const updates: Record<string, IExpected["status"]> = {};

    let changed = false;
    let final = _.clone(state.list);

    for (let i = 0; i < configured.length; i++) {
      const status = await check(library, {
        storedStreams,
        expected: configured[i],
      });

      const previous = configured[i].status;

      if (status !== previous) {
        changed = true;
        if (status === "failed" || status === "done") {
          final = final.filter((item) => item.id !== configured[i].id);
        } else {
          final = final.map((item) =>
            item.id !== configured[i].id ? item : { ...item, status },
          );
        }
      }
    }

    if (changed) {
      state.api.setList(final);
    }

    return updates;
  }, [configured, library, storedExpected, storedStreams]);

  const hashKey = useMemo(() => configured.join("-"), [configured]);

  const { isLoading } = useQuery({
    queryKey: [...REQUEST_ID.expected, address, chainId, owned, hashKey],
    queryFn,
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    enabled: condition,
  });

  return {
    isLoading,
  };
}

export default useExpectedCheck;
