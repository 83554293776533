import styled from "styled-components";
import { Chart as Base } from "@sablier/v2-components/molecules";
import { _ } from "@sablier/v2-mixins";
import { ILockup } from "@sablier/v2-models";
import { useChart } from "./logic";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

interface Props {
  stream: ILockup | undefined;
}

function Chart({ stream }: Props) {
  const { datasets, options } = useChart(stream);

  return (
    <Wrapper>
      <Inner>
        <Base token={stream?.token} options={options} datasets={datasets} />
      </Inner>
    </Wrapper>
  );
}

export default Chart;
