import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import useStore from "~/client/stores/token";

export function useLocalTokenStore() {
  const [local, api] = useStore(
    useShallow((state) => [state.local, state.api]),
  );

  return useMemo(
    () => ({
      api,
      local,
    }),
    [api, local],
  );
}
