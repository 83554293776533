export const LockupActionCategory = {
  APPROVAL: "Approval",
  APPROVAL_FOR_ALL: "ApprovalForAll",
  CREATE: "Create",
  CANCEL: "Cancel",
  RENOUNCE: "Renounce",
  TRANSFER: "Transfer",
  WITHDRAW: "Withdraw",
} as const;

export const FlowActionCategory = {
  APPROVAL: "Approval",
  APPROVAL_FOR_ALL: "ApprovalForAll",
  ADJUST: "Adjust",
  CREATE: "Create",
  DEPOSIT: "Deposit",
  PAUSE: "Pause",
  REFUND: "Refund",
  RESTART: "Restart",
  TRANSFER: "Transfer",
  VOID: "Void",
  WITHDRAW: "Withdraw",
} as const;

export const AirstreamActionCategory = {
  CLAIM: "Claim",
  CLAWBACK: "Clawback",
  CREATE: "Create",
} as const;

export type LockupActionCategory =
  (typeof LockupActionCategory)[keyof typeof LockupActionCategory];
export type FlowActionCategory =
  (typeof FlowActionCategory)[keyof typeof FlowActionCategory];
export type AirstreamActionCategory =
  (typeof AirstreamActionCategory)[keyof typeof AirstreamActionCategory];
