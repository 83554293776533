import {
  ArrowUpTrayIcon,
  ArrowsRightLeftIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  DocumentIcon,
  LockClosedIcon,
  LockOpenIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
  SlashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { links } from "@sablier/v2-constants";
import { IImage } from "@sablier/v2-types";
import { Icon } from "~/components/molecules";
import type { SideProps } from "../particles";
import type { ComponentProps } from "react";

export const image = (value: IImage): SideProps => ({
  purpose: "icon",
  options: {
    purpose: "image",
    size: 20,
    value,
  },
});

export const loader = (): SideProps => ({
  purpose: "icon",
  options: {
    color: "orange",
    purpose: "spinner",
    size: 22,
  },
});

export const lock = (): SideProps => ({
  purpose: "icon",
  options: {
    purpose: "heroicon",
    size: 20,
    value: LockClosedIcon,
  },
});

export const search = (): SideProps => ({
  purpose: "icon",
  options: {
    purpose: "heroicon",
    size: 20,
    value: MagnifyingGlassIcon,
  },
});

export const clear = (onClear?: () => void): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    isMini: true,
    onClick: onClear,
    leftPurpose: "heroicon",
    left: XMarkIcon,
    title: "",
  },
});

export const change = (onClick?: () => void): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    isMini: true,
    onClick,
    title: "Change",
  },
});

export const addIcon = (): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    purpose: "external",
    appearance: "solid",
    left: PlusIcon,
    isMini: true,
    title: "Icon",
    titleShort: "Icon",
    to: links.github.communityList,
  },
});

export const choose = (onClick?: () => void): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    isMini: true,
    onClick,
    title: "Choose",
  },
});

export const add = (
  onClick?: () => void,
  title = "Add",
  titleShort = "",
  isDisabled = false,
): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    left: PlusIcon,
    isMini: true,
    isDisabled,
    onClick,
    title,
    titleShort,
  },
});

export const prefill = (
  onClick?: () => void,
  title = "Prefill",
  isDisabled = false,
): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    left: ArrowUpTrayIcon,
    isMini: true,
    isDisabled,
    onClick,
    title,
    titleShort: "",
  },
});

export const resolution = (
  title?: string,
  right?: ComponentProps<typeof Icon>["value"],
): SideProps => ({
  purpose: "button",
  options: {
    accent: "dark900",
    appearance: "transparent",
    isMini: true,
    isPreview: true,
    right,
    title,
  },
});

export const resolutionLock = (title?: string): SideProps =>
  resolution(title, LockClosedIcon);

export const max = (title?: string, onMax?: () => void): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    isMini: true,
    onClick: onMax,
    leftPurpose: "heroicon",
    title,
    titleShort: title?.includes("Max") ? "Max" : title,
  },
});

export const suggestion = (
  title?: string,
  onSuggest?: () => void,
): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    isMini: true,
    onClick: onSuggest,
    leftPurpose: "heroicon",
    title,
  },
});

export const token = (
  symbol?: string,
  image?: IImage,
  size?: number | [number, number],
): SideProps => ({
  purpose: "token",
  options: {
    image,
    symbol,
    isFallbackShown: !!symbol,
    size,
  },
});

export const plus = (onPlus?: () => void, isChevron = false): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    isMini: true,
    onClick: onPlus,
    leftPurpose: "heroicon",
    left: isChevron ? ChevronRightIcon : PlusIcon,
    title: "",
  },
});

export const minus = (onMinus?: () => void, isChevron = false): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    isMini: true,
    onClick: onMinus,
    leftPurpose: "heroicon",
    left: isChevron ? ChevronLeftIcon : MinusIcon,
    title: "",
  },
});

export const clock = (): SideProps => ({
  purpose: "icon",
  options: {
    purpose: "heroicon",
    size: 20,
    value: ClockIcon,
  },
});

export const slash = (): SideProps => ({
  purpose: "icon",
  options: {
    purpose: "heroicon",
    size: 20,
    value: SlashIcon,
  },
});

export const arrowDown = (onClick?: () => void): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    onClick,
    isMini: true,
    leftPurpose: "heroicon",
    left: ChevronDownIcon,
  },
});

export const calendar = (): SideProps => ({
  purpose: "icon",
  options: {
    purpose: "heroicon",
    size: 20,
    value: CalendarIcon,
  },
});

export const unlock = (): SideProps => ({
  purpose: "icon",
  options: {
    purpose: "heroicon",
    size: 20,
    value: LockOpenIcon,
  },
});

export const flip = (
  title?: string,
  onClick?: () => void,
  titleShort?: string,
): SideProps => ({
  purpose: "button",
  options: {
    accent: "input",
    appearance: "solid",
    isMini: true,
    left: ArrowsRightLeftIcon,
    onClick,
    title: title ?? "",
    titleShort: titleShort ?? "",
  },
});

export const chain = (
  name?: string,
  image?: IImage,
  size?: number | [number, number],
): SideProps => ({
  purpose: "chain",
  options: {
    image,
    name,
    size,
  },
});

export const file = (): SideProps => ({
  purpose: "icon",
  options: {
    value: DocumentIcon,
  },
});
