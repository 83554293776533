/** Keep this import specific because the build tsconfig doesn't have access to paths. The direct import circumvents unsupported loaders for cypress. */
import chains from "@sablier/v2-constants/src/chains";
import { _ } from "@sablier/v2-mixins";

/** Keep this import specific because the build tsconfig doesn't have access to paths. The direct import circumvents unsupported loaders for cypress.  */
import vendors from "@sablier/v2-utils/src/vendors";
import { GraphQLClient } from "graphql-request";
import type { Translate } from "@sablier/v2-locales";
import type { IEnvironmentVariable, ISubgraph } from "@sablier/v2-types";
import type { RequestOptions, Variables } from "graphql-request";

/** This is the only practical way of importing `VariablesAndRequestHeadersArgs` */
import type { VariablesAndRequestHeadersArgs } from "graphql-request/build/esm/types";
import middleware, {
  SUBGRAPH_HEADER_VENDOR,
  resultMiddleware as result,
} from "./middleware";

const JUMP = "Missing entity on primary query. Moving onto secondary query.";

const SUBGRAPH_API_KEY = (process.env.NEXT_PUBLIC_SUBGRAPH_API_KEY ??
  "") satisfies IEnvironmentVariable;

type EndpointProps = {
  chainId?: (typeof chains)[string]["chainId"];
  feature?: "protocol" | "airstream" | "flow";
};

type EndpointResult = {
  primary: ISubgraph | undefined;
  secondary?: ISubgraph | undefined;
};

export function getEndpointByChain({
  chainId,
  feature = "protocol",
}: EndpointProps): EndpointResult {
  if (!_.isNilOrEmptyString(chainId)) {
    const chain = Object.values(chains).find(
      (chain) => chain.chainId === chainId,
    );

    if (_.isNil(chain)) {
      return { primary: undefined, secondary: undefined };
    }

    const pair = chain.subgraph[feature];
    if (_.isNilOrEmptyString(pair.secondary)) {
      return {
        primary: chain.subgraph[feature].primary,
        secondary: chain.subgraph[feature].primary,
      };
    }
    return pair;
  }

  return { primary: undefined, secondary: undefined };
}

export async function request<T, V extends Record<string, unknown> = Variables>(
  endpoint: EndpointResult,
  document: RequestOptions<V, T>["document"],
  variables: RequestOptions<V, T>["variables"],
  requestHeaders?: RequestOptions<V, T>["requestHeaders"],
  jump?: (result: Awaited<T>) => boolean,
) {
  const primary =
    typeof endpoint.primary === "object"
      ? endpoint.primary
      : { url: endpoint.primary, vendor: "" };

  const secondary =
    typeof endpoint.secondary === "object"
      ? endpoint.secondary
      : { url: endpoint.secondary, vendor: "" };

  try {
    if (_.isNilOrEmptyString(primary.url?.(SUBGRAPH_API_KEY))) {
      throw new Error(
        "Missing primary subgraph. This may have been intended if connecting to a chain with restricted access.",
      );
    }

    /** Attempt a first request, using the primary endpoint */

    const options = [
      variables,
      { ...requestHeaders, [SUBGRAPH_HEADER_VENDOR]: primary.vendor },
    ] as unknown as VariablesAndRequestHeadersArgs<V>;

    const client_primary = new GraphQLClient(
      primary.url?.(SUBGRAPH_API_KEY) ?? "",
      middleware,
    );

    const raw = await client_primary.request(document, ...options);
    const awaited = await result(raw);

    /**
     * Even if the first request doesn't fail, it might be slow to index updates.
     * If there's a jump clause, try another query with the secondary channel if the condition is met.
     */

    if (jump?.(awaited)) {
      throw new Error(JUMP);
    }

    return awaited;
  } catch (error) {
    if ((error as Error)?.message !== JUMP) {
      vendors.crash.log(error);
    }

    if (_.isNilOrEmptyString(secondary.url?.(SUBGRAPH_API_KEY) ?? "")) {
      throw error;
    }
  }
  /** Attempt a second request, using the secondary endpoint */

  const options = [
    variables,
    { ...requestHeaders, [SUBGRAPH_HEADER_VENDOR]: secondary.vendor },
  ] as unknown as VariablesAndRequestHeadersArgs<V>;

  const client_secondary = new GraphQLClient(
    secondary.url?.(SUBGRAPH_API_KEY) ?? "",
    middleware,
  );
  const raw = await client_secondary.request(document, ...options);
  return await result(raw);
}

export function issue(
  error: Error | undefined | unknown,
  t: Translate,
): string | undefined {
  if (!_.isNilOrEmptyString(error)) {
    const status = _.get(error, "response.status");
    const message = _.get(error, "response.error");
    if (!_.isNilOrEmptyString(message)) {
      if (
        status === 200 &&
        _.toString(message).includes("service is overloaded")
      ) {
        return t("errors.subgraphOverloaded");
      }

      if (_.toString(message).includes("Invalid subgraph name")) {
        return t("errors.subgraphInvalidName");
      }

      return `${_.capitalize(t("structs.subgraphIssue"))}: ${_.toString(
        message,
      )}`;
    }
  }
  return undefined;
}
