import { _ } from "@sablier/v2-mixins";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import type { IStoreSession } from "~/client/types";
import { PERSIST_ID } from "../constants";

const initial: Omit<IStoreSession, "api"> = {
  isPolling: true,
  layout: undefined,
};

const useSessionStore = create<IStoreSession>()(
  devtools(
    persist(
      (set) => ({
        ...initial,
        api: {
          setLayout: (value: IStoreSession["layout"]) =>
            set((_prev) => {
              return { layout: value };
            }),
          setPolling: (value: boolean) =>
            set((_prev) => {
              return { isPolling: value };
            }),
          reset: (omit?: (keyof IStoreSession)[]) =>
            set(() => _.omit(initial, omit || [])),
        },
      }),
      {
        name: PERSIST_ID.session,
        partialize: (_state) => ({}),
      },
    ),
  ),
);

export { useSessionStore };
