import { LockupShape, StreamFlavor, ZERO } from "@sablier/v2-constants";
import {
  LockupCategory,
  LockupStatus,
  LockupVersion,
} from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type {
  IAddress,
  IMilliseconds,
  ISeconds,
  IValue,
} from "@sablier/v2-types";
import Action from "../LockupAction";
import Segment from "../Segment";
import Token from "../Token";
import Tranche from "../Tranche";
import Base, { Params as BaseParams } from "../abstract/Stream";

export type Params = BaseParams<"Lockup"> & {
  cancelable: boolean;
  canceled: boolean;
  canceledTime?: ISeconds;
  cliff?: boolean;
  cliffAmount?: string;
  cliffTime?: ISeconds;
  depositAmount: string;
  endTime: ISeconds;
  funder: IAddress | undefined;
  intactAmount: string;
  proxender?: string;
  proxied: boolean;
  renounceTime?: ISeconds;
  startTime: ISeconds;
  withdrawnAmount: string;

  segments: {
    id: string;
    position: string;
    /** --------------- */
    amount: string;
    exponent: string;
    milestone: ISeconds;
    /** --------------- */
    endAmount: string;
    endTime: ISeconds;
    startAmount: string;
    startTime: ISeconds;
  }[];
  tranches?: {
    id: string;
    position: string;
    /** --------------- */
    amount: string;
    timestamp: ISeconds;
    /** --------------- */
    endAmount: string;
    endTime: ISeconds;
    startAmount: string;
    startTime: ISeconds;
  }[];
};

export default abstract class Attributes extends Base<"Lockup"> {
  /**
   * -------------------------------
   * ------- Native Attributes -------
   * -------------------------------
   */

  readonly funder: IAddress;
  readonly payload: Params;
  readonly flavor = StreamFlavor.Lockup;

  /** ---- Amounts ---- */

  readonly cliffAmount: IValue;
  readonly depositAmount: IValue;
  readonly intactAmount: IValue;
  readonly withdrawnAmount: IValue;

  /** ---- Times ---- */

  readonly canceledTime: IMilliseconds;
  readonly cliffTime: IMilliseconds;
  readonly endTime: IMilliseconds;
  readonly renounceTime: IMilliseconds;
  readonly startTime: IMilliseconds;

  readonly cliffDuration: IMilliseconds;
  readonly duration: IMilliseconds;

  /** ---- Percentages ---- */

  readonly cliffPercentage: BigNumber;
  readonly intactAmountPercentage: BigNumber;
  readonly withdrawnAmountPercentage: BigNumber;

  /** ---- Flags ---- */

  readonly cliff: boolean;
  readonly isCancelable: boolean;
  readonly isCanceled: boolean;
  readonly isTransferable: boolean;
  readonly wasCancelable: boolean;

  /** ---- Proxy (V2.0) ---- */

  readonly proxied: boolean;
  readonly proxender: IAddress | undefined;

  /** ---- Components ---- */

  public actions: Action[] = [];
  public segments: Segment[] = [];
  public tranches: Tranche[] = [];

  /**
   * ------------------------------------------------
   * -------------- Derived Attributes --------------
   * ------------------------------------------------
   * These attributes will be kept fresh by doUpdate()
   * or can suffer changes after the initial binding.
   * ------------------------------------------------
   */

  private _shape?: LockupShape;

  protected _returnableAmount: IValue;
  protected _returnableAmountPercentage: BigNumber;

  protected _status: LockupStatus;
  protected _streamedAmount: IValue;
  protected _streamedAmountPercentage: BigNumber;
  protected _streamedAmountEstimate: IValue;
  protected _streamedAmountEstimatePercentage: BigNumber;
  protected _streamedDuration: IMilliseconds;
  protected _streamedDurationPercentage: BigNumber;
  protected _timeSinceCliff: IMilliseconds;
  protected _timeSinceEnd: IMilliseconds;
  protected _withdrawableAmount: IValue;
  protected _withdrawableAmountPercentage: BigNumber;

  constructor(params: Params, token: Token) {
    super(params, token);

    this.payload = params;
    this.funder = _.toAddress(params.funder);
    this.category = this.category || LockupCategory.LOCKUP_LINEAR;
    this.version = this.version || LockupVersion.V22;

    /** ---- Amounts ---- */

    this.cliffAmount = _.toValue({
      decimals: token.decimals,
      raw: params.cliffAmount,
    });
    this.depositAmount = _.toValue({
      decimals: token.decimals,
      raw: params.depositAmount,
    });
    this.intactAmount = _.toValue({
      decimals: token.decimals,
      raw: params.intactAmount,
    });
    this.withdrawnAmount = _.toValue({
      decimals: token.decimals,
      raw: params.withdrawnAmount,
    });

    /** ---- Times ---- */

    this.canceledTime = _.toMilliseconds(params.canceledTime);
    this.cliffTime = _.toMilliseconds(params.cliffTime);
    this.endTime = _.toMilliseconds(params.endTime);
    this.renounceTime = _.toMilliseconds(params.renounceTime);
    this.startTime = _.toMilliseconds(params.startTime);

    if (this.startTime === this.endTime) {
      this.endTime = new BigNumber(this.startTime).plus(1).toString();
    }

    this.duration = new BigNumber(this.endTime)
      .minus(new BigNumber(this.startTime))
      .toString();

    this.cliffDuration = new BigNumber(this.cliffTime)
      .minus(new BigNumber(this.startTime))
      .toString();

    /** ---- Percentages ---- */

    this.cliffPercentage = new BigNumber(this.cliffDuration)
      .times(100)
      .dividedBy(this.duration);

    this.withdrawnAmountPercentage = this.withdrawnAmount.raw
      .dividedBy(this.depositAmount.raw)
      .times(new BigNumber(100));
    this.intactAmountPercentage = this.intactAmount.raw
      .dividedBy(this.depositAmount.raw)
      .times(new BigNumber(100));

    /** ---- Flags ---- */

    this.cliff = !!params.cliff;
    this.isCancelable = params.cancelable;
    this.isCanceled = params.canceled;
    this.isTransferable = params.transferable;
    this.wasCancelable = params.canceled || params.cancelable;

    /** ---- Proxy (V2.0) ---- */

    this.proxied = params.proxied;
    this.proxender = !_.isNilOrEmptyString(params.proxender)
      ? _.toAddress(params.proxender)
      : undefined;

    /** ---- Components ---- */

    this.segments = [
      ...params.segments.map((data) => new Segment(data, token)),
    ].sort((a, b) => new BigNumber(a.startTime).minus(b.startTime).toNumber());

    this.tranches = [
      ...(params.tranches || []).map((data) => new Tranche(data, token)),
    ].sort((a, b) => new BigNumber(a.startTime).minus(b.startTime).toNumber());

    /** ---- Derived ---- */

    const zero = ZERO(this.token.decimals);

    this._returnableAmount = zero;
    this._returnableAmountPercentage = zero.raw;
    this._streamedAmount = zero;
    this._streamedAmountPercentage = zero.raw;
    this._streamedAmountEstimate = zero;
    this._streamedAmountEstimatePercentage = zero.raw;
    this._streamedDuration = zero.raw.toString();
    this._streamedDurationPercentage = zero.raw;
    this._timeSinceCliff = zero.raw.toString();
    this._timeSinceEnd = zero.raw.toString();
    this._withdrawableAmount = zero;
    this._withdrawableAmountPercentage = zero.raw;
    this._status = LockupStatus.STREAMING;

    if (this.category === LockupCategory.LOCKUP_TRANCHED) {
      /**
       * For compatibility across the app, tranched streams will receive fake "segments"
       * stemming from the tranches (horizontal cliff segment, vertical unlock segment)
       */

      this.segments = this.tranches
        .map((tranche) => tranche.toSegments())
        .flat();
    }
  }

  get returnableAmount() {
    return this._returnableAmount;
  }

  get returnableAmountPercentage() {
    return this._returnableAmountPercentage;
  }

  get shape() {
    return this._shape;
  }

  set shape(type: LockupShape | undefined) {
    this._shape = type;
  }

  get status() {
    return this._status;
  }

  get streamedAmount() {
    return this._streamedAmount;
  }

  get streamedAmountPercentage() {
    return this._streamedAmountPercentage;
  }

  get streamedAmountEstimate() {
    return this._streamedAmountEstimate;
  }

  get streamedAmountEstimatePercentage() {
    return this._streamedAmountEstimatePercentage;
  }

  get streamedDuration() {
    return this._streamedDuration;
  }

  get streamedDurationPercentage() {
    return this._streamedDurationPercentage;
  }

  get timeSinceCliff() {
    return this._timeSinceCliff;
  }

  get timeSinceEnd() {
    return this._timeSinceEnd;
  }

  get withdrawableAmount() {
    return this._withdrawableAmount;
  }

  get withdrawableAmountPercentage() {
    return this._withdrawableAmountPercentage;
  }

  get isDepleted() {
    return (
      this.status === LockupStatus.DEPLETED_CANCELED ||
      this.status === LockupStatus.DEPLETED_SETTLED
    );
  }

  get isAlive() {
    return (
      this.status === LockupStatus.PENDING ||
      this.status === LockupStatus.STREAMING
    );
  }

  get isCliffing() {
    return (
      this.status === LockupStatus.STREAMING &&
      this.cliff &&
      this.category === LockupCategory.LOCKUP_LINEAR &&
      this.streamedAmountPercentage.isLessThanOrEqualTo(this.cliffPercentage)
    );
  }

  get isSettled() {
    return (
      this.status === LockupStatus.SETTLED ||
      this.status === LockupStatus.DEPLETED_SETTLED
    );
  }
}
