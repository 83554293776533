import { MAX_GROUP_STREAM_SIZE } from "@sablier/v2-constants";
import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import type { IStoreSelector, IToken } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";
import type { IFlowInterval } from "~/client/types";

export interface IFlow {
  id: string;
  address: {
    id: string;
    isFocused: boolean;
    isLoading: boolean;
    resolution?: {
      address?: string;
      ens?: string;
      preview?: string;
      short?: string;
    };
    value?: string;
    warning?: string;
  };
  initialDeposit: {
    id: string;
    isLoading: boolean;
    value?: string;
    warning?: string;
  };
  rate: {
    id: string;
    isLoading: boolean;
    value?: string;
    warning?: string;
  };
  rateInterval: {
    id: string;
    value: IFlowInterval;
  };
  max: {
    id: string;
    value?: string;
  };
}

function createFlow(id: string): IFlow {
  return {
    id,
    address: {
      id: `address`,
      isFocused: false,
      isLoading: false,
      resolution: undefined,
      value: undefined,
      warning: undefined,
    },
    initialDeposit: {
      id: `initialDeposit`,
      isLoading: false,
      value: undefined,
      warning: undefined,
    },
    rate: {
      id: `rate`,
      isLoading: false,
      value: undefined,
      warning: undefined,
    },
    rateInterval: {
      id: `interval`,
      value: "month",
    },
    max: {
      id: `max`,
      value: undefined,
    },
  };
}

export interface IForm {
  transferability: {
    id: string;
    value: boolean;
    warning?: string;
  };
  token: {
    id: string;
    value?: IToken;
    warning?: string;
  };
  max: {
    id: string;
    value?: string;
  };
  file: {
    id: string;
    isLoading: boolean;
    /** Pagination for the recipients preview table */
    preview: number;
    /** Status of the underlying file-to-backend interaction */
    status: "missing" | "uploaded" | "successful" | "failed";
    value: File | undefined;
    warning: string | undefined;
  };
  streams: {
    id: string;
    value: IFlow[];
  };
}

const initial: IForm = {
  transferability: {
    id: "transferability",
    value: true,
    warning: undefined,
  },
  token: {
    id: "token",
    value: undefined,
    warning: undefined,
  },
  max: {
    id: "max",
    value: undefined,
  },
  streams: {
    id: "streams",
    value: [createFlow("0")],
  },
  file: {
    id: "file",
    isLoading: false,
    preview: MAX_GROUP_STREAM_SIZE,
    status: "missing",
    value: undefined,
    warning: undefined,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor() {
  return useFormContext<IForm>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

function useFactory() {
  return { createFlow };
}

export { useAccessor, useField, useForm, useFactory };

export default Provider;
