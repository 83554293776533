import { BigNumber, _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import type { IToken } from "@sablier/v2-types";
import { C } from "./atoms";

export const faucet = {
  title: (t: Translate) => t("machines.faucet.title"),
  confirm: (t: Translate) => ({
    description: t("machines.faucet.confirm"),
  }),
  fail: (t: Translate) => ({
    description: t("machines.faucet.fail"),
  }),
  success: (t: Translate, amount: string) => ({
    description: t("machines.faucet.success", { amount }),
  }),
  send: (t: Translate, confirm = false, amount = "0") => ({
    description: t("machines.faucet.send", {
      amount,
      confirm: C(t, confirm),
    }),
  }),
  prepare: (token: IToken, value: BigNumber | string) => ({
    amount: `${token.symbol} ${_.toNumeralPrice(
      _.toValue({ raw: value, decimals: token.decimals }).humanized,
    )}`,
  }),
} as const;
