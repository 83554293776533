import { DEFAULT_RESET_SLEEP, LockupVersion } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { zeroAddress } from "viem";
import { isHostSafe } from "~/client/contexts/Web3";
import { toast } from "~/client/hooks/useToast";
import { peripheral } from "~/client/utils";
import type { useMachineForm } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { ILockup } from "@sablier/v2-models";
import type {
  IAddress,
  ISigner,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import type { useModalTransaction } from "~/client/hooks/modals";
import { IForm } from "../../contexts/Form/Lockup/WithdrawMultiple";
import helper from "../helper";
import { lockupWithdrawMultiple as wording } from "../helper/wording";

export interface Check {
  signer: ISigner | undefined;
  streams: ILockup[];
  proxy: IAddress | undefined;
  api: {
    t: Translate;
  };
}
export interface Create extends Check {
  fields: IForm;
  signer: ISigner | undefined;
  streams: ILockup[];
  library: IWagmiConfig | undefined;
  proxy: IAddress | undefined;
  api: {
    reset: () => void;
    t: Translate;
    setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
    updateData: ReturnType<typeof useModalTransaction>["updateData"];
  };
}

export interface Result {
  message?: string;
}

type Machine = Parameters<typeof useMachineForm<Check, Create, Result>>;

type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];
type onProcess = Parameters<Machine["0"]["onProcess"]>["0"];
type onValidate = Parameters<Machine["0"]["onValidate"]>["0"];

export async function onCheck({ event }: onCheck): Promise<void> {
  const { proxy, signer, streams } = event.payload;
  const { t } = event.payload.api;

  if (_.isNil(signer)) {
    throw new Error(policy.signer.missing(t));
  }

  const address = _.toAddress(signer.account?.address);
  const chainId = signer.chain!.id;
  const stream = streams[0];
  const actor = helper.identify({ address, proxy, stream });

  const guard = guards.validateLockupsWithdrawable({
    actor,
    t,
    chainId,
    /** This action is enabled for all types of owners: proxies, native sender/recipient or public */
    owner: actor === "sender-proxy" ? proxy : address,
    streams,
    isHostSafe,
  });

  if (guard) {
    throw new Error(guard);
  }
}

export async function onValidate({ context }: onValidate): Promise<undefined> {
  const { api, proxy, library, signer, streams } = context.payload;
  const { t } = api;

  api.setOpen(true, {
    status: "verify",
    title: wording.title(t),
    description: wording.confirm(t).description,
    isNotClosable: true,
  });

  try {
    await onCheck({ event: context });

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const stream = streams[0];
    const address = _.toAddress(signer.account?.address);
    const chainId = stream.chainId;

    const actor = helper.identify({ address, stream, proxy });

    await guards.validateInputs(
      library,
      t,
      [
        {
          purpose: "signer",
          options: {
            expected:
              actor === "recipient"
                ? [stream.recipient]
                : actor === "public"
                ? [address] /** LockupVersion.V22 enabled public withdrawals */
                : [stream.sender, stream.proxender || zeroAddress],
            chainId: streams[0].chainId,
            value: signer,
          },
        },
        {
          purpose: "screening",
          options: {
            chainId,
            addresses: [
              signer.account!.address,
              stream.sender,
              stream.recipient,
            ],
          },
        },
      ],
      chainId,
      { toast },
    );
  } catch (error) {
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: _.toString(error),
        data: error,
      },
      isNotClosable: false,
    });
    throw error;
  }
  return undefined;
}

/**
 *  Machine state that actually triggers the transaction.
 *  It relies on defined, pre-validated values checked within the `onValidate` step.
 */

export async function onProcess({ context }: onProcess): Promise<void> {
  const { api, fields, library, proxy, streams, signer } = context.payload;
  const { t } = api;

  let query: ReturnType<typeof framework.contextualize> | undefined = undefined;

  try {
    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = streams[0].chainId;

    api.updateData({
      status: "confirm",
      description: wording.send(t, true).description,
      isNotClosable: true,
    });

    const onContextualizeStreams = async (streams: ILockup[]) => {
      const stream = streams[0];
      const address = _.toAddress(signer.account?.address);
      const chainId = stream.chainId;
      const recipient = stream.recipient;
      const lockup = stream.contract;
      const amounts = fields.amounts.value;

      const target = peripheral(chainId, "targetApprove").address;
      const actor = helper.identify({ address, stream, proxy });

      return await (async () => {
        if (actor === "sender-proxy") {
          const data = framework.contextualize(
            target,
            chainId,
            "targetApprove",
            "withdrawMultiple",
            [
              lockup as IWagmiAddress,
              streams.map((stream) => _.toBigInt(stream.tokenId)),
              recipient as IWagmiAddress,
              streams.map((stream) =>
                _.toBigInt(
                  _.toValuePrepared({
                    raw: amounts!.find((a) => a.id === stream.id)!.amount.raw,
                    decimals: stream.token.decimals,
                  }),
                ),
              ),
            ],
          );

          const calldata = await framework.encode(
            "targetApprove",
            "withdrawMultiple",
            data.inputs,
          );

          return framework.contextualize(proxy!, chainId!, "proxy", "execute", [
            target as IWagmiAddress,
            calldata,
          ]);
        } else if (
          actor === "sender-native" ||
          actor === "recipient" ||
          actor === "public"
        ) {
          // this is needed in order to ensure backwards compatibility for the withdraw multiple action
          if (
            streams[0].version === LockupVersion.V20 ||
            streams[0].version === LockupVersion.V21
          ) {
            return framework.contextualize(
              lockup,
              chainId,
              "dynamic21", // any version before v2.2 either linear or dynamic should work
              "withdrawMultiple",
              [
                streams.map((stream) => _.toBigInt(stream.tokenId)),
                recipient as IWagmiAddress,
                streams.map((stream) =>
                  _.toBigInt(
                    _.toValuePrepared({
                      raw: amounts!.find((a) => a.id === stream.id)!.amount.raw,
                      decimals: stream.token.decimals,
                    }),
                  ),
                ),
              ],
            );
          }
          return framework.contextualize(
            lockup,
            chainId,
            streams[0].category,
            "withdrawMultiple",
            [
              streams.map((stream) => _.toBigInt(stream.tokenId)),
              streams.map((stream) =>
                _.toBigInt(
                  _.toValuePrepared({
                    raw: amounts!.find((a) => a.id === stream.id)!.amount.raw,
                    decimals: stream.token.decimals,
                  }),
                ),
              ),
            ],
          );
        }
        throw new Error(policy.error.unidentified(t));
      })();
    };

    const onExecuteSafe = async () => {
      // group each stream initially based on the contract and after that on recipient
      const groupedStreams = new Map<string, Map<string, ILockup[]>>();
      streams.forEach((stream) => {
        let recipientMap = groupedStreams.get(stream.contract);
        if (!recipientMap) {
          recipientMap = new Map<string, ILockup[]>();
          groupedStreams.set(stream.contract, recipientMap);
        }

        let streamsForRecipient = recipientMap.get(stream.recipient);
        if (!streamsForRecipient) {
          streamsForRecipient = [];
          recipientMap.set(stream.recipient, streamsForRecipient);
        }

        streamsForRecipient.push(stream);
      });

      const queriesPromises = Array.from(groupedStreams).map(
        async ([_key, recipientMap]) => {
          const recipientPromises = Array.from(recipientMap).flatMap(
            ([_key, streams]) => {
              // Split streams into proxied and non-proxied
              const proxiedStreams = streams.filter((stream) => stream.proxied);
              const nativeStreams = streams.filter((stream) => !stream.proxied);

              // Create separate promises for proxied and non-proxied streams
              const promises: ReturnType<typeof onContextualizeStreams>[] = [];
              if (proxiedStreams.length > 0) {
                promises.push(onContextualizeStreams(proxiedStreams));
              }
              if (nativeStreams.length > 0) {
                promises.push(onContextualizeStreams(nativeStreams));
              }
              return promises;
            },
          );

          return Promise.all(recipientPromises);
        },
      );

      const queriesNested = await Promise.all(queriesPromises);
      const queries = queriesNested.flat();

      console.info("%c[pre-transaction] SAFE", "color: mediumslateblue", {
        queries,
      });

      const transaction = await framework.safeWrite(library, { queries });

      api.updateData({
        status: "pending",
        description: wording.send(t, false).description,
        hash: undefined,
        isNotClosable: false,
      });

      const receipt = await framework.safeWait(library, { hash: transaction });

      return { receipt, transaction };
    };

    const onExecuteWallet = async () => {
      query = await onContextualizeStreams(streams);

      const prepared = await helper.configure(library, {
        chainId,
        query,
        signer,
      });

      console.info("%c[pre-transaction]", "color: mediumslateblue", {
        query,
        prepared,
      });

      const transaction = await framework.write(library, { prepared });

      api.updateData({
        status: "pending",
        description: wording.send(t, false).description,
        hash: transaction,
        isNotClosable: false,
      });

      const receipt = await framework.wait(library, {
        hash: transaction,
        onReplaced: (replaced) => {
          api.updateData({
            hash: replaced.transaction.hash,
          });
        },
      });

      return { receipt, transaction };
    };

    const { receipt, transaction } = isHostSafe
      ? await onExecuteSafe()
      : await onExecuteWallet();

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }
    await _.sleep(DEFAULT_RESET_SLEEP);

    api.updateData({
      status: "success",
      description: wording.success(t).description,
      hash: receipt.transactionHash,
      isNotClosable: false,
    });

    api.reset();
  } catch (error) {
    void helper.debug(
      {
        query,
        signer,
      },
      vendors.crash.log(error),
    );
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: policy.error.message(t, error),
        data: error,
      },
      isNotClosable: false,
    });

    throw error;
  }
}
