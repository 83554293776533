import { useMemo } from "react";
import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
  ArrowRightIcon,
  ArrowUpCircleIcon,
  CheckIcon,
  CircleStackIcon,
  EllipsisHorizontalIcon,
  EyeSlashIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import { FlowStatus } from "@sablier/v2-constants";
import { useCopy, useWindowMediaQuery } from "@sablier/v2-hooks";
import { Translate, useT } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { client } from "@sablier/v2-subgraphs";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import numeral from "numeral";
import { pages, tabs } from "~/client/constants";
import type { Table } from "@sablier/v2-components/organisms";
import type { ISTableCell } from "@sablier/v2-components/organisms/Table";
import type { Flow } from "@sablier/v2-models";
import type { ITableRow } from "@sablier/v2-types/table";
import type { ComponentProps } from "react";
import { useSessionLayout } from "./store";
import useAccount from "./useAccount";
import useFlags from "./useFlags";
import useFlowDashboardSearch from "./useFlowDashboardSearch";
import useFlowDashboardTab from "./useFlowDashboardTab";
import useFlowsOwned from "./useFlowsOwned";
import useToast from "./useToast";
import useTokens from "./useTokens";

type Data = ComponentProps<typeof Table>["data"];
type Instructions = Data["instructions"];
type Options = Data["options"];

function findChips(stream: Flow, t: Translate): ISTableCell["Chips"] {
  const chips = stream.findChips();
  const tips = stream.findTips(t);

  const amount = (source: BigNumber) =>
    numeral(source.toFixed()).format(`0,0[.][00]a`);

  const value: ISTableCell["Chips"]["value"] = chips.map((item) => {
    switch (item) {
      case "debt": {
        return {
          left: MinusCircleIcon,
          accent: "chipRed",
          appearance: "outline",
          title: `${_.capitalize(t("words.debt"))} ${amount(
            stream.debtAmount.humanized,
          )}`,
        };
      }
      case "withdrawable": {
        return {
          accent: "chipWhite",
          appearance: "outline",
          left: ArrowDownTrayIcon,
          title: amount(stream.withdrawableAmount.humanized),
        };
      }

      case "done": {
        return {
          accent: "chipPassive",
          appearance: "outline",
          left: CheckIcon,
          title: "",
        };
      }
      case "returnable": {
        // TODO rename to refundable ?
        return {
          accent: "chipPassive",
          appearance: "outline",
          left: CircleStackIcon,
          title: amount(stream.returnableAmount.humanized),
        };
      }
      case "voided": {
        return {
          accent: "chipPassive",
          appearance: "outline",
          left: EyeSlashIcon,
          title: _.capitalize(t("words.voided")),
        };
      }
    }
  });

  const menu: ISTableCell["Chips"]["menu"] = {
    tip: tips.join(" "),
    title: _.capitalize(_.words(stream.status).join(" ")),
    items: [
      {
        label: {
          value: _.capitalize(t("words.debt")),
        },
        value: {
          accent: "red",
          appearance: "transparent",
          left: MinusCircleIcon,
          title: _.toNumeralPrice(stream.debtAmount.humanized),
        },
      },
      {
        label: {
          value: _.capitalize(t("words.withdrawable")),
        },
        value: {
          accent: "white",
          appearance: "transparent",
          left: ArrowDownTrayIcon,
          title: _.toNumeralPrice(stream.withdrawableAmount.humanized),
        },
      },
      {
        label: {
          value: _.capitalize(t("words.refundable")),
        },
        value: {
          accent: "gray400",
          appearance: "transparent",
          left: CircleStackIcon,
          title: _.toNumeralPrice(stream.returnableAmount.humanized),
        },
      },
    ],
  };

  return {
    menu,
    value,
  };
}

function useData() {
  const { t } = useT();
  const { address } = useAccount();
  const { tab } = useFlowDashboardTab();
  const owned = useFlowsOwned();
  const searched = useFlowDashboardSearch();
  const { isWaitingInitialFlows } = useFlags();
  const { layout } = useSessionLayout();

  const isSearch = useMemo(() => {
    if (_.isNil(tab)) {
      return false;
    }

    return tab.identifier === tabs.payments.search.identifier;
  }, [tab]);

  const isRecipient = useMemo(() => {
    if (_.isNil(tab)) {
      return false;
    }

    return tab.identifier === tabs.payments.recipient.identifier;
  }, [tab]);

  const isSender = useMemo(() => {
    if (_.isNil(tab)) {
      return false;
    }

    return tab.identifier === tabs.payments.sender.identifier;
  }, [tab]);

  const doMore = useMemo(() => {
    if (!_.isNil(isSearch)) {
      if (isSearch) {
        if (
          !searched.result?.options.isComplete &&
          searched.result?.streams.length
        ) {
          return searched.doMore;
        }
      } else {
        if (!owned.result?.options.isComplete && owned.result?.streams.length) {
          return owned.doMore;
        }
      }
    }

    return undefined;
  }, [isSearch, owned, searched]);

  const isLoading = useMemo(() => {
    if (isWaitingInitialFlows) {
      return true;
    }

    if (isSearch) {
      return searched.isLoading;
    }

    return owned.isLoading;
  }, [isSearch, owned, searched, isWaitingInitialFlows]);

  const isLayoutDebt = useMemo(() => layout === "debt", [layout]);

  const error = useMemo(() => {
    if (_.isNil(isSearch)) {
      return undefined;
    }

    return isSearch ? searched.error : owned.error;
  }, [isSearch, owned, searched]);

  const list = useMemo(() => {
    if (_.isNil(isSearch)) {
      return [];
    }

    const result = isSearch ? searched.result : owned.result;

    if (_.isNil(result)) {
      return [];
    }

    return result.streams;
  }, [isSearch, owned, searched]);

  const streams = useMemo(() => {
    switch (tab?.identifier) {
      case tabs.payments.all.identifier:
      case tabs.payments.search.identifier:
        return list;
      case tabs.payments.recipient.identifier:
        /**
         * Show flows where the connected account is the recipient
         */
        return list.filter((stream) => stream.recipient === address);
      case tabs.payments.sender.identifier:
        /**
         * Show flow created either through the proxy (sender is proxy)
         * or natively without one (sender is the connected account)
         */
        return list.filter((stream) => stream.sender === address);
      default:
        return [];
    }
  }, [address, list, tab]);

  const isEmpty = useMemo(
    () => !isLoading && streams.length === 0,
    [isLoading, streams],
  );

  const options = useMemo((): Options => {
    const issue = client.issue(error, t);

    const empty = (() => {
      switch (tab?.identifier) {
        case tabs.payments.recipient.identifier:
          return "vesting-recipient" as const;
        case tabs.payments.all.identifier:
        case tabs.payments.sender.identifier:
          return "vesting-sender" as const;
        default:
          return undefined;
      }
    })();

    return {
      appearance: "spaced",
      empty,
      error: issue,
    };
  }, [error, t, tab]);

  const sectioned = useMemo(() => {
    if (!isLayoutDebt || isSearch) {
      return streams;
    }

    return [...streams].sort((a, b) => {
      if (
        a.status === FlowStatus.STREAMING_DEBT ||
        a.status == FlowStatus.PAUSED_DEBT
      ) {
        if (
          b.status === FlowStatus.STREAMING_DEBT ||
          b.status == FlowStatus.PAUSED_DEBT
        ) {
          return 0;
        }

        return -1;
      } else if (
        a.status === FlowStatus.STREAMING_FUNDED ||
        a.status == FlowStatus.PAUSED_FUNDED
      ) {
        if (
          b.status === FlowStatus.STREAMING_DEBT ||
          b.status == FlowStatus.PAUSED_DEBT
        ) {
          return 1;
        }

        if (b.status === FlowStatus.VOIDED) {
          return -1;
        }
      }

      return 0;
    });
  }, [streams, isLayoutDebt, isSearch]);

  return {
    doMore,
    error,
    isAnimated: false,
    isEmpty,
    isLayoutDebt,
    isLoading,
    isSearch,
    isRecipient,
    isSender,
    options,
    streams: sectioned,
  };
}

type Cells =
  | [ISTableCell["Section"]]
  | [
      ISTableCell["TextDeckSelect"],
      ISTableCell["AddressDeck"],
      ISTableCell["AmountDeck"],
      ISTableCell["TextDeck"],
      ISTableCell["Chips"],
      ISTableCell["Actions"],
    ];

export default function useFlowDashboardTable(): Data {
  const { t } = useT();
  const toast = useToast();
  const router = useRouter();
  const { address } = useAccount();

  const {
    doMore,
    isAnimated,
    isEmpty,
    isLayoutDebt,
    isLoading,
    isSearch,
    isSender,
    isRecipient,
    options,
    streams,
  } = useData();

  const { maxMD: isMobile } = useWindowMediaQuery();
  const { find: findToken } = useTokens(false);
  const [, , doCopy] = useCopy();

  const columns = useMemo(() => {
    return [
      {
        id: "1",
        layout: "TextDeckSelect",
        title: t("words.status"),
        weight: "var(--dashboard-column-1)",
      },
      {
        id: "2",
        layout: "AddressDeck",
        title: t("structs.fromTo"),
        weight: "var(--dashboard-column-2)",
      },
      {
        id: "3",
        layout: "AmountDeck",
        title: t("structs.ratePerTotal"),
        weight: "var(--dashboard-column-3)",
      },
      {
        id: "4",
        layout: "TextDeck",
        title: t("words.timeline"),
        weight: "var(--dashboard-column-4)",
      },
      {
        id: "5",
        layout: "Chips",
        title: t("words.amounts"),
        weight: "var(--dashboard-column-5)",
      },
      {
        id: "6",
        layout: "Actions",
        title: t("words.actions"),
        weight: "var(--dashboard-column-6)",
      },
    ];
  }, [t]);

  const instructions = useMemo(() => {
    const actions: Instructions = {};

    if (_.isFunction(doMore)) {
      actions.onMore = (_event) => {
        try {
          doMore();
        } catch (error) {
          vendors.crash.log(error);
        }
      };
    }

    actions.onRowClick = (alias, _event) => {
      try {
        if (_.isWindow()) {
          if (window?.getSelection()?.toString().length) {
            return;
          }
        }
      } catch (error) {
        vendors.crash.log(error);
      }

      if (!isMobile) {
        vendors.track.log("clickStreamElement");
        void router.push(pages.payments.profile.builder(alias), undefined, {
          shallow: true,
        });
      }
    };

    return actions;
  }, [doMore, isMobile, router]);

  const rows = useMemo(
    () =>
      streams.map((stream) => {
        const token = findToken({ token: stream.token });
        const preview = stream.findPreview(t);

        const label = (() => {
          let value = _.toAlias(stream.alias) || t("words.stream");

          if (stream.isBatched && stream.batch) {
            const { label, position, size } = stream.batch;
            const suffix = `G${label} ${position + 1}/${size}`;
            value = `${_.toAlias(stream.alias)} ${suffix}`;
          }
          return {
            value,
            tooltip: {
              isMini: true,
              mouseEnterDelay: 1,
              value,
            },
          };
        })();

        const party = (() => {
          if (!isSearch) {
            if (isRecipient) {
              return {
                purpose: "recipient" as const,
                sender: stream.sender,
                chainId: stream.chainId,
                label: {
                  icon: ArrowDownCircleIcon,
                  value: _.capitalize(t("words.incoming")),
                },
              };
            }

            if (isSender) {
              return {
                purpose: "sender" as const,
                recipient: stream.recipient,
                chainId: stream.chainId,
                label: {
                  icon: ArrowUpCircleIcon,
                  value: _.capitalize(t("words.outgoing")),
                },
              };
            }

            if (stream.recipient === address) {
              return {
                purpose: "recipient" as const,
                sender: stream.sender,
                chainId: stream.chainId,
                label: {
                  icon: ArrowDownCircleIcon,
                  value: _.capitalize(t("words.incoming")),
                },
              };
            }

            /** Flow created natively, without a proxy */
            if (stream.sender === address) {
              return {
                purpose: "sender" as const,
                recipient: stream.recipient,
                chainId: stream.chainId,
                label: {
                  icon: ArrowUpCircleIcon,
                  value: _.capitalize(t("words.outgoing")),
                },
              };
            }
          }

          return {
            purpose: "public" as const,
            recipient: stream.recipient,
            sender: stream.sender,
            chainId: stream.chainId,
          };
        })();

        const color = (() => {
          if (stream.status === FlowStatus.VOIDED) {
            return "gray400";
          }
          return undefined;
        })();

        const cells: Cells = [
          {
            value: {
              content: preview.status,
              label,
              contentColor: color,
            },
          },
          {
            value: party,
          },
          {
            value: {
              label: `${_.toNumeralPrice(preview.ratePerMonth)} /month`,
              amount: {
                token,
                value: stream.streamedAmount.humanized.toString(),
              },
            },
          },
          {
            value: {
              content: preview.significantDateOnly,
              label: {
                value: _.toDuration(stream.startTime, "date-short")[0],
              },
            },
          },
          {
            ...findChips(stream, t),
          },
          {
            value: {
              buttons: [
                {
                  right: EllipsisHorizontalIcon,
                  title: "",
                  appearance: "outline",
                  accent: "iconic",
                  isMenu: true,
                  menu: [
                    {
                      group: "custom",
                      items: [
                        {
                          title: t("structs.copyForSearch"),
                          onClick: () => {
                            vendors.track.log("clickCopyIdForSearch");
                            doCopy(_.toAlias(stream.alias));
                            toast.add({
                              duration: 3500,
                              id: "identifier",
                              type: "success",
                              message: t("structs.shortCopied", {
                                id: _.toAlias(stream.alias),
                              }),
                            });
                          },
                        },
                      ],
                    },
                    {
                      group: "general",
                      items: [
                        {
                          title: t("structs.viewStream"),
                          onClick: () => {
                            vendors.track.log("clickViewStream");
                          },
                          purpose: "internal",
                          to: pages.payments.profile.builder(
                            _.toAlias(stream.alias),
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  right: ArrowRightIcon,
                  title: "",
                  appearance: "outline",
                  accent: "iconic",
                  purpose: "internal",
                  to: pages.payments.profile.builder(_.toAlias(stream.alias)),
                },
              ],
            },
          },
        ] satisfies Cells;
        return {
          id: _.toAlias(stream.alias),
          cells,
        };
      }),
    [
      streams,
      findToken,
      t,
      isSearch,
      isRecipient,
      isSender,
      address,
      doCopy,
      toast,
    ],
  );

  const sectioned = useMemo(() => {
    if (!rows.length || !isLayoutDebt) {
      return rows;
    }

    const funded = streams.findIndex(
      (stream) =>
        stream.status === FlowStatus.STREAMING_FUNDED ||
        stream.status === FlowStatus.PAUSED_FUNDED,
    );
    const debt = streams.findIndex(
      (stream) =>
        stream.status === FlowStatus.STREAMING_DEBT ||
        stream.status === FlowStatus.PAUSED_DEBT,
    );
    const voided = streams.findIndex(
      (stream) => stream.status === FlowStatus.VOIDED,
    );

    const sections: Record<"debt" | "funded" | "voided", ITableRow> = {
      debt: {
        isSection: true,
        isSelected: false,
        id: "section-debt",
        cells: [
          {
            value: {
              accent: "red",
              title: {
                value: _.startCase(t("structs.inDebt")),
              },
              description: t("structs.haveToBeVoidedOrPaidOff"),
            },
          } satisfies ISTableCell["Section"],
        ] satisfies Cells,
      },
      funded: {
        isSection: true,
        isSelected: false,
        id: "section-funded",
        cells: [
          {
            value: {
              accent: "secondaryMiddle",
              title: {
                value: _.startCase(t("words.funded")),
              },
              description: t("structs.enoughAssetsDeposited"),
            },
          } satisfies ISTableCell["Section"],
        ] satisfies Cells,
      },
      voided: {
        isSection: true,
        isSelected: false,
        id: "section-voided",
        cells: [
          {
            value: {
              accent: "gray500",
              title: {
                value: _.startCase(t("words.voided")),
              },
              description: t("words.completed"),
            },
          } satisfies ISTableCell["Section"],
        ] satisfies Cells,
      },
    };

    const list: ITableRow[] = [...rows];

    let shift = 0;

    if (debt >= 0) {
      list.splice(0, 0, sections.debt);
      shift++;
    }

    if (funded >= 0) {
      list.splice(funded + shift, 0, sections.funded);
      shift++;
    }

    if (voided >= 0) {
      list.splice(voided + shift, 0, sections.voided);
      shift++;
    }

    return list;
  }, [isLayoutDebt, rows, streams, t]);

  return {
    columns,
    instructions,
    options,
    rows: sectioned,
    isAnimated,
    isEmpty,
    isLoading,
  };
}
