import { _ } from "@sablier/v2-mixins";
import type {
  FlowActionCategory,
  LockupActionCategory,
  StreamFlavor,
} from "@sablier/v2-constants";
import type {
  IAddress,
  IMilliseconds,
  ISeconds,
  IValue,
} from "@sablier/v2-types";
import Token from "../../Token";

export type StreamActionCategory<Flavor extends StreamFlavor> =
  Flavor extends "Flow" ? FlowActionCategory : LockupActionCategory;

type Params<
  Flavor extends StreamFlavor,
  Category = StreamActionCategory<Flavor>,
> = {
  id: string;
  chainId: number;
  /* --------------- */
  addressA?: string;
  addressB?: string;
  amountA?: string;
  amountB?: string;
  category: Category | (string & {});
  from: string;
  /* --------------- */
  block?: string;
  hash?: string;
  timestamp?: ISeconds;
};

export type Preview = {
  timestamp?: string;
};

export default abstract class StreamAction<
  Flavor extends StreamFlavor,
  Category = StreamActionCategory<Flavor>,
> {
  readonly id: string;
  readonly chainId: number;
  /* --------------- */
  readonly addressA?: IAddress;
  readonly addressB?: IAddress;
  readonly amountA: IValue;
  readonly amountB: IValue;
  readonly category: Category;
  readonly from: IAddress;
  readonly token: Token;
  /* --------------- */
  block?: string;
  hash?: string;
  timestamp?: IMilliseconds;

  constructor(params: Params<Flavor>, token: Token) {
    this.id = params.id;
    this.chainId = params.chainId;
    this.token = token;
    /* --------------- */
    this.addressA = params.addressA;
    this.addressB = params.addressB;
    this.amountA = _.toValue({
      decimals: token.decimals,
      raw: params.amountA,
    });
    this.amountB = _.toValue({
      decimals: token.decimals,
      raw: params.amountB,
    });
    this.category = _.capitalize(params.category) as Category;
    this.from = params.from;
    /* --------------- */

    this.block = params.block;
    this.hash = params.hash;
    this.timestamp = _.toMilliseconds(params.timestamp);
  }
}
