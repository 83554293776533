import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import useFlowDashboardQuery from "./useFlowDashboardQuery";
import useFlowSearch from "./useFlowSearch";

export default function useFlowDashboardSearch() {
  const { filter, isReady, isFallback } = useFlowDashboardQuery();

  const { doMore, error, isLoading, result } = useFlowSearch(
    filter,
    isReady && !isFallback,
  );

  return useMemo(
    () => ({
      doMore,
      error,
      isLoading,
      result,
    }),
    [doMore, error, isLoading, result],
  );
}
