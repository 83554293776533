import { parseToRgb, rgbToColorString } from "polished";
import type { RgbaColor } from "polished/lib/types/color";
import type { MouseEvent } from "react";

export function formatRgbaToRgb(rgbaColor: string, bgColor = "#fff") {
  const color = parseToRgb(rgbaColor) as RgbaColor;
  const bg = parseToRgb(bgColor);

  const flattenedColor = {
    red: Math.round(color.alpha * color.red + (1 - color.alpha) * bg.red),
    green: Math.round(color.alpha * color.green + (1 - color.alpha) * bg.green),
    blue: Math.round(color.alpha * color.blue + (1 - color.alpha) * bg.blue),
  };

  return rgbToColorString(flattenedColor);
}

/**
 *
 * @hack
 *
 * Parents with onClick listeners will be triggered by children with onClick events.
 * For example, an onClick tooltip in the table actions will trigger the table row's onClick.
 * To prevent this, we will stop propagating the event towards the parent.
 *
 * Unfortunately, tooltips require a global event for them to close.
 * For that we're adding a fake click to make sure all tooltips receive the signal.
 *
 * Question: why doesn't the current tooltip (that just opened) get this and close as well?
 */
export function depropagate(e: MouseEvent) {
  e.stopPropagation();
  document.body.click();
}
