import { LockupShapes, links } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type {
  IExtensionConstructorParams,
  IExtensionHeaders,
} from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import { Extension } from "../../system/setup";

export interface IDynamicCliffExponential {
  purpose: Extension;
  cliffDuration: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  cliffEnd: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  exponent: string;
}

export function initial({
  cliffDuration,
  cliffEnd,
}: IExtensionConstructorParams): IDynamicCliffExponential {
  return {
    purpose: Extension.DYNAMIC_CLIFF_EXPONENTIAL,
    cliffDuration: {
      id: "cliffDuration",
      value: cliffDuration,
      warning: undefined,
    },
    cliffEnd: {
      id: "cliffEnd",
      value: cliffEnd,
      warning: undefined,
    },
    exponent: "3",
  };
}

export const UNLOCK_DURATION: IMilliseconds = "1000";
export const EXPECTED_SEGMENTS = 3;

export function headers(
  isDuration: boolean,
): IExtensionHeaders<keyof IDynamicCliffExponential> {
  return isDuration
    ? [
        {
          header: "cliffDuration",
          type: "duration",
        },
      ]
    : [{ header: "cliffEnd", type: "date" }];
}

export function warnings(extension: unknown): string[] {
  const fields = extension as IDynamicCliffExponential;
  const warnings: string[] = [];
  if (!_.isNilOrEmptyString(fields.cliffDuration.warning)) {
    warnings.push(`Cliff duration: ${fields.cliffDuration.warning}`);
  }
  if (!_.isNilOrEmptyString(fields.cliffEnd.warning)) {
    warnings.push(`Cliff end: ${fields.cliffEnd.warning}`);
  }
  return warnings;
}

export const shape = LockupShapes.dynamicCliffExponential.id;
export const template = links.streamTemplate.dynamicCliffExponential;
