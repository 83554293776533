import { LockupStatus, LockupVersion, chains } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Lockup } from "@sablier/v2-models";
import type { Translate } from "@sablier/v2-locales";
import type {
  IActor,
  IAddress,
  IStreamAlias,
  IStreamId,
} from "@sablier/v2-types";
import policy from "./policy";

interface StreamIdProps {
  t: Translate;
  value: IStreamId | undefined;
}

export function validateStreamId({
  t,
  value,
}: StreamIdProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.stream.missing(t);
    }

    if (!Lockup.isId(value)) {
      return policy.stream.invalid(t);
    }
  } catch (error) {
    return policy.stream.invalid(t);
  }

  return undefined;
}

interface StreamAliasProps {
  t: Translate;
  value: IStreamAlias | undefined;
  supported?: { alias: string; address: string }[];
}

export function validateStreamAlias({
  t,
  value,
  supported,
}: StreamAliasProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.stream.missing(t);
    }

    if (!Lockup.isAlias(value, supported)) {
      return policy.stream.invalid(t);
    }

    if (!_.isNil(supported)) {
      const { source: alias } = Lockup.doSplitIdentifier(value);
      if (!supported.find((item) => item.alias.toLowerCase() === alias)) {
        return policy.stream.supported(t);
      }
    }
  } catch (error) {
    return policy.stream.invalid(t);
  }

  return undefined;
}

export function validateStreamIdentifier({
  t,
  value,
  supported,
  chainId,
}: StreamIdProps & StreamAliasProps & { chainId?: number }):
  | string
  | undefined {
  const id = validateStreamId({ t, value });

  if (!_.isNil(id)) {
    const alias = validateStreamAlias({ t, value, supported });

    if (!_.isNil(alias)) {
      return alias;
    }
  }

  if (
    !_.isNilOrEmptyString(value) &&
    !_.isNilOrEmptyString(supported) &&
    !_.isNilOrEmptyString(chainId)
  ) {
    return validateStreamChain({
      t,
      id: Lockup.doIdentify(value, supported).withAddress,
      chainId,
    });
  }

  return undefined;
}

interface StreamChainProps {
  t: Translate;
  id: IStreamId | IStreamAlias | undefined;
  chainId: number | undefined;
}

export function validateStreamChain({ t, id, chainId }: StreamChainProps) {
  const validateId = validateStreamId({ t, value: id });
  if (!_.isNilOrEmptyString(validateId)) {
    return validateId;
  }

  const parts = Lockup.doSplitIdentifier(id);
  if (parts.chainId !== chainId) {
    return policy.stream.chain(
      t,
      chainId ? chains[chainId]?.name : undefined,
      parts.chainId ? _.toString(parts.chainId) : undefined,
    );
  }

  return undefined;
}

interface LockupsCancelableProps {
  actor: IActor;
  t: Translate;
  chainId: number | undefined;
  owner: IAddress | undefined;
  streams: Lockup[];
  isHostSafe: boolean;
}

export function validateLockupsCancelable({
  actor,
  t,
  chainId,
  owner,
  streams,
  isHostSafe,
}: LockupsCancelableProps) {
  if (
    _.isNilOrEmptyString(owner) ||
    _.isNilOrEmptyString(chainId) ||
    _.isNil(streams) ||
    !streams.length
  ) {
    return policy.stream.setMisconfigured(t);
  }

  if (streams.some((stream) => stream.chainId !== chainId)) {
    return policy.stream.setUnchained(t);
  }

  if (
    streams.some(
      (stream) =>
        ![stream.sender, stream.proxender]
          .filter((address) => !_.isNilOrEmptyString(address))
          .includes(owner),
    )
  ) {
    return policy.stream.setPermission(t);
  }

  if (!isHostSafe) {
    /** This will automatically help differentiate between lockup categories and versions and should be place above the sameCategory verification*/
    const { address: anchor } = Lockup.doIdentify(streams[0].id, []);
    if (streams.some((stream) => anchor !== _.toAddress(stream.contract))) {
      return policy.stream.setSource(t);
    }
  }

  if (
    (actor === "sender-proxy" || actor === "sender-native") &&
    streams.some((stream) => _.toAddress(stream.sender) !== _.toAddress(owner))
  ) {
    return policy.stream.sameCategory(t);
  }

  if (streams.some((stream) => !stream.isCancelable)) {
    return policy.stream.setNonCancelable(t);
  }

  if (
    streams.some(
      (stream) =>
        stream.status === LockupStatus.CANCELED ||
        stream.status === LockupStatus.DEPLETED_CANCELED,
    )
  ) {
    return policy.stream.setCanceled(t);
  }

  if (
    streams.some(
      (stream) =>
        stream.status === LockupStatus.SETTLED ||
        stream.status === LockupStatus.DEPLETED_SETTLED,
    )
  ) {
    return policy.stream.setEnded(t);
  }

  return undefined;
}

interface LockupsWithdrawableProps {
  actor: IActor;
  t: Translate;
  chainId: number | undefined;
  owner: string | undefined;
  streams: Lockup[];
  isHostSafe: boolean;
}

export function validateLockupsWithdrawable({
  actor,
  t,
  chainId,
  owner,
  streams,
  isHostSafe,
}: LockupsWithdrawableProps) {
  if (
    _.isNilOrEmptyString(owner) ||
    _.isNilOrEmptyString(chainId) ||
    _.isNil(streams) ||
    !streams.length
  ) {
    return policy.stream.setMisconfigured(t);
  }

  /** All streams are on the same chain */
  if (streams.some((stream) => stream.chainId !== chainId)) {
    return policy.stream.setUnchained(t);
  }

  /** All streams are still active - depleted ones are already fully withdrawn */
  if (streams.some((stream) => stream.isDepleted)) {
    return policy.stream.someDepleted(t);
  }

  /**
   * For V20 and V21 streams, only known actors are permitted to withdraw.
   */

  if (
    streams.some(
      (stream) =>
        stream.version === LockupVersion.V20 ||
        stream.version === LockupVersion.V21,
    )
  ) {
    /* Perform a superficial check that the owner/user is at least one of these permitted entities */
    if (
      streams.some(
        (stream) =>
          ![stream.sender, stream.proxender, stream.recipient]
            .filter((address) => !_.isNilOrEmptyString(address))
            .includes(owner),
      )
    ) {
      return policy.stream.setPermission(t);
    }

    if (
      !isHostSafe &&
      (actor === "sender-proxy" || actor === "sender-native") &&
      streams.some(
        (stream) => _.toAddress(stream.sender) !== _.toAddress(owner),
      )
    ) {
      return policy.stream.sameCategory(t);
    }

    if (
      !isHostSafe &&
      (actor === "sender-proxy" || actor === "sender-native") &&
      streams.some(
        (stream) =>
          _.toAddress(stream.recipient) !== _.toAddress(streams[0].recipient),
      )
    ) {
      return policy.stream.sameRecipient(t);
    }

    if (
      !isHostSafe &&
      actor === "recipient" &&
      streams.some(
        (stream) => _.toAddress(stream.recipient) !== _.toAddress(owner),
      )
    ) {
      return policy.stream.sameCategory(t);
    }
  } else {
    /** Streams after LockupVersion.V22 enabled public withdrawals */
  }

  if (
    streams.some(
      (stream) =>
        stream.status === LockupStatus.DEPLETED_CANCELED ||
        stream.status === LockupStatus.DEPLETED_SETTLED ||
        stream.status === LockupStatus.PENDING,
    )
  ) {
    return policy.stream.setUnavailable(t);
  }

  if (
    streams.some(
      (stream) =>
        _.isNil(stream.withdrawableAmount) ||
        stream.withdrawableAmount.humanized.isZero(),
    )
  ) {
    return policy.stream.onlyNonZeroWithdrawals(t);
  }

  if (streams.some((stream) => stream.isCliffing)) {
    return policy.stream.setUnavailable(t);
  }

  if (!isHostSafe) {
    /** This will automatically help differentiate between lockup categories and versions */
    const { address: anchor } = Lockup.doIdentify(streams[0].id, []);
    if (streams.some((stream) => anchor !== _.toAddress(stream.contract))) {
      return policy.stream.setSource(t);
    }
  }

  return undefined;
}
