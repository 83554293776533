import { REQUEST_ID, requests } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards } from "@sablier/v2-machines/guards";
import { _ } from "@sablier/v2-mixins";
import { ISigner, IWagmiConfig } from "@sablier/v2-types";
import { vendors } from "@sablier/v2-utils";
import { QueryClient } from "@tanstack/react-query";
import type { useMachineInstantiator } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { IForm } from "~/client/contexts/Form/Airstream/Edit";
import type { useModalAirstreamEdit } from "~/client/hooks";

export interface Payload {
  signer: ISigner | undefined;
  chainId: number;
  address: string;
  lastEdit: string;
  fields: IForm;
  library: IWagmiConfig | undefined;
  api: {
    t: Translate;
    updateData: ReturnType<typeof useModalAirstreamEdit>["updateData"];
    onSuccess: (campaign: { address: string; chainId: number }) => void;
  };
}

type Machine = Parameters<typeof useMachineInstantiator<Payload>>;

type onCreate = Parameters<Machine["0"]["onCreate"]>["0"];

async function _onCheckForm({ context }: onCreate): Promise<void> {
  const { ...fields } = context.payload.fields;
  const { t } = context.payload.api;

  const flags = guards.validateFormFlags({
    t,
    isLoadingIncluded: true,
    isWarningIncluded: true,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    throw new Error(flags);
  }
}

export async function onCreate({ context }: onCreate): Promise<void> {
  const { chainId, address, library, lastEdit, fields, signer, api } =
    context.payload;
  const hide = fields.hide.value!;
  const geoblock = fields.geoblock.value!;
  const eligibilityLink = fields.eligibilityLink.value;
  await _onCheckForm({ context });

  try {
    api.updateData({
      status: "pending",
    });

    const signature = await framework.signMessage(library!, {
      account: signer?.account,
      message: lastEdit,
    });

    await new QueryClient().fetchQuery({
      queryKey: [
        ...REQUEST_ID.maskMerkleUpdate,
        { unique: [chainId, address] },
      ],
      queryFn: async () =>
        requests.sablierUpdateCampaign({
          query: { chainId, address },
          form: { hide, geoblock: geoblock ?? [], signature, eligibilityLink },
        }),
    });

    api.updateData({
      status: "success",
    });
    api.onSuccess({ address, chainId });
  } catch (error) {
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      error: error as unknown as Error,
    });

    throw error;
  }
}
