import {
  BACKWEIGHTED_YEARS_MAX,
  BACKWEIGHTED_YEARS_MIN,
  EXPONENT_DECIMALS,
  LockupCategory,
} from "@sablier/v2-constants";
import { guards, policy } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Lockup, Segment, Token, Tranche } from "@sablier/v2-models";
import type { ITranchedBackweighted } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { UNLOCK_DURATION } from "./config";

type IExtension = ITranchedBackweighted;

export function certify({
  t,
  data,
  field,
}: IExtensionCertify): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  if (field === "years") {
    return guards.validateAmount({
      t,
      context: "steps",
      max: (BACKWEIGHTED_YEARS_MAX + 1).toString() /** Strict < */,
      value: fields.years.value,
      min: (BACKWEIGHTED_YEARS_MIN - 1).toString() /** Strict > */,
    });
  }

  return undefined;
}

export function check({
  t,
  data,
  isLoadingIncluded = false,
  isWarningIncluded = false,
}: IExtensionCheck): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  const flags = guards.validateFormFlags({
    t,
    isWarningIncluded,
    isLoadingIncluded,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    return flags;
  }

  const ids: (keyof typeof fields)[] = ["years"];

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: { ...fields },
  });

  if (!_.isNilOrEmptyString(required)) {
    return required;
  }

  let unlocksWarning = undefined;
  fields.unlocks.value.forEach((unlock) => {
    if (
      !_.isNilOrEmptyString(unlock.warning) ||
      _.isNilOrEmptyString(unlock.percentage)
    ) {
      unlocksWarning = policy.form.values(t);
    }
  });
  if (unlocksWarning) {
    return unlocksWarning;
  }

  const percentageSum = fields.unlocks.value.reduce(
    (prev, curr) => prev.plus(curr.percentage || "0"),
    new BigNumber(0),
  );

  // The sum of all the percentage unlocks should equal to 100
  if (!percentageSum.isEqualTo(100)) {
    return policy.percentage.inaccurate(t, "100");
  }

  return undefined;
}

export function identify(stream: Lockup): boolean {
  if (
    stream.category === LockupCategory.LOCKUP_TRANCHED &&
    stream.segments.length
  ) {
    let areAmountsEqual = true;
    const initialSegmentAmount = new BigNumber(stream.segments[1].amount.raw);

    if (stream.segments.length % 2 === 0) {
      stream.segments.forEach((segment, index) => {
        if (
          index % 2 == 1 &&
          areAmountsEqual &&
          !new BigNumber(segment.amount.raw).isEqualTo(initialSegmentAmount)
        ) {
          areAmountsEqual = false;
        }
        if (index % 2 == 1) {
          if (
            !new BigNumber(segment.duration).isLessThanOrEqualTo(
              new BigNumber(1000),
            )
          ) {
            return false;
          }
        } else if (!segment.amount.raw.isZero()) {
          return false;
        }
      });
      return !areAmountsEqual;
    }
  }
  return false;
}

export function precomputeSingle({
  dependencies,
  extras,
}: IPrecomputeParams<"single">): IPrecomputeResult {
  const { amount, token } = dependencies;
  const { purpose: _purpose, ...extended } = extras as IExtension;
  const unlocks = extended.unlocks.value;
  const raw = _.toValuePrepared({
    humanized: amount,
    decimals: token!.decimals,
  });
  const trancheAmounts = unlocks.map((unlock) =>
    _.toValuePrepared({
      raw: new BigNumber(raw)
        .multipliedBy(new BigNumber(unlock.percentage ?? "0"))
        .dividedBy(new BigNumber(100)),
      decimals: token!.decimals,
    }),
  );
  return {
    amount: _.toValuePrepared({
      raw: trancheAmounts.reduce((accumulator, currentValue) => {
        return accumulator.plus(new BigNumber(currentValue));
      }, new BigNumber(0)),
      decimals: token!.decimals,
    }),
  };
}

export function precomputeGroup({
  dependencies,
}: IPrecomputeParams<"group">): IPrecomputeResult {
  const { streams, token, signer } = dependencies;

  return {
    amount:
      streams
        ?.reduce((p, c) => {
          const { amount } = precomputeSingle({
            dependencies: {
              amount: c.amount,
              token,
              signer,
            },
            extras: c.extension,
          });
          return p.plus(new BigNumber(amount));
        }, new BigNumber(0))
        .toString() ?? "0",
  };
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Lockup;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Lockup;
export function simulate(params: IExtensionParamsSimulate): Lockup;

export function simulate({
  dependencies,
  extras,
}: IExtensionParamsSimulate): Lockup {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose, ...extended } = extras as IExtension;
  const { amount, cancelability, transferability, chainId, end, start, token } =
    dependencies;

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const startTime = _.toSeconds(start);
  const endTime = _.toSeconds(end);

  const unlock = new BigNumber(UNLOCK_DURATION).dividedBy(1000);
  const years = new BigNumber(extended.years.value || 0);

  const unlocks = extended.unlocks.value;

  const N = [...Array(years.toNumber()).keys()];
  const N2 = [...Array(years.toNumber() * 2).keys()];
  const segmentExponents = N.map(() => [
    _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
    _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
  ]).flat();

  const segmentAmounts = unlocks
    .map((unlock) => [
      new BigNumber(0),
      new BigNumber(
        new BigNumber(deposit).multipliedBy(
          new BigNumber(unlock.percentage ?? "0").dividedBy(new BigNumber(100)),
        ),
      ),
    ])
    .flat();

  const segmentStartTime = N.map((i) => {
    const firstMilestone = _.addCalendarUnit(start!, i.toString(), "year");
    const secondMilestone = _.addCalendarUnit(
      start!,
      (i + 1).toString(),
      "year",
    );
    return [
      new BigNumber(_.toSeconds(firstMilestone)),
      new BigNumber(_.toSeconds(secondMilestone)).minus(unlock),
    ];
  }).flat();

  const segmentEndTime = N.map((i) => {
    const milestone = _.addCalendarUnit(start!, (i + 1).toString(), "year");
    return [
      new BigNumber(_.toSeconds(milestone)).minus(unlock),
      new BigNumber(_.toSeconds(milestone)),
    ];
  }).flat();

  let percentageSum = new BigNumber(0);
  const segmentStartAmount = N.map((i) => {
    const amount = new BigNumber(deposit).multipliedBy(
      new BigNumber(percentageSum).dividedBy(new BigNumber(100)),
    );
    percentageSum = percentageSum.plus(
      new BigNumber(unlocks[i].percentage ?? "0"),
    );
    return [amount, amount];
  }).flat();
  percentageSum = new BigNumber(0);
  const segmentEndAmount = N.map((i) => {
    const horizontalAmount = new BigNumber(deposit).multipliedBy(
      new BigNumber(percentageSum).dividedBy(new BigNumber(100)),
    );
    percentageSum = percentageSum.plus(
      new BigNumber(unlocks[i].percentage ?? "0"),
    );
    const verticalAmount = new BigNumber(deposit).multipliedBy(
      new BigNumber(percentageSum).dividedBy(new BigNumber(100)),
    );
    return [horizontalAmount, verticalAmount];
  }).flat();

  const segments = N2.map((i) => ({
    id: String(i),
    position: String(i),
    milestone: "",
    startTime: segmentStartTime[i].toString(),
    startAmount: segmentStartAmount[i].toString(),
    endTime: segmentEndTime[i].toString(),
    endAmount: segmentEndAmount[i].toString(),
    exponent: segmentExponents[i],
    amount: segmentAmounts[i].toString(),
  }));

  return new Lockup(
    {
      ...Lockup.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: LockupCategory.LOCKUP_TRANCHED,
      depositAmount: deposit,
      endTime,
      intactAmount: deposit,
      startTime,
      tranches: N.map(
        (i) =>
          Tranche.fromSegments(
            new Segment(segments[i * 2], streamToken),
            new Segment(segments[i * 2 + 1], streamToken),
          ).payload,
      ),
    },
    streamToken,
  );
}
