import { LockupShapes, links } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type {
  IExtensionConstructorParams,
  IExtensionHeaders,
} from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import { Extension } from "../../system/setup";

export interface IDynamicUnlockLinear {
  purpose: Extension;
  unlock: {
    id: string;
    value?: string;
    warning?: string;
  };
}

export function initial({
  unlock,
}: IExtensionConstructorParams): IDynamicUnlockLinear {
  return {
    purpose: Extension.DYNAMIC_UNLOCK_LINEAR,
    unlock: {
      id: "unlock",
      value: unlock,
      warning: undefined,
    },
  };
}

export function headers(
  _isDuration: boolean,
): IExtensionHeaders<keyof IDynamicUnlockLinear> {
  return [{ header: "unlock", type: "amount" }];
}

export function warnings(extension: unknown): string[] {
  const fields = extension as IDynamicUnlockLinear;
  const warnings: string[] = [];
  if (!_.isNilOrEmptyString(fields.unlock.warning)) {
    warnings.push(`Unlock amount: ${fields.unlock.warning}`);
  }
  return warnings;
}

export const UNLOCK_DURATION: IMilliseconds = "1000";
export const EXPECTED_SEGMENTS = 2;

export const shape = LockupShapes.dynamicUnlockLinear.id;
export const template = links.streamTemplate.dynamicUnlockLinear;
