import { useMemo } from "react";
import { QUERY_CACHE_TIME, REQUEST_ID } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { _ } from "@sablier/v2-mixins";
import { useQuery } from "@tanstack/react-query";
import { useConfig } from "wagmi";
import type { Output } from "@sablier/v2-contracts";
import type { IAddress, IWagmiAddress, IWagmiConfig } from "@sablier/v2-types";

interface QueryProps {
  contract: IAddress | undefined;
  chainId: number | undefined;
  library: IWagmiConfig;
}

export async function request({
  library,
  contract,
  chainId,
}: QueryProps): Promise<IWagmiAddress[]> {
  try {
    if (_.isNil(contract) || _.isNilOrEmptyString(chainId)) {
      throw new Error("Missing contract data.");
    }

    const query = framework.contextualize(
      contract!,
      chainId,
      "multisig",
      "getOwners",
      [],
    );

    const previews = await framework.preview({ queries: [query] });
    const results = await framework.read(library, { previews });

    const output = results[0].result as Output<"multisig", "getOwners">;

    return [...output];
  } catch (error) {
    return [];
  }
}

interface Props {
  contract: string | undefined;
  chainId: number | undefined;
  isEnabled: boolean;
}

export default function useRequestMultisigOwners({
  contract,
  chainId,
  isEnabled: _isEnabled,
}: Props) {
  const library = useConfig();

  const isEnabled = useMemo(
    () =>
      !_.isNilOrEmptyString(contract) &&
      !_.isNilOrEmptyString(chainId) &&
      _isEnabled,
    [contract, chainId, _isEnabled],
  );

  const {
    data: value,
    error,
    isLoading,
  } = useQuery<IWagmiAddress[]>({
    queryKey: [...REQUEST_ID.multisigOwners, { unique: [contract, chainId] }],
    queryFn: async () => request({ library, contract, chainId }),
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    retry: false,
    enabled: isEnabled,
  });

  return useMemo(
    () => ({
      error,
      isLoading,
      data: value,
      request,
    }),
    [error, isLoading, value],
  );
}
