import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import {
  DEFAULT_RESET_SLEEP,
  RATE_LIMITED_EXCEPTION,
  REQUEST_ID,
  requests,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Airstream, type IAirstream, Lockup } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { pages, tabs } from "~/client/constants";
import { useAirstreamCurrentDetails } from "~/client/hooks";
import * as geolocation from "~/client/hooks/useRequestGeolocation";
import { contractor_lockup } from "~/client/utils";
import type { Output } from "@sablier/v2-contracts";
import type { useMachineFacilitator } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type {
  IEligibility,
  ISigner,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import type { QueryClient } from "@tanstack/react-query";
import type { IForm } from "~/client/contexts/Form/Airstream/Eligibility";
import type { useModalTransaction } from "~/client/hooks/modals";
import { isHostSafe } from "../../contexts/Web3";
import helper from "../helper";
import { airstreamEligibility as wording } from "../helper/wording";

export interface Check {
  fields: IForm;
  library: IWagmiConfig | undefined;
  airstream: IAirstream;
  client: QueryClient;
  api: {
    t: Translate;
  };
}

export interface Create extends Check {
  fields: IForm;
  library: IWagmiConfig | undefined;
  signer: ISigner | undefined;
  airstream: IAirstream;
  details: ReturnType<typeof useAirstreamCurrentDetails>["details"];
  api: {
    reset: () => void;
    t: Translate;
    setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
    updateData: ReturnType<typeof useModalTransaction>["updateData"];
  };
}

export interface Transitive {
  amount?: string;
  index?: string;
  proof?: string[];
  isRateLimited?: boolean;
}

type Machine = Parameters<
  typeof useMachineFacilitator<Check, Create, Transitive>
>;

type onCreate = Parameters<Machine["0"]["onCreate"]>["0"];
type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];

async function _onCheckForm({ event }: Pick<onCheck, "event">): Promise<void> {
  const { ...fields } = event.payload.fields;
  const { t } = event.payload.api;

  const flags = guards.validateFormFlags({
    t,
    isLoadingIncluded: true,
    isWarningIncluded: true,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    throw new Error(flags);
  }

  const required = guards.validateFormRequired({
    t,
    required: ["address"],
    value: fields,
  });

  if (!_.isNilOrEmptyString(required)) {
    throw new Error(required);
  }
}

export async function onCheck({ context: _context, event }: onCheck): Promise<{
  status: IEligibility;
  transitive: Transitive | undefined;
}> {
  const { airstream, client, library } = event.payload;
  const { t } = event.payload.api;
  const { ...fields } = event.payload.fields;
  await _onCheckForm({ event });

  if (event.payload.soft) {
    return {
      status: "idle",
      transitive: undefined,
    };
  }

  const recipient = fields.address.resolution?.address;
  const contract = airstream.address;
  const chainId = airstream.chainId;

  try {
    if (_.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    /**
     * Ping the server: is this user eligible to claim?
     */
    const eligibility = await client.fetchQuery({
      queryKey: [
        ...REQUEST_ID.maskMerkleEligible,
        { unique: [airstream.ipfsCID, fields.address.value] },
      ],
      queryFn: async () =>
        requests.sablierServiceMerkleEligibility({
          query: {
            cid: airstream.ipfsCID,
            address: recipient as IWagmiAddress,
          },
        }),
    });

    /**
     * A non-eligible result will cause a throw.
     * Now, let's check if they've already claimed.
     */

    const query = framework.contextualize(
      contract,
      chainId,
      Airstream.findPurpose(airstream.category),
      "hasClaimed",
      [_.toBigInt(eligibility.index)],
    );

    const previews = await framework.preview({ queries: [query] });
    const results = await framework.read(library, { previews });
    const isClaimed = results[0].result as Output<
      "merkleLL" | "merkleLT",
      "hasClaimed"
    >;

    if (isClaimed) {
      return { status: "expired", transitive: undefined };
    }

    return {
      status: "true",
      transitive: {
        amount: eligibility.amount,
        index: eligibility.index.toString(),
        proof: eligibility.proof,
      },
    };
  } catch (error) {
    if (error instanceof Error && error.message === RATE_LIMITED_EXCEPTION) {
      return {
        status: "false",
        transitive: {
          isRateLimited: true,
        },
      };
    }
    return {
      status: "false",
      transitive: undefined,
    };
  }
}
/**
 *  Machine state that actually triggers the transaction.
 *  It relies on transient data prepared by the onCheck service (through the "check" state).
 */

export async function onCreate({ context, event }: onCreate): Promise<void> {
  const { status, transitive } = context;
  const { api, details, fields, airstream, library, signer } = event.payload;
  const { t } = api;
  let query = undefined;
  try {
    if (
      status !== "true" ||
      _.isNil(transitive?.proof) ||
      transitive?.proof?.length === 0
    ) {
      throw new Error(policy.transitive.payload(t));
    }

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    if (await geolocation.includes(details?.geoblock || [])) {
      throw new Error(policy.geolocation.blocked(t));
    }

    const contract = airstream.address;
    const chainId = signer.chain!.id;
    const recipient = fields.address.resolution?.address;
    const { amount, index, proof } = transitive || {};

    const preview = wording.prepare(
      airstream.token,
      new BigNumber(amount || "0"),
    );

    query = framework.contextualize(
      contract,
      chainId,
      Airstream.findPurpose(airstream.category),
      "claim",
      [
        _.toBigInt(index),
        recipient as IWagmiAddress,
        _.toBigInt(amount),
        proof as IWagmiAddress[],
      ],
    );

    api.setOpen(true, {
      status: "verify",
      title: wording.title(t),
      description: wording.confirm(t).description,
      isNotClosable: true,
    });

    const prepared = await helper.configure(library, {
      chainId,
      query,
      signer,
    });

    console.info("%c[pre-transaction]", "color: mediumslateblue", {
      query,
      prepared,
    });

    const transaction = await framework.write(library, { prepared });

    api.updateData({
      status: "pending",
      description: wording.send(
        t,
        false,
        _.toShortAddress(recipient),
        preview.amount,
        _.toDuration(airstream.streamTotalDuration, "time-full")[0],
      ).description,
      hash: !isHostSafe ? transaction : undefined,
      isNotClosable: false,
    });

    const receipt = isHostSafe
      ? await framework.safeWait(library, { hash: transaction })
      : await framework.wait(library, {
          hash: transaction,
          onReplaced: (replaced) => {
            api.updateData({
              hash: replaced.transaction.hash,
            });
          },
        });

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }

    const lockup = contractor_lockup(chainId, airstream.category);

    const tokenId = await helper.extractStreamId({
      receipt,
      purpose: airstream.category,
    });

    const alias = tokenId
      ? Lockup.doGenerateAlias(lockup.alias, chainId, tokenId)
      : "unknown";

    await _.sleep(DEFAULT_RESET_SLEEP);

    api.updateData({
      status: "success",
      description: wording.success(
        t,
        _.toShortAddress(recipient),
        preview.amount,
        _.toDuration(airstream.streamTotalDuration, "time-full")[0],
      ).description,
      footer: [
        {
          to: pages.vesting.profile.builder(alias),
          title: _.startCase(t("structs.viewStream")),
        },
        {
          to: tabs.vesting.sender.builder(),
          title: _.capitalize(t("words.dashboard")),
          right: ArrowUpCircleIcon,
        },
      ],
      hash: receipt.transactionHash,
      isNotClosable: false,
    });

    api.reset();
  } catch (error) {
    void helper.debug(
      {
        query,
        signer,
      },
      vendors.crash.log(error),
    );

    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: policy.error.message(t, error),
        data: error,
      },
      isNotClosable: false,
    });

    throw error;
  }
}
