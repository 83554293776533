import { useMemo } from "react";
import { FlowCategory, REQUEST_ID } from "@sablier/v2-constants";
import { useRequestFlowOnchain } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Flow } from "@sablier/v2-models";
import { contracts } from "~/client/constants";
import useFlowCurrent from "./useFlowCurrent";

export default function useFlowCurrentOnchain() {
  const { t } = useT();
  const { id, isLoading, isMissing } = useFlowCurrent();

  const { address, chainId, tokenId } = useMemo(() => {
    const { source: address, chainId, tokenId } = Flow.doSplitIdentifier(id);
    return {
      address,
      chainId,
      tokenId,
    };
  }, [id]);

  const isEnabled = useMemo(
    () => !_.isNilOrEmptyString(id) && isMissing,
    [id, isMissing],
  );

  const title = useMemo(() => {
    if (!_.isNilOrEmptyString(id)) {
      const aliases = Flow.findAliases(chainId, contracts);
      const { withAlias, withAddress } = Flow.doIdentify(id, aliases);

      if (
        !(_.isNilOrEmptyString(withAddress) && _.isNilOrEmptyString(withAlias))
      ) {
        return `${_.capitalize(t("words.stream"))} ${_.toUpper(
          withAlias || withAddress,
        )}`;
      }
    }
    return _.capitalize(t("words.stream"));
  }, [chainId, id, t]);

  const {
    data,
    error,
    isLoading: isLoadingOnchain,
    isMissing: isMissingOnchain,
  } = useRequestFlowOnchain({
    key: REQUEST_ID.flowOnchain,
    id,
    purpose: FlowCategory.FLOW,
    isEnabled,
  });

  return {
    address,
    chainId,
    data,
    error,
    isLoading: isLoading || isLoadingOnchain,
    isMissing: isMissingOnchain,
    title,
    tokenId,
  };
}
