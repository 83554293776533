import { QUERY_CACHE_TIME, REQUEST_ID, requests } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { useQuery } from "@tanstack/react-query";
import type { IAddress, ResultCampaignDetails } from "@sablier/v2-types";

interface DetailsProps {
  address: IAddress | undefined;
  chainId: number | undefined;
  isEnabled: boolean;
}

export default function useRequestAirstreamSettings({
  address,
  chainId,
  isEnabled,
}: DetailsProps) {
  const { error, data, isLoading } = useQuery<ResultCampaignDetails | null>({
    queryKey: [...REQUEST_ID.airstreamDetails, { unique: [chainId, address] }],
    queryFn: async () =>
      requests.campaignDetails({
        query: { address: address!, chainId: chainId! },
      }),
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    retry: false,
    enabled:
      !_.isNilOrEmptyString(address) &&
      !_.isNilOrEmptyString(chainId) &&
      isEnabled,
  });

  return { error, data, isLoading };
}
