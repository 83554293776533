import { Fragment } from "react";
import styled from "styled-components";
import type { Props as SideProps } from "../Side";
import Side from "../Side";

interface Props {
  items: SideProps[];
  on: "center" | "left" | "right";
}

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}

  &[data-on="left"], &[data-on="center"] {
    padding-left: ${(props) => props.theme.sizes.inputEdge};
  }

  &[data-on="right"],
  &[data-on="center"] {
    padding-right: ${(props) => props.theme.sizes.inputEdge};
  }
`;
const Divider = styled.div`
  width: 2px;
  height: 28px;
  margin: 0 calc(${(props) => props.theme.sizes.inputEdge} / 2);
  background-color: ${(props) => props.theme.colors.dark200};
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    &[data-on="left"],
    &[data-on="center"] {
      padding-left: ${(props) => props.theme.sizes.inputEdgeSmall};
    }

    &[data-on="right"],
    &[data-on="center"] {
      padding-right: ${(props) => props.theme.sizes.inputEdgeSmall};
    }

    ${Divider} {
      margin: 0 4px;
    }
  }
`;

function Sides({ items, on }: Props) {
  return (
    <Wrapper data-component={"sides"} data-on={on}>
      {items.map((item, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <Divider />}
            <Side {...item} />
          </Fragment>
        );
      })}
    </Wrapper>
  );
}

export default Sides;
