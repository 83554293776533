import { useCallback, useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import type { IExtensionDependencies } from "../types";
import type { IAddress, IExpected } from "@sablier/v2-types";
import {
  useExpectedStoreItem,
  useExpectedStoreList,
} from "./store/useExpectedStore";
import useAccount from "./useAccount";

type Dependencies<T extends "group" | "single" = "single"> =
  IExtensionDependencies<T> & {
    sender: IAddress;
  };

function useExpected() {
  const { address, chainId, isConfigured } = useAccount();
  const { list, set } = useExpectedStoreList();
  const { add, remove, update } = useExpectedStoreItem();

  const track = useCallback(
    function track<T extends "group" | "single" = "single">(
      hash: string,
      name: string,
      dependencies: Dependencies<T>,
      prepare = false,
    ) {
      const id = `${hash.toLowerCase()}-${dependencies.chainId}`;
      const expected = {
        id,
        chainId: dependencies.chainId,
        createdOn: Date.now().toString(),
        hash,
        sender: dependencies.sender,
        status: "pending",
        stream: {
          name,
        },
      } satisfies IExpected;

      if (!prepare) {
        add(expected);
      }
      return id;
    },
    [add],
  );

  const untrack = useCallback(
    (id: string) => {
      remove(id);
    },
    [remove],
  );

  const reset = useCallback(() => {
    set([]);
  }, [set]);

  const configured = useMemo(() => {
    if (!isConfigured) {
      return [];
    }

    return list.filter(
      (item) =>
        item.chainId === chainId &&
        _.toAddress(item.sender) === _.toAddress(address),
    );
  }, [address, chainId, isConfigured, list]);

  return {
    configured,
    list,
    add,
    remove,
    reset,
    track,
    untrack,
    update,
  };
}

export default useExpected;
