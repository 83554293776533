import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { tabs } from "~/client/constants";
import useFlowDashboardQuery from "./useFlowDashboardQuery";

export default function useFlowDashboardTab() {
  const { identifier, isReady } = useFlowDashboardQuery();

  const tab = useMemo(() => {
    return Object.values(tabs.vesting).find(
      (tab) => tab.identifier === identifier,
    );
  }, [identifier]);

  return {
    identifier,
    isReady,
    tab,
  };
}
