import { _ } from "@sablier/v2-mixins";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { ISearchFlow } from "@sablier/v2-models";
import type { IStoreFlow } from "~/client/types";
import { STORE_ID } from "../constants";

const initial: Omit<IStoreFlow, "api"> = {
  owned: undefined,
  preview: undefined,
  search: undefined,
};

const useStore = create<IStoreFlow>()(
  devtools(
    (set) => ({
      ...initial,
      api: {
        setSearch: (search: ISearchFlow | undefined) =>
          set((_prev) => {
            return { search };
          }),
        setPreview: (search: ISearchFlow | undefined) =>
          set((_prev) => {
            return { preview: search };
          }),
        setOwned: (search: ISearchFlow | undefined) =>
          set((_prev) => {
            return { owned: search };
          }),
        reset: (omit?: (keyof IStoreFlow)[]) =>
          set(() => _.omit(initial, omit || [])),
      },
    }),
    {
      name: STORE_ID.flow,
      store: STORE_ID.flow,
    },
  ),
);

export default useStore;
